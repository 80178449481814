import React, { useEffect, useState } from "react";
import { BiSend } from "react-icons/bi";
import { TbPlayerEjectFilled } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import {
  getInfoFromLezhnaTbaleAction,
  getReciveMediaByVisitAction,
  rejectMediaReciveAction,
  sendMediaAction,
  storeAllInfoPatientAction,
} from "../../../Action/Media/sendMediaAction";
import { getDoctorAction } from "../../../Action/Doctor/doctorAction";
import Logo from "../../../Image/Logo.jpg";
import Swal from "sweetalert2";
const SendMedia = ({ sectionId, lezhna_id, locationId, visitId }) => {
  const [radioValue, setRadioValue] = useState("");
  const dispatch = useDispatch();
  const getInfoFromLezhnaTbale = useSelector(
    (state) => state.getInfoFromLezhnaTbale
  );

  const getReciveMediaByVisit = useSelector(
    (state) => state.getReciveMediaByVisit
  );
  const { receiveByVisit } = getReciveMediaByVisit;
  useEffect(() => {
    if (radioValue == "coustm") {
      console.log(getInfoFromLezhnaTbale);
      dispatch(getReciveMediaByVisitAction(visitId));
    } else {
      return;
    }
  }, [radioValue]);

  const getDoctor = useSelector((state) => state.getDoctor);
  const { doctors } = getDoctor;
  const { datas, loading } = getInfoFromLezhnaTbale;

  let doctorArray = [];

  doctors.map((doctor) => {
    doctorArray.push({
      value: doctor.dr_name,
      label: doctor.dr_name,
    });
  });

  const handelRadioInputChange = (event, value) => {
    // const { value } = event.target;
    setRadioValue(value);
  };
  useEffect(() => {
    dispatch(getDoctorAction());

    dispatch(getInfoFromLezhnaTbaleAction(lezhna_id));
  }, [visitId, lezhna_id]);

  const [inputValue, setInputValue] = useState({
    id: "",
    pName: "",
    doctor: "",
    typeSurgury: "",
    timeSurgery: "",
    hospitalName: "",
  });
  useEffect(() => {
    if (receiveByVisit == undefined || receiveByVisit.length == 0) {
      return;
    }
    receiveByVisit.map((recive) => {
      setInputValue({
        ...inputValue,
        id: recive.id,
        timeSurgery: recive.timeOfSurgery,
        hospitalName: recive.hospName,
      });
    });
  }, [radioValue, receiveByVisit]);
  const [doctor, setDoctor] = useState([]);
  const [opens, setOpens] = useState(false);
  const toggleOptionDoctor = (value) => {
    if (doctor.includes(value)) {
      setDoctor(doctor.filter((opt) => opt !== value));
    } else {
      setDoctor([...doctor, value]);
    }
  };
  useEffect(() => {
    if (
      lezhna_id == null ||
      lezhna_id == undefined ||
      lezhna_id == "" ||
      loading == true
    ) {
      return;
    }
    // console.log("daataaa : ",datas)
    datas.map((data) => {
      if (doctor.length < data.commite.Doctor.length) {
        setDoctor(...doctor, data.commite.Doctor);
      }
      setInputValue({
        ...inputValue,
        pName: data.commite.patients.Pname,
        doctor: data.commite.Doctor,
        typeSurgury: data.commite.TYP,
      });
      // setArrayTypeSurgery(...arrayTypeSurgery, ...data.commite.TYP);
    });
  }, [lezhna_id, visitId, datas]);
  const [error, setError] = useState({
    doctor: "",
    hospital: "",
    timeOfSurgery: "",
  });
  const handelSend = (event) => {
    event.preventDefault();

    if (radioValue == "all") {
      dispatch(storeAllInfoPatientAction(lezhna_id, 6));
    }

    if (locationId == null || locationId == undefined) {
      Swal.fire({
        icon: "error",
        timer: 1500,
        title: "سەرکەوتوو نەبوو",
        text: "تکایە لقێک هەڵبژێرە بۆ ناردن",
      });
      return;
    }

    if (inputValue.doctor == "" || inputValue.doctor == null) {
      setError({
        ...error,
        doctor: "تکایە خانەکە پڕکەرەوە",
        hospital: "",
        timeOfSurgery: "",
      });
      return;
    }
    if (inputValue.hospitalName == "" || inputValue.hospitalName == null) {
      setError({
        ...error,
        doctor: "",
        hospital: "تکایە خانەکە پڕکەرەوە",
        timeOfSurgery: "",
      });
      return;
    }
    if (inputValue.timeSurgery == "" || inputValue.timeSurgery == null) {
      setError({
        ...error,
        doctor: "",
        hospital: "",
        timeOfSurgery: "تکایە خانەکە پڕکەرەوە",
      });
      return;
    }

    if (radioValue == "coustm") {
      dispatch(
        sendMediaAction(
          inputValue.id,
          locationId,
          6,
          inputValue.pName,
          doctor,
          inputValue.typeSurgury,
          inputValue.hospitalName,
          inputValue.timeSurgery,
          visitId
        )
      );
      setRadioValue("");
    }
  };
  const handleReject = (event) => {
    event.preventDefault();
    // console.log(inputValue.id);
    dispatch(rejectMediaReciveAction(inputValue.id));
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  };
  return (
    <>
      {loading ||
      datas == undefined ||
      doctors == undefined ||
      receiveByVisit == undefined ? (
        <div className="flex flex-col my-5">
          <img src={Logo} alt="Logo" className="w-56 m-auto animate-pulse  " />
          <div className="text-2xl text-center font-bold  ">
            <div className="flex justify-center gap-4">
              <p className="animate-charcter">Please Wait</p>
              <div class="container2">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>{" "}
            </div>
          </div>
        </div>
      ) : (
        <div>
          <form action="">
            <div className="flex gap-4 pt-4">
              <div class="flex items-center w-full pl-4 border border-gray-200 rounded dark:border-gray-700">
                <input
                  id="bordered-radio-1"
                  type="radio"
                  value={radioValue}
                  onChange={(event) => handelRadioInputChange(event, "coustm")}
                  name="bordered-radio"
                  class="w-4 h-4 text-purple-500 bg-gray-100 "
                />
                <label
                  for="bordered-radio-1"
                  class="w-full py-4 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Coustm Information
                </label>
              </div>
              <div class="flex items-center w-full pl-4 border border-gray-200 rounded dark:border-gray-700">
                <input
                  id="bordered-radio-2"
                  type="radio"
                  value={radioValue}
                  onChange={(event) => handelRadioInputChange(event, "all")}
                  name="bordered-radio"
                  class="w-4 h-4 text-purple-500 bg-gray-100 "
                />
                <label
                  for="bordered-radio-2"
                  class="w-full py-4 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  All Information
                </label>
              </div>
            </div>
            {radioValue === "all" && (
              <div className="mt-3">
                <button
                  onClick={handelSend}
                  className="relative hover:text-white duration-100 inline-flex items-center justify-center p-0.5  mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500  focus:ring-4 focus:outline-none focus:ring-blue-300 "
                >
                  <span className="relative px-5 py-2.5 transition-all duration-300 ease-in  bg-white  rounded-md group-hover:bg-opacity-0">
                    <div className="flex items-center gap-3">
                      <div>
                        <span>send</span>
                      </div>
                      <p>
                        <BiSend />
                      </p>
                    </div>
                  </span>
                </button>
              </div>
            )}
            {radioValue === "coustm" && (
              <div className="grid grid-cols-2 gap-4">
                <div className="w-full ">
              <div className="flex justify-end ">
                <label htmlFor="doctorSend" className="lblDesign">
                  {" "}
                  * دکتۆر
                </label>
              </div>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setOpens(!opens);
                }} // Updated variable name
                className="  border-2 shadow-md rounded-md bg-white  py-2 w-full px-4 text-left"
              >
                {doctor.length > 0 ? doctor.join(" , ") : "Select options..."}
              </button>

              {opens && (
                <div className="optionMultiple h-72 overflow-y-auto">
                  {doctorArray.map((opt) => (
                    <label
                      key={opt.label}
                      className="flex items-center px-4 py-2"
                    >
                      <input
                        type="checkbox"
                        value={opt.value}
                        checked={doctor.includes(opt.label)}
                        onChange={() => {
                          toggleOptionDoctor(opt.label);
                          // toggleOption(opt.label);
                          // setTypeSurgeryId(opt.value);
                        }}
                        className="mr-2"
                      />
                      {opt.label}
                    </label>
                  ))}
                </div>
              )}
            </div>

                {/* end input */}
                <div className="mb-6 mt-1.5">
                  <label
                    htmlFor="default-input"
                    className="block mb-2 text-sm font-medium text-gray-900 text-right pr-4"
                  >
                    جۆری عەمەلیات
                  </label>
                  <input
                    type="text"
                    id="default-input"
                    name="typeSurgury"
                    value={inputValue.typeSurgury}
                    placeholder="جۆری عەمەلیات"
                    className="bg-white text-right border focus:outline-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                  />
                </div>

                <div className="mb-6">
                  <label
                    htmlFor="hosp"
                    className="block mb-2 text-sm font-medium text-gray-900 text-right pr-4"
                  >
                    ناوی نەخۆشخانە
                  </label>
                  <input
                    type="text"
                    id="hosp"
                    onChange={handleInputChange}
                    name="hospitalName"
                    value={inputValue.hospitalName}
                    placeholder="  ناوی نەخۆشخانە"
                    className="bg-white text-right border focus:outline-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                  />
                  <p className="text-red-500 text-md text-right mt-1 mr-2">
                    {error.hospital}
                  </p>
                </div>

                <div className="mb-6">
                  <label
                    htmlFor="time"
                    className="block mb-2 text-sm font-medium text-gray-900 text-right pr-4"
                  >
                    کاتی عەمەلیات
                  </label>
                  <input
                    type="date"
                    id="time"
                    onChange={handleInputChange}
                    value={inputValue.timeSurgery}
                    name="timeSurgery"
                    placeholder="کاتی عەمەلیات"
                    className="bg-white text-right border focus:outline-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  "
                  />
                  <p className="text-red-500 text-md text-right mt-1 mr-2">
                    {error.timeOfSurgery}
                  </p>
                </div>
                <div className="flex gap-2">
                  <div>
                    <button
                      onClick={handelSend}
                      className="relative hover:text-white duration-100 inline-flex items-center justify-center p-0.5  mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500  focus:ring-4 focus:outline-none focus:ring-blue-300 "
                    >
                      <span className="relative px-5 py-2.5 transition-all duration-300 ease-in  bg-white  rounded-md group-hover:bg-opacity-0">
                        <div className="flex items-center gap-3">
                          <div>
                            <span>send</span>
                          </div>
                          <p>
                            <BiSend />
                          </p>
                        </div>
                      </span>
                    </button>
                  </div>
                  {inputValue.id == "" ? (
                    ""
                  ) : (
                    <div>
                      <button
                        onClick={handleReject}
                        className="relative hover:text-white duration-100 inline-flex items-center justify-center p-0.5  mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-red-600 to-orange-500 group-hover:from-orange-600 group-hover:to-red-500  focus:ring-4 focus:outline-none focus:ring-blue-300 "
                      >
                        <span className="relative px-5 py-2.5 transition-all duration-300 ease-in  bg-white  rounded-md group-hover:bg-opacity-0">
                          <div className="flex items-center gap-3">
                            <div>
                              <span>Reject</span>
                            </div>
                            <p>
                              <TbPlayerEjectFilled />
                            </p>
                          </div>
                        </span>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </form>
        </div>
      )}
    </>
  );
};

export default SendMedia;
