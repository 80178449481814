import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFeedbackImageAction } from "../../Action/lezhnaAction";
import Logo from "../../Image/Logo.jpg";
const ShowImageFeedback = ({ closeModal, patientId }) => {
  const dispatch = useDispatch();
  const getFeedbackImage = useSelector((state) => state.getFeedbackImage);
  const { loading, feedbackImage } = getFeedbackImage;
  useEffect(() => {
    console.log(patientId);
    dispatch(getFeedbackImageAction(patientId));
  }, []);
  return (
    <>
      {loading ? (
        <div className="flex absolute top-10 left-[42%] flex-col">
          <img
            src={Logo}
            alt="Logo"
            className="w-56 m-auto animate-pulse mt-20 "
          />
          <div className="text-2xl text-center font-bold  ">
            <div className="flex justify-center gap-4">
              <p className="animate-charcter">Please Wait</p>
              <div class="container2">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>{" "}
            </div>
          </div>
        </div>
      ) : (
        <div className="parentModal">
          <div className="overlay" onClick={() => closeModal(false)}></div>
          <div className="childModal">
            <div className="flex justify-between items-center">
              <div
                className="text-red-500 cursor-pointer"
                onClick={() => closeModal(false)}
              >
                <p>X</p>
              </div>
              <div className="">
                <p>Patient </p>
              </div>
            </div>
            <div className="flex items-center justify-center mt-5 flex-wrap gap-10 ">
              {feedbackImage.map((image, index) => {
                return (
                  <div key={index}>
                    <img src={image.image} className="w-96" alt="logog" />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ShowImageFeedback;
