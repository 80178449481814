import React, { useEffect, useState } from "react";
import { FaUserDoctor } from "react-icons/fa6";
// import { BiSolidEditAlt } from "react-icons/bi";
import { FaRegEdit } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import DoctorModal from "../../Modal/DoctorModal/DoctorModal";
import { useDispatch, useSelector } from "react-redux";
import Logo from '../../Image/Logo.jpg'
import {
  getDoctorAction,
  updateDoctorAction,
} from "../../Action/Doctor/doctorAction";

import { PiCheckBold } from "react-icons/pi";

import "./css/doctor.css";

const Doctor = () => {
  const [id, setId] = useState(null);
  const [indexx, setIndex] = useState(null);
  const [name, setName] = useState(null);
  const [status, setStatus] = useState("Active");
  const [isOpenDoctorModal, setIsOpenDoctorModal] = useState(false);
  const dispatch = useDispatch();
  const getDoctor = useSelector((state) => state.getDoctor);
  const { doctors, loading } = getDoctor;
  const [edit, setEdit] = useState(false);
  useEffect(() => {
    dispatch(getDoctorAction());
  }, []);
  const handleUpdate = (id, name, status) => {
    dispatch(updateDoctorAction(id, name, status));
    setEdit(false);
    setId(null);
    setIndex(null);
    dispatch(getDoctorAction());
    dispatch(getDoctorAction());
  };

  const handleChange = (e) => {

    setStatus(e.target.value);
  };
  return (
    <>
      {loading ? (
        <div className="flex flex-col">
          <img
            src={Logo}
            alt="Logo"
            className="w-56 m-auto animate-pulse mt-20 "
          />
          <div className="text-2xl text-center font-bold  ">
            <div className="flex justify-center gap-4">
              <p className="animate-charcter">Please Wait</p>
              <div class="container2">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>{" "}
            </div>
          </div>
        </div>
      ) : (
        <div className="relative bg-white md:h-[18rem] xl:h-[30rem]  3xl:h-[43rem]   w-[98%] m-auto  sm:rounded-lg  mt-4">
          <div className="flex justify-between bg-gray-100 px-5 py-3">
            <div className="flex gap-2 items-center">
              <p className="text-green-800 text-xl">
                <FaUserDoctor />
              </p>
              <p>Doctors</p>
            </div>
            <div>
              <button
                className="btnCoustmDesignGreen"
                onClick={() => setIsOpenDoctorModal(true)}
              >
                Doctor +
              </button>
            </div>
          </div>
          <hr />
          <div class="relative w-full overflow-y-auto rounded-md overflow-x-auto md:h-[84%] xl:h-[90%]">
            <table className="w-full">
              <thead className="sticky top-0 ">
                <tr>
                  <th className="text-base   font-bold">#</th>
                  <th className="text-base font-bold">Name</th>
                  <th className="text-base font-bold">Status</th>
                  <th className="text-base font-bold">Action</th>
                </tr>
              </thead>
              <tbody>
                {doctors.map((doctor, index) => {
                  return (
                    <tr
                      key={index}

                    >
                      <td>{index + 1}</td>
                      <td>
                        {indexx != index && <p> dr. {doctor.dr_name} </p>}

                        {indexx == index && (
                          <input
                            type="text"
                            className="bg-transparent  border focus:outline-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-7/12 p-2    "
                            placeholder="Name "
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                          />
                        )}
                      </td>
                      <td>
                        <div className="w-48">
                          {indexx != index && <p className={doctor.status == "Active" ? "textActive" : "textDeactive"}>{doctor.status}</p>}
                          {indexx == index && (
                            <select
                              id="selectDropdown"
                              className="w-full bg-white border border-gray-300 rounded-md shadow-sm  px-3 text-gray-700 focus:outline-none focus:ring focus:ring-indigo-300"
                              value={status}
                              onChange={handleChange}
                            >
                              <option value="Active">Active</option>
                              <option value="Deactive">Deactive</option>
                            </select>
                          )}
                        </div>
                      </td>
                      <td>
                        {!edit && (
                          <button
                            className="btnCoustmDesignGreen"
                            onClick={() => {
                              setEdit(!edit);
                              setStatus(doctor.status);
                              setName(doctor.dr_name);
                              setIndex(index);
                              setId(doctor.id);
                            }}
                          >
                            <p>
                              <FaRegEdit />
                            </p>{" "}
                            <p className="text-sm"> Edit</p>
                          </button>
                        )}

                        {edit && (
                          <div className="flex gap-2">
                            <button
                              className="checkBtn"
                              onClick={() =>
                                handleUpdate(doctor.id, name, status)
                              }
                            >
                              <PiCheckBold />
                            </button>
                            <button
                              className="btnCross"
                              onClick={() => {
                                setEdit(false);
                                setId(null);
                                setIndex(null);
                              }}
                            >
                              <RxCross2 />{" "}
                            </button>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <DoctorModal
            isOpenDoctorModal={isOpenDoctorModal}
            setIsOpenDoctorModal={setIsOpenDoctorModal}
          />
        </div>
      )}

      {/* <div className="pb-20"></div> */}
    </>
  );
};

export default Doctor;
