export const CREATE_RECIVE_FILE_ADMINSTRATOR_REQUEST =
  "CREATE_RECIVE_FILE_ADMINSTRATOR_REQUEST";
export const CREATE_RECIVE_FILE_ADMINSTRATOR_SUCCESS =
  "CREATE_RECIVE_FILE_ADMINSTRATOR_SUCCESS";
export const CREATE_RECIVE_FILE_ADMINSTRATOR_FAIL =
  "CREATE_RECIVE_FILE_ADMINSTRATOR_FAIL";

export const SHOW_RECIVE_FILE_ADMINSTRATOR_REQUEST =
  "SHOW_RECIVE_FILE_ADMINSTRATOR_REQUEST";
export const SHOW_RECIVE_FILE_ADMINSTRATOR_SUCCESS =
  "SHOW_RECIVE_FILE_ADMINSTRATOR_SUCCESS";
export const SHOW_RECIVE_FILE_ADMINSTRATOR_FAIL =
  "SHOW_RECIVE_FILE_ADMINSTRATOR_FAIL";

export const UPDATE_RECIVE_FILE_ADMINSTRATOR_REQUEST =
  "UPDATE_RECIVE_FILE_ADMINSTRATOR_REQUEST";
export const UPDATE_RECIVE_FILE_ADMINSTRATOR_SUCCESS =
  "UPDATE_RECIVE_FILE_ADMINSTRATOR_SUCCESS";
export const UPDATE_RECIVE_FILE_ADMINSTRATOR_FAIL =
  "UPDATE_RECIVE_FILE_ADMINSTRATOR_FAIL";

export const FETCH_RECIVE_FILE_ADMINSTRATOR_REQUEST =
  "FETCH_RECIVE_FILE_ADMINSTRATOR_REQUEST";
export const FETCH_RECIVE_FILE_ADMINSTRATOR_SUCCESS =
  "FETCH_RECIVE_FILE_ADMINSTRATOR_SUCCESS";
export const FETCH_RECIVE_FILE_ADMINSTRATOR_FAIL =
  "FETCH_RECIVE_FILE_ADMINSTRATOR_FAIL";
