import React, { useEffect, useRef, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { BsFillCloudArrowUpFill, BsFillTrashFill } from "react-icons/bs";
import { createPatient, createPatientAdminstratorAction, getPatient } from "../../Action/patient";
import { useDispatch } from "react-redux";

const PatientAdminstrartorModal = ({ closeModal, locationID }) => {
  const dispatch = useDispatch();

  const [image, setImage] = useState([""]);

  const [inputValue, setInputValue] = useState({
    fullName: null,
    phone: null,
    birth: null,
    created_at: "",
    gender: "Male",
  });
  const handelDelete = (event, index) => {
    event.preventDefault();
    const deleteValue = [...image];
    deleteValue.splice(index, 1);
    setImage(deleteValue);
  };
  const handleFileChange = (e, index) => {
    const updatedImages = [...image];
    updatedImages[index] = e.target.files[0];
    setImage(updatedImages);
  };
  const handelInputChange = (event) => {
    const { name, value } = event.target;
    setInputValue({ ...inputValue, [name]: value });
  };
  const handelAddMore = (event) => {
    event.preventDefault();
    setImage([...image, ""]);
  };

  const reset = () => {
    setInputValue("");
  };

  const [error, setError] = useState({
    fullName: null,
    phone: null,
    gender: null,
    birth: null,
  });
  const handelPatient = (event) => {
    event.preventDefault();

    const { fullName, phone, birth, gender, created_at } = inputValue;
    const arabicNumerals = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
    const westernNumerals = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

    let convertedPhone = phone;
    for (let i = 0; i < arabicNumerals.length; i++) {
      const arabicNumeral = new RegExp(arabicNumerals[i], "g");
      convertedPhone = convertedPhone.replace(
        arabicNumeral,
        westernNumerals[i]
      );
    }
    convertedPhone.replace(/\s/g, "");

    if (fullName == null) {
      closeModal(true);
      setError({
        ...error,
        fullName: "تکایە خانەکە پڕکەرەوە",
        phone: "",
        birth: "",
      });
    } else if (phone == null) {
      closeModal(true);
      setError({
        ...error,
        phone: "تکایە خانەکە پڕکەرەوە",
        fullName: "",
        birth: "",
      });
    } else if (birth == null) {
      closeModal(true);
      setError({
        ...error,
        birth: "تکایە خانەکە پڕکەرەوە",
        fullName: "",
        phone: "",
      });
    } else {
      closeModal(false);
      dispatch(
        createPatientAdminstratorAction(
          fullName,
          convertedPhone.replace(/\s/g, ""),
          gender,
          birth,
          image,
          locationID,
          reset,
          created_at
        )
      );
      // dispatch(getPatient(2, 0, 10, "", "", "", "", ""));
      dispatch(getPatient(locationID, 0, 10, "", "", "", "", "", 1));
      dispatch(getPatient(locationID, 0, 10, "", "", "", "", "", 1));
      dispatch(getPatient(locationID, 0, 10, "", "", "", "", "", 1));
      dispatch(getPatient(locationID, 0, 10, "", "", "", "", "", 1));
      // setInputValue("");
    }
  };

  return (
    <div
      className={
        closeModal
          ? "modalContainer overflow-y-auto pb-10"
          : "closeModalContainer"
      }
    >
      <div className=" modalChildAssignInvoice ">
        <div className="flex justify-between">
          <div>
            <p className="text-xl">Patient Register</p>
          </div>
          <div
            className="text-3xl cursor-pointer text-red-600"
            onClick={() => closeModal(false)}
          >
            x
          </div>
        </div>
        {/* form */}
        <form action="">
          <div className="w-full flex flex-col lg:flex-row lg:gap-4 py-1 ">
            <div className="w-full mt-2">
              <div className="w-full flex justify-end">
                <label htmlFor="number"> *ژمارەی مۆبایل</label>
              </div>
              <div className="flex flex-col">
                <input
                  type="text"
                  name="phone"
                  onChange={handelInputChange}
                  value={inputValue.phone}
                  id="number"
                  className="inputLogin w-full text-right"
                  placeholder="ژمارەی مۆبایل"
                />
              </div>
              <div className="text-red-500 pt-2">
                {" "}
                <p className="text-right">{error.phone}</p>{" "}
              </div>
            </div>
            {/* end parent Input & label */}

            <div className="w-full mt-2">
              <div className="w-full flex justify-end">
                <label htmlFor="name">*ناوی نەخۆش</label>
              </div>
              <input
                type="text"
                name="fullName"
                onChange={handelInputChange}
                value={inputValue.fullName}
                id="name"
                className="inputLogin w-full text-right"
                placeholder="ناوی نەخۆش"
              />
              <div className="text-red-500 pt-2">
                {" "}
                <p className="text-right">{error.fullName}</p>{" "}
              </div>
            </div>
            {/* end parent Input & label */}
          </div>

          <div className="childForm flex flex-col lg:flex-row lg:gap-4">
            <div className="parentInput w-full lg:mt-3 mt-1">
              <div className="w-full flex justify-end pb-2">
                <label htmlFor="Gender">* ڕەگەز</label>
              </div>
              <div className="">
                <select
                  onChange={handelInputChange}
                  name="gender"
                  className="select"
                  id="Gender"
                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  {/* <option value="">d</option> */}
                </select>
              </div>
            </div>

            {/* end parent Input & label */}

            <div className="w-full mt-2">
              <div className="w-full flex  justify-end pb-[0.6rem]">
                <label htmlFor="name">* بەرواری لە دایکبوون</label>
              </div>
              <div>
                <input
                  type="date"
                  onChange={handelInputChange}
                  value={inputValue.birth}
                  name="birth"
                  className="select py-2 mt-0.5"
                  placeholder="any"
                />

                <div className="text-red-500 pt-2">
                  {" "}
                  <p className="text-right">{error.birth}</p>{" "}
                </div>
              </div>
            </div>
            {/* end parent Input & label */}
          </div>
          <div>
            <input
              type="datetime-local"
              onChange={handelInputChange}
              value={inputValue.created_at}
              name="created_at"
              className="select py-2 mt-0.5"
              placeholder="any"
            />

            <div className="text-red-500 pt-2">
              {" "}
              <p className="text-right">{error.birth}</p>{" "}
            </div>
          </div>
          <div className="pt-6">
            <div className="flex justify-end">
              <button
                className="editBtn"
                onClick={(e) => {
                  handelAddMore(e);
                }}
              >
                <AiOutlinePlus />
              </button>
            </div>
            {image.map((img, index) => {
              return (
                <div
                  className="py-1  flex justify-between items-center"
                  key={index}
                >
                  <label
                    id="large_size"
                    className="p-4 flex flex-col items-center gap-2 w-10/12 bg-violet-50 text-violet-500 rounded-lg hover:bg-violet-100 cursor-pointer"
                  >
                    <p>
                      <BsFillCloudArrowUpFill className="w-6 h-6" />
                    </p>
                    <span>{img.name}</span>
                    <input
                      onChange={(e) => handleFileChange(e, index)}
                      name={`image_${index}`}
                      id="large_size"
                      type="file"
                      className="hidden"
                    />
                  </label>

                  <button
                    className="rejectBtn"
                    onClick={(e) => {
                      handelDelete(e, index);
                    }}
                  >
                    <BsFillTrashFill />
                  </button>
                  {/* <input
                    className="block w-full  text-lg  text-gray-900 border  border-gray-300 rounded-lg cursor-pointer bg-gray-50 "
                    onChange={(e) => handleFileChange(e, index)}
                    name={`image_${index}`}
                    id="large_size"
                    type="file"
                  /> */}

                  {/* <button className="rejectBtn">Delete</button> */}
                </div>
              );
            })}
          </div>

          <div className="flex items-center mt-5 w-3/12">
            <div>
              <button onClick={handelPatient} className="assignBtn">
                Assign
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="pb-20"></div>
    </div>
  );
};

export default PatientAdminstrartorModal;
