import React, { useEffect, useState } from "react";
import { LiaUserNurseSolid } from "react-icons/lia";
import { useDispatch, useSelector } from "react-redux";
import { getSurgeryAction } from "../../Action/Surgery/surgeryAction";
import { IoIosSettings } from "react-icons/io";
import MangeSurgeryModal from "../../Modal/MangeSurgery/MangeSurgeryModal";
import Logo from "../../Image/Logo.jpg";
import SurgeryModal from "../../Modal/MangeSurgery/SurgeryModal";
import { MdGridView } from "react-icons/md";
import { AiOutlineEye } from "react-icons/ai";
import ViewSurgeryModal from "../../Modal/MangeSurgery/ViewSurgeryModal";
import OrganLevelSurgery from "../../Modal/MangeSurgery/OrganLevelSurgery";
const Surgery = () => {
  const dispatch = useDispatch();
  const [isOpenOraganLevelModal, setIsOpenOrganLevelModal] = useState(false);
  const [surgeryName, setSurgeryName] = useState(null);
  const getSurgery = useSelector((state) => state.getSurgery);
  const { surgery, loading } = getSurgery;
  const [isOpenMangeModal, setIsOpenMangeModal] = useState(false);
  const [isOpenSurgeryModal, setIsOpenSurgeryModal] = useState(false);
  const [isOpenViewSurgeryModal, setIsOpenViewSurgeryModal] = useState(false);
  const [id, setId] = useState(null);
  useEffect(() => {
    dispatch(getSurgeryAction());
  }, []);
  return (
    <>
      {loading ? (
        <div className="flex flex-col">
          <img
            src={Logo}
            alt="Logo"
            className="w-56 m-auto animate-pulse mt-20 "
          />
          <div className="text-2xl text-center font-bold  ">
            <div className="flex justify-center gap-4">
              <p className="animate-charcter">Please Wait</p>
              <div class="container2">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>{" "}
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-white w-full xl:w-11/12 h-[80%]   shadow-xl m-auto rounded-md ">
          <div className="flex justify-between bg-gray-100 px-5 py-3">
            <div className="flex gap-2 items-center">
              <p className="text-blue-800 text-xl">
                <LiaUserNurseSolid />
              </p>
              <p>Surgery</p>
            </div>
            <div className="flex gap-2">
              <button
                className="btnCoustmDesignPurple"
                onClick={() => setIsOpenSurgeryModal(true)}
              >
                Surgery +
              </button>

              <button
                className="btnCoustmDesignBlue"
                onClick={() => setIsOpenOrganLevelModal(true)}
              >
                + ئەندام
              </button>
            </div>
          </div>
          <hr />
          <div className="overflow-x-auto h-[21rem] xl:h-[27rem] 3xl:h-[40rem]  m-auto">
            <table className="w-full m-auto">
              <thead className="sticky top-0 ">
                <tr>
                  <th className="text-base   font-bold">#</th>
                  <th className="text-base font-bold">Name</th>
                  <th className="text-base font-bold">Action</th>
                </tr>
              </thead>
              <tbody>
                {surgery.map((surg, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td className="text-right">{surg.surguryName}</td>
                      <td  className="w-72">
                        <div className="flex gap-2">
                        <button
                          className="btnCoustmDesignBlue  group"
                          onClick={() => {
                            setIsOpenMangeModal(true);
                            setSurgeryName(surg.surguryName);
                            setId(surg.id);
                          }}
                        >
                          <p>
                            <IoIosSettings className="group-hover:rotate-90 duration-500" />
                          </p>{" "}
                          Mange{" "}
                        </button>
                        <button
                          className="btnCoustmDesignPurple group"
                          onClick={() => {
                            setIsOpenViewSurgeryModal(true);
                            setSurgeryName(surg.surguryName);
                            setId(surg.id);
                          }}
                        >
                          <p>
                            <AiOutlineEye className=" duration-500" />
                          </p>{" "}
                          View{" "}
                        </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <SurgeryModal
            isOpenModal={isOpenSurgeryModal}
            closeModal={setIsOpenSurgeryModal}
          />
          <MangeSurgeryModal
            id={id}
            isOpenModal={isOpenMangeModal}
            surgeryName={surgeryName}
            closeModal={setIsOpenMangeModal}
          />
          <OrganLevelSurgery
            isOpenModal={isOpenOraganLevelModal}
            closeModal={setIsOpenOrganLevelModal}
          />
          {isOpenViewSurgeryModal && (
            <ViewSurgeryModal
              closeModal={setIsOpenViewSurgeryModal}
              isOpenModal={isOpenViewSurgeryModal}
              id={id}
            />
          )}
        </div>
      )}
    </>
  );
};

export default Surgery;
