export const CREATE_ATTENDANCE_REQUEST = "CREATE_ATTENDANCE_REQUEST";
export const CREATE_ATTENDANCE_SUCCESS = "CREATE_ATTENDANCE_SUCCESS";
export const CREATE_ATTENDANCE_FAIL = "CREATE_ATTENDANCE_FAIL";

export const STORE_RECORD_ATTENDANCE_REQUEST = "STORE_RECORD_ATTENDANCE_REQUEST";
export const STORE_RECORD_ATTENDANCE_SUCCESS = "STORE_RECORD_ATTENDANCE_SUCCESS";
export const STORE_RECORD_ATTENDANCE_FAIL = "STORE_RECORD_ATTENDANCE_FAIL";


export const FETCH_ATTENDANCE_REQUEST = "FETCH_ATTENDANCE_REQUEST";
export const FETCH_ATTENDANCE_SUCCESS = "FETCH_ATTENDANCE_SUCCESS";
export const FETCH_ATTENDANCE_FAIL = "FETCH_ATTENDANCE_FAIL";

export const UPDATE_ATTENDANCE_REQUEST = "UPDATE_ATTENDANCE_REQUEST";
export const UPDATE_ATTENDANCE_SUCCESS = "UPDATE_ATTENDANCE_SUCCESS";
export const UPDATE_ATTENDANCE_FAIL = "UPDATE_ATTENDANCE_FAIL";

export const SHOW_ATTENDANCE_REQUEST = "SHOW_ATTENDANCE_REQUEST";
export const SHOW_ATTENDANCE_SUCCESS = "SHOW_ATTENDANCE_SUCCESS";
export const SHOW_ATTENDANCE_FAIL = "SHOW_ATTENDANCE_FAIL";

