import {
  CREATE_PERMISSION_PAGE_FAIL,
  CREATE_PERMISSION_PAGE_REQUEST,
    CREATE_PERMISSION_PAGE_SUCCESS,
    FETCH_PAGE_FAIL,
  FETCH_PAGE_REQUEST,
  FETCH_PAGE_SUCCESS,
  SHOW_PAGE_FAIL,
  SHOW_PAGE_REQUEST,
  SHOW_PAGE_SUCCESS,
  SHOW_PERMISSION_PAGE_FAIL,
  SHOW_PERMISSION_PAGE_REQUEST,
  SHOW_PERMISSION_PAGE_SUCCESS,
} from "../../ActionType/PageType/PageType";

export const getPageReducer = (
  state = { loading: true, pages: [], error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_PAGE_REQUEST:
      return {
        loading: true,
      };
    case FETCH_PAGE_SUCCESS:
      return {
        loading: false,
        pages: action.payload,
        error: "",
      };
    case FETCH_PAGE_FAIL:
      return {
        loading: false,
        pages: action.payload,
        error: "Please Check",
      };
    default:
      return state;
  }
};


export const showPageReducer = (
  state = { loading: true, pages: [], error: "" },
  action
) => {
  switch (action.type) {
    case SHOW_PAGE_REQUEST:
      return {
        loading: true,
      };
    case SHOW_PAGE_SUCCESS:
      return {
        loading: false,
        pages: action.payload,
        error: "",
      };
    case SHOW_PAGE_FAIL:
      return {
        loading: false,
        pages: action.payload,
        error: "Please Check",
      };
    default:
      return state;
  }
};

export const createPermissionPageReducer = (
  state = { loading: true, permission: [], error: "" },
  action
) => {
  switch (action.type) {
    case CREATE_PERMISSION_PAGE_REQUEST:
      return {
        loading: true,
      };
    case CREATE_PERMISSION_PAGE_SUCCESS:
      return {
        loading: false,
        permission: action.payload,
        error: "",
      };
    case CREATE_PERMISSION_PAGE_FAIL:
      return {
        loading: false,
        permission: [],
        error: "Please Check",
      };
    default:
      return state;
  }
};

export const showPermissionPageReducer = (
  state = { loading: true, permissions: [], error: "" },
  action
) => {
  switch (action.type) {
    case SHOW_PERMISSION_PAGE_REQUEST:
      return {
        loading: true,
      };
    case SHOW_PERMISSION_PAGE_SUCCESS:
      return {
        loading: false,
        permissions: action.payload,
        error: "",
      };
    case SHOW_PERMISSION_PAGE_FAIL:
      return {
        loading: false,
        permissions: [],
        error: "Please Check",
      };
    default:
      return state;
  }
};

