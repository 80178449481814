import React, { useEffect, useState } from "react";
import { FaFile } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { getReciveFilesAdminstratorAction } from "../../Action/ReciveFilesAdminstratorAction/ReciveFilesAdminstratorAction";
import Logo from "../../Image/Logo.jpg";
import { getUserAuth } from "../../Action/userRegister";
import { getSection } from "../../Action/section";
import { getBranch } from "../../Action/branch";
import { Link } from "react-router-dom";
const ReciveFilesAdminstrator = () => {
  const getReciveFilesAdminstrator = useSelector(
    (state) => state.getReciveFilesAdminstrator
  );

  useEffect(() => {
    dispatch(getBranch());
    dispatch(getSection());
    dispatch(getUserAuth());
  }, []);

  const getUsers = useSelector((state) => state.getUser);
  const { users } = getUsers;

  const locationId = localStorage.getItem("locationId");
  const getBranchReducer = useSelector((state) => state.getBranch);
  const { branchs } = getBranchReducer;
  const getSectionReducer = useSelector((state) => state.getSection);
  const { sections } = getSectionReducer;
  const [branchName, setBranchName] = useState(null);
  const [sectionName, setSectionName] = useState(null);
  useEffect(() => {
    if (branchs == undefined || sections == undefined) {
      return;
    }

    branchs
      .filter((filter) => filter.id == locationId)
      .map((branch) => {
        setBranchName(branch.bName);
      });
    sections
      .filter((filter) => filter.id == users.section_id)
      .map((Section) => {
        setSectionName(Section.sName);
      });
  }, [branchs, sections]);

  const { reciveFiles, loading } = getReciveFilesAdminstrator;
  const dispatch = useDispatch();
  useEffect(() => {
    if (branchName == null || sectionName == null) {
      return;
    }
    dispatch(getReciveFilesAdminstratorAction(branchName, sectionName,users.id));
  }, [branchName, sectionName]);
  return (
    <>
      {(reciveFiles == undefined && loading) ||
      branchName == null ||
      sectionName == null ? (
        <div className="flex flex-col">
          <img
            src={Logo}
            alt="Logo"
            className="w-56 m-auto animate-pulse mt-20 "
          />
          <div className="text-2xl text-center font-bold  ">
            <div className="flex justify-center gap-4">
              <p className="animate-charcter">Please Wait</p>
              <div class="container2">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>{" "}
            </div>
          </div>
        </div>
      ) : (
        <div className="parentPrimary">
          <div className="parentHeadPrimary">
            <div className="flex items-center gap-2">
              <p className="text-blue-500 text-lg">
                <FaFile />
              </p>
              <div>
                <p className="font-bold">Files</p>
              </div>
            </div>
          </div>
          <div className="parentTablePrimary">
            <table className="tablePrimary">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Number</th>

                  <th>Date</th>
                  <th>Document</th>
                </tr>
              </thead>
              <tbody>
                {reciveFiles.map((recive, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{recive.sent_file.number}</td>
                      <td>{recive.sent_file.date}</td>
                      <td className="w-20">
                        <Link to={`${recive.sent_file.document}`}>
                          <p className="text-xl text-blue-500 "><FaFile /></p>
                        </Link>
                      </td>

                      {/* <td>fds</td>
                  <td>fds</td>
                  <td>fds</td> */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default ReciveFilesAdminstrator;
