import {
  CREATE_ADMINSTRATOR_FAIL,
  CREATE_ADMINSTRATOR_REQUEST,
  CREATE_ADMINSTRATOR_SUCCESS,
  DELETE_ADMINSTRATOR_FAIL,
  DELETE_ADMINSTRATOR_REQUEST,
  DELETE_ADMINSTRATOR_SUCCESS,
  FETCH_ADMINSTRATOR_FAIL,
  FETCH_ADMINSTRATOR_REQUEST,
  FETCH_ADMINSTRATOR_SUCCESS,
  UPDATE_ADMINSTRATOR_FAIL,
  UPDATE_ADMINSTRATOR_REQUEST,
  UPDATE_ADMINSTRATOR_SUCCESS,
} from "../../ActionType/AdminstratorType/AdminstratorType";

export const createAdminstratorReducer = (
  state = { loading: true, admins: [], error: "" },
  action
) => {
  switch (action.type) {
    case CREATE_ADMINSTRATOR_REQUEST:
      return {
        loading: true,
      };
    case CREATE_ADMINSTRATOR_SUCCESS:
      return {
        loading: false,
        admins: action.payload,
        error: "",
      };
    case CREATE_ADMINSTRATOR_FAIL:
      return {
        loading: false,
        admins: [],
        error: "",
      };
    default:
      return state;
  }
};

export const getAdminstratorReducer = (
  state = { loading: true, datas: [], total: "", error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_ADMINSTRATOR_REQUEST:
      return {
        loading: true,
      };
    case FETCH_ADMINSTRATOR_SUCCESS:
      return {
        loading: false,
        datas: action.payload.data,
        total: action.payload.total,
        error: "",
      };
    case FETCH_ADMINSTRATOR_FAIL:
      return {
        loading: false,
        datas: [],
        error: "",
      };
    default:
      return state;
  }
};

export const deleteAdminstratorReducer = (
  state = { loading: true, admins: [], error: "" },
  action
) => {
  switch (action.type) {
    case DELETE_ADMINSTRATOR_REQUEST:
      return {
        loading: true,
      };
    case DELETE_ADMINSTRATOR_SUCCESS:
      return {
        loading: false,
        admins: action.payload,
        error: "",
      };
    case DELETE_ADMINSTRATOR_FAIL:
      return {
        loading: false,
        admins: [],
        error: "",
      };
    default:
      return state;
  }
};

export const updateAdminstratorReducer = (
  state = { loading: true, admins: [], error: "" },
  action
) => {
  switch (action.type) {
    case UPDATE_ADMINSTRATOR_REQUEST:
      return {
        loading: true,
      };
    case UPDATE_ADMINSTRATOR_SUCCESS:
      return {
        loading: false,
        admins: action.payload,
        error: "",
      };
    case UPDATE_ADMINSTRATOR_FAIL:
      return {
        loading: false,
        admins: [],
        error: "",
      };
    default:
      return state;
  }
};
