import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showReceiveAction } from "../../Action/receiveAction";
import Logo from "../../Image/Logo.jpg";
const ViewReciveModal = ({ recive_id, closeModal }) => {
  const dispatch = useDispatch();
  const showReceive = useSelector((state) => state.showReceive);
  const { loading, receive } = showReceive;
  useEffect(() => {
    dispatch(showReceiveAction(recive_id));
  }, [recive_id]);
  return (
    <>
      {loading ? (
        <div className="absolute top-0 left-[47%] flex flex-col">
          <img
            src={Logo}
            alt="Logo"
            className="w-56 m-auto animate-pulse mt-20 "
          />
          <div className="text-2xl text-center font-bold  ">
            <div className="flex justify-center gap-4">
              <p className="animate-charcter">Please Wait</p>
              <div class="container2">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>{" "}
            </div>
          </div>
        </div>
      ) : (
        <div className="absolute top-0 left-0 w-[95%] ">
          <div className="overlay z-10" onClick={() => closeModal(false)}></div>

          <div className="absolute left-[53%] -translate-x-[50%] bg-white w-full p-4 rounded-md shadow-md z-50 mt-4">
            <div className="flex justify-between">
              <div
                className="text-xl text-red-400 cursor-pointer"
                onClick={() => closeModal(false)}
              >
                <p>X</p>
              </div>
            </div>
            {/* head */}
            <form action="">
              {receive.map((element, index) => {
                return (
                  <div key={index} className="">
                    <div className="bg-teal-500 mt-7 m-auto rounded-sm shadow-lg text-white py-2 px-3 w-7/12 md:w-2/12">
                      <p className="text-center">فۆرمی زانیاری نەخۆش </p>
                    </div>
                    <div className="parentInputGrid">
                      <div className="w-full">
                        <div className="w-full flex justify-end">
                          <label htmlFor="number"> *رەقەم مۆبایل</label>
                        </div>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.commite.patients.phone}
                        />
                      </div>
                      {/* end Of Input */}
                      <div className="w-full">
                        <div className="w-full flex justify-end">
                          <label htmlFor="gender">*ڕەگەز </label>
                        </div>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.commite.patients.gender}
                        />
                      </div>
                      {/* end Of Input  patient */}

                      <div className="w-full">
                        <div className="w-full flex justify-end">
                          <label htmlFor="name">*ناوی نەخۆش</label>
                        </div>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.commite.patients.Pname}
                        />
                      </div>
                      {/* end Of Input */}

                      <div className="w-full">
                        <label htmlFor="">Patient ID *</label>
                        <input
                          type="text"
                          className="inputLogin  w-full"
                          readOnly
                          value={element.lezhna.commite.patients.code}
                        />
                      </div>
                      {/* end Of Input */}
                    </div>

                    <div className="bg-yellow-500 text-white py-2 xl:w-2/12 rounded-sm shadow-md mt-4  m-auto">
                      <p className="text-center">فۆرمی لیژنەی بەدواداچوون </p>
                    </div>

                    <div className="parentInputGrid">
                      <div className="mt-2 w-full">
                        <div className="flex justify-end pr-5">
                          <label htmlFor="province">* پارێزگا </label>
                        </div>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.commite.province}
                        />
                      </div>
                      {/* end Of Input */}

                      <div className="mt-2 w-full">
                        <div className="w-full flex justify-end pr-5">
                          <label htmlFor="nation"> *نەتەوە</label>
                        </div>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.commite.nation}
                        />
                      </div>
                      {/* end Of Input */}
                      <div className="mt-2 w-full">
                        <div className="w-full flex justify-end pr-5">
                          <label htmlFor="country"> * وڵات </label>
                        </div>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.commite.country}
                        />
                      </div>
                      {/* end Of Input */}

                      <div className="mt-2 w-full">
                        <div className="w-full flex justify-end pr-5">
                          <label htmlFor="name"> *پیشە</label>
                        </div>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.commite.occuption}
                        />
                      </div>
                      {/* end Of Input */}
                      <div className="mt-2 w-full">
                        <div className="flex justify-end pr-5">
                          <label htmlFor="gender"> * خێزاندارە </label>
                        </div>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.commite.married}
                        />
                      </div>
                      {/* end Of Input */}

                      <div className="mt-2 w-full">
                        <div className="flex justify-end pr-5">
                          <label htmlFor="closetHome">
                            *نزیکترین شوێن لە ماڵیانەوە
                          </label>
                        </div>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.commite.closetHome}
                        />
                      </div>
                      {/* end Of Input */}

                      <div className="mt-2 w-full">
                        <div className="flex justify-end pr-5">
                          <label htmlFor="province">*شەقام گەڕەک </label>
                        </div>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.commite.street}
                        />
                      </div>
                      {/* end Of Input */}

                      <div className="mt-2 w-full">
                        <div className="flex justify-end pr-5">
                          <label htmlFor="province">* ناحیە/قەزا </label>
                        </div>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.commite.district}
                        />
                      </div>
                      {/* end Of Input */}

                      <div className="mt-2 w-full">
                        <div className="w-full justify-end flex">
                          <label htmlFor="scoundPhoneNo">
                            *ژ.مۆبایلی دووەم
                          </label>
                        </div>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.commite.secoundHome}
                        />
                      </div>
                      {/* end Of Input */}
                      <div className="mt-2 w-full">
                        <div className="w-full flex justify-end pr-5">
                          <label htmlFor="name"> *جگەرە کێشە</label>
                        </div>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.commite.smoke}
                        />
                      </div>
                      {/* end Of Input */}
                      <div className="mt-2 w-full">
                        <div className="w-full justify-end flex">
                          <label htmlFor="timeAllot">
                            * کاتی دیاریکراو بۆ هەڵسەنگاندن{" "}
                          </label>
                        </div>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.commite.timeAllote}
                        />
                      </div>
                      <div className="mt-2 w-full">
                        <div className="flex justify-end pr-5">
                          <label htmlFor="noFamily">*ژمارەی خێزان</label>
                        </div>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.commite.noFamily}
                        />
                      </div>
                      {/* end Of Input */}

                      <div className="mt-2 w-full">
                        <div className="flex justify-end pr-5">
                          <label htmlFor="name">* لەسەر ئاستی ئەندام</label>
                        </div>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.commite.memberLevel}
                        />
                      </div>
                      {/* end Of Input */}
                      <div className="mt-2 w-full">
                        <div className="flex justify-end pr-5">
                          <label htmlFor="member">* ئەندام</label>
                        </div>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.commite.member}
                        />
                      </div>
                      {/* end Of Input */}

                      <div className="mt-2 w-full">
                        <label htmlFor="" className="capitalize">
                          TYP *
                        </label>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.commite.TYP}
                        />
                      </div>
                      {/* end Of Input */}

                      <div className="mt-2 w-full">
                        <div className="flex justify-end pr-5">
                          <label htmlFor="number">* پسپۆڕی جۆری نەخۆشی </label>
                        </div>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.commite.SITD}
                        />
                      </div>
                      {/* end Of Input */}
                      <div className="mt-2 w-full">
                        <div className="flex justify-end pr-5">
                          <label htmlFor="name">* نەخۆشی پێش وەخت</label>
                        </div>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.commite.earlyPatient}
                        />
                      </div>
                      {/* end Of Input */}
                      <div className="mt-2 w-full">
                        <label htmlFor="" className="capitalize">
                          indiction *
                        </label>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.commite.indiction}
                        />
                      </div>
                      {/* end Of Input */}
                      <div className="mt-2 w-full">
                        <label htmlFor="" className="capitalize">
                          HEP *
                        </label>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.commite.HEP}
                        />
                      </div>
                      {/* end Of Input */}
                      <div className="mt-2 w-full">
                        <label htmlFor="" className="capitalize">
                          final Diagnosis *
                        </label>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.commite.finalDiagnosis}
                        />
                      </div>
                      {/* end Of Input */}

                      <div className="mt-2 w-full">
                        <div className="flex justify-end pr-5">
                          <label htmlFor="name">
                            * پێداویستی پزیشکی بۆ دابینکراوە{" "}
                          </label>
                        </div>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.commite.Typemedicalsupplies}
                        />
                      </div>
                      {/* end Of Input */}

                      <div className="mt-2 w-full">
                        <div className="flex justify-end pr-5">
                          <label htmlFor="ZODoctor">
                            {" "}
                            *پزیشکی هاوکاری ڕیکخراوی زادە
                          </label>
                        </div>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.commite.ZODoctor}
                        />
                      </div>
                      {/* end Of Input */}

                      <div className="mt-2 w-full">
                        <div className="flex justify-end pr-5">
                          <label htmlFor="doctor"> * پزیشکی نێردراو بۆ</label>
                        </div>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.commite.Doctor}
                        />
                      </div>
                      {/* end Of Input */}
                      <div className="mt-2 w-full">
                        <div className="flex justify-end pr-5">
                          <label htmlFor="name">* نەشتەرگەری پێشوەخت </label>
                        </div>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.commite.earlySurgery}
                        />
                      </div>
                      {/* end Of Input */}
                      <div className="mt-2 w-full">
                        <div className="flex justify-end pr-5">
                          <label htmlFor="supplyLocation">
                            شوێنی دابینکردنی پێداویستی
                          </label>
                        </div>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.commite.supplyLocation}
                        />
                      </div>
                      {/* end Of Input */}
                      <div className="mt-2 w-full">
                        <div className="flex justify-end pr-5">
                          <label htmlFor="supplyLocation">
                            هاوکاری رێکخراوی زادە
                          </label>
                        </div>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.commite.helpingZO}
                        />
                      </div>
                      {/* end Of Input Commite */}
                    </div>
                    <div className="bg-blue-500 text-white py-2 xl:w-2/12 rounded-sm shadow-md mt-4  m-auto">
                      <p className="text-center">فۆرمی کاروباری نەشتەرگەری </p>
                    </div>
                    <div className="parentInputGrid">
                      <div className="mt-2 w-full">
                        <label htmlFor="" className="capitalize">
                          alive *
                        </label>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.alive}
                        />
                      </div>
                      {/* end Of Input */}
                      <div className="mt-2 w-full">
                        <label htmlFor="" className="capitalize">
                          salaryEmploye *
                        </label>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.salaryEmploye}
                        />
                      </div>
                      {/* end Of Input */}
                      <div className="mt-2 w-full">
                        <label htmlFor="" className="capitalize">
                          Visit Date *
                        </label>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.date}
                        />
                      </div>
                      {/* end Of Input */}
                      <div className="mt-2 w-full">
                        <label htmlFor="" className="capitalize">
                          house *
                        </label>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.house}
                        />
                      </div>
                      {/* end Of Input */}
                      <div className="mt-2 w-full">
                        <label htmlFor="" className="capitalize">
                          teanant *
                        </label>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.teanant}
                        />
                      </div>
                      {/* end Of Input */}
                      <div className="mt-2 w-full">
                        <label htmlFor="" className="capitalize">
                          Vehicle *
                        </label>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.Vehicle}
                        />
                      </div>
                      {/* end Of Input */}
                      <div className="mt-2 w-full">
                        <label htmlFor="" className="capitalize">
                          land *
                        </label>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.land}
                        />
                      </div>
                      {/* end Of Input */}
                      <div className="mt-2 w-full">
                        <label htmlFor="" className="capitalize">
                          debt *
                        </label>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.debt}
                        />
                      </div>
                      {/* end Of Input */}
                      <div className="mt-2 w-full">
                        <div className="w-full  text-right">
                          <label htmlFor="number">* چەند مەترە </label>
                        </div>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.metters}
                        />
                      </div>
                      {/* end Of Input */}
                      <div className="mt-2 w-full text-right">
                        <label htmlFor="number ">
                          * جۆری موڵک یاخود زەوی نرخی خامڵیندراو
                        </label>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.typeofLandEstimatedPrice}
                        />
                      </div>
                      <div className="mt-2 w-full">
                        <div className="w-full  text-right">
                          <label
                            htmlFor="number"
                            className="text-right  w-full"
                          >
                            * کارمەندی حکومییە یان کەرتی تایبەت
                          </label>
                        </div>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.govOrPriveteEmp}
                        />
                      </div>
                      <div className="mt-2 w-full">
                        <div className="w-full  text-right">
                          <label
                            htmlFor="number"
                            className="text-right  w-full"
                          >
                            * باری ئابووری
                          </label>
                        </div>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.economicStatus}
                        />
                      </div>
                      {/* end Of Input */}
                      <div className="mt-2 w-full">
                        <div className="w-full text-right">
                          <label htmlFor="number">* بڕی قەرزەکە </label>
                        </div>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.amountofLoan}
                        />
                      </div>
                      {/* end Of Input */}{" "}
                      <div className="mt-2 w-full">
                        <div className="w-full text-right">
                          <label htmlFor="number">* بڕی مووچە </label>
                        </div>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.amountofSalary}
                        />
                      </div>
                      {/* end Of Input */}
                      <div className="mt-2 w-full">
                        <div className="w-full text-right">
                          <label htmlFor="number">* بڕی کرێ خانوو</label>
                        </div>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.amountOfRent}
                        />
                      </div>
                      {/* end Of Input */}
                      <div className="mt-2 w-full">
                        <div className="w-full text-right">
                          <label htmlFor="number">* جۆری گەرەک</label>
                        </div>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.NeighborhoodTypeselect}
                        />
                      </div>
                      {/* end Of Input */}
                      <div className="mt-2 w-full">
                        <div className="w-full flex justify-end">
                          <label htmlFor="number"> * ژ. خوێندکار</label>
                        </div>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.numberofStudent}
                        />
                      </div>
                      {/* end Of Input */}
                      <div className="mt-2 w-full">
                        <div className="w-full flex justify-end">
                          <label htmlFor="number">* نەخۆشی تریان هەیە *</label>
                        </div>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.haveOtherDiseases}
                        />
                      </div>
                      {/* end Of Input */}
                      <div className="mt-2 w-full">
                        <div className="w-full flex justify-end">
                          <label htmlFor="number">* جۆری نەخۆشیەکە</label>
                        </div>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.Typeofdisease}
                        />
                      </div>
                      {/* end Of Input */}
                      <div className="mt-2 w-full">
                        <div className="w-full flex justify-end">
                          <label htmlFor="number">* ڕێژەی هەژاری </label>
                        </div>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.PovertyRates}
                        />
                      </div>
                      {/* end Of Input */}
                      <div className="mt-2 w-full">
                        <div className="w-full text-right">
                          <label htmlFor="number">* جۆری ئۆتۆمبێل </label>
                        </div>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.typeofVehicle}
                        />
                      </div>
                      {/* end Of Input */}
                      <div className="mt-2 w-full">
                        <div className="w-full flex justify-end">
                          <label htmlFor="number">
                            * توانای لە ئەستۆ گرتنی تێچوی نەشتەرگەری
                          </label>
                        </div>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.HMCabilitToSurgery}
                        />
                      </div>
                      {/* end Of Input */}
                      <div className="mt-2 w-full">
                        <div className="w-full flex justify-end">
                          <label htmlFor="number">
                            {" "}
                            * داهاتی مانگانەی هاوسەری یان خێزانی
                          </label>
                        </div>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.monthlyIncomeFamily}
                        />
                      </div>
                      {/* end Of Input */}
                      <div className="mt-2 w-full">
                        <div className="w-full flex justify-end">
                          <label htmlFor="number">* ژ. مناڵ</label>
                        </div>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.numberofChildren}
                        />
                      </div>
                      {/* end Of Input */}
                      {/* end Of Input */}
                      <div className="mt-2 w-full">
                        <div className="w-full flex justify-end">
                          <label htmlFor="number">
                            * شایستەی کاری راگەیاندنە
                          </label>
                        </div>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.PMediaWork}
                        />
                      </div>
                      {/* end Of Input */}
                      <div className="mt-2 w-full">
                        <div className="w-full text-right">
                          <label htmlFor="number">* خزمایەتی</label>
                        </div>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.relationship}
                        />
                      </div>
                      {/* end Of Input */}
                      <div className="mt-2 w-full">
                        <div className="w-full text-right">
                          <label htmlFor="number">* ناوی خزم یان کەس</label>
                        </div>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.Name}
                        />
                      </div>
                      {/* end Of Input */}
                      <div className="mt-2 w-full">
                        <div className="w-full text-right">
                          <label htmlFor="number">
                            * رەقەمی مۆبایل خزم یان کەس
                          </label>
                        </div>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.resonOfDebt}
                        />
                      </div>
                      {/* end Of Input */}
                      <div className="mt-2 w-full">
                        <div className="w-full flex justify-end">
                          <label htmlFor="" className="capitalize">
                            *ڕەقەمی خزم و کەسوکار
                          </label>
                        </div>
                        <input
                          type="text"
                          className="inputLogin w-full text-right"
                          readOnly
                          value={element.lezhna.phoneNumber}
                        />
                      </div>
                      {/* end Of Input */}
                    </div>
                    <div className="mt-2 w-full">
                      <label
                        for="message"
                        class="block mb-2 text-sm font-medium text-gray-900 "
                      >
                        Your message
                      </label>
                      <textarea
                        id="message"
                        rows="4"
                        class="block p-2 w-full  text-sm   rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500    dark:focus:ring-blue-500 dark:focus:border-blue-500 text-black"
                        value={element.lezhna.note}
                        placeholder="Write your thoughts here..."
                      ></textarea>
                    </div>
                  </div>
                );
              })}
              <div className="flex gap-4">
                {/* {image.map((element, index) => {
                return (
                  <Link
                    to={element.image}
                    key={index}
                    target="_blank"
                    className="border-2 border-black rounded-md mt-4  shadow-lg"
                  >
                    <div className="mt-2 w-full">
                      <label
                        for="message"
                        class="block mb-2 text-sm font-medium text-gray-900 "
                      >
                        Image
                      </label>

                      <img
                        src={element.image}
                        className="w-full h-72 object-cover border-2  "
                        alt=""
                      />
                    </div>
                  </Link>
                );
              })} */}
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewReciveModal;
