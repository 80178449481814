export const CREATE_TYPE_MEDICAL_REQUEST="CREATE_TYPE_MEDICAL_REQUEST";
export const CREATE_TYPE_MEDICAL_SUCCESS="CREATE_TYPE_MEDICAL_SUCCESS";
export const CREATE_TYPE_MEDICAL_FAIL="CREATE_TYPE_MEDICAL_FAIL";

export const FETCH_TYPE_MEDICAL_REQUEST="FETCH_TYPE_MEDICAL_REQUEST";
export const FETCH_TYPE_MEDICAL_SUCCESS="FETCH_TYPE_MEDICAL_SUCCESS";
export const FETCH_TYPE_MEDICAL_FAIL="FETCH_TYPE_MEDICAL_FAIL";

export const FETCH_EXPORT_EXCEL_TYPE_MEDICAL_REQUEST="FETCH_EXPORT_EXCEL_TYPE_MEDICAL_REQUEST";
export const FETCH_EXPORT_EXCEL_TYPE_MEDICAL_SUCCESS="FETCH_EXPORT_EXCEL_TYPE_MEDICAL_SUCCESS";
export const FETCH_EXPORT_EXCEL_TYPE_MEDICAL_FAIL="FETCH_EXPORT_EXCEL_TYPE_MEDICAL_FAIL";

export const FETCH_TYPE_IMAGE_MEDICAL_REQUEST="FETCH_TYPE_IMAGE_MEDICAL_REQUEST";
export const FETCH_TYPE_IMAGE_MEDICAL_SUCCESS="FETCH_TYPE_IMAGE_MEDICAL_SUCCESS";
export const FETCH_TYPE_IMAGE_MEDICAL_FAIL="FETCH_TYPE_IMAGE_MEDICAL_FAIL";

export const FETCH_TYPE_MEDICAL_BY_VISIT_ID_REQUEST="FETCH_TYPE_MEDICAL_BY_VISIT_ID_REQUEST";
export const FETCH_TYPE_MEDICAL_BY_VISIT_ID_SUCCESS="FETCH_TYPE_MEDICAL_BY_VISIT_ID_SUCCESS";
export const FETCH_TYPE_MEDICAL_BY_VISIT_ID_FAIL="FETCH_TYPE_MEDICAL_BY_VISIT_ID_FAIL";