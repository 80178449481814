import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { useDispatch, useSelector } from "react-redux";
import { RiFileExcel2Fill } from "react-icons/ri";
import {
  getWhatsAppInfoExcelAction,
  getWhatsUpInfoAction,
} from "../../Action/WhatsAppAction/whatsAppAction";
import Logo from "../../Image/Logo.jpg";

import ReactPaginate from "react-paginate";
import "./index.css";
import { FaFilter } from "react-icons/fa";
import { getToken } from "../../Action/login";
import { api } from "../../Api/api";

const WhatsUpSurgery = () => {
  const getWhatsUpInfo = useSelector((state) => state.getWhatsUpInfo);

  const { data, loading, total } = getWhatsUpInfo;

  const [openFilter, setOpenFilter] = useState(false);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [searchParams, setSearchParams] = useState({
    fullname: "",
    organ_level: "",
    doctor: "",
    hospital: "",
    totalOfSurgery: "",
    totalOfPatient: "",
    total_of_hospital: "",
    helpDr: "",
    zad: "",
    date: "",
  });
  const {
    fullname,
    organ_level,
    doctor,
    hospital,
    totalOfPatient,
    totalOfSurgery,
    total_of_hospital,
    zad,
    date,
  } = searchParams;

  const [loadingExcle, setLoadingExcle] = useState(false);
  const exportToExcel = async () => {
    setLoadingExcle(true);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        // Authorization: `Bearer ${getToken()}`,
        Authorization: `Bearer ${getToken()}`,
      },
      params: {
        from,
        to,
      },
      responseType: "blob",
    };
    try {
      const response = await api.get("whatsApp/excel", config);

      const blob = new Blob([response.data], { type: "text/csv" });
      if (response.status == 200) {
        setLoadingExcle(false);
      }
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);

      link.download = "واتسئاپ.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading CSV:", error);
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const locationId = localStorage.getItem("locationId");
  const [perPage, setPerPage] = useState(20);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getWhatsUpInfoAction(
        perPage,
        currentPage,
        fullname,
        organ_level,
        doctor,
        hospital,
        totalOfPatient,
        totalOfSurgery,
        total_of_hospital,
        zad,
        date,
        locationId,
        "",
        searchParams.helpDr,
        from,
        to
      )
    );
  }, [perPage, searchParams, from, to]);

  const handleSearchParams = (event) => {
    const { name, value } = event.target;
    setSearchParams({
      ...searchParams,
      [name]: value,
    });
  };
  return (
    <>
      {loading || loadingExcle ? (
        <div className="flex flex-col">
          <img
            src={Logo}
            alt="Logo"
            className="w-56 m-auto animate-pulse mt-20 "
          />
          <div className="text-2xl text-center font-bold  ">
            <div className="flex justify-center gap-4">
              <p className="animate-charcter">Please Wait</p>
              <div class="container2">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>{" "}
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="flex gap-2">
            <button onClick={exportToExcel}>
              <div className="flex items-center justify-between duration-500 text-white gap-2 ml-5 bg-green-500 bg-opacity-75 hover:bg-opacity-90 rounded-md px-4 py-2 shadow-md cursor-pointer">
                <p>Export </p>
                <span>
                  <RiFileExcel2Fill />
                </span>
              </div>
            </button>
            <div className="flex gap-2">
              <input
                type="date"
                onChange={(e) => setFrom(e.target.value)}
                className="inputUser w-full"
              />
              <div className="">
                <label htmlFor="">To</label>
              </div>
              <div>
                <input
                  type="date"
                  className="w-full inputUser"
                  onChange={(e) => setTo(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className=" bg-white   md:h-[18rem] xl:h-[29rem] pb-2  3xl:h-[43rem]   w-[98%] m-auto    ">
            <div className="overflow-y-auto w-full h-[75%] xl:h-[90%]">
              <table className="w-[130%] overflow-x-auto">
                <thead className="sticky top-0 ">
                  <tr>
                    <th className="py-4 pl-5 border">#</th>
                    <th className="thead  ">
                      <div className="flex items-center justify-between">
                        Full Name
                        <div
                          className="text-base rounded-md shadow-md  p-2 bg-teal-600 hover:bg-teal-600 text-white"
                          onClick={() => setOpenFilter(!openFilter)}
                        >
                          <FaFilter />
                        </div>
                      </div>
                      {openFilter && (
                        <input
                          onBlur={handleSearchParams}
                          type="text"
                          name="fullname"
                          className="inputLogin p-1.5 shadow-xl rounded  "
                          placeholder="Full Name"
                        />
                      )}
                    </th>
                    <th className="thead">
                      <div className="flex items-center justify-between">
                        Organ Level
                        <div
                          className="text-base rounded-md shadow-md  p-2 bg-teal-600 hover:bg-teal-600 text-white"
                          onClick={() => setOpenFilter(!openFilter)}
                        >
                          <FaFilter />
                        </div>
                      </div>
                      {openFilter && (
                        <input
                          type="text"
                          name="organ_level"
                          onBlur={handleSearchParams}
                          className="inputLogin p-1.5 shadow-xl rounded  "
                          placeholder="Organ Level"
                        />
                      )}
                    </th>
                    <th className="thead">
                      <div className="flex items-center justify-between">
                        Doctor
                        <div
                          className="text-base rounded-md shadow-md  p-2 bg-teal-600 hover:bg-teal-600 text-white"
                          onClick={() => setOpenFilter(!openFilter)}
                        >
                          <FaFilter />
                        </div>
                      </div>
                      {openFilter && (
                        <input
                          type="text"
                          name="doctor"
                          onBlur={handleSearchParams}
                          className="inputLogin p-1.5 shadow-xl rounded  "
                          placeholder="Doctor"
                        />
                      )}
                    </th>
                    <th className="thead">
                      <div className="flex items-center justify-between">
                        Hospital
                        <div
                          className="text-base rounded-md shadow-md  p-2 bg-teal-600 hover:bg-teal-600 text-white"
                          onClick={() => setOpenFilter(!openFilter)}
                        >
                          <FaFilter />
                        </div>
                      </div>
                      {openFilter && (
                        <input
                          type="text"
                          name="hospital"
                          onBlur={handleSearchParams}
                          className="inputLogin p-1.5 shadow-xl rounded  "
                          placeholder="Hospital"
                        />
                      )}
                    </th>
                    <th className="thead">
                      <div className="flex items-center justify-between">
                        Total Of Surgery
                        <div
                          className="text-base rounded-md shadow-md  p-2 bg-teal-600 hover:bg-teal-600 text-white"
                          onClick={() => setOpenFilter(!openFilter)}
                        >
                          <FaFilter />
                        </div>
                      </div>
                      {openFilter && (
                        <input
                          type="text"
                          name="totalOfSurgery"
                          onBlur={handleSearchParams}
                          className="inputLogin p-1.5 shadow-xl rounded  "
                          placeholder="Total Of Surgery"
                        />
                      )}
                    </th>
                    <th className="thead">
                      <div className="flex items-center justify-between">
                        Total Of Patient
                        <div
                          className="text-base rounded-md shadow-md  p-2 bg-teal-600 hover:bg-teal-600 text-white"
                          onClick={() => setOpenFilter(!openFilter)}
                        >
                          <FaFilter />
                        </div>
                      </div>
                      {openFilter && (
                        <input
                          type="text"
                          name="totalOfPatient"
                          onBlur={handleSearchParams}
                          className="inputLogin p-1.5 shadow-xl rounded  "
                          placeholder="Total Of Patient"
                        />
                      )}
                    </th>
                    <th className="thead">
                      <div className="flex items-center justify-between">
                        Total Of Hospital
                        <div
                          className="text-base rounded-md shadow-md  p-2 bg-teal-600 hover:bg-teal-600 text-white"
                          onClick={() => setOpenFilter(!openFilter)}
                        >
                          <FaFilter />
                        </div>
                      </div>
                      {openFilter && (
                        <input
                          type="text"
                          name="total_of_hospital"
                          onBlur={handleSearchParams}
                          className="inputLogin p-1.5 shadow-xl rounded  "
                          placeholder="Total Of Hospital"
                        />
                      )}
                    </th>
                    <th className="thead">
                      <div className="flex items-center justify-between">
                        Helping Of Doctor
                        <div
                          className="text-base rounded-md shadow-md  p-2 bg-teal-600 hover:bg-teal-600 text-white"
                          onClick={() => setOpenFilter(!openFilter)}
                        >
                          <FaFilter />
                        </div>
                      </div>
                      {openFilter && (
                        <input
                          type="text"
                          name="helpDr"
                          onBlur={handleSearchParams}
                          className="inputLogin p-1.5 shadow-xl rounded  "
                          placeholder="Helping Of Doctor"
                        />
                      )}
                    </th>
                    <th className="thead">
                      <div className="flex items-center justify-between">
                        Zad
                        <div
                          className="text-base rounded-md shadow-md  p-2 bg-teal-600 hover:bg-teal-600 text-white"
                          onClick={() => setOpenFilter(!openFilter)}
                        >
                          <FaFilter />
                        </div>
                      </div>
                      {openFilter && (
                        <input
                          type="text"
                          name="zad"
                          onBlur={handleSearchParams}
                          className="inputLogin p-1.5 shadow-xl rounded  "
                          placeholder="Zad"
                        />
                      )}
                    </th>
                    <th className="thead">
                      <div className="flex items-center justify-between">
                        Date
                        <div
                          className="text-base rounded-md shadow-md  p-2 bg-teal-600 hover:bg-teal-600 text-white"
                          onClick={() => setOpenFilter(!openFilter)}
                        >
                          <FaFilter />
                        </div>
                      </div>
                      {openFilter && (
                        <input
                          type="date"
                          name="date"
                          onBlur={handleSearchParams}
                          className="inputLogin p-1.5 shadow-xl rounded  "
                          placeholder="Date"
                        />
                      )}
                    </th>
                  </tr>
                </thead>
                <tbody className="">
                  {data.map((item, index) => {
                    return (
                      <tr key={index} className={"tableRow"}>
                        <td>{index + 1}</td>
                        <td>{item.fullname}</td>
                        <td className="flex">
                          {item.organ_level.map((organ, index) => {
                            return <div key={index}> {organ + " , "} </div>;
                          })}
                        </td>

                        <td>{item.doctor}</td>
                        <td>{item.hospital}</td>
                        <td>{item.total_of_surgery}</td>
                        <td>{item.total_of_patient}</td>
                        <td>{item.total_of_hospital}</td>
                        <td>{item.helpDr}</td>

                        <td>{item.zad}</td>
                        <td>{item.date}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="gap-3 px-3 flex justify-end items-center pt-2  pb-3">
              <div>
                <select
                  name=""
                  className="mt-1  bg-white border w-20 focus:ring-2  focus:ring-slate-900 rounded-md   focus:outline-none"
                  onChange={(e) => setPerPage(e.target.value)}
                  id=""
                >
                  <option value="20">20</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>

              <div className="flex justify-center  mt-1">
                <ReactPaginate
                  previousLabel="<"
                  nextLabel={">"}
                  // nextClassName='bg-blue-500  hover:bg-blue-600 text-white font-semibold px-2 py-2 rounded-md'
                  breakLabel="..."
                  className="flex  items-center   -space-x-px h-8 text-sm"
                  previousClassName="flex items-center justify-center px-3 h-8 ml-0  border border-gray-300 rounded-l-lg "
                  nextClassName="flex items-center justify-center px-3 h-8 leading-tight text-gray-500  border border-gray-300 rounded-r-lg "
                  pageLinkClassName="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500  border border-gray-300  "
                  breakClassName="w-8 py-2 text-center"
                  pageCount={Math.ceil(total / perPage)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={(event) => {
                    let currentPage = event.selected + 1;
                    dispatch(
                      getWhatsUpInfoAction(
                        perPage,
                        currentPage,
                        fullname,
                        organ_level,
                        doctor,
                        hospital,
                        totalOfPatient,
                        totalOfSurgery,
                        total_of_hospital,
                        zad,
                        date,
                        locationId,
                        exportToExcel
                      )
                    );
                    setCurrentPage(currentPage);
                  }}
                  activeLinkClassName="bg-black text-white flex  items-center  -space-x-px h-8 text-sm"
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default WhatsUpSurgery;
