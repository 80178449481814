// import moment from "moment";
import React, { useEffect, useState } from "react";
import { addfeedback, saveFeebackAction } from "../../../Action/lezhnaAction";
import { useDispatch } from "react-redux";

import { useParams } from "react-router-dom";
import { getCommiteWithVisitAction } from "../../../Action/commiteAction";
import Swal from "sweetalert2";

const Visit = ({ patient, visit_id, activeTab }) => {
  // const currentDate = moment().format("YYYY-MM-DD");
  const [currentDate, setCurrentDate] = useState(new Date());
  const { id } = useParams();
  const [commiteId, setCommiteId] = useState(null);
  const [color, setColor] = useState(null);

  const [inputValueText, setInputValueText] = useState({
    commite_id: null,
    lezhna_id: null,
    amountOfRent: null,
    GovOrPrivateEmp: null,
    metters: null,
    NeighborhoodType: null,
    economicStatus: null,
    typeVihicle: null,
    numberOfHouse: null,
    typeOfEstiPrice: null,

    amountOfSalary: null,
    amountOfLoan: null,
    resonOfDebt: null,
    haveOtherDeasise: null,
    typeOfDeasise: null,
    numOfchildren: null,
    numberOfStudent: null,
    monthlyIncomeFamily: null,
    povertyRates: null,
    HMCabilitySurgery: null,
    patientDeserveMediaWork: null,
    relationship: null,
    name: null,
    phone: null,
    note: null,
    numberOfland: null,
    nowDate: currentDate,
    mercy: null,
    Typeofdisease: null,
  });
  const handelInputTextChange = (e) => {
    const { name, value } = e.target;
    setInputValueText({ ...inputValueText, [name]: value });
  };
  const [inputValue, setInputValue] = useState({
    alive: "no",
    salaryEmploey: "no",
    house: "no",
    tenant: "no",
    car: "no",
    land: "no",
    debt: "no",
    monthlyIncome: "no",
    poverty: "no",
    relativesHelp: "no",
  });

  const handelInput = (e) => {
    if (e.target.checked) {
      setInputValue({
        ...inputValue,
        [e.target.name]: "yes",
      });
    } else if (!e.target.checked || inputValue.alive == null) {
      setInputValue({
        ...inputValue,
        [e.target.name]: "no",
      });
    }
  };

  const dispatch = useDispatch();
  const number = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const handleCost = [
    10, 20, 30, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100,
  ];
  const {
    alive,
    salaryEmploey,
    house,
    tenant,
    car,
    land,
    debt,
    monthlyIncome,
    poverty,
    relativesHelp,
  } = inputValue;
  const {
    lezhna_id,
    commite_id,
    amountOfLoan,
    amountOfRent,
    GovOrPrivateEmp,
    metters,
    NeighborhoodType,
    economicStatus,
    typeVihicle,
    typeOfEstiPrice,
    amountOfSalary,
    resonOfDebt,
    haveOtherDeasise,
    typeOfDeasise,
    numOfchildren,
    numberOfStudent,
    monthlyIncomeFamily,
    povertyRates,
    HMCabilitySurgery,
    patientDeserveMediaWork,
    relationship,
    name,
    phone,
    note,
    numberOfland,
    nowDate,
    numberOfHouse,
    Typeofdisease,
  } = inputValueText;
  const handelSave = (event) => {
    event.preventDefault();
    if (commiteId == null) {
      Swal.fire({
        icon: "warning",
        timer: 2000,
        title: "Not Completed",
        text: "You must first fill out a medical form",
      });
      return;
    }
    dispatch(
      saveFeebackAction(
        1,
        visit_id,
        lezhna_id,
        commiteId,
        amountOfLoan,
        amountOfRent,
        GovOrPrivateEmp,
        metters,
        NeighborhoodType,
        economicStatus,
        typeVihicle,
        typeOfEstiPrice,
        amountOfSalary,
        resonOfDebt,
        haveOtherDeasise,
        typeOfDeasise,
        numOfchildren,
        numberOfStudent,
        monthlyIncomeFamily,
        povertyRates,
        HMCabilitySurgery,
        patientDeserveMediaWork,
        relationship,
        name,
        phone,
        note,
        numberOfland,
        nowDate,
        numberOfHouse,
        alive,
        salaryEmploey,
        poverty,
        house,
        tenant,
        car,
        land,
        debt,
        monthlyIncome,
        inputValueText.mercy,
        Typeofdisease

        // GovOrPrivateEmp
      )
    );
    dispatch(getCommiteWithVisitAction(id));
    dispatch(getCommiteWithVisitAction(id));

    activeTab(0);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (commiteId == null) {
      Swal.fire({
        icon: "warning",
        timer: 2000,
        title: "Not Completed",
        text: "You must first fill out a medical form",
      });
      return;
    }

    dispatch(
      addfeedback(
        1,
        visit_id,
        lezhna_id,
        commiteId,
        amountOfLoan,
        amountOfRent,
        GovOrPrivateEmp,
        metters,
        NeighborhoodType,
        economicStatus,
        typeVihicle,
        typeOfEstiPrice,
        amountOfSalary,
        resonOfDebt,
        haveOtherDeasise,
        typeOfDeasise,
        numOfchildren,
        numberOfStudent,
        monthlyIncomeFamily,
        povertyRates,
        HMCabilitySurgery,
        patientDeserveMediaWork,
        relationship,
        name,
        phone,
        note,
        numberOfland,
        nowDate,
        numberOfHouse,
        alive,
        salaryEmploey,
        poverty,
        house,
        tenant,
        car,
        land,
        debt,
        monthlyIncome,
        inputValueText.mercy,
        Typeofdisease

        // GovOrPrivateEmp
      )
    );
    dispatch(getCommiteWithVisitAction(id));
    dispatch(getCommiteWithVisitAction(id));

    activeTab(0);
  };

  // const [inputValue, setInputValue] = useState({
  //   Pname: "",
  // })

  useEffect(() => {
    dispatch(getCommiteWithVisitAction(id));
    if (patient == undefined) {
      return;
    }

    patient.map((item) => {
      item.commites
        .filter((filter) => filter.visit_id == visit_id)
        .map((element) => {
          setCommiteId(element.id);
          setColor(element.color);

          element.lezhnas.map((lezhna) => {
            setInputValue({
              alive: lezhna.alive,
              salaryEmploey: lezhna.salaryEmploye,
              house: lezhna.house,
              tenant: lezhna.teanant,
              poverty: lezhna.poverty,
              car: lezhna.Vehicle,
              land: lezhna.land,
              debt: lezhna.debt,
              monthlyIncome: lezhna.monthlyIncome,
            });
            setInputValueText({
              lezhna_id: lezhna.id,
              GovOrPrivateEmp: lezhna.govOrPriveteEmp,
              amountOfRent: lezhna.amountOfRent,
              economicStatus: lezhna.economicStatus,
              NeighborhoodType: lezhna.NeighborhoodTypeselect,
              metters: lezhna.metters,
              typeVihicle: lezhna.typeofVehicle,
              typeOfEstiPrice: lezhna.typeofLandEstimatedPrice,
              amountOfSalary: lezhna.amountofSalary,
              amountOfLoan: lezhna.amountofLoan,
              resonOfDebt: lezhna.resonOfDebt,
              haveOtherDeasise: lezhna.haveOtherDiseases,
              // typeOfDeasise: lezhna.Typeofdisease,
              numOfchildren: lezhna.numberofChildren,
              numberOfStudent: lezhna.numberofStudent,
              monthlyIncomeFamily: lezhna.monthlyIncomeFamily,
              povertyRates: lezhna.PovertyRates,
              HMCabilitySurgery: lezhna.HMCabilitToSurgery,
              patientDeserveMediaWork: lezhna.PMediaWork,
              relationship: lezhna.relationship,
              name: lezhna.Name,
              phone: lezhna.phoneNumber,
              numberOfland: lezhna.numberOfLand,
              nowDate: lezhna.nowDate,
              numberOfHouse: lezhna.numberOfHouse,
              note: lezhna.note,
              Typeofdisease: lezhna.typeOfDeasise,
              mercy: lezhna.mercy,
            });
          });
        });
    });
    // console.log(inputValue.pNmae)
  }, [visit_id]);
  return (
    <div>
      <form action="" className="parentFormVisit">
        <div className="childFormVisit">
          <div className="checkbox-wrapper-42">
            <input
              id="cbx-42"
              name="car"
              checked={inputValue.car == "yes" ? true : false}
              onChange={handelInput}
              defaultValue={inputValue.Vehicle}
              type="checkbox"
            />
            <label className="cbx" htmlFor="cbx-42"></label>
            <label className="lbl" htmlFor="cbx-42">
              ئۆتۆمبیلی هەیە
            </label>
          </div>

          <div className="checkbox-wrapper-42">
            <input
              id="cbx-43"
              checked={inputValue.land == "yes" ? true : false}
              name="land"
              onChange={handelInput}
              value={inputValue.land != null ? "yes" : "no"}
              type="checkbox"
            />
            <label className="cbx" htmlFor="cbx-43"></label>
            <label className="lbl" htmlFor="cbx-43">
              موڵک و زەوی هەیە
            </label>
          </div>
          {/*end */}

          <div className="checkbox-wrapper-42">
            <input
              id="cbx-44"
              checked={inputValue.monthlyIncome == "yes" ? true : false}
              name="monthlyIncome"
              onChange={handelInput}
              value={inputValue.monthlyIncome != null ? "yes" : "no"}
              type="checkbox"
            />
            <label className="cbx" htmlFor="cbx-44"></label>
            <label className="lbl" htmlFor="cbx-44">
              داهاتی مانگانەی
            </label>
          </div>
          {/*end */}

          <div className="checkbox-wrapper-42">
            <input
              id="cbx-45"
              checked={inputValue.poverty == "yes" ? true : false}
              name="poverty"
              onChange={handelInput}
              value={inputValue.poverty != null ? "yes" : "no"}
              type="checkbox"
            />
            <label className="cbx" htmlFor="cbx-45"></label>
            <label className="lbl" htmlFor="cbx-45">
              هەژارە
            </label>
          </div>
          {/*end */}

          <div className="checkbox-wrapper-42">
            <input
              id="cbx-46"
              checked={inputValue.debt == "yes" ? true : false}
              name="debt"
              onChange={handelInput}
              value={inputValue.debt != null ? "yes" : "no"}
              type="checkbox"
            />
            <label className="cbx" htmlFor="cbx-46"></label>
            <label className="lbl" htmlFor="cbx-46">
              قەرزارە
            </label>
          </div>
          {/*end */}
          <div className="checkbox-wrapper-42">
            <input
              id="cbx-47"
              checked={inputValue.tenant == "yes" ? true : false}
              name="tenant"
              onChange={handelInput}
              value={inputValue.tenant != null ? "yes" : "no"}
              type="checkbox"
            />
            <label className="cbx" htmlFor="cbx-47"></label>
            <label className="lbl" htmlFor="cbx-47">
              کرێچیــە
            </label>
          </div>
          {/*end */}

          <div className="checkbox-wrapper-42">
            <input
              id="cbx-100"
              checked={inputValue.house == "yes" ? true : false}
              name="house"
              onChange={handelInput}
              value={inputValue.house != null ? "yes" : "no"}
              type="checkbox"
            />
            <label className="cbx" htmlFor="cbx-100"></label>
            <label className="lbl" htmlFor="cbx-100">
              خانووی خۆیەتی
            </label>
          </div>
          {/*end */}
          <div className="checkbox-wrapper-42">
            <input
              id="cbx-48"
              checked={inputValue.salaryEmploey == "yes" ? true : false}
              name="salaryEmploey"
              onChange={handelInput}
              value={inputValue.salaryEmploey}
              type="checkbox"
            />
            <label className="cbx" htmlFor="cbx-48"></label>
            <label className="lbl" htmlFor="cbx-48">
              مووچە خۆرە یاخود نا
            </label>
          </div>
          {/*end */}
          <div className="checkbox-wrapper-42">
            <input
              id="cbx-49"
              name="alive"
              checked={inputValue.alive == "yes" ? true : false}
              onChange={handelInput}
              value={inputValue.alive}
              type="checkbox"
            />
            <label className="cbx" htmlFor="cbx-49"></label>
            <label className="lbl" htmlFor="cbx-49">
              لە ژیاندا ماوە
            </label>
          </div>
          {/*end */}
        </div>

        <div className="parentInputGrid">
          <div className="w-full ">
            <div className="w-full  text-right">
              <label htmlFor="number"> * چەند مەترە</label>
            </div>
            <input
              type="text"
              name="metters"
              value={inputValueText.metters}
              onChange={handelInputTextChange}
              id="number"
              className="inputLogin text-right w-full"
              placeholder="چەند مەترە"
            />
          </div>

          <div className="w-full ">
            <div className="w-full  text-right">
              <label htmlFor="numberOfHouse">* ژمارەی خانوو </label>
            </div>
            <input
              type="text"
              name="numberOfHouse"
              value={inputValueText.numberOfHouse}
              onChange={handelInputTextChange}
              id="numberOfHouse"
              className="inputLogin text-right  w-full"
              placeholder="ژمارەی خانوو"
            />
          </div>

          <div className="w-full ">
            <div className="w-full text-right">
              <label htmlFor="NeighborhoodType">* جۆری گەرەک </label>
            </div>
            <select
              name="NeighborhoodType"
              value={inputValueText.NeighborhoodType}
              onChange={handelInputTextChange}
              className="select mt-2 bg-white"
              id="NeighborhoodType"
            >
              <option value="">Select ..</option>
              <option value="هەژارنشین">هەژارنشین </option>
              <option value="ناوەند">ناوەند</option>
              <option value="دەوڵەمەند">دەوڵەمەند</option>
            </select>
          </div>
          <div className="w-full  ">
            <div className="w-full  text-right">
              <label htmlFor="ec" className="text-right  w-full">
                * باری ئابووری
              </label>
            </div>
            <select
              name="economicStatus" // Make sure this matches the state variable key
              value={inputValueText.economicStatus}
              onChange={handelInputTextChange}
              className="select mt-2 bg-white"
              id="ec"
            >
              <option value="">Select ..</option>
              <option value="خراپ">خراپ</option>
              <option value="ناوەند">ناوەند</option>
              <option value="باش">باش</option>
              <option value="زۆر باش">زۆر باش</option>
            </select>
          </div>

          <div className="w-full ">
            <div className="w-full text-right">
              <label htmlFor="emp">* شایستەی کاری راگەیاندنە</label>
            </div>

            <select
              name="patientDeserveMediaWork"
              value={inputValueText.patientDeserveMediaWork}
              onChange={handelInputTextChange}
              className="select mt-2 bg-white"
              id="emp"
            >
              <option value="">Select ..</option>
              <option value="بەڵێ">بەڵێ </option>
              <option value="نەخێر">نەخێر </option>
            </select>
          </div>

          <div className="w-full ">
            <div className="w-full text-right">
              <label htmlFor="ch">* ژ. مناڵ</label>
            </div>

            <select
              name="numOfchildren"
              value={inputValueText.numOfchildren}
              onChange={handelInputTextChange}
              className="select mt-2 bg-white"
              id="ch"
            >
              <option value="">Select ..</option>
              {number.map((item, index) => {
                return (
                  <option key={index} value={item}>
                    {item}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="w-full ">
            <div className="w-full text-right">
              <label htmlFor="numberOfStudent">* ژ. خوێندکار </label>
            </div>
            <select
              name="numberOfStudent"
              value={inputValueText.numberOfStudent}
              onChange={handelInputTextChange}
              className="select mt-2 bg-white"
              id="numberOfStudent"
            >
              <option value="">Select ..</option>
              {number.map((item, index) => {
                return (
                  <option key={index} value={item}>
                    {item}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="w-full">
            <div className="w-full text-right">
              <label htmlFor="hmc">
                * توانای لە ئەستۆ گرتنی تێچوی نەشتەرگەری
              </label>
            </div>
            <input
              name="HMCabilitySurgery"
              value={inputValueText.HMCabilitySurgery}
              onChange={handelInputTextChange}
              placeholder="توانای لە ئەستۆ گرتنی تێچوی نەشتەرگەری"
              className="inputLogin text-right  w-full"
              id="encom"
            />
          </div>

          <div className="w-full ">
            <div className="w-full text-right">
              <label htmlFor="nameRealtion">* ناوی خزم یان کەس</label>
            </div>
            <input
              type="text"
              name="name"
              value={inputValueText.name}
              onChange={handelInputTextChange}
              id="nameRealtion"
              className="inputLogin text-right  w-full"
              placeholder="ناوی خزم و کەس"
            />
          </div>

          <div className="w-full ">
            <div className="w-full text-right">
              <label htmlFor="encom">
                * داهاتی مانگانەی هاوسەری یان خێزانی
              </label>
            </div>
            <input
              type="text"
              name="monthlyIncomeFamily"
              value={inputValueText.monthlyIncomeFamily}
              onChange={handelInputTextChange}
              id="encom"
              className="inputLogin text-right  w-full"
              placeholder="داهاتی مانگانەی هاوسەری یان خێزانی"
            />
          </div>
          <div className="w-full ">
            <div className="w-full text-right">
              <label htmlFor="Typeofdisease">* جۆری نەخۆشیەکە</label>
            </div>
            <input
              type="text"
              name="Typeofdisease"
              value={inputValueText.Typeofdisease}
              onChange={handelInputTextChange}
              id="Typeofdisease"
              className="inputLogin text-right  w-11/12"
              placeholder="جۆری نەخۆشیەکە"
            />
          </div>

          {/* media */}
          <div className="w-full ">
            <div className="w-full text-right">
              <label htmlFor="haveOtherDeasise">* نەخۆشی تریان هەیە </label>
            </div>

            <select
              value={inputValueText.haveOtherDeasise}
              name="haveOtherDeasise"
              onChange={handelInputTextChange}
              className="select mt-2 bg-white"
              id="haveOtherDeasise"
            >
              <option value="">Select ..</option>
              <option value="بەڵێ">بەڵێ </option>
              <option value="نەخێر">نەخێر </option>
            </select>
          </div>

          <div></div>
          <div></div>

          <div className="w-full ">
            <div className="w-full text-right">
              <label htmlFor="numberRelation">
                * رەقەمی مۆبایل خزم یان کەس
              </label>
            </div>
            <input
              type="text"
              name="phone"
              value={inputValueText.phone}
              onChange={handelInputTextChange}
              id="numberRelation"
              className="inputLogin text-right  w-full"
              placeholder="رەقەمی مۆبایل خزم یان کەس "
            />
          </div>

          <div className="w-full ">
            <div className="w-full text-right">
              <label htmlFor="relationShip">* خزمایەتی</label>
            </div>
            <input
              type="text"
              name="relationship"
              value={inputValueText.relationship}
              onChange={handelInputTextChange}
              id="relationShip"
              className="inputLogin text-right  w-full"
              placeholder="خزمایەتی "
            />
          </div>

          <div className="w-full hidden ">
            <label htmlFor="number">Current Date *</label>
            <input
              type="text"
              name="nowDate"
              readOnly
              value={currentDate}
              onChange={handelInputTextChange}
              id="number"
              className="inputLogin text-right  w-full"
              placeholder="Phone Number "
            />
          </div>
        </div>
        {/* grid parent INput */}

        <div className="parentInputGrid">
          {inputValue.debt == "yes" ? (
            <div className="w-full ">
              <div className="w-full text-right">
                <label htmlFor="debt">*بڕی قەرزەکە </label>
              </div>
              <input
                type="text"
                name="amountOfLoan"
                value={inputValueText.amountOfLoan}
                onChange={handelInputTextChange}
                id="debt"
                className="inputLogin text-right  w-full"
                placeholder="بڕی قەرزەکە "
              />
            </div>
          ) : (
            ""
          )}

          {inputValue.tenant == "yes" ? (
            <div className="w-full ">
              <div className="w-full text-right">
                <label htmlFor="ژمارەی خاوەن خانوو">
                  * ژمارەی خاوەن خانوو{" "}
                </label>
              </div>
              <input
                type="text"
                value={inputValueText.numberOfland}
                name="numberOfland"
                onChange={handelInputTextChange}
                id="ژمارەی خاوەن خانوو"
                className="inputLogin text-right  w-full"
                placeholder="ژمارەی خاوەن خانوو"
              />
            </div>
          ) : null}

          {inputValue.tenant == "yes" ? (
            <div className="w-full ">
              <div className="w-full text-right">
                <label htmlFor="numberOfHouse">* بڕی کرێ خانوو </label>
              </div>
              <input
                type="text"
                name="amountOfRent"
                value={inputValueText.amountOfRent}
                onChange={handelInputTextChange}
                id="numberOfHouse"
                className="inputLogin text-right  w-full"
                placeholder="بڕی کرێ خانوو"
              />
            </div>
          ) : null}

          {inputValue.salaryEmploey == "yes" ? (
            <div className="w-full ">
              <div className="w-full  text-right">
                <label htmlFor="GovOrPrivateEmp">
                  * کارمەندی حکومییە یان کەرتی تایبەت
                </label>
              </div>

              <select
                name="GovOrPrivateEmp" // Make sure this matches the state variable key
                value={inputValueText.GovOrPrivateEmp}
                onChange={handelInputTextChange}
                className="select mt-2 bg-white"
                id="GovOrPrivateEmp"
              >
                <option value="">Select ..</option>
                <option value="حکومەت">حکومەت </option>
                <option value="تایبەت">تایبەت </option>
              </select>
            </div>
          ) : null}

          {inputValue.land == "yes" ? (
            <div className="w-full ">
              <div className="w-full text-right">
                <label htmlFor="Price">
                  * جۆری موڵک یاخود زەوی نرخی خامڵیندراو
                </label>
              </div>
              <input
                type="text"
                name="typeOfEstiPrice"
                value={inputValueText.typeOfEstiPrice}
                onChange={handelInputTextChange}
                id="Price"
                className="inputLogin text-right  w-full"
                placeholder="جۆری موڵک یاخود زەوی نرخی خامڵیندراو"
              />
            </div>
          ) : null}

          {inputValue.monthlyIncome == "yes" ? (
            <div className="w-full ">
              <div className="w-full text-right">
                <label htmlFor="salary">* بڕی مووچە </label>
              </div>
              <input
                type="text"
                name="amountOfSalary"
                value={inputValueText.amountOfSalary}
                onChange={handelInputTextChange}
                id="salary"
                className="inputLogin  text-right w-full"
                placeholder="بڕی مووچە"
              />
            </div>
          ) : null}

          {inputValue.poverty == "yes" ? (
            <div className="w-full ">
              <div className="w-full text-right">
                <label htmlFor="pov">* ڕێژەی هەژاری </label>
              </div>

              <select
                name="povertyRates"
                value={inputValueText.povertyRates}
                onChange={handelInputTextChange}
                className="select mt-2 bg-white"
                id="pov"
              >
                <option value="">Select ..</option>
                {handleCost.map((cost, index) => {
                  return (
                    <option key={index} value={cost}>
                      {cost}
                    </option>
                  );
                })}
              </select>
            </div>
          ) : null}

          {inputValue.debt == "yes" ? (
            <div className="w-full ">
              <div className="w-full text-right">
                <label htmlFor="resonDebt">*هۆکاری قەرزەکە </label>
              </div>
              <input
                type="text"
                value={inputValueText.resonOfDebt}
                name="resonOfDebt"
                onChange={handelInputTextChange}
                id="resonDebt"
                className="inputLogin text-right   w-full"
                placeholder="هۆکاری قەرزاری"
              />
            </div>
          ) : (
            <div className="hidden"> </div>
          )}
          {inputValue.car == "yes" ? (
            <div className="w-full ">
              <div className="w-full text-right">
                <label htmlFor="typeCAR">* جۆری ئۆتۆمبێل </label>
              </div>
              <input
                type="text"
                name="typeVihicle"
                value={inputValueText.typeVihicle}
                onChange={handelInputTextChange}
                id="typeCAR"
                className="inputLogin text-right  w-full"
                placeholder="جۆری ئۆتۆمبێل"
              />
            </div>
          ) : null}
        </div>
        <div>
          <div className="py-5 flex gap-3 ">
            <div className="text-right w-full mr-2">
              <label htmlFor="">* تێبینی </label>
              <textarea
                name="note"
                onChange={handelInputTextChange}
                value={inputValueText.note}
                placeholder="تێبینی"
                className="outline-none ring-1 px-4 ring-indigo-500 rounded-lg w-full text-right"
                rows="7"
              ></textarea>
            </div>

            <div className="w-full">
              <div className="w-full flex justify-end ">
                <label htmlFor="" className="text-right w-full mr-2">
                 * بەزەیی
                </label>
              </div>
              <textarea
                name="mercy"
                onChange={handelInputTextChange}
                value={inputValueText.mercy}
                placeholder="بەزەیی"
                className="outline-none  ring-1 px-4 ring-indigo-500 rounded-lg w-full text-right"
                rows="7"
              ></textarea>
            </div>
          </div>
        </div>

        <div className="flex items-center gap-4">
          <div>
            <button
              className="btnCoustmDesignPurple px-10 "
              onClick={handelSave}
            >
              Save
            </button>
          </div>
          <div>
            <button onClick={handleSubmit} className="btnCoustmDesignBlue">
              Assigned
            </button>
          </div>
        </div>
      </form>
      <div className="pb-20"></div>
    </div>
  );
};

export default Visit;
