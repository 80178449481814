import {
  CREATE_ADMINSTRATOR_FAIL,
  CREATE_ADMINSTRATOR_SUCCESS,
} from "../../ActionType/AdminstratorType/AdminstratorType";
import {
  CREATE_ATTENDANCE_REQUEST,
  FETCH_ATTENDANCE_FAIL,
  FETCH_ATTENDANCE_REQUEST,
  FETCH_ATTENDANCE_SUCCESS,
  SHOW_ATTENDANCE_FAIL,
  SHOW_ATTENDANCE_REQUEST,
  SHOW_ATTENDANCE_SUCCESS,
  STORE_RECORD_ATTENDANCE_FAIL,
  STORE_RECORD_ATTENDANCE_REQUEST,
  STORE_RECORD_ATTENDANCE_SUCCESS,
  UPDATE_ATTENDANCE_FAIL,
  UPDATE_ATTENDANCE_REQUEST,
  UPDATE_ATTENDANCE_SUCCESS,
} from "../../ActionType/AttendanceType/AttendanceType";

export const createAttendanceReducer = (
  state = { loading: true, attendance: [], error: "" },
  action
) => {
  switch (action.type) {
    case CREATE_ATTENDANCE_REQUEST:
      return {
        loading: true,
      };
    case CREATE_ADMINSTRATOR_SUCCESS:
      return {
        loading: false,
        attendance: action.payload,
        error: "",
      };
    case CREATE_ADMINSTRATOR_FAIL:
      return {
        loading: false,
        attendance: [],
        error: "Please Check Your Request",
      };
    default:
      return state;
  }
};

export const showAttendanceReducer = (
  state = { loading: true, attendances: [],total:0, error: "" },
  action
) => {
  switch (action.type) {
    case SHOW_ATTENDANCE_REQUEST:
      return {
        loading: true,
      };
    case SHOW_ATTENDANCE_SUCCESS:
      return {
        loading: false,
        attendances: action.payload.data,
        total:action.payload.total,
        error: "",
      };
    case SHOW_ATTENDANCE_FAIL:
      return {
        loading: false,
        attendances: [],
        error: "Please Check Your Request",
      };
    default:
      return state;
  }
};

export const storeRecordAttendanceReducer = (
  state = { loading: true, attendance: [], error: "" },
  action
) => {
  switch (action.type) {
    case STORE_RECORD_ATTENDANCE_REQUEST:
      return {
        loading: true,
      };
    case STORE_RECORD_ATTENDANCE_SUCCESS:
      return {
        loading: false,
        attendance: action.payload,
        error: "",
      };
    case STORE_RECORD_ATTENDANCE_FAIL:
      return {
        loading: false,
        attendance: [],
        error: "Please Check Your Request",
      };
    default:
      return state;
  }
};

export const getAttendanceReducer = (
  state = { loading: true, attendances: [],total:0, error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_ATTENDANCE_REQUEST:
      return {
        loading: true,
      };
    case FETCH_ATTENDANCE_SUCCESS:
      return {
        loading: false,
        attendances: action.payload.data,
        total:action.payload.total,
        error: "",
      };
    case FETCH_ATTENDANCE_FAIL:
      return {
        loading: false,
        attendances: [],
        error: "Please Check Your Request",
      };
    default:
      return state;
  }
};


export const updateAttendanceReducer = (
  state = { loading: true, attendance: [], error: "" },
  action
) => {
  switch (action.type) {
    case UPDATE_ATTENDANCE_REQUEST:
      return {
        loading: true,
      };
    case UPDATE_ATTENDANCE_SUCCESS:
      return {
        loading: false,
        attendance: action.payload,
        // attendance: action.payload,
        error: "",
      };
    case UPDATE_ATTENDANCE_FAIL:
      return {
        loading: false,
        attendance: [],
        error: "Please Check Your Request",
      };
    default:
      return state;
  }
};

