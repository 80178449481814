import Swal from "sweetalert2";
import {
  CREATE_RECEIVED_REQUEST,
  CREATE_RECEIVED_SUCCESS,
  FETCH_NOTIFICATION_FAIL,
  FETCH_NOTIFICATION_REQUEST,
  FETCH_NOTIFICATION_SUCCESS,
  FETCH_RECEIVE_FAIL,
  FETCH_RECEIVE_SUCCESS,
  FETCH_RECEVIE_REQUEST,
  SEEN_NOTIFICATION_FAIL,
  SEEN_NOTIFICATION_REQUEST,
  SEEN_NOTIFICATION_SUCCESS,
  SHOW_ALL_RECEIVE_FAIL,
  SHOW_ALL_RECEIVE_SUCCESS,
  SHOW_ALL_RECEVIE_REQUEST,
  SHOW_RECEIVE_SUCCESS,
  SHOW_RECEVIE_REQUEST,
} from "../ActionType/receivedType";
import { api } from "../Api/api";
import { getToken } from "./login";

export const getReciveAction =
  (
    locationID,
    patientFrom,
    code,
    fullname,
    gender,
    phone,
    lezhna_id,
    page,
    perPage,
    doctor
  ) =>
  (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      params: {
        patientFrom,
        code,
        fullname,
        gender,
        phone,
        lezhna_id,
        page,
        perPage,
        doctor
      },
    };
console.log(doctor)
    // dispatch({ type: FETCH_RECEVIE_REQUEST });
    api
      .get(`received/${locationID}`, config)
      .then((response) => {
        dispatch({ type: FETCH_RECEVIE_REQUEST });
        const { status, data } = response;

        if (status === 200) {
          if (lezhna_id == "" || lezhna_id == undefined || lezhna_id == null) {
            console.log(data);
            dispatch({
              type: FETCH_RECEIVE_SUCCESS,
              payload: { data: data.data, total: data.total },
            });
          } else if (
            lezhna_id != "" ||
            lezhna_id != undefined ||
            lezhna_id != null
          ) {
            console.log(data);
            dispatch({
              type: FETCH_RECEIVE_SUCCESS,
              payload: { data: data, total: data.total },
            });
          }
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_RECEIVE_FAIL,
        });
      });
  };
export const showReceiveAction = (id) => (dispatch) => {
  dispatch({
    type: SHOW_ALL_RECEVIE_REQUEST,
  });

  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
  api
    .get(`received/show/${id}`, config)
    .then((response) => {
      const { data, status } = response;

      console.log(data);
      if (status == 200) {
        dispatch({
          type: SHOW_ALL_RECEIVE_SUCCESS,
          payload: data,
        });
      }
    })
    .catch((error) => [console.log(error)]);
};
export const createReceiveAction =
  (recive_id, branchID, lezhnaID, note, bName) => (dispatch) => {
    console.log(recive_id);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    };
    api
      .post(
        "received/create",
        {
          recive_id,
          branch_id: branchID,
          lezhna_id: lezhnaID,
          note,
          branch_name: bName,
        },
        config
      )
      .then((response) => {
        console.log(response);
        dispatch({ type: CREATE_RECEIVED_REQUEST });

        const { status } = response.data;
        const { received } = response.data;
        if (status === 200) {
          dispatch({ type: CREATE_RECEIVED_SUCCESS, payload: received });
          Swal.fire("Good job!", "Patient Was Send", "success");
        }
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Request Is Faild",
        });
      });
  };

export const getNotificationAction = (branchID) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
  api
    .get(`received/getNotification/${branchID}`, config)
    .then((response) => {
      dispatch({ type: FETCH_NOTIFICATION_REQUEST });
      const { status, notifi } = response.data;
      if (status === 200) {
        dispatch({ type: FETCH_NOTIFICATION_SUCCESS, payload: notifi });
      } else {
        console.log("query is not correct");
      }
    })
    .catch((error) => {
      dispatch({ type: FETCH_NOTIFICATION_FAIL });
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Request Is Faild",
      });
    });
};

export const seenNotificationAction = (id) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
  api
    .post(
      `received/seenNotification`,
      {
        id,
      },
      config
    )
    .then((response) => {
      dispatch({ type: SEEN_NOTIFICATION_REQUEST });
      const { status, seen } = response.data;
      if (status === 200) {
        dispatch({ type: SEEN_NOTIFICATION_SUCCESS, payload: seen });

        Swal.fire({
          icon: "success",
          title: "سەرکەوتوو بوو",
          text: "زانیاریەکە بینرا",
          timer: 1500,
        });
      } else {
        console.log("query is not correct");
      }
    })
    .catch((error) => {
      dispatch({ type: SEEN_NOTIFICATION_FAIL });
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Request Is Faild",
      });
    });
};
