import React, { useEffect, useState } from "react";
import { BiSolidParty } from "react-icons/bi";
import { BsCalendarDateFill, BsFillCloudArrowUpFill } from "react-icons/bs";
import {
  FaBirthdayCake,
  FaChild,
  FaCodeBranch,
  FaLocationArrow,
  FaPlus,
  FaTrash,
  FaUserAlt,
  FaUserShield,
} from "react-icons/fa";
import { FaCircleUser, FaLocationCrosshairs, FaPhone } from "react-icons/fa6";
import { IoLanguage } from "react-icons/io5";
import { GiRadarCrossSection } from "react-icons/gi";
import Logo from "../../Image/Logo.jpg";
import { GrPersonalComputer } from "react-icons/gr";
import {
  MdBloodtype,
  MdOutlineEmail,
  MdOutlineSick,
  MdOutlineTransgender,
  MdSensorOccupied,
} from "react-icons/md";
import { TbCertificate } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { getSection } from "../../Action/section";
import { getBranch } from "../../Action/branch";
import { getRolesAction } from "../../Action/RolesAction/RolesAction";
import {
  handleDeleteAlert,
  showUserAction,
  updateUserInformationAction,
} from "../../Action/userRegister";
import { useNavigate, useParams } from "react-router-dom";
import UserCvModal from "../../Modal/UserCv/UserCvModal";
import DangerAlert from "../Alerts/DangerAlert";
const EditUser = () => {
  const getSectionReducer = useSelector((state) => state.getSection);
  const { sections } = getSectionReducer;
  const getBranchReducer = useSelector((state) => state.getBranch);
  const { branchs } = getBranchReducer;
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    dispatch(showUserAction(id));
    dispatch(getSection());
    dispatch(getBranch());
  }, []);
  const [isOpenUserCvModal, setIsOpenUserCvModal] = useState(false);
  const [imageArray, setImageArray] = useState([]);
  const handleFileChange = (event, index) => {
    const newValue = [...imageArray];
    newValue[index] = event.target.files[0];
    setImageArray(newValue);
  };

  const getRoles = useSelector((state) => state.getRoles);
  const { roles } = getRoles;
  // const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState({
    EnName: "",
    KuName: "",
    birthDate: "",
    gender: "",
    phone: "",
    secPhone: "",
    pnpc: "",
    email: "",
    married: "",
    NumberOfChildern: "",
    bloodGorup: "",
    DateOfCommencement: "",
    ChronicDiseases: "",
    certificate: "",
    certificateField: "",
    OccupationOutside: "",
    Workplace: "",
    jobTitle: "",
    LanguageLevel: "",
    ku_lang: "",
    ar_lang: "",
    Computerskills: "",
    branch: "",
    section: "",
    role: "",
    hourlyWage: "",
    extraWage: "",
  });

  const handleInputChange = (event) => {
    const { value, name } = event.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  };
  const [image, setImage] = useState(null);
  const [idd, setIdd] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getRolesAction(1, 100));
  }, []);

  const updateUserInformation = useSelector(
    (state) => state.updateUserInformation
  );
  const { errors } = updateUserInformation;
  const showUser = useSelector((state) => state.showUser);
  const { user } = showUser;

  useEffect(() => {
    if (user == undefined || user.length == 0) {
      return;
    }
    const newValueImageArray = user.user_cvs.map((cv) => cv.cv);
    setImageArray(newValueImageArray);
  }, [user]);

  const handleDeleteImage = (id) => {
    dispatch(handleDeleteAlert(id));
    // dispatch(showUserAction(id))
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const {
      EnName,
      KuName,
      birthDate,
      gender,
      phone,
      secPhone,
      pnpc,
      email,
      married,
      NumberOfChildern,
      bloodGorup,
      DateOfCommencement,
      ChronicDiseases,
      certificate,
      certificateField,
      OccupationOutside,
      Workplace,
      jobTitle,
      LanguageLevel,
      Computerskills,
      branch,
      section,
      role,
      hourlyWage,
      extraWage,
      ku_lang,
      ar_lang,
    } = inputValue;

    dispatch(
      updateUserInformationAction(
        id,
        EnName,
        KuName,
        birthDate,
        gender,
        phone,
        secPhone,
        pnpc,
        email,
        married,
        NumberOfChildern,
        bloodGorup,
        DateOfCommencement,
        ChronicDiseases,
        certificate,
        certificateField,
        OccupationOutside,
        Workplace,
        jobTitle,
        LanguageLevel,
        ku_lang,
        ar_lang,
        Computerskills,
        branch,
        section,
        role,
        hourlyWage,
        extraWage,
        image,
     
      )
    );
    dispatch(showUserAction(id));
    dispatch(showUserAction(id));
  };
  useEffect(() => {
    if (user == undefined) {
      return;
    }
    // setImage(user.user_cv)
    setInputValue({
      EnName: user.fullname_en,
      KuName: user.fullname_ku,
      birthDate: user.birth_date,
      gender: user.gender,
      phone: user.phone,
      secPhone: user.secound_phone,
      pnpc: user.close_phone,
      email: user.email,
      married: user.married,
      NumberOfChildern: user.number_of_children,
      bloodGorup: user.blood_group,
      DateOfCommencement: user.date_of_commencement,
      ChronicDiseases: user.chronic_diseases,
      certificate: user.certificate,
      certificateField: user.certificate_field,
      OccupationOutside: user.occupationoutside,
      Workplace: user.work_place,
      jobTitle: user.job_tiltle,
      LanguageLevel: user.en_lang,
      ku_lang: user.ku_lang,
      ar_lang: user.ar_lang,
      Computerskills: user.computer_skills,
      branch: user.branch_id,
      section: user.section_id,
      role: user.role_id,
      hourlyWage: user.hourly_wage,
      extraWage: user.extra_wage,
    });
    // console.log(user);
  }, [user]);

  return (
    <>
      {user == undefined || user.user_cvs == undefined ? (
        <div className="flex flex-col">
          <img
            src={Logo}
            alt="Logo"
            className="w-56 m-auto animate-pulse mt-20 "
          />
          <div className="text-2xl text-center font-bold  ">
            <div className="flex justify-center gap-4">
              <p className="animate-charcter">Please Wait</p>
              <div class="container2">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>{" "}
            </div>
          </div>
        </div>
      ) : (
        <>
          <div
            className={`bg-white w-[95%] m-auto px-4 py-2 rounded-md shadow-md ${
              isOpenUserCvModal ? "overflow-hidden" : "overflow-auto"
            }`}
          >
            <div className="parentHeadPrimary items-center">
              <div className="flex gap-2 items-center">
                <div>
                  <button onClick={() => navigate(-1)} className="btn-plus">
                    Back
                  </button>
                </div>
              </div>
              <div className="flex gap-2 items-center">
                <div>
                  <p className="text-blue-500 font-bold">
                    <FaUserAlt />
                  </p>
                </div>
                <p className="font-bold">User</p>
              </div>
            </div>
            <form action=" mt-2">
              <div className="parentInputGrid">
                <div>
                  <label htmlFor="en" className="ml-1">
                    English Name *
                  </label>
                  <div className="parentInputEditUser">
                    <div className="flex -mr-px">
                      <span className="iconInput">
                        <FaCircleUser />
                      </span>
                    </div>
                    <input
                      onChange={handleInputChange}
                      id="en"
                      type="text"
                      className="inputUser"
                      value={inputValue.EnName}
                      placeholder="English Name"
                      name="EnName"
                    />
                  </div>
                  <p className="text-red-400"> {errors && errors.fullname_en}</p>
                </div>{" "}
                <div>
                  <label htmlFor="ku" className="ml-1">
                    Kurdish Name *
                  </label>
                  <div className="parentInputEditUser">
                    <div className="flex -mr-px">
                      <span className="iconInput">
                        <FaCircleUser />
                      </span>
                    </div>
                    <input
                      onChange={handleInputChange}
                      id="ku"
                      type="text"
                      className="inputUser"
                      value={inputValue.KuName}
                      placeholder="Kurdish Name"
                      name="KuName"
                    />
                  </div>{" "}
                  <p className="text-red-500">{errors && errors.fullname_ku}</p>
                </div>{" "}
                <div>
                  <label htmlFor="birth" className="ml-1">
                    Birth Date *
                  </label>
                  <div className="parentInputEditUser">
                    <div className="flex -mr-px">
                      <span className="iconInput">
                        <FaBirthdayCake />
                      </span>
                    </div>

                    <input
                      onChange={handleInputChange}
                      id="birth"
                      type="date"
                      className="inputUser"
                      value={inputValue.birthDate}
                      placeholder="Kurdish Name"
                      name="birthDate"
                    />
                  </div>{" "}
                  <p className="text-red-500">{errors && errors.birth_date}</p>
                </div>{" "}
                <div>
                  <label htmlFor="gender" className="ml-1">
                    Gender *
                  </label>
                  <div className="parentInputEditUser">
                    <div className="flex -mr-px">
                      <span className="iconInput">
                        <MdOutlineTransgender />
                      </span>
                    </div>
                    <select
                      name="gender"
                      onChange={handleInputChange}
                      className="inputUser"
                      id="gender"
                      value={inputValue.gender}
                    >
                      <option value="">Select Gender ...</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                  </div>{" "}
                  <p className="text-red-500">{errors && errors.gender}</p>
                </div>{" "}
                <div>
                  <label htmlFor="phone1" className="ml-1">
                    Phone Number *
                  </label>
                  <div className="parentInputEditUser">
                    <div className="flex -mr-px">
                      <span className="iconInput">
                        <FaPhone />
                      </span>
                    </div>
                    <input
                      onChange={handleInputChange}
                      id="phone1"
                      type="tel"
                      className="inputUser"
                      placeholder="Phone Number"
                      name="phone"
                      value={inputValue.phone}
                    />
                  </div>{" "}
                  <p className="text-red-500">{errors && errors.phone}</p>
                </div>{" "}
                <div>
                  <label htmlFor="phone1" className="ml-1">
                    Secound Phone *
                  </label>
                  <div className="parentInputEditUser">
                    <div className="flex -mr-px">
                      <span className="iconInput">
                        <FaPhone />
                      </span>
                    </div>
                    <input
                      onChange={handleInputChange}
                      id="phone1"
                      type="tel"
                      className="inputUser"
                      value={inputValue.secPhone}
                      placeholder="Secound Phone "
                      name="secPhone"
                    />
                  </div>{" "}
                </div>{" "}
                <div>
                  <label htmlFor="pnpc" className="ml-1">
                    phone number person close *
                  </label>
                  <div className="parentInputEditUser">
                    <div className="flex -mr-px">
                      <span className="iconInput">
                        <FaPhone />
                      </span>
                    </div>
                    <input
                      onChange={handleInputChange}
                      id="pnpc"
                      type="text"
                      className="inputUser"
                      value={inputValue.pnpc}
                      placeholder="The phone number person close"
                      name="pnpc"
                    />
                  </div>{" "}
                </div>{" "}
                <div>
                  <label htmlFor="email" className="ml-1">
                    Email *
                  </label>
                  <div className="parentInputEditUser">
                    <div className="flex -mr-px">
                      <span className="iconInput">
                        <MdOutlineEmail />
                      </span>
                    </div>
                    <input
                      onChange={handleInputChange}
                      id="email"
                      type="email"
                      className="inputUser"
                      value={inputValue.email}
                      placeholder="Email"
                      name="email"
                    />
                  </div>{" "}
                  <p className="text-red-500">{errors && errors.email}</p>
                </div>{" "}
                <div>
                  <label htmlFor="married" className="ml-1">
                    Married *
                  </label>
                  <div className="parentInputEditUser">
                    <div className="flex -mr-px">
                      <span className="iconInput">
                        <BiSolidParty />{" "}
                      </span>
                    </div>
                    <select
                      onChange={handleInputChange}
                      name="married"
                      className="inputUser"
                      id="married"
                      value={inputValue.married}
                    >
                      <option value="">Select Married ...</option>
                      <option value="Married"> Married</option>
                      <option value="Single">Single</option>
                    </select>
                  </div>{" "}
                </div>{" "}
                <div>
                  <label htmlFor="Number Of Childern" className="ml-1">
                    Number Of Childern *
                  </label>
                  <div className="parentInputEditUser">
                    <div className="flex -mr-px">
                      <span className="iconInput">
                        <FaChild />
                      </span>
                    </div>
                    <select
                      onChange={handleInputChange}
                      value={inputValue.NumberOfChildern}
                      name="NumberOfChildern"
                      className="inputUser"
                      id="Number Of Childern"
                    >
                      <option value="">Number Of Childern ...</option>
                      <option value="1"> 1</option>
                      <option value="2">2</option>
                      <option value="3">3 </option>
                      <option value="4">4</option>
                      <option value="5"> 5</option>
                      <option value="6">6</option>
                      <option value="7"> 7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                    </select>
                  </div>{" "}
                </div>{" "}
                <div>
                  <label htmlFor="bloodGorup" className="ml-1">
                    Blood Gorup *
                  </label>
                  <div className="parentInputEditUser">
                    <div className="flex -mr-px">
                      <span className="iconInput">
                        <MdBloodtype />{" "}
                      </span>
                    </div>
                    <select
                      onChange={handleInputChange}
                      name="bloodGorup"
                      className="inputUser"
                      id="bloodGorup"
                      value={inputValue.bloodGorup}
                    >
                      <option value="">Blood Gorup ...</option>
                      <option value="AB+"> AB+</option>
                      <option value="AB-">AB-</option>
                      <option value="A+">A+ </option>
                      <option value="A-">A-</option>
                      <option value="B+"> B+</option>
                      <option value="B-">B-</option>
                      <option value="O+"> O+</option>
                      <option value="O-">O-</option>
                    </select>
                  </div>
                </div>{" "}
                <div>
                  <label htmlFor="DOC" className="ml-1">
                    Date Of Commencement *
                  </label>
                  <div className="parentInputEditUser">
                    <div className="flex -mr-px">
                      <span className="iconInput">
                        <BsCalendarDateFill />
                      </span>
                    </div>
                    <input
                      onChange={handleInputChange}
                      type="date"
                      className="inputUser"
                      id="DOC"
                      placeholder="Date"
                      name="DateOfCommencement"
                      value={inputValue.DateOfCommencement}
                    />
                  </div>
                </div>{" "}
                <div>
                  <label htmlFor="CD" className="ml-1">
                    Chronic diseases *
                  </label>
                  <div className="parentInputEditUser">
                    <div className="flex -mr-px">
                      <span className="iconInput">
                        <MdOutlineSick />
                      </span>
                    </div>
                    <select
                      className="inputUser"
                      onChange={handleInputChange}
                      id="CD"
                      value={inputValue.ChronicDiseases}
                      name="ChronicDiseases"
                    >
                      <option value="">Select Chronic Diseases ...</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                </div>{" "}
                <div>
                  <label htmlFor="certificate" className="ml-1">
                    Certificate *
                  </label>
                  <div className="parentInputEditUser">
                    <div className="flex -mr-px">
                      <span className="iconInput">
                        <TbCertificate />
                      </span>
                    </div>
                    <select
                      onChange={handleInputChange}
                      className="inputUser"
                      id="certificate"
                      name="certificate"
                      value={inputValue.certificate}
                    >
                      <option value="">Select Certificate ...</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                </div>{" "}
                <div>
                  <label htmlFor="Certificate field" className="ml-1">
                    Certificate field *
                  </label>
                  <div className="parentInputEditUser">
                    <div className="flex -mr-px">
                      <span className="iconInput">
                        <TbCertificate />
                      </span>
                    </div>
                    <input
                      onChange={handleInputChange}
                      type="text"
                      id="Certificate field"
                      name="certificateField"
                      className="inputUser"
                      placeholder="Certificate Field"
                      value={inputValue.certificateField}
                    />
                  </div>
                </div>{" "}
                <div>
                  <label
                    htmlFor="Occupation outside
"
                    className="ml-1"
                  >
                    Occupation outside *
                  </label>
                  <div className="parentInputEditUser">
                    <div className="flex -mr-px">
                      <span className="iconInput">
                        <MdSensorOccupied />
                      </span>
                    </div>
                    <input
                      onChange={handleInputChange}
                      type="text"
                      id="Occupation outside"
                      name="OccupationOutside"
                      className="inputUser"
                      placeholder="Occupation outside"
                      value={inputValue.OccupationOutside}
                    />
                  </div>
                </div>{" "}
                <div>
                  <label htmlFor="Workplace" className="ml-1">
                    Work Place *
                  </label>
                  <div className="parentInputEditUser">
                    <div className="flex -mr-px">
                      <span className="iconInput">
                        <FaLocationCrosshairs />
                      </span>
                    </div>
                    <input
                      onChange={handleInputChange}
                      type="text"
                      id="Workplace"
                      name="Workplace"
                      className="inputUser"
                      placeholder="Work Place "
                      value={inputValue.Workplace}
                    />
                  </div>
                </div>{" "}
                <div>
                  <label htmlFor="job Title" className="ml-1">
                    Job Tiltle *
                  </label>
                  <div className="parentInputEditUser">
                    <div className="flex -mr-px">
                      <span className="iconInput">
                        <FaLocationArrow />
                      </span>
                    </div>
                    <input
                      onChange={handleInputChange}
                      type="text"
                      id="job Title"
                      name="jobTitle"
                      className="inputUser"
                      placeholder="Job Title"
                      value={inputValue.jobTitle}
                    />
                  </div>
                </div>{" "}
                <div>
                  <label htmlFor="Languagelevel" className="ml-1">
                    English Level*
                  </label>
                  <div className="parentInputEditUser">
                    <div className="flex -mr-px">
                      <span className="iconInput">
                        <IoLanguage />
                      </span>
                    </div>
                    <select
                      onChange={handleInputChange}
                      type="text"
                      id="Languagelevel"
                      name="LanguageLevel"
                      className="inputUser"
                      placeholder="Language Level"
                      value={inputValue.LanguageLevel}
                    >
                      <option value="">Select Level Languge...</option>
                      <option value="Bad">Bad</option>
                      <option value="Good">Good</option>
                      <option value="Very Good">Very Good</option>
                      <option value="Perfect">Perfect</option>
                    </select>
                  </div>
                </div>{" "}
                <div>
                  <label htmlFor="ku_lang" className="ml-1">
                    Kurdish Level*
                  </label>
                  <div className="parentInputEditUser">
                    <div className="flex -mr-px">
                      <span className="iconInput">
                        <IoLanguage />
                      </span>
                    </div>
                    <select
                      onChange={handleInputChange}
                      type="text"
                      id="ku_lang"
                      name="ku_lang"
                      className="inputUser"
                      placeholder="Language Level"
                      value={inputValue.ku_lang}
                    >
                      <option value="">Select Level Languge...</option>
                      <option value="Bad">Bad</option>
                      <option value="Good">Good</option>
                      <option value="Very Good">Very Good</option>
                      <option value="Perfect">Perfect</option>
                    </select>
                  </div>
                </div>{" "}
                <div>
                  <label htmlFor="ar_lang" className="ml-1">
                    Arabic Level*
                  </label>
                  <div className="parentInputEditUser">
                    <div className="flex -mr-px">
                      <span className="iconInput">
                        <IoLanguage />
                      </span>
                    </div>
                    <select
                      onChange={handleInputChange}
                      type="text"
                      id="ar_lang"
                      name="ar_lang"
                      className="inputUser"
                      placeholder="Language Level"
                      value={inputValue.ar_lang}
                    >
                      <option value="">Select Level Languge...</option>
                      <option value="Bad">Bad</option>
                      <option value="Good">Good</option>
                      <option value="Very Good">Very Good</option>
                      <option value="Perfect">Perfect</option>
                    </select>
                  </div>
                </div>{" "}
                <div>
                  <label htmlFor="Computerskills" className="ml-1">
                    Computer skills *
                  </label>
                  <div className="parentInputEditUser">
                    <div className="flex -mr-px">
                      <span className="iconInput">
                        <GrPersonalComputer />
                      </span>
                    </div>
                    <select
                      onChange={handleInputChange}
                      value={inputValue.Computerskills}
                      type="text"
                      id="Computerskills"
                      name="Computerskills"
                      className="inputUser"
                      placeholder="Computer Skills"
                    >
                      <option value="">Select Computer Skills...</option>
                      <option value="Bad">Bad</option>
                      <option value="Good">Good</option>
                      <option value="Very Good">Very Good</option>
                      <option value="Perfect">Perfect</option>
                    </select>
                  </div>
                </div>{" "}
                <div>
                  <label htmlFor="branch" className="ml-1">
                    Branch *
                  </label>
                  <div className="parentInputEditUser">
                    <div className="flex -mr-px">
                      <span className="iconInput">
                        <FaCodeBranch />
                      </span>
                    </div>
                    <select
                      type="text"
                      id="branch"
                      name="branch"
                      className="inputUser"
                      value={inputValue.branch}
                      onChange={handleInputChange}
                    >
                      <option value="">Select Branch...</option>
                      {branchs?.map((branch, index) => {
                        return (
                          <option key={index} value={branch.id}>
                            {branch?.bName}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <p className="text-red-500">{errors && errors.branch_id}</p>
                </div>{" "}
                <div>
                  <label htmlFor="Section" className="ml-1">
                    Section *
                  </label>
                  <div className="parentInputEditUser">
                    <div className="flex -mr-px">
                      <span className="iconInput">
                        <GiRadarCrossSection />
                      </span>
                    </div>
                    <select
                      type="text"
                      id="Section"
                      name="section"
                      className="inputUser"
                      value={inputValue.section}
                      onChange={handleInputChange}
                    >
                      <option value="">Select Section...</option>
                      {sections?.map((section, index) => {
                        return (
                          <option value={section.id} key={index}>
                            {section?.sName}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <p className="text-red-500">{errors && errors.section_id}</p>
                </div>{" "}
                <div>
                  <label htmlFor="role" className="ml-1">
                    Role *
                  </label>
                  <div className="parentInputEditUser">
                    <div className="flex -mr-px">
                      <span className="iconInput">
                        <FaUserShield />
                      </span>
                    </div>
                    <select
                      type="text"
                      id="role"
                      name="role"
                      className="inputUser"
                      onChange={handleInputChange}
                      value={inputValue.role}
                    >
                      <option value="">Select Role ...</option>
                      {roles?.map((role, index) => {
                        return (
                          <option key={index} value={role.id}>
                            {role?.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <p className="text-red-500">{errors && errors.role_id}</p>
                </div>{" "}
                <div>
                  <label htmlFor="Hourly Wage" className="ml-1">
                    Hourly Wage *
                  </label>
                  <div className="parentInputEditUser">
                    <div className="flex -mr-px">
                      <span className="iconInput">
                        <FaLocationArrow />
                      </span>
                    </div>
                    <input
                      onChange={handleInputChange}
                      type="text"
                      id="Hourly Wage"
                      name="hourlyWage"
                      className="inputUser"
                      placeholder="Hourly Wage"
                      value={inputValue.hourlyWage}
                    />
                  </div>
                  <p className="text-red-500">{errors && errors.hourly_wage}</p>
                </div>{" "}
                <div>
                  <label htmlFor="Extra Wage" className="ml-1">
                    Extra Wage *
                  </label>
                  <div className="parentInputEditUser">
                    <div className="flex -mr-px">
                      <span className="iconInput">
                        <FaLocationArrow />
                      </span>
                    </div>
                    <input
                      onChange={handleInputChange}
                      type="text"
                      id="Extra Wage"
                      name="extraWage"
                      className="inputUser"
                      placeholder="Extra Wage"
                      value={inputValue.extraWage}
                    />
                  </div>
                  <p className="text-red-500">{errors && errors.extra_wage}</p>
                </div>{" "}
              </div>
              <div>
                <table className="lg:w-10/12 m-auto">
                  <thead>
                    <tr>
                      <th className="p-1 global-th capitalize">
                        Prevent Image
                      </th>
                      <th
                        onClick={() => {
                          setIsOpenUserCvModal(true);
                          // console.log(id)
                          setIdd(id);
                        }}
                        className="p-1 w-32 global-th"
                      >
                        <p className="text-center cursor-pointer rounded-sm text-white  bg-teal-500 py-2 hover:bg-teal-600 duration-500 flex justify-center">
                          <FaPlus />
                        </p>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {user.user_cvs.map((cv, index) => {
                      return (
                        <tr key={index} className="global-tr">
                          <td className="global-td ">
                            <img
                              src={cv.cv}
                              alt={`Preview ${index}`}
                              className="max-h-32 w-7/12 m-auto object-cover"
                            />
                          </td>
                          <td
                            onClick={() => handleDeleteImage(cv.id)}
                            className="global-td"
                          >
                            <p className="text-center cursor-pointer text-white  bg-red-500 py-2 hover:bg-red-600 rounded-sm duration-500 flex justify-center">
                              <FaTrash />
                            </p>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="w-full flex justify-end mt-2">
                <button className="btn-primary" onClick={handleSubmit}>
                  Submit
                </button>
              </div>
            </form>
            {
              errors && <DangerAlert />
            }
          </div>

          <div className="pb-20"></div>
        </>
      )}

      {isOpenUserCvModal && (
        <UserCvModal onClose={setIsOpenUserCvModal} userId={idd} />
      )}
    </>
  );
};

export default EditUser;
