import { CREATE_IMAGE_FAIL, CREATE_IMAGE_REQUEST, CREATE_IMAGE_SUCCESS, FETCH_IMAGE_FAIL, FETCH_IMAGE_REQUEST, FETCH_IMAGE_SUCCESS } from "../../ActionType/ImageType/imageType"

export const createImageReducer = (state ={loading:true,image:[],error:''} , action) => {
    switch (action.type) {
        case CREATE_IMAGE_REQUEST:
            return {
                loading:true
            }
        case CREATE_IMAGE_SUCCESS:
            
            return {
                loading:false,
                image:action.payload,
                error:''
            }
        case CREATE_IMAGE_FAIL:
            return {
                loading:false,
                image:[],
                error:"please Check Your Request"
            }
        default:
            return state
    }
}



export const getImageReducer = (state = {loading:true,image:[],error:""}, action) => {
    switch (action.type) {
        case FETCH_IMAGE_REQUEST:
            return {
                loading:true,
            }
        case FETCH_IMAGE_SUCCESS:
            return {
                loading:false,
                image:action.image,
                error:''
            }
        case FETCH_IMAGE_FAIL:
            return {
                loading:false,
                image:[],
                error:"Pleae Check Your Request "
            }
        default:
            return state
    }
}