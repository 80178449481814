import Swal from "sweetalert2";
import { api } from "../Api/api";
import { getToken } from "./login";
import {
  CREATE_COMMITE_FAIL,
  CREATE_COMMITE_REQUEST,
  CREATE_COMMITE_SUCCESS,
  FETCH_COMMITE_INFO_SUCCESS,
  FETCH_COMMITE_INFo_FAIL,
  FETCH_COMMITE_SUCCESS,
  FETCH_COMMITE_WITH_VISIT_FAIL,
  FETCH_COMMITE_WITH_VISIT_REQUEST,
  FETCH_COMMITE_WITH_VISIT_SUCCESS,
} from "../ActionType/commiteType";
import { updateVisitAction } from "./patient";

export const createCommite =
  (
    visit_id,
    PatientId,
    assign,
    occuption,
    nation,
    country,
    married,
    noFamily,
    province,
    district,
    street,
    closetHome,
    secoundHome,
    card,
    smoke,
    timeAllote,
    SITD,
    member,
    memberLevel,
    TYP,
    indiction,
    HEP,
    finalDiagnosis,
    earlyPatient,
    earlySurgery,
    Doctor,
    ZODoctor,
    medicalProvided,
    Typemedicalsupplies,
    supplyLocation,
    helpingZO,
    reset,
    type_of_help,
    note,
    literate,
    had_surgery

  ) =>
  (dispatch) => {
    const arraySurgery = [];
    const doctorArray=[];
       
    for (let index = 0; index < Doctor.length; index++) {
      const elementSurg = Doctor[index].label;
      doctorArray.push(elementSurg);
    }
    for (let index = 0; index < SITD.length; index++) {
      const elementSurg = SITD[index].label;

      arraySurgery.push(elementSurg);
    }

    const memberArray = [];
    

    for (let index = 0; index < member.length; index++) {
      const elementSurg = member[index].label;
      memberArray.push(elementSurg);
    }
    const organLevel=[];
    for (let index = 0; index < memberLevel.length; index++) {
      const elementSurg = memberLevel[index].label;
      organLevel.push(elementSurg);
    }
    // console.log("had surgery : ",had_surgery)
    updateAssignAction(assign, PatientId);
    if (assign == 2) {
      dispatch(updateVisitAction(visit_id));
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    };
    
    api
      .post(
        "commite/create",
        {
          visit_id: visit_id,
          PatientId: PatientId,
          // assign,
          occuption,
          nation,
          married,
          country,
          noFamily,
          province,
          district,
          street,
          closetHome,
          secoundHome,
          card,
          smoke,

          timeAllote,
          SITD: arraySurgery,
          member:memberArray,
          memberLevel:organLevel,
          TYP,
          indiction,
          HEP,
          finalDiagnosis,
          earlyPatient,
          earlySurgery,
          Doctor:doctorArray,
          ZODoctor,
          medicalProvided,
          Typemedicalsupplies,
          supplyLocation,
          helpingZO,
          type_of_help,
          note,
          literate,
          had_surgery
        },
        config
      )
      .then((response) => {
        dispatch({ type: CREATE_COMMITE_REQUEST });
        console.log(response)
        if (response.data.status == 200) {
          
          const suliCommite = response.data.suliCommite;
          dispatch({ type: CREATE_COMMITE_SUCCESS, payload: suliCommite });
          Swal.fire({
            icon: "success",
            title: "Good Job",
            text: assign == 1 ? "patient Was Saved" : "Patient Was Assigned",
            timer: 1500,
          });
        } else if (response.data.status == 404) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Pleas Fill all the gaps",
          });
        }
      })
      .catch(() => {
        dispatch({ type: CREATE_COMMITE_FAIL });
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Request Is Faild",
        });
      });
  };

// Commite Page
export const getCommite =
  (location_id, currentPage, perPage, fullname, phone, code, age, gender) =>
  (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",

        Authorization: `Bearer ${getToken()}`,
      },
    };
    api
      .get(
        `commite/${location_id}?page=${currentPage}&perPage=${perPage}&fullname=${fullname}&age=${age}&gender=${gender}&code=${code}&phone=${phone}`,
        config
      )
      // .get(`commite/${location_id}?currentPage=${currentPage}&perPage=${perPage}`, config)
      .then((response) => {
        const { total } = response.data;
        // console.log(JSON.stringify(response.data.json));
        if (response.data.status === 200) {
          const data = response.data.paginate.data;
          dispatch({
            type: FETCH_COMMITE_SUCCESS,
            payload: data,
            total: total,
          });
        }
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Request Is Faild",
        });
      });
  };
// view Modal Page
export const getInfoCommite = (location_id) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
  api
    .get(`commite/getInformationPatient/${location_id}`, config)
    .then((response) => {
      console.log(response);

      const jsonData = response.data.data[0].SITD;

      const decodedData = JSON.parse(jsonData);
      if (Array.isArray(decodedData) && decodedData.length > 0) {
        console.log(decodedData[0]);
      }
      if (response.data.status == 200) {
        const { data } = response.data;
        dispatch({ type: FETCH_COMMITE_INFO_SUCCESS, payload: data });
      } else if (response.data.status === 404) {
        console.log(response.data);
      }
    })
    .catch(() => {
      dispatch({ type: FETCH_COMMITE_INFo_FAIL });
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Request Is Faild",
      });
    });
};

export const updateAssignAction = (assign, patientId) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
  api
    .post(
      "patient/updateAssign",
      {
        assign,
        patientId,
      },
      config
    )
    .then((response) => {})
    .catch((err) => {});
};

export const getCommiteWithVisitAction = (id) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
  api
    .get(`commite/show/${id}`, config)
    .then((response) => {
      
      dispatch({ type: FETCH_COMMITE_WITH_VISIT_REQUEST });
      if (response.status == 200) {
        const { data } = response;

        dispatch({ type: FETCH_COMMITE_WITH_VISIT_SUCCESS, payload: data });
      } else if (response.status === 404) {
        // console.log("hi");
      }
    })
    .catch(() => {
      dispatch({ type: FETCH_COMMITE_WITH_VISIT_FAIL });
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Request Is Faild",
      });
    });
};
