import React, { useState } from "react";
import { BsFillCloudArrowUpFill, BsFillTrashFill } from "react-icons/bs";
import { FiPlus } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { createTypeOfSurgery } from "../../Action/SurgeryTypeAction/surgery";
const MangeSurgeryModal = ({ isOpenModal, id, surgeryName, closeModal }) => {
  const [typeSurgery, setTypeSurgery] = useState([]);
  
  const [valueTypeSurgery, setValueTypeSurgery] = useState([]);
  const [valueTypeNameSurgeryEn, setValueTypeNameSurgeryEn] = useState([]);
  const dispatch = useDispatch();
  const handelAddMore = (event) => {
    event.preventDefault();
    setTypeSurgery([...typeSurgery, ""]);
  };


  const handelDelete = (event, index) => {
    event.preventDefault();
    const deleteValue = [...typeSurgery];
    // const deleteValue = [...typeSurgery];
    const deleteValueType = [...valueTypeSurgery];
    const deleteValueTypeNameEn = [...valueTypeNameSurgeryEn];
    deleteValue.splice(index, 1);
    deleteValueType.splice(index, 1);
    deleteValueTypeNameEn.splice(index, 1);
    setValueTypeNameSurgeryEn(deleteValueTypeNameEn)
    setValueTypeSurgery(deleteValueType);
    setTypeSurgery(deleteValue);
  };




  const handelTypeNameEN = (e,index) => {
    
    const valueSurgery = [...valueTypeNameSurgeryEn];
    valueSurgery[index]=e.target.value;
    setValueTypeNameSurgeryEn(valueSurgery);
  };


  const handleInputChange = (e,index) => {
    
    const valueSurgery = [...valueTypeSurgery];
    valueSurgery[index]=e.target.value;
    setValueTypeSurgery(valueSurgery);
  };

  const handleSave = (e) => {
    e.preventDefault();
    if (valueTypeSurgery.length < 1) {
      console.log("length error")
      return ;
    } else {
      
      dispatch(createTypeOfSurgery(valueTypeSurgery, valueTypeNameSurgeryEn, id));
      closeModal(false);
      setValueTypeSurgery([]);
      setValueTypeNameSurgeryEn([]);
      setTypeSurgery([...typeSurgery]);
      const inputFields = document.querySelectorAll(".surgery-input");
      inputFields.forEach((input) => {
        input.value = "";
      });
    }
  };
  return (
    <div
      className={
        isOpenModal
          ? "h-screen fixed w-full bg-gray-400 duration-500 opacity-100 bg-opacity-20 top-0 left-0 z-50  "
          : "h-screen fixed w-full bg-gray-400   -z-50 opacity-0 duration-500 bg-opacity-20 top-0 left-0 "
      }
    >
      <div
        className="   h-screen  w-full p-20  relative cursor-pointer  z-20"
        onClick={() => closeModal(false)}
      />
      <div
        className={
          typeSurgery.length <= 5
            ? "bg-white overflow-y-auto z-50 h-auto w-11/12 md:w-7/12  xl:w-[40%] py-3 px-2 absolute top-[10%] left-4  md:left-[22%] xl:left-[32%]  -translate-X-[90%]  rounded-md shadow-lg  "
            : "bg-white overflow-y-auto z-50 h-[70%]  w-11/12 md:w-7/12 xl:w-[40%] py-3 px-2 absolute top-[10%] left-4  md:left-[22%] xl:left-[32%]  -translate-X-[90%]  rounded-md shadow-lg  "
        }
      >
        <div className="flex justify-between">
          <div className="flex items-center gap-4">
            
            <p>Type Surgery</p>
          </div>
          <div
            className="text-2xl hover:backdrop-blur-xl  cursor-pointer"
            onClick={() => closeModal(false)}
          >
            x
          </div>
        </div>
        <hr />
        <form action="" className="w-full">
          <div className=" w-full flex gap-3 items-center mt-4 pb-4">
            <input
              //   onChange={(e) => setName(e.target.value)}
              type="text"
              id="dr"
              readOnly
              value={surgeryName || ""}
              className="inputLogin w-full   p-2"
              placeholder="Doctor Name"
            />

            <div className="cursor-pointer mr-10 mt-1">
              <button className="btnCoustmDesignGreen" onClick={handelAddMore}>
                <FiPlus />
              </button>
            </div>
          </div>

          {typeSurgery.map((typeSurgery, index) => {
            return (
              <div
                className="  w-full flex gap-3 items-center mt-1 pb-4"
                key={index}
              >
                <input
                  type="text"
                  onChange={(e)=>handleInputChange(e,index)}
                  // value={typeSurgery}
                  placeholder="ئەندام (ئەندامی نەشتەرگەری لەسەر کراو)"
                  className="inputLogin p-2 w-11/12  text-right   surgery-input"
                />
                <input
                  type="text"
                  onChange={(e)=>handelTypeNameEN(e,index)}
                  
                  placeholder="TYP EN"
                  className="inputLogin w-11/12   p-2 surgery-input"
                />

                <button
                  className="btnCoustmDesignRed mr-10  mt-1.5 p-1.5"
                  onClick={(e) => {
                    handelDelete(e, index);
                  }}
                >
                  <BsFillTrashFill />
                </button>
              </div>
            );
          })}

          <hr />
          <div className="flex gap-3 mt-2 ml-4">
            <input
              type="submit"
              value="Save"
              onClick={handleSave}
              className="btnCoustmDesignGreen"
            />
            <input
              type="submit"
              value="Cancel"
              className="btnCoustmDesignRed"
              onClick={(e) => {
                e.preventDefault();
                closeModal(false);
              }}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default MangeSurgeryModal;
