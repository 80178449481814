import {
  CREATE_FEEDBACK_FAIL,
  CREATE_FEEDBACK_REQUEST,
  CREATE_FEEDBACK_SUCCESS,
  CREATE_SAVE_FEEDBACK_FAIL,
  CREATE_SAVE_FEEDBACK_REQUEST,
  CREATE_SAVE_FEEDBACK_SUCCESS,
  FETCH_EDIT_FEEDBACK_FAIL,
  FETCH_EDIT_FEEDBACK_REQUEST,
  FETCH_EDIT_FEEDBACK_SUCCESS,
  FETCH_FEEDBACK_FAIL,
  FETCH_FEEDBACK_IMAGE_FAIL,
  FETCH_FEEDBACK_IMAGE_REQUEST,
  FETCH_FEEDBACK_IMAGE_SUCCESS,
  FETCH_FEEDBACK_REQUEST,
  FETCH_FEEDBACK_SUCCESS,
  UPDATE_FEEDBACK_FAIL,
  UPDATE_FEEDBACK_REQUEST,
  UPDATE_FEEDBACK_SUCCESS,
} from "../ActionType/lezhnaActionType";
export const getFeedbackReducer = (
  state = { loading: true, feedback: [], total: 0, error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_FEEDBACK_REQUEST:
      return {
        loading: true,
      };
    case FETCH_FEEDBACK_SUCCESS:
      return {
        loading: false,
        feedback: action.payload,
        total: action.total,
        error: "",
      };
    case FETCH_FEEDBACK_FAIL:
      return {
        loading: false,
        feedback: [],
        error: "Please Check Your Request",
      };
    default:
      return state;
  }
};

export const addFeedbackReducer = (
  state = { loading: true, feedback: [], error: "" },
  action
) => {
  switch (action.type) {
    case CREATE_FEEDBACK_REQUEST:
      return {
        loading: true,
      };
    case CREATE_FEEDBACK_SUCCESS:
      return {
        loading: false,
        feedback: action.payload,
        error: "",
      };
    case CREATE_FEEDBACK_FAIL:
      return {
        loading: false,
        feedback: [],
        error: "Please Check Your Request",
      };
    default:
      return state;
  }
};

export const saveFeedbackReducer = (
  state = { loading: true, feedback: [], error: "" },
  action
) => {
  switch (action.type) {
    case CREATE_SAVE_FEEDBACK_REQUEST:
      return {
        loading: true,
      };
    case CREATE_SAVE_FEEDBACK_SUCCESS:
      return {
        loading: false,
        feedback: action.payload,
        error: "",
      };
    case CREATE_SAVE_FEEDBACK_FAIL:
      return {
        loading: false,
        feedback: [],
        error: "Please Check Your Request",
      };
    default:
      return state;
  }
};

export const editFeedbackReducer = (
  state = { loader: true, data: [], error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_EDIT_FEEDBACK_REQUEST:
      return {
        loader: true,
      };
    case FETCH_EDIT_FEEDBACK_SUCCESS:
      return {
        loader: false,
        data: action.payload,
        error: "",
      };
    case FETCH_EDIT_FEEDBACK_FAIL:
      return {
        loader: false,
        data: [],
        error: "Please Check Your Request",
      };
    default:
      return state;
  }
};

export const updateFeedbackReducer = (
  state = { loading: true, update: [], error: "" },
  action
) => {
  switch (action.type) {
    case UPDATE_FEEDBACK_REQUEST:
      return {
        loading: true,
      };
    case UPDATE_FEEDBACK_SUCCESS:
      return {
        loading: false,
        update: action.payload,
        error: "",
      };
    case UPDATE_FEEDBACK_FAIL:
      return {
        loading: false,
        update: [],
        error: "Please Check Your Request",
      };
    default:
      return state;
  }
};

export const getFeedbackImageReducer = (
  state = { loading: true, feedbackImage: [], total: 0, error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_FEEDBACK_IMAGE_REQUEST:
      return {
        loading: true,
      };
    case FETCH_FEEDBACK_IMAGE_SUCCESS:
      return {
        loading: false,
        feedbackImage: action.payload,
        error: "",
      };
    case FETCH_FEEDBACK_IMAGE_FAIL:
      return {
        loading: false,
        feedbackImage: [],
        error: "Please Check Your Request",
      };
    default:
      return state;
  }
};
