import React, { useEffect, useState } from "react";
import "../../Pages/Global.css";

import Logo from "../../Image/Logo.jpg";
import { useDispatch, useSelector } from "react-redux";
import { showAttendanceAction } from "../../Action/AttendanceAction/AttendanceAction";
import { useParams } from "react-router-dom";
import { CiFilter } from "react-icons/ci";

import ReactPaginate from "react-paginate";
import AttendanceApprovedModal from "../../Modal/Attendance/AttendanceApprovedModal";
import ExtraHourModal from "../../Modal/AdminstratorModal/ExtraHourModal";
const EmployeeAttendanceProfile = () => {
  const [searchParams, setSearchParams] = useState({
    from: "",
    to: "",

  });
  const [idd, setId] = useState(null);
  const [isOpenExtraHour, setIsOpenExtraHour] = useState(false);
  const [extraHour, setExtraHour] = useState(null);

  const [isOpenAttendanceModal, setIsOpenAttendanceModal] = useState(false);
  const [time, setTime] = useState(null);
  const [check, setCheck] = useState(null);
  const [approveCheckIn, setApproveCheckIn] = useState(null);
  const [selectedTab, setSelectedTab] = useState("");
  const [page, setPage] = useState(0);

  const [perPage, setPerPage] = useState(100);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSearchParams({
      ...searchParams,
      [name]: value,
    });
  };

  const [approveArray, setApproveArray] = useState();
  const [approveExtraHour, setApproveExtraHour] = useState({
    hour: "",
    minutes: "",
  });
  const handleSearch = () => {
    const { from, to } = searchParams;
    dispatch(showAttendanceAction(id, perPage, page, from, to));
  };

  const [approvedTime, setApprovedTime] = useState(null);
  const { id } = useParams();

  const [name, setName] = useState({
    en: "",
    ku: "",
  });

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
    // You can perform additional actions based on the selected tab if needed
  };
  const showAttendance = useSelector((state) => state.showAttendance);
  const { attendances, loading, total } = showAttendance;
  const dispatch = useDispatch();
  const [hourlyWage, setHourlyWage] = useState(0);
  const [hourlyWageExtra, setHourlyWageExtra] = useState(0);

  // const [indexx, setIndexx] = useState(null);
  // const [filterIsOpen, setFilterIsOpen] = useState(false);
  useEffect(() => {
    const { from, to } = searchParams;
    dispatch(showAttendanceAction(id, perPage, page, from, to));
  }, [dispatch, id, selectedTab, perPage]);
  useEffect(() => {
    attendances &&
      attendances.map((attendance) => {
        setHourlyWage(attendance.users.hourly_wage);
        setHourlyWageExtra(attendance.users.extra_wage);
        setName({
          ...name,
          en: attendance.users.fullname_en,
          ku: attendance.users.fullname_ku,
        });
      });
  }, [attendances]);

  function getHourDifference(startTime, endTime) {
    const [startHours, startMinutes, startSeconds] = startTime
      .split(":")
      .map(Number);
    const [endHours, endMinutes, endSeconds] = endTime.split(":").map(Number);

    const totalMilliseconds =
      (endHours - startHours) * 3600000 +
      (endMinutes - startMinutes) * 60000 +
      (endSeconds - startSeconds) * 1000;

    const totalHours = totalMilliseconds / (1000 * 60 * 60);
    return formatTime(totalHours);
  }
  function formatTime(totalHours) {
    const hours = Math.floor(totalHours);
    const minutes = Math.floor((totalHours % 1) * 60);
    const seconds = Math.floor((((totalHours % 1) * 60) % 1) * 60);

    return [
      String(hours).padStart(2, "0"),
      String(minutes).padStart(2, "0"),
      String(seconds).padStart(2, "0"),
    ].join(":");
  }

  useEffect(() => {
    if (attendances === undefined || attendances.length === 0) {
      // Handle the case when attendances is undefined or empty
      return;
    }

    // Calculate the hour differences array
    const hourDifferencesArray = attendances.map((att) => {
      // Check if both approve_checkin and approve_checkout are not null
      if (att.approve_checkin && att.approve_checkout) {
        return getHourDifference(
          att.approve_checkin.toString().split(" ")[1],
          att.approve_checkout.toString().split(" ")[1]
        );
      } else {
        // Handle the case where either approve_checkin or approve_checkout is null
        return "0:00"; // or return a default value or handle it as needed
      }
    });

    // Set the array of individual hour differences in state
    setApproveArray(hourDifferencesArray);

    // Sum the hours and minutes from the array
    const totalHours = Math.floor(
      hourDifferencesArray.reduce(
        (acc, time) => acc + parseInt(time.split(":")[0]),
        0
      )
    );
    const totalMinutes = hourDifferencesArray.reduce(
      (acc, time) => acc + parseInt(time.split(":")[1]),
      0
    );

    // Set the total time in state
    setApprovedTime({
      hours: totalHours,
      minutes: totalMinutes,
    });
  }, [attendances]);
  useEffect(() => {
    if (attendances === undefined || attendances.length === 0) {
      return;
    }

    // Calculate total hours and minutes
    const totalExtraTime = attendances.reduce((total, attendance) => {
      if (
        attendance.extra_hour !== null &&
        attendance.extra_hour !== undefined &&
        typeof attendance.extra_hour === "string"
      ) {
        const timeComponents = attendance.extra_hour.split(":");

        if (timeComponents.length === 2) {
          const [hours, minutes] = timeComponents.map(Number);

          if (!isNaN(hours) && !isNaN(minutes)) {
            // Accumulate the total time in minutes
            return total + hours * 60 + minutes;
          }
        }
      }

      return total; // Skip invalid or unexpected extra_hour values
    }, 0);

    // Extract the whole hours and remaining minutes
    const totalHours = Math.floor(totalExtraTime / 60);
    const remainingMinutes = totalExtraTime % 60;
    setApproveExtraHour({
      hour: totalHours,
      minutes: remainingMinutes,
    });
  }, [attendances]);

  const [image, setImage] = useState();
  useEffect(() => {
    if (!attendances) {
      return;
    }
    attendances.map((attendance) => {
      attendance.users.user_cvs.map((cv) => {
        setImage(cv.cv);
      });
    });
  }, [attendances]);

  if (approvedTime === null) {
    return (
      <div className="flex flex-col">
        <img
          src={Logo}
          alt="Logo"
          className="w-56 m-auto animate-pulse mt-20 "
        />
        <div className="text-2xl text-center font-bold  ">
          <div className="flex justify-center gap-4">
            <p className="animate-charcter">Please Wait</p>
            <div class="container2">
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
            </div>{" "}
          </div>
        </div>
      </div>
    ); // or some other loading indicator
  }
  const getToday = () => {
    const currentDate = new Date();
    const today = new Date(currentDate);
    today.setDate(currentDate.getDate() - 1);

    const formattedToday = today.toISOString().split("T")[0];
    const formattedDateNow = currentDate.toISOString().split("T")[0];

    setSearchParams({
      ...searchParams,
      from: formattedToday,
      to: formattedDateNow,
    });
  };
  const getLast30DaysDate = () => {
    const currentDate = new Date();
    const lastThirtyDays = new Date(currentDate);
    lastThirtyDays.setDate(currentDate.getDate() - 30);

    const formattedLastThirtyDays = lastThirtyDays.toISOString().split("T")[0];
    const formattedDateNow = currentDate.toISOString().split("T")[0];

    setSearchParams({
      ...searchParams,
      from: formattedLastThirtyDays,
      to: formattedDateNow,
    });
  };

  const getLast7DaysDate = () => {
    const currentDate = new Date();
    const lastSevenDays = new Date(currentDate);
    lastSevenDays.setDate(currentDate.getDate() - 7);

    const formattedLastSevenDays = lastSevenDays.toISOString().split("T")[0];
    const formattedDateNow = currentDate.toISOString().split("T")[0];

    setSearchParams({
      ...searchParams,
      from: formattedLastSevenDays,
      to: formattedDateNow,
    });
  };

  // Calculate total salary
  const totalSalary =
    approvedTime.hours * hourlyWage + (approvedTime.minutes / 60) * hourlyWage;

  const totalSalaryExtra =
    approveExtraHour.hour * hourlyWageExtra +
    (approveExtraHour.minutes / 60) * hourlyWageExtra;
  const thead = [
    {
      value: "#",
      label: "#",
    },
    {
      value: "fullname",
      label: "Full name",
    },
    {
      value: "dep",
      label: "Department",
    },

    {
      value: "dep",
      label: "Actual Check In",
    },

    {
      value: "dep",
      label: "Actual Check Out",
    },
    {
      value: "checkin",
      label: "Check In",
    },
    {
      value: "checkout",
      label: "Check Out",
    },
    {
      value: "approvetk",
      label: "Approve time",
    },
    {
      value: "fullname",
      label: "Hourly Wage",
    },

    {
      value: "dailyWage",
      label: "Daily Wage",
    },
    {
      value: "extraHour",
      label: "Extra Hour",
    },

    {
      value: "extraHour",
      label: "Extra Hourly Wage",
    },
    {
      value: "extraWag",
      label: "Extra Wage",
    },
  ];

  return (
    <>
      {!attendances || loading ? (
        <div className="flex flex-col">
          <img
            src={Logo}
            alt="Logo"
            className="w-56 m-auto animate-pulse mt-20 "
          />
          <div className="text-2xl text-center font-bold  ">
            <div className="flex justify-center gap-4">
              <p className="animate-charcter">Please Wait</p>
              <div class="container2">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>{" "}
            </div>
          </div>
        </div>
      ) : (
        <div className="parentContainer">
          <div className="headerContainer ">
            <div className="w-full lg:w-4/12 h-[15rem] lg:h-[19rem]">
              <img
                src={image}
                className=" object-cover  bg-top  h-full w-full"
                alt=""
              />
            </div>
            <div className=" w-full  ">
              <div className="flex justify-between items-center text-[#f3f3f3] bg-[#212121] py-14 px-3">
                <div>
                  <p>{name.en && name.en}</p>
                </div>
                <div>
                  <p>{name.ku && name.ku}</p>
                </div>
              </div>
              <div className="flex lg:flex-nowrap flex-wrap">
                <div className="bg-[#5DBF74] py-14 w-full  ">
                  <p className="text-center text-xl font-bold text-[#e7e7e7]">
                    Total Approved Time :
                  </p>
                  <p className="font-bold text-[#e7e7e7] text-center text-xl">
                    {approvedTime.hours} h {approvedTime.minutes}m
                  </p>
                </div>
                <div className="bg-indigo-500 py-14 w-full ">
                  {/* <p className="font-bold text-[#e7e7e7] text-center text-xl">
                    {approvedTime.hours} h {approvedTime.minutes} m
                  </p> */}
                  <p className="text-center text-xl font-bold text-[#e7e7e7]">
                    Total IQD :
                  </p>
                  <p className="text-center text-xl font-bold text-[#e7e7e7]">
                    {/* {totalSalary} */}
                    {totalSalary
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </p>
                </div>

                <div className="bg-[#32373D] py-14 w-full ">
                  {/* <p className="font-bold text-[#e7e7e7] text-center text-xl">
                    {approvedTime.hours} h {approvedTime.minutes} m
                  </p> */}
                  <p className="text-center text-xl font-bold text-[#e7e7e7]">
                    Total Extra Hour :
                  </p>
                  <p className="text-center text-xl font-bold text-[#e7e7e7]">
                    {approveExtraHour.hour} h {approveExtraHour.minutes} m
                  </p>
                </div>
                <div className="bg-[#F9777F] py-14 w-full ">
                  {/* <p className="font-bold text-[#e7e7e7] text-center text-xl">
                    {approvedTime.hours} h {approvedTime.minutes} m
                  </p> */}
                  <p className="text-center text-xl font-bold text-[#e7e7e7]">
                    Total Extra Wage :
                  </p>
                  <p className="text-center text-xl font-bold text-[#e7e7e7]">
                    {/* {totalSalary} */}
                    {
                      totalSalaryExtra
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        .split(".")[0]
                    }
                  </p>
                </div>

                <div className="bg-[#866EC7] py-14 w-full  ">
                  <p className="text-center text-xl font-bold text-[#e7e7e7]">
                    Total IQD :
                  </p>
                  <p className="font-bold text-[#e7e7e7] text-center text-xl">
                    {
                      (totalSalary + totalSalaryExtra)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        .split(".")[0]
                    }
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center lg:flex-nowrap flex-wrap gap-2 mt-3  justify-between ">
            <div className="">
              <div className="flex ">
                <button
                  className={`p-2 ${
                    selectedTab === "today"
                    ? "bg-indigo-600 text-white rounded-none"
                      : "bg-gray-100 backdrop-blur-3xl "
                  }`}
                  onClick={() => {
                    handleTabChange("today");
                    getToday();
                  }}
                >
                  Today
                </button>
                <button
                  className={`p-2 ${
                    selectedTab === "last7days"
                    ? "bg-indigo-600 text-white rounded-none"
                      : "bg-gray-100 backdrop-blur-3xl "
                  }`}
                  onClick={() => {
                    handleTabChange("last7days");
                    getLast7DaysDate();
                  }}
                >
                  Last 7 Days
                </button>
                <button
                  className={`p-2 ${
                    selectedTab === "last30days"
                    ? "bg-indigo-600 text-white rounded-none"
                      : "bg-gray-100 backdrop-blur-3xl "
                  }`}
                  onClick={() => {
                    handleTabChange("last30days");
                    getLast30DaysDate();
                  }}
                >
                  Last 30 Days
                </button>
              </div>
              {/* {renderContent()}  */}
            </div>
            <div className="flex items-center lg:flex-nowrap flex-wrap   gap-4">
              <input
                type="date"
                onChange={handleInputChange}
                value={searchParams.from}
                name="from"
                className="inputUser w-full"
              />
              <p>To</p>
              <div>
                <input
                  name="to"
                  onChange={handleInputChange}
                  type="date"
                  value={searchParams.to}
                  className="inputUser w-full"
                />
              </div>
              <div>
                <button onClick={handleSearch} className="btn-plus px-2 py-2">
                  <CiFilter />
                </button>
              </div>
            </div>
          </div>
          <div className="h-[28rem]">
            <div className="h-[95%] mt-2 overflow-y-auto">
              <table className="w-full h- mt-2 overflow-y-auto">
                <thead className="sticky top-0">
                  <tr className="global-tr">
                    {thead.map((th, index) => {
                      return (
                        <th
                          key={index}
                          className="global-th py-2 text-left relative text-sm tracking-wider"
                        >
                          {th.label}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {attendances.map((attendance, index) => {
                    return (
                      <tr key={index}>
                        <td className="global-td ">{index + 1}</td>
                        <td className="global-td text-center">
                          {attendance.users.fullname_en}
                        </td>
                        <td className="global-td text-center">
                          {attendance.users.section.sName}
                        </td>
                        <td className="global-td text-center font-bold">
                          {attendance.checkin
                            .toString()
                            .slice(10, 16)
                            .split(":")[0] +
                            " h " +
                            attendance.checkin
                              .toString()
                              .slice(10, 16)
                              .split(":")[1] +
                            " m"}
                        </td>
                        <td className="global-td text-center font-bold">
                        {attendance.checkout
                            .toString()
                            .slice(10, 16)
                            .split(":")[0] +
                            " h " +
                            attendance.checkout
                              .toString()
                              .slice(10, 16)
                              .split(":")[1] +
                            " m"}
                        </td>
                        <td
                          className={
                            attendance.approve_checkin
                              ? "global-td bg-blue-300 text-center cursor-pointer"
                              : "global-td bg-gray-100 text-center cursor-pointer"
                          }
                          onClick={() => {
                            setTime(attendance.checkin);
                            setApproveCheckIn(attendance.approve_checkin);
                            setIsOpenAttendanceModal(true);
                            setId(attendance.id);
                            setCheck("in");
                          }}
                        >
                          <p>
                            {attendance.approve_checkin
                              ? attendance.approve_checkin
                                  .toString()
                                  .split(" ")[1]
                              : "-"}
                          </p>
                          <p className="font-bold">
                            {attendance.approve_checkin
                              ? attendance.approve_checkin
                                  .toString()
                                  .slice(5, 20)
                                  .split(" ")[0]
                              : ""}
                          </p>
                        </td>
                        <td
                          className={
                            attendance.approve_checkin
                              ? "global-td bg-blue-300 text-center cursor-pointer"
                              : "global-td bg-gray-100 text-center cursor-pointer"
                          }
                          onClick={() => {
                            setTime(attendance.checkout);
                            setApproveCheckIn(attendance.approve_checkout);
                            setIsOpenAttendanceModal(true);
                            setId(attendance.id);
                            setCheck("out");
                          }}
                        >
                          {attendance.approve_checkout
                            ? attendance.approve_checkout
                                .toString()
                                .split(" ")[1]
                            : "-"}
                          <p className="font-bold">
                            {attendance.approve_checkout
                              ? attendance.approve_checkout
                                  .toString()
                                  .slice(5, 20)
                                  .split(" ")[0]
                              : ""}
                          </p>
                        </td>
                        <td className="global-td text-center ">
                          <div className="flex gap-2 font-bold justify-center ">
                            {approveArray[index].toString().split(":")[0] +
                              "" +
                              "h"}
                            <p>
                              {""}
                              {approveArray[index].toString().split(":")[1] +
                                "" +
                                "m"}
                            </p>
                          </div>
                        </td>
                        <td className="font-extrabold global-td text-center">
                          {attendance.users.hourly_wage
                            ? attendance.users.hourly_wage
                            : "-"}
                        </td>
                        <td className="font-bold global-td text-center">
                          {" "}
                          {(approveArray[index] &&
                            parseFloat(
                              approveArray[index]
                                .split(":")
                                .slice(0, 2) // Take only hours and minutes, ignoring seconds
                                // .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                .reduce(
                                  (acc, val, i) =>
                                    acc + val / (i === 0 ? 1 : 60),
                                  0
                                )
                            ) * attendance.users.hourly_wage) +
                            " " +
                            "IQD" || 0 + " " + "IQD"}
                        </td>
                        <td
                          onClick={() => {
                            setIsOpenExtraHour(true);
                            setId(attendance.id);
                            setExtraHour(attendance.extra_hour);
                          }}
                          className={
                            attendance.extra_hour
                              ? "font-extrabold global-td text-center bg-blue-300 cursor-pointer"
                              : "font-extrabold global-td text-center bg-gray-100 cursor-pointer"
                          }
                        >
                          {attendance.extra_hour
                            ? attendance.extra_hour.toString().split(":")[0] +
                              " h" +
                              " " +
                              attendance.extra_hour.toString().split(":")[1] +
                              " m "
                            : "-"}
                        </td>
                        <td className="font-bold global-td text-center">
                          {attendance.users.extra_wage}
                        </td>
                        <td className="font-extrabold global-td text-center">
                          {attendance.extra_hour
                            ? (
                                attendance.extra_hour.toString().split(":")[0] *
                                  attendance.users.extra_wage +
                                (attendance.extra_hour
                                  .toString()

                                  .split(":")[1] *
                                  attendance.users.extra_wage) /
                                  60
                              )
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                .split(".")[0]
                            : "-"}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot className="sticky bottom-0 bg-gray-300">
                  <tr className="global-tr">
                    <td className="global-td"></td>
                    <td></td>
                    <td></td>
                    <td className="global-td"></td>
                    <td className="global-td"></td>
                    <td className="global-td"></td>
                    <td className="global-td"></td>
                    <td className="global-td">
                      {" "}
                      <p className="font-bold  text-center ">
                        {approvedTime.hours} h {approvedTime.minutes}m
                      </p>
                    </td>
                    <td></td>

                    <td className="global-td font-bold">
                      {" "}
                      {totalSalary
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        .split(".")[0] +
                        " " +
                        "IQD"}
                    </td>

                    <td className="global-td">
                      {" "}
                      <p className="text-center  font-bold ">
                        {approveExtraHour.hour} h {approveExtraHour.minutes} m
                      </p>
                    </td>
                    <td></td>
                    <td className="global-td font-semibold">
                      {totalSalaryExtra
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        .split(".")[0] +
                        " " +
                        "IQD"}
                    </td>

                    {/* <td className="global-td"></td> */}
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
          <div className="gap-3 px-3 flex justify-between items-center  pt-2  ">
            <div className="flex items-center gap-4 ">
              <p className="text-xl ">Total: </p>
              <div
                className={
                  "bg-teal-600 px-4 py-0.5 text-white rounded-md shadow-md "
                }
              >
                {total}
              </div>
            </div>
            <div className="flex items-center gap-4">
              <div>
                <select
                  name=""
                  className="mt-1  bg-white border w-20 focus:ring-2  focus:ring-slate-900 rounded-md   focus:outline-none"
                  onChange={(e) => setPerPage(e.target.value)}
                  value={perPage}
                  id=""
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>

              <div className="flex justify-center mt-1">
                <ReactPaginate
                  forcePage={page}
                  previousLabel="<"
                  nextLabel=">"
                  breakLabel="..."
                  className="flex items-center -space-x-px h-8 text-sm"
                  previousClassName="flex items-center justify-center px-3 h-8 ml-0 border border-gray-300 rounded-l-lg"
                  nextClassName="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 border border-gray-300 rounded-r-lg"
                  pageLinkClassName="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 border border-gray-300"
                  breakClassName="w-8 py-2 text-center"
                  pageCount={Math.ceil(total / perPage)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={(event) => {
                    const currentPage = event.selected + 1;
                    // console.log(event.selected);
                    dispatch(
                      showAttendanceAction(
                        id,
                        perPage,
                        currentPage,
                        searchParams.from,
                        searchParams.to
                      )
                    );
                    setPage(event.selected); // Use the selected page directly
                  }}
                  activeLinkClassName="bg-black text-white flex items-center -space-x-px h-8 text-sm"
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {isOpenAttendanceModal && (
        <AttendanceApprovedModal
          isOpen={isOpenAttendanceModal}
          onClose={setIsOpenAttendanceModal}
          selectedTime={time}
          id={idd}
          approveCheckIn={approveCheckIn}
          check={check}
          idShowAttendance={id}
        />
      )}

      {isOpenExtraHour && (
        <ExtraHourModal
          closeModal={()=>setIsOpenExtraHour(false)}
          id={idd}
          idShowAttendance={id}
          extraHour={extraHour}
        />
      )}

      <div className="pb-32"></div>
    </>
  );
};

export default EmployeeAttendanceProfile;
