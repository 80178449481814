import React, { useEffect, useState } from "react";
import { FaFilter, FaSuitcaseMedical } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import {
  getTypeMedicalAction,
  getTypeMedicalExcelAction,
} from "../../../Action/TypeMedicalAction/typeMedicalAction";
import Logo from "../../../Image/Logo.jpg";
import ReactPaginate from "react-paginate";
import { BsCardImage } from "react-icons/bs";
import TypeMedicalModal from "../../../Modal/TypeMedicalModal/TypeMedicalModal";

import { RiFileExcel2Fill } from "react-icons/ri";
import { getToken } from "../../../Action/login";
import { api } from "../../../Api/api";

const MedicalSuplies = () => {
  const dispatch = useDispatch();
  const getTypeMedical = useSelector((state) => state.getTypeMedical);
  const [currentPage, setCurrentPage] = useState(1);
  const locationId = localStorage.getItem("locationId");
  const [perPage, setPerPage] = useState(20);
  const [patientId, setPatientId] = useState(null);
  const [typeMedcaliId, setTypeMedicalId] = useState(null);
  const { data, total, loading } = getTypeMedical;
  const [searchParams, setSearchParams] = useState({
    fullname: "",
    type_of_help: "",
    place: "",
    hospital: "",
    type_of_test: "",
    totalOf: "",
    date: "",
  });
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [openFilter, setOpenFilter] = useState(false);
  const handleSearchParams = (event) => {
    const { name, value } = event.target;
    setSearchParams({
      ...searchParams,
      [name]: value,
    });
  };

  const {
    fullname,
    type_of_help,
    place,
    hospital,
    type_of_test,
    totalOf,
    date,
  } = searchParams;
  useEffect(() => {
    dispatch(
      getTypeMedicalAction(
        locationId,
        currentPage,
        perPage,
        fullname,
        type_of_help,
        place,
        hospital,
        type_of_test,
        totalOf,
        date,
        from,
        to
      )
    );
  }, [
    fullname,
    perPage,
    type_of_help,
    type_of_test,
    place,
    hospital,
    totalOf,
    date,
    from,
    to
  ]);


  // const [excel, setExcel] = useState(false);

  const [openModal, setOpenModal] = useState(false);

  const [loadingExcle, setLoadingExcle] = useState(false);
  const exportToExcel = async () => {
    setLoadingExcle(true);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        // Authorization: `Bearer ${getToken()}`,
        Authorization: `Bearer ${getToken()}`,
      },
      params:{
        from,
        to
      },
      responseType: "blob",
    };
    try {
      const response = await api.get("type_medical/excel", config);
      // console.log(response)
      const blob = new Blob([response.data], { type: "text/csv" });
      if (response.status == 200) {
        setLoadingExcle(false);
      }
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);

      link.download = "پێداویستی پزیشکی.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading CSV:", error);
    }
  };
  return (
    <>
      {loading || loadingExcle ? (
        <div className="flex flex-col">
          <img
            src={Logo}
            alt="Logo"
            className="w-56 m-auto animate-pulse mt-20 "
          />
          <div className="text-2xl text-center font-bold  ">
            <div className="flex justify-center gap-4">
              <p className="animate-charcter">Please Wait</p>
              <div class="container2">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>{" "}
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="flex gap-2">
            <button onClick={exportToExcel}>
              <div className="flex items-center justify-between duration-500 text-white gap-2 ml-5 bg-green-500 bg-opacity-75 hover:bg-opacity-90 rounded-md px-4 py-2 shadow-md cursor-pointer">
                <p>Export </p>
                <span>
                  <RiFileExcel2Fill />
                </span>
              </div>
            </button>
            <div className="flex gap-2 items-center ">
              <input
                type="date"
                onChange={(e) => setFrom(e.target.value)}
                className="inputUser w-full"
              />
              <div>
                <label htmlFor="">To</label>
              </div>
              <div>
                <input
                  type="date"
                  onChange={(e) => setTo(e.target.value)}
                  className="inputUser w-full"
                />
              </div>
            </div>
          </div>
          <div className="relative bg-white md:h-[18rem] xl:h-[28rem]  3xl:h-[43rem]   w-[98%] m-auto  sm:rounded-lg  mt-1">
            <div className="flex justify-between items-center mb-2">
              <div className="flex items-center px-4 gap-3">
                <p className="text-teal-500">
                  <FaSuitcaseMedical />
                </p>
                <h1>Medical Supplies</h1>
              </div>
              <div className="xl:w-1/12 mt-1 mr-2">
                <select
                  name="type_of_help"
                  onChange={handleSearchParams}
                  value={searchParams.type_of_help}
                  className="select"
                  id=""
                >
                  <option value="">All ...</option>
                  <option value="تیشک">تیشک</option>
                  <option value="دەرمان">دەرمان</option>
                  <option value="پشکنینی خوێن">پشکنینی خوێن</option>
                  <option value="غەسلی پلازما">غەسلی پلازما</option>
                  <option value="غەسلی گورچیلە">غەسلی گورچیلە</option>
                  <option value="مانەوەی لە نەخۆشخانە">
                    مانەوەی لە نەخۆشخانە
                  </option>
                  <option value="نازۆر">نازۆر</option>
                  <option value="نازۆری گەدە">نازۆری گەدە</option>
                  <option value="نازۆری کۆڵۆن">نازۆری کۆڵۆن</option>
                  <option value="لابردنی سۆندەی گورچیلە">
                    لابردنی سۆندەی گورچیلە
                  </option>
                  <option value="پشکنینی خوێن">پشکنینی خوێن</option>
                  <option value="دانانی فیچولان">دانانی فیچولان</option>
                  <option value="دانانی سیڤیلاین">دانانی سیڤیلاین</option>
                </select>
              </div>
            </div>
            <hr />

            <div className="overflow-y-auto w-full h-[75%] xl:h-[90%]">
              <table className="w-full">
                <thead className="sticky top-0 ">
                  <tr>
                    <th className=" pl-5 border">#</th>
                    <th className="thead  ">
                      <div className="flex items-center justify-between">
                        Full Name
                        <div
                          className="text-base rounded-md shadow-md  p-2 bg-teal-600 hover:bg-teal-600 text-white"
                          onClick={() => setOpenFilter(!openFilter)}
                        >
                          <FaFilter />
                        </div>
                      </div>
                      {openFilter && (
                        <input
                          onBlur={handleSearchParams}
                          type="text"
                          name="fullname"
                          className="inputLogin p-1.5 shadow-xl rounded "
                          placeholder="Full Name"
                        />
                      )}
                    </th>
                    <th className="thead">
                      <div className="flex items-center justify-between">
                        Type Of Help
                        <div
                          className="text-base rounded-md shadow-md p-2  bg-teal-600 hover:bg-teal-600 text-white"
                          onClick={() => setOpenFilter(!openFilter)}
                        >
                          <FaFilter />
                        </div>
                      </div>
                      {openFilter && (
                        <input
                          type="text"
                          name="type_of_help"
                          onBlur={handleSearchParams}
                          className="inputLogin p-1.5 shadow-xl rounded "
                          placeholder="Type Of Help"
                        />
                      )}
                    </th>
                    <th className="thead">
                      <div className="flex items-center justify-between">
                        Place
                        <div
                          className="text-base rounded-md shadow-md p-2  bg-teal-600 hover:bg-teal-600 text-white"
                          onClick={() => setOpenFilter(!openFilter)}
                        >
                          <FaFilter />
                        </div>
                      </div>
                      {openFilter && (
                        <input
                          type="text"
                          name="place"
                          onBlur={handleSearchParams}
                          className="inputLogin p-1.5 shadow-xl rounded "
                          placeholder="Place"
                        />
                      )}
                    </th>
                    <th className="thead">
                      <div className="flex items-center justify-between">
                        Hospital
                        <div
                          className="text-base rounded-md shadow-md p-2  bg-teal-600 hover:bg-teal-600 text-white"
                          onClick={() => setOpenFilter(!openFilter)}
                        >
                          <FaFilter />
                        </div>
                      </div>
                      {openFilter && (
                        <input
                          type="text"
                          name="hospital"
                          onBlur={handleSearchParams}
                          className="inputLogin p-1.5 shadow-xl rounded  "
                          placeholder="Hospital"
                        />
                      )}
                    </th>
                    <th className="thead">
                      <div className="flex items-center justify-between">
                        Type Of Test
                        <div
                          className="text-base rounded-md shadow-md p-2  bg-teal-600 hover:bg-teal-600 text-white"
                          onClick={() => setOpenFilter(!openFilter)}
                        >
                          <FaFilter />
                        </div>
                      </div>
                      {openFilter && (
                        <input
                          type="text"
                          name="type_of_test"
                          onBlur={handleSearchParams}
                          className="inputLogin p-1.5 shadow-xl rounded "
                          placeholder="Type Of Test"
                        />
                      )}
                    </th>
                    <th className="thead">
                      <div className="flex items-center justify-between">
                        Total
                        <div
                          className="text-base rounded-md shadow-md p-2  bg-teal-600 hover:bg-teal-600 text-white"
                          onClick={() => setOpenFilter(!openFilter)}
                        >
                          <FaFilter />
                        </div>
                      </div>
                      {openFilter && (
                        <input
                          type="text"
                          name="totalOf"
                          onBlur={handleSearchParams}
                          className="inputLogin p-1.5 shadow-xl rounded "
                          placeholder="Zad"
                        />
                      )}
                    </th>
                    <th className="thead">
                      <div className="flex items-center justify-between">
                        Date
                        <div
                          className="text-base rounded-md shadow-md p-2  bg-teal-600 hover:bg-teal-600 text-white"
                          onClick={() => setOpenFilter(!openFilter)}
                        >
                          <FaFilter />
                        </div>
                      </div>
                      {openFilter && (
                        <input
                          type="date"
                          name="date"
                          onBlur={handleSearchParams}
                          className="inputLogin p-1.5 shadow-xl rounded "
                          placeholder="Date"
                        />
                      )}
                    </th>
                    <th className="thead">Action</th>
                  </tr>
                </thead>
                <tbody className="">
                  {data.map((data, index) => {
                    return (
                      <tr className="tableRow" key={index}>
                        <td>{index + 1}</td>
                        <td>{data.patients.Pname}</td>
                        <td>{data.type_of_help}</td>
                        <td>{data.place}</td>
                        <td>{data.hospital}</td>
                        <td>{data.type_of_test}</td>
                        <td>{data.total}</td>
                        <td>{data.date}</td>
                        <td
                          onClick={() => {
                            setOpenModal(true);
                            setPatientId(data.patients.id);
                            setTypeMedicalId(data.id);
                          }}
                          className="bg-blue-500 text-white m-auto "
                        >
                          <p className="text-center ml-10 ">
                            <BsCardImage />
                          </p>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="flex absolute bottom-2 right-0 justify-end  mt-2 mr-4">
              <ReactPaginate
                previousLabel="<"
                nextLabel={">"}
                // nextClassName='bg-blue-500  hover:bg-blue-600 text-white font-semibold px-2 py-2 rounded-md'
                breakLabel="..."
                className="flex  items-center   -space-x-px h-8 text-sm"
                previousClassName="flex items-center justify-center px-3 h-8 ml-0  border border-gray-300 rounded-l-lg "
                nextClassName="flex items-center justify-center px-3 h-8 leading-tight text-gray-500  border border-gray-300 rounded-r-lg "
                pageLinkClassName="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500  border border-gray-300  "
                breakClassName="w-8 py-2 text-center"
                pageCount={Math.ceil(total / perPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                // activeClassName=''
                onPageChange={(event) => {
                  let currentPage = event.selected + 1;
                  dispatch(
                    // getPatient(locationID, currentPage, perPage, "", "", "", "", ""
                    getTypeMedicalAction(
                      locationId,
                      currentPage,
                      perPage,
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      ""
                    )
                  );
                  setCurrentPage(currentPage);
                }}
                activeLinkClassName="bg-black text-white flex  items-center  -space-x-px h-8 text-sm"
              />
            </div>
          </div>
        </>
      )}

      {openModal && (
        <TypeMedicalModal
          patientId={patientId}
          typeMedcaliId={typeMedcaliId}
          closeModal={setOpenModal}
        />
      )}
    </>
  );
};

export default MedicalSuplies;
