import {
  ADD_USER_CV_FAIL,
  ADD_USER_CV_REQUEST,
  ADD_USER_CV_SUCCESS,
  CREATE_USER_CV_FAIL,
  CREATE_USER_CV_REQUEST,
  CREATE_USER_CV_SUCCESS,
  CREATE_USER_FAIL,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  FETCH_ALL_USER_FAIL,
  FETCH_ALL_USER_REQUEST,
  FETCH_ALL_USER_SUCCESS,
  FETCH_USER_FAIL,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  SHOW_USER_ADMINSTRATOR_FAIL,
  SHOW_USER_ADMINSTRATOR_REQUEST,
  SHOW_USER_ADMINSTRATOR_SUCCESS,
  SHOW_USER_FAIL,
  SHOW_USER_REQUEST,
  SHOW_USER_SUCCESS,
  UPDATE_USER_FAIL,
  UPDATE_USER_INFORMATION_FAIL,
  UPDATE_USER_INFORMATION_REQUEST,
  UPDATE_USER_INFORMATION_SUCCESS,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
} from "../ActionType/userCreateType";

export const userCreateReducer = (
  state = { loading: false, users: [], errors: "" },
  action
) => {
  switch (action.type) {
    case CREATE_USER_REQUEST:
      return {
        loading: true,
      };
    case CREATE_USER_SUCCESS:
      return {
        loading: true,
        users: action.payload,
        errors: "",
      };
    case CREATE_USER_FAIL:
      return {
        loading: false,
        users: [],
        errors: action.error,
      };
    default:
      return state;
  }
};

export const getUserReducer = (
  state = { loading: true, users: [], loggedIn: false, error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_USER_REQUEST:
      return {
        loading: true,
      };

    case FETCH_USER_SUCCESS:
      return {
        loading: false,
        loggedIn: true,
        users: action.payload,
        error: "",
      };
    case FETCH_USER_FAIL:
      return {
        loading: false,
        users: [],
        error: "",
      };
    default:
      return state;
  }
};

export const showUserAdminstratorReducer = (
  state = { loading: true, users: [], error: "" },
  action
) => {
  switch (action.type) {
    case SHOW_USER_ADMINSTRATOR_REQUEST:
      return {
        loading: true,
      };

    case SHOW_USER_ADMINSTRATOR_SUCCESS:
      return {
        loading: false,
        users: action.payload,
        error: "",
      };
    case SHOW_USER_ADMINSTRATOR_FAIL:
      return {
        loading: false,
        users: [],
        error: "",
      };
    default:
      return state;
  }
};

export const getAllUserReducer = (
  state = { loading: true, user: [], total: 0, error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_ALL_USER_REQUEST:
      return {
        loading: true,
      };
    case FETCH_ALL_USER_SUCCESS:
      return {
        loading: false,
        user: action.payload.data,
        total: action.payload.total,
        error: "",
      };
    case FETCH_ALL_USER_FAIL:
      return {
        loading: false,
        total: 0,
        user: [],
        error: "",
      };
    default:
      return state;
  }
};

export const showUserReducer = (
  state = { loading: true, user: [], error: "" },
  action
) => {
  switch (action.type) {
    case SHOW_USER_REQUEST:
      return {
        loading: true,
      };
    case SHOW_USER_SUCCESS:
      return {
        loading: false,
        user: action.payload,
        error: "",
      };
    case SHOW_USER_FAIL:
      return {
        loading: false,

        user: [],
        error: "",
      };
    default:
      return state;
  }
};

export const updateUserInformationReducer = (
  state = { loading: true, user: [], errors: "" },
  action
) => {
  switch (action.type) {
    case UPDATE_USER_INFORMATION_REQUEST:
      return {
        loading: true,
      };
    case UPDATE_USER_INFORMATION_SUCCESS:
      return {
        loading: false,
        user: action.payload,
        errors: "",
      };
    case UPDATE_USER_INFORMATION_FAIL:
      return {
        loading: false,

        user: [],
        errors: action.error,
      };
    default:
      return state;
  }
};

export const updateUserReducer = (
  state = { loading: true, user: [], error: "" },
  action
) => {
  switch (action.type) {
    case UPDATE_USER_REQUEST:
      return {
        loading: true,
      };
    case UPDATE_USER_SUCCESS:
      return {
        loading: false,
        user: action.payload,
        error: "",
      };
    case UPDATE_USER_FAIL:
      return {
        loading: false,
        user: [],
        error: "",
      };
    default:
      return state;
  }
};


// USER CV REDUCER
export const createUserCvReducer = (
  state = { loading: false, users: [], error: "" },
  action
) => {
  switch (action.type) {
    case CREATE_USER_CV_REQUEST:
      return {
        loading: true,
      };
    case CREATE_USER_CV_SUCCESS:
      return {
        loading: true,
        users: action.payload,
        error: "",
      };
    case CREATE_USER_CV_FAIL:
      return {
        loading: false,
        users: [],
        error: "",
      };
    default:
      return state;
  }
};

export const deleteUserCvReducer = (
  state = { loading: false, users: [], error: "" },
  action
) => {
  switch (action.type) {
    case CREATE_USER_CV_REQUEST:
      return {
        loading: true,
      };
    case CREATE_USER_CV_SUCCESS:
      return {
        loading: true,
        users: action.payload,
        error: "",
      };
    case CREATE_USER_CV_FAIL:
      return {
        loading: false,
        users: [],
        error: "",
      };
    default:
      return state;
  }
};

export const addUserCvReducer = (
  state = { loading: false, users: [], error: "" },
  action
) => {
  switch (action.type) {
    case ADD_USER_CV_REQUEST:
      return {
        loading: true,
      };
    case ADD_USER_CV_SUCCESS:
      return {
        loading: true,
        users: action.payload,
        error: "",
      };
    case ADD_USER_CV_FAIL:
      return {
        loading: false,
        users: [],
        error: "",
      };
    default:
      return state;
  }
};