import React from "react";
import Logo from "../../Image/Logo.jpg";
import { Link } from "react-router-dom";
const Administrator = () => {
  return (
    <div className="containerDash text-black w-11/12 m-auto">
      <Link to={"sentFile"}>
        <div className="bg-white shadow-xl rounded-md">
          <img className="w-full  h-40 object-cover" src={Logo} alt="Card" />
          <div className="p-2">
            <h3 className="text-lg font-semibold text-center">ڕۆشتووەکان</h3>
            {/* <p className="text-gray-600">{description}</p> */}
          </div>
        </div>
      </Link>

      <div className="bg-white shadow-xl rounded-md">
        <img className="w-full  h-40 object-cover" src={Logo} alt="Card" />
        <div className="p-2">
          <h3 className="text-lg font-semibold text-center">هاتووەکان</h3>
          {/* <p className="text-gray-600">{description}</p> */}
        </div>
      </div>

      <div className="bg-white shadow-xl rounded-md">
        <img className="w-full  h-40 object-cover" src={Logo} alt="Card" />
        <div className="p-2">
          <h3 className="text-lg font-semibold text-center">ئەرشیف</h3>
          {/* <p className="text-gray-600">{description}</p> */}
        </div>
      </div>
      <Link to={"patient"}>
        <div className="bg-white shadow-xl rounded-md">
          <img className="w-full  h-40 object-cover" src={Logo} alt="Card" />
          <div className="p-2">
            <h3 className="text-lg font-semibold text-center">
              زیاد کردنی نەخۆش
            </h3>
            {/* <p className="text-gray-600">{description}</p> */}
          </div>
        </div>
      </Link>
    </div>
  );
};

export default Administrator;
