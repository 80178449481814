import React, { useEffect, useState } from "react";
import "./adminstarator.css";
import { useDispatch, useSelector } from "react-redux";
import { getBranch } from "../../Action/branch";
import { getSection } from "../../Action/section";
import Select from "react-select";
import {
  createReciveFilesAdminstratorAction,
  showReciveFilesAdminstratorAction,
  updateReciveFilesAdminstratorAction,
} from "../../Action/ReciveFilesAdminstratorAction/ReciveFilesAdminstratorAction";
import Logo from "../../Image/Logo.jpg";
import { showUserAdminstratorAction } from "../../Action/userRegister";
const TransferSentFileAdminstratorModal = ({ id, closeModal }) => {
  const dispatch = useDispatch();
  const [branchId, setBranchId] = useState();
  const [sectionId, setSectionId] = useState();
  const showReciveFilesAdminstrator = useSelector(
    (state) => state.showReciveFilesAdminstrator
  );
  const { reciveFiles, loading } = showReciveFilesAdminstrator;
  const [reciveFilesId, setReciveFilesId] = useState(null);

  useEffect(() => {
    dispatch(showReciveFilesAdminstratorAction(id));
  }, []);

  const showUserAdminstrator = useSelector(
    (state) => state.showUserAdminstrator
  );
  const { users } = showUserAdminstrator;
  let showUserNameArr = [];

  useEffect(() => {
    if (reciveFiles !== undefined) {
      let branchIdsArray = [];
      let sectionIdArray = [];
      let userIdArray = [];
      reciveFiles.forEach((recive) => {
        recive.branch_id.forEach((branch) => {
          branchIdsArray.push({
            value: branch,
            label: branch,
          });
        });
      });

      reciveFiles.forEach((recive) => {
        recive.user.forEach((user) => {
          userIdArray.push({
            value: user.value,
            label: user.label,
          });
        });
      });

      reciveFiles.forEach((recive) => {
        recive.section_id.forEach((section) => {
          sectionIdArray.push({
            value: section,
            label: section,
          });
        });
      });

      setBranchId(branchIdsArray);
      setSectionId(sectionIdArray);
      setUserNameValue(userIdArray);
      if (reciveFiles.length > 0) {
        setReciveFilesId(reciveFiles[0].id);
      }
    } else {
      setBranchId([]);
      setSectionId([]);
      setUserNameValue([])
      setReciveFilesId(null);
    }
  }, [reciveFiles]);

  const [showBranchId, setShowBranchId] = useState([]);
  const [showSectionhId, setShowSectionhId] = useState([]);
  useEffect(() => {
    if (showBranchId.length == 0 || showSectionhId.length==0) {
      return;
    }
    dispatch(showUserAdminstratorAction(showBranchId,showSectionhId));
  }, [showBranchId,showSectionhId]);
  const [userNameValue, setUserNameValue] = useState([]);
  users.map((user) => {
    showUserNameArr.push({
      label: user.fullname_en,
      value: user.id,
    });
  });

  const getBranchReducer = useSelector((state) => state.getBranch);
  const getSectionReducer = useSelector((state) => state.getSection);
  const { sections } = getSectionReducer;
  const { branchs } = getBranchReducer;
  useEffect(() => {
    dispatch(getBranch());
    dispatch(getSection());
  }, []);

  const optBranch = [];
  const optSection = [];
  branchs.map((branch) => {
    optBranch.push({
      value: branch.id,
      label: branch.bName,
    });
  });

  sections.map((section) => {
    optSection.push({
      value: section.id,
      label: section.sName,
    });
  });

  const [error, setError] = useState({
    brnachId: "",
    sectionId: "",
  });
  const handleSend = () => {
    if (branchId.length == 0) {
      setError({
        brnachId: "تکایە زانیاریەکە پڕکەرەوە",
        sectionId: "",
        user:""
      });
      return;
    }
    if (sectionId.length == 0) {
      setError({
        brnachId: "",
        sectionId: "تکایە زانیاریەکە پڕکەرەوە",
        user:""
      });
      return;
    }
    if (userNameValue.length == 0) {
      setError({
        brnachId: "",
        sectionId: "",
        user: "تکایە زانیاریەکە پڕکەرەوە",
      });
      return;
    }
    setError({
      brnachId: "",
      sectionId: "",
      user: "",
    });
    dispatch(
      createReciveFilesAdminstratorAction(
        branchId,
        sectionId,
        id,
        userNameValue
      )
    );
    dispatch(showReciveFilesAdminstratorAction(id));
    dispatch(showReciveFilesAdminstratorAction(id));
  };
  const handleUpdate = () => {
    if (branchId.length == 0) {
      setError({
        brnachId: "تکایە زانیاریەکە پڕکەرەوە",
        sectionId: "",
        user:""
      });
      return;
    }
    if (sectionId.length == 0) {
      setError({
        brnachId: "",
        sectionId: "تکایە زانیاریەکە پڕکەرەوە",
        user:""
      });
      return;
    }
    if (userNameValue.length == 0) {
      setError({
        brnachId: "",
        sectionId: "",
        user: "تکایە زانیاریەکە پڕکەرەوە",
      });
      return;
    }
    dispatch(
      updateReciveFilesAdminstratorAction(
        branchId,
        sectionId,
        reciveFilesId,
        userNameValue
      )
    );
    dispatch(showReciveFilesAdminstratorAction(id));
    dispatch(showReciveFilesAdminstratorAction(id));
    setError({
      brnachId: "",
      sectionId: "",
      user: "",
    });
  };

  return (
    <>
      {loading && reciveFiles == undefined ? (
        <div className="flex absolute top-10 m-auto left-[24%] xl:left-[42%]  flex-col">
          <img
            src={Logo}
            alt="Logo"
            className="w-56 m-auto animate-pulse mt-20 "
          />
          <div className="text-2xl text-center font-bold  ">
            <div className="flex justify-center gap-4">
              <p className="animate-charcter">Please Wait</p>
              <div class="container2">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>{" "}
            </div>
          </div>
        </div>
      ) : (
        <div className="parentModalPrimary">
          <div
            className="overlayPrimary"
            onClick={() => closeModal(closeModal)}
          ></div>
          <div className="containerPrimary">
            <div className="headerPrimaryModal">
              <div className="flex flex-wrap gap-2">
                <div className="xl:w-72 ">
                  <Select
                    isMulti
                    options={optBranch}
                    onChange={(selectOption) => {
                      setBranchId(selectOption);
                      setShowBranchId(selectOption);
                    }}
                    value={branchId}
                  />
                  <div>
                    <p className="text-right text-red-500">{error.brnachId}</p>
                  </div>
                </div>
                <div className="xl:w-72">
                  <Select
                    isMulti
                    options={optSection}
                    onChange={(selectOption) => {
                      setSectionId(selectOption);
                      setShowSectionhId(selectOption)
                    }}
                    value={sectionId}
                  />
                  <div>
                    <p className="text-right text-red-500">{error.sectionId}</p>
                  </div>
                </div>

                <div className="xl:w-72">
                  <Select
                    isMulti
                    options={showUserNameArr}
                    onChange={(selectOption) => {
                      setUserNameValue(selectOption);
                    }}
                    value={userNameValue}
                  />
                  <div>
                    <p className="text-right text-red-500">{error.user}</p>
                  </div>
                </div>
                {/* )} */}
              </div>

              <div>
                <p
                  className="text-xl text-red-400 cursor-pointer"
                  onClick={() => closeModal(false)}
                >
                  {" "}
                  X
                </p>
              </div>
            </div>
            <div>
              {reciveFilesId == null ||
              reciveFilesId == "" ||
              reciveFilesId == undefined ? (
                <button className="btn" onClick={handleSend}>
                  Send
                </button>
              ) : (
                <button className="btn" onClick={handleUpdate}>
                  Update
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TransferSentFileAdminstratorModal;
