import Swal from "sweetalert2";
import {
  CREATE_USER_CV_SUCCESS,
  CREATE_USER_FAIL,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  FETCH_ALL_USER_FAIL,
  FETCH_ALL_USER_REQUEST,
  FETCH_ALL_USER_SUCCESS,
  FETCH_USER_FAIL,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  SHOW_USER_ADMINSTRATOR_FAIL,
  SHOW_USER_ADMINSTRATOR_REQUEST,
  SHOW_USER_ADMINSTRATOR_SUCCESS,
  SHOW_USER_FAIL,
  SHOW_USER_REQUEST,
  SHOW_USER_SUCCESS,
  UPDATE_USER_FAIL,
  UPDATE_USER_INFORMATION_FAIL,
  UPDATE_USER_INFORMATION_REQUEST,
  UPDATE_USER_INFORMATION_SUCCESS,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
} from "../ActionType/userCreateType";
import { api } from "../Api/api";
import { getToken, logOut } from "./login";

export const userRegister =
  (
    EnName,
    KuName,
    birthDate,
    gender,
    phone,
    secPhone,
    pnpc,
    email,
    married,
    NumberOfChildern,
    bloodGorup,
    DateOfCommencement,
    ChronicDiseases,
    certificate,
    certificateField,
    OccupationOutside,
    Workplace,
    jobTitle,
    LanguageLevel,
    ku_lang,
    ar_lang,
    Computerskills,
    branch,
    section,
    role,
    image,
    hourly_wage,
    extra_wage
  ) =>
  async (dispatch) => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    };
    const location_id = localStorage.getItem("locationId");

    api
      .post(
        "user/create",
        {
          fullname_en: EnName,
          fullname_ku: KuName,
          email: email,
          password: "12345678",
          birth_date: birthDate,
          gender: gender,
          phone: phone,
          secound_phone: secPhone,
          close_phone: pnpc,
          married: married,
          number_of_children: NumberOfChildern,
          blood_group: bloodGorup,
          date_of_commencement: DateOfCommencement,
          chronic_diseases: ChronicDiseases,
          certificate: certificate,

          certificate_field: certificateField,
          occ: EnName,
          occupationoutside: OccupationOutside,
          work_place: Workplace,
          job_tiltle: jobTitle,
          en_lang: LanguageLevel,
          computer_skills: Computerskills,
          branch_id: branch || location_id,
          section_id: section,
          role_id: role,
          ku_lang: ku_lang,
          ar_lang: ar_lang,
          image,
          hourly_wage,
          extra_wage,
        },
        config
      )
      .then((response) => {
    
        dispatch({ type: CREATE_USER_REQUEST });
        if (response.status == 200) {

          const user = response.data;
          if (image != null) {
            dispatch(createUserCvAction(user.id, image));
          }

          dispatch({ type: CREATE_USER_SUCCESS, payload: user });
          Swal.fire("Good job!", "Account Was Created", "success");
        } else if (response.status === 404) {
          
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Please Fill The Gaps",
          });
        }
      })
      .catch((error) => {
        
        const {status,data}=error.response;
        if(status==422){  
          
          dispatch({ type: CREATE_USER_FAIL, payload: [],error:data.errors });  
          return;
        }
        dispatch({ type: CREATE_USER_FAIL, payload: [] });
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Request Is Faild",
        });
      });
  };

export const getUserAuth = () => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };

  api
    .get("user/getAuth", config)
    .then((response) => {
      dispatch({ type: FETCH_USER_REQUEST });
      if (response.data.status === 200) {
        // console.log("resp: ", response);
        dispatch({ type: FETCH_USER_SUCCESS, payload: response.data.userInfo });
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("locationId");
        localStorage.removeItem("notifi");
        localStorage.removeItem("loggedIn");
      }
      dispatch({ type: FETCH_USER_FAIL, payload: [] });
    });
};

export const showUserAdminstratorAction = (location, section) => (dispatch) => {
  const arrayLocationTemp = [];
  location.map((loc) => {
    arrayLocationTemp.push(loc.value);
  });
  const arraySectionTemp = [];
  section.map((loc) => {
    arraySectionTemp.push(loc.value);
  });
  // console.log(arraySectionTemp);
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    params: {
      location: arrayLocationTemp,
      section: arraySectionTemp,
    },
  };

  api
    .get("user/showUserAdminstrator", config)
    .then((response) => {
      const { status, data } = response;
      // console.log('data1 : ',data);
      dispatch({ type: SHOW_USER_ADMINSTRATOR_REQUEST });
      if (status == 200) {
        dispatch({ type: SHOW_USER_ADMINSTRATOR_SUCCESS, payload: data });
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch({ type: SHOW_USER_ADMINSTRATOR_FAIL, payload: [] });
    });
};

export const updateUserAction = (id, password) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
  api
    .post(
      "user/update",
      {
        id: id,
        password: password,
      },
      config
    )
    .then((response) => {
      const { status } = response;

      dispatch({ type: UPDATE_USER_REQUEST });
      if (status === 200) {
        dispatch({
          type: UPDATE_USER_SUCCESS,
          payload: response.data.userInfo,
        });
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "success",
          title: "User Was Saved",
        });
      }

      if (status == 500) {
        dispatch({
          type: UPDATE_USER_SUCCESS,
          payload: response.data.userInfo,
        });
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: "Some thing Went Wrong",
        });
      }
    })
    .catch((error) => {
      dispatch({ type: UPDATE_USER_FAIL, payload: [] });

      dispatch({
        type: UPDATE_USER_SUCCESS,
        // payload: response.data.userInfo,
      });
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        title: "Your Request Is Faild",
      });
    });
};

export const getAllUserAction =
  (
    page,
    perPage,
    fullname_ku,
    fullname_en,
    email,
    birth_date,
    gender,
    phone,
    secound_phone,
    close_phone,
    married,
    number_of_children,
    blood_group,
    date_of_commencement,
    chronic_diseases,
    certificate,
    certificate_field,
    occupationoutside,
    work_place,
    job_tiltle,
    language_level,
    computer_skills,
    ar_lang,
    ku_lang
  ) =>
  (dispatch) => {

    dispatch({
      type: FETCH_ALL_USER_REQUEST,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      params: {
        page,
        perPage,
        fullname_ku,
        fullname_en,
        email,
        birth_date,
        gender,
        phone,
        secound_phone,
        close_phone,
        married,
        number_of_children,
        blood_group,
        date_of_commencement,
        chronic_diseases,
        certificate,
        certificate_field,
        occupationoutside,
        work_place,
        job_tiltle,
        language_level,
        computer_skills,
        ar_lang,
        ku_lang,
      },
    };
    api
      .get("user", config)
      .then((response) => {
        const { data, status } = response;
        dispatch({
          type: FETCH_ALL_USER_REQUEST,
        });
        if (status == 200) {
          dispatch({
            type: FETCH_ALL_USER_SUCCESS,
            payload: { data: data.data, total: data.total },
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_ALL_USER_FAIL,
          payload: [],
          error: "Please Check Your Request",
        });
      });
  };

export const resetPasswordUserAction = (id) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
  api
    .post(`user/reset/${id}`, {}, config)
    .then((response) => {
      const { status } = response;

      if (status === 200) {
      }

      if (status == 500) {
      }
    })
    .catch((error) => {
      dispatch({ type: UPDATE_USER_FAIL, payload: [] });

      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        title: "Your Request Is Faild",
      });
    });
};

export const showUserAction = (id) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
  dispatch({
    type: SHOW_USER_REQUEST,
    // payload:response.data,
  });
  api
    .get(`user/show/${id}`, config)
    .then((response) => {
      const { status, data } = response;

      if (status === 200) {
        dispatch({
          type: SHOW_USER_SUCCESS,
          payload: data,
        });
      }

      if (status == 500) {
        dispatch({
          type: SHOW_USER_FAIL,
          payload: data,
        });
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_USER_FAIL, payload: [] });

      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        title: "Your Request Is Faild",
      });
    });
};

export const updateUserInformationAction =
  (
    id,
    EnName,
    KuName,
    birthDate,
    gender,
    phone,
    secPhone,
    pnpc,
    email,
    married,
    NumberOfChildern,
    bloodGorup,
    DateOfCommencement,
    ChronicDiseases,
    certificate,
    certificateField,
    OccupationOutside,
    Workplace,
    jobTitle,
    en_lang,
    ku_lang,
    ar_lang,
    Computerskills,
    branch,
    section,
    role,
    
    hourly_wage,
    extra_wage,
    image
  ) =>
  (dispatch) => {
    
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    };
    dispatch({
      type: UPDATE_USER_INFORMATION_REQUEST,
    });

    const formData = new FormData();
    formData.append("fullname_en", EnName);
    formData.append("fullname_ku", KuName);
    formData.append("email", email);
    // formData.append("password", "12345678");
    formData.append("birth_date", birthDate);
    formData.append("gender", gender);
    formData.append("phone", phone);
    formData.append("secound_phone", secPhone);
    formData.append("close_phone", pnpc);
    formData.append("married", married);
    formData.append("number_of_children", NumberOfChildern);
    formData.append("blood_group", bloodGorup);
    formData.append("date_of_commencement", DateOfCommencement);
    formData.append("chronic_diseases", ChronicDiseases);
    formData.append("certificate", certificate);
    formData.append("certificate_field", certificateField);
    formData.append("occupationoutside", OccupationOutside);
    formData.append("work_place", Workplace);
    formData.append("job_tiltle", jobTitle);
    formData.append("en_lang", en_lang);
    formData.append("ku_lang", ku_lang);
    formData.append("ar_lang", ar_lang);
    formData.append("computer_skills", Computerskills);
    formData.append("branch_id", branch);
    formData.append("section_id", section);
    formData.append("role_id", role);
    formData.append("user_cv", image);
    formData.append("hourly_wage",hourly_wage)
    formData.append("extra_wage",extra_wage)

    api
      .post(`user/updateUser/${id}`, formData, config)
      .then((response) => {
        const { status, data } = response;
        
        if (status === 200) {
          dispatch({
            type: UPDATE_USER_INFORMATION_SUCCESS,
            payload: data,
          });
          Swal.fire({
            icon: "success",
            timer: 1500,
            title: "سەرکەوتوو بوو",
            text: "زانیاریەکە گۆڕدرا",
          });
        }

        if (status == 500) {
          dispatch({
            type: UPDATE_USER_INFORMATION_FAIL,
            payload: data,
          });
        }
      })
      .catch((error) => {
        
        const {status,data}=error.response;
        if(status==422){
          console.log("errors : ",data.errors)
          dispatch({ type: UPDATE_USER_INFORMATION_FAIL, payload: [],error:data.errors });
        }
        
        
        
      });
  };

export const createUserCvAction = (userId, cvs) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
  const formData = new FormData();
  formData.append("user_id", userId);
  formData.append("cv", cvs);
  formData.append("profile", "1");

  // cvs.map((cv) => {
  //   formData.append("cv[]", cv);
  // });
  api
    .post("userCv/create", formData, config)
    .then((response) => {
      const { status, data } = response;
      if (status == 200) {
        dispatch({
          type: CREATE_USER_CV_SUCCESS,
          payload: data,
        });
      } else {
        Swal.fire({
          icon: "warning",
          title: "Ops !!",
          text: "Please Check Your Code",
        });
      }
    })
    .catch((err) => {
      console.log(err);
      Swal.fire({
        icon: "error",
        title: "Ops !!",
        text: "Please Check Your Request",
      });
    });
};

export const handleDeleteAlert = (id) => (dispatch) => {
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn-success ",
      cancelButton: "btn-danger",
    },
    buttonsStyling: false,
  });
  swalWithBootstrapButtons
    .fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    })
    .then((result) => {
      if (result.isConfirmed) {
        swalWithBootstrapButtons.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
        });

        dispatch(deleteUserCvAction(id));
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire({
          title: "Cancelled",
          text: "Your imaginary file is safe :)",
          icon: "error",
        });
      }
    });
};

export const deleteUserCvAction = (id) => (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
  api
    .delete(`userCv/delete/${id}`, config)
    .then((response) => {
      const { status, data } = response;

      if (status == 200) {
        dispatch(showUserAction(data.user_id));
        dispatch(showUserAction(data.user_id));
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const addUserCvAction = (userId, cvs, profiles) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
  const formData = new FormData();
  formData.append("user_id", userId);

  cvs.map((cv) => {
    formData.append("cv[]", cv);
  });
  profiles.map((profile) => {
    formData.append("profile[]", profile);
  });

  api
    .post("userCv/addCv", formData, config)
    .then((response) => {
      const { status, data } = response;
      if (status == 200) {
        dispatch({
          type: CREATE_USER_CV_SUCCESS,
          payload: data,
        });
        Swal.fire({
          icon: "success",
          timer: 1500,
          title: "سەرکەوتوو بوو ",
          text: "ڕەسمەکان بە سەرکەوتووی داخل کرا ",
        });
      } else {
        Swal.fire({
          icon: "warning",
          title: "Ops !!",
          text: "Please Check Your Code",
        });
      }
    })
    .catch((err) => {
      console.log(err);
      Swal.fire({
        icon: "error",
        title: "Ops !!",
        text: "Please Check Your Request",
      });
    });
};
