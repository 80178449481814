import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaFilter } from "react-icons/fa";
import { RiSendPlaneFill } from "react-icons/ri";

import { AiOutlineEye } from "react-icons/ai";
// import '../../Pages/Branchs/css/invoice.css'
import { getFeedbackAction } from "../../Action/lezhnaAction";
import Logo from "../../Image/Logo.jpg";
import ViewAllInfoPatient from "../../Modal/ViewAllInfoPatient";
import SendModalAllBranch from "../../Modal/SendModalAllBrnach/SendModalAllBranch";
import ReactPaginate from "react-paginate";
const Feedback = () => {
  const location_id = localStorage.getItem("locationId");
  const dispatch = useDispatch();
  const getFeedback = useSelector((state) => state.getFeedback);
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const { feedback, total, loading } = getFeedback;
  const [searchParams, setSearchParams] = useState({
    fullname: "",
    phone: "",
    code: "",
    age: "",
    gender: "",
  });
  const { fullname, phone, code, age, gender } = searchParams;
  const [isOpenViewAllInfoModal, setIsOpenViewAllInfoModal] = useState(false);
  const [isOpenSendModal, setIsOpenSendModal] = useState(false);
  useEffect(() => {
    // console.log(code);
    dispatch(
      getFeedbackAction(
        location_id,
        code,
        fullname,
        gender,
        age,
        phone,
        currentPage,
        perPage
      )
    );
  }, [code, fullname, gender, age, phone, currentPage, perPage]);

  const [lezhnaId, setLezhnaId] = useState(null);
  const [patientId, setPatientId] = useState(null);

  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const handleOpenFilter = () => {
    setIsOpenFilter(!isOpenFilter);
  };
  const handleSearchParams = (event) => {
    const { name, value } = event.target;
    setSearchParams({
      ...searchParams,
      [name]: value,
    });
  };
  const regex = /^(\d{4})(\d{3})(\d{4})$/;
  return (
    <>
      {loading ? (
        <div className="flex flex-col">
          <img
            src={Logo}
            alt="Logo"
            className="w-56 m-auto animate-pulse mt-20 "
          />
          <div className="text-2xl text-center font-bold  ">
            <div className="flex justify-center gap-4">
              <p className="animate-charcter">Please Wait</p>
              <div class="container2">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>{" "}
            </div>
          </div>
        </div>
      ) : (
        <div className="relative bg-white md:h-[24rem] lg:h-[20rem] xl:h-[30rem]  3xl:h-[43rem]   w-[98%] m-auto  sm:rounded-lg  mt-4">
        <div className="flex gap-2 items-center bg-gray-100 px-4 border-b py-4 rounded-md shadow-xl  ">
          <p className="text-xl text-teal-600">
            {/* <HiOutlineUserGroup /> */}
          </p>
          <div>
            <h1 className="text-xl">Affairs Of Surgery</h1>
          </div>
        </div>
        <div></div>

        <div className="overflow-x-auto  h-[70%]  xl:h-[75%] 3xl:h-[45rem]  m-auto">
        <table className="w-full text-sm  overflow-y-auto m-auto   text-left text-gray-900">
            <thead className="sticky top-0">
              <tr>
                <th>
                  <div className="parentTH">
                    <p className="pl-1">Patient ID</p>
                    <div className="filterIcon" onClick={handleOpenFilter}>
                      <FaFilter />
                    </div>
                  </div>
                  <div>
                    <div className="w-full">
                      <input
                        onBlur={handleSearchParams}
                        name="code"
                        type="text"
                        placeholder="Code"
                        className={
                          isOpenFilter
                            ? "inputLogin p-1.5 shadow-xl rounded  "
                            : "hidden"
                        }
                      />
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parentTH">
                    <p className="pl-1">Full Name</p>
                    <div className="filterIcon" onClick={handleOpenFilter}>
                      <FaFilter />
                    </div>
                  </div>
                  <div>
                    <div className="w-full">
                      <input
                        onBlur={handleSearchParams}
                        name="fullname"
                        type="text"
                        placeholder="Full Name"
                        className={
                          isOpenFilter
                            ? "inputLogin p-1.5 shadow-xl rounded  "
                            : "hidden"
                        }
                      />
                    </div>
                  </div>
                </th>
                <th>
                  <div className="parentTH">
                    <p className="pl-1">Phone</p>
                    <div className="filterIcon" onClick={handleOpenFilter}>
                      <FaFilter />
                    </div>
                  </div>
                  <div>
                    <div className="w-full">
                      <input
                        onBlur={handleSearchParams}
                        name="phone"
                        type="text"
                        placeholder="Phone Number"
                        className={
                          isOpenFilter
                            ? "inputLogin p-1.5 shadow-xl rounded  "
                            : "hidden"
                        }
                      />
                    </div>
                  </div>
                </th>
                <th>
                  {" "}
                  <div className="parentTH">
                    <p className="pl-1">Gender</p>
                    <div className="filterIcon" onClick={handleOpenFilter}>
                      <FaFilter />
                    </div>
                  </div>
                  <div>
                    <div className="w-full">
                      <input
                        onBlur={handleSearchParams}
                        name="gender"
                        type="text"
                        placeholder="Gender"
                        className={
                          isOpenFilter
                            ? "inputLogin p-1.5 shadow-xl rounded  "
                            : "hidden"
                        }
                      />
                    </div>
                  </div>
                </th>
                <th>
                  {" "}
                  <div className="parentTH">
                    <p className="pl-1">Birth Date</p>
                    <div className="filterIcon" onClick={handleOpenFilter}>
                      <FaFilter />
                    </div>
                  </div>
                  <div>
                    <div className="w-full">
                      <input
                        onBlur={handleSearchParams}
                        name="age"
                        type="text"
                        placeholder="Birth Date"
                        className={
                          isOpenFilter
                            ? "inputLogin p-1.5 shadow-xl rounded  "
                            : "hidden"
                        }
                      />
                    </div>
                  </div>
                </th>
                <th>Date</th>
                <th>Visits</th>
                <th>Send</th>
                <th>View</th>
              </tr>
            </thead>
            <tbody>
              {feedback.map((item, index) => {
                return (
                  <tr key={index} className="tableRow">
                    <td>{item.code}</td>
                    <td>{item.Pname}</td>
                    <td>{item.phone.replace(regex, "$1-$2-$3")}</td>
                    <td>{item.gender}</td>
                    <td>{item.birthDate}</td>
                    <td>{item.created_at.toString().slice(0, 10)}</td>
                    <td>{item.visits_with_reject_count}</td>
                    <td
                      className="relative group"
                      onClick={() => {
                        setIsOpenSendModal(true);
                        item.commites.map((commite) => {
                          setPatientId(commite.PatientId);
                          commite.lezhnas.map((lezhna) => {
                            setLezhnaId(lezhna.id);
                          });
                        });
                      }}
                    >
                      <p className="text-indigo-500 text-xl">
                        <RiSendPlaneFill />
                      </p>
                      <div className="tooltip">
                        <p className="text-sm">Send </p>
                      </div>
                    </td>

                    <td
                      className="relative group"
                      onClick={() => {
                        setIsOpenViewAllInfoModal(true);
                        item.commites.map((commite) => {
                          setPatientId(commite.PatientId);
                          commite.lezhnas.map((lezhna) => {
                            
                            setLezhnaId(lezhna.id);
                          });
                        });
                      }}
                    >
                      <p className="text-teal-500 text-xl">
                        <AiOutlineEye />
                      </p>
                      <div className="tooltipView">
                        <p className="text-sm">View </p>
                      </div>
                    </td>
                  </tr>
                );
              })}
              <tr></tr>
            </tbody>
          </table>

        </div>

        <div className="gap-3 px-3 flex  items-center justify-end py-2 m-auto">
            <div className="w-20">
              <select
                name=""
                className=" bg-white border  w-full focus:ring-2   focus:ring-slate-900 rounded-md   focus:outline-none"
                onChange={(e) => setPerPage(e.target.value)}
                value={perPage}
                id=""
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
            <div className="flex justify-center  ">
              <ReactPaginate
                previousLabel="<"
                nextLabel={">"}
                // nextClassName='bg-blue-500  hover:bg-blue-600 text-white font-semibold px-2 py-2 rounded-md'
                breakLabel="..."
                className="flex  items-center   -space-x-px h-8 text-sm"
                previousClassName="flex items-center justify-center px-3 h-8 ml-0  border border-gray-300 rounded-l-lg "
                nextClassName="flex items-center justify-center px-3 h-8 leading-tight text-gray-500  border border-gray-300 rounded-r-lg "
                pageLinkClassName="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500  border border-gray-300  "
                breakClassName="w-8 py-2 text-center"
                pageCount={Math.ceil(total / perPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={(event) => {
                  let currentPage = event.selected + 1;
                  dispatch(
                    getFeedbackAction(
                      location_id,
                      "",
                      "",
                      "",
                      "",
                      "",
                      currentPage,
                      perPage
                    )
                  );
                  // console.log(currentPage);
                  setCurrentPage(currentPage);
                }}
                // activeClassName="bg-red-900 text-white"
                // style={currentPage==1?"bg-black":""}
                activeLinkClassName="bg-black text-white flex  items-center  -space-x-px h-8 text-sm"
              />
            </div>
          </div>

      </div>
      )}

      {isOpenViewAllInfoModal && (
        <ViewAllInfoPatient
          lezhnaId={lezhnaId}
          closeModal={setIsOpenViewAllInfoModal}
          patientId={patientId}
        />
      )}
      {isOpenSendModal && (
        <SendModalAllBranch
          lezhna_id={lezhnaId}
          closeModal={setIsOpenSendModal}
        />
      )}
    </>
  );
};

export default Feedback;
