import React, { useEffect, useState } from "react";
import { RiSendPlaneFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import {
  createReceiveAction,
  getReciveAction,
} from "../../../Action/receiveAction";
import { getBranch } from "../../../Action/branch";
import Swal from "sweetalert2";
import Logo from "../../../Image/Logo.jpg";

const ComeBack = ({ receive, lezhna_id, location_id }) => {
  const [note, setNote] = useState(null);
  const [reciveId, setReciveId] = useState(null);

  const [brnachName, setBranchName] = useState(null);
  const dispatch = useDispatch();

  const locationId = localStorage.getItem("locationId");
  const getBranchs = useSelector((state) => state.getBranch);
  const { branchs } = getBranchs;
  useEffect(() => {
    dispatch(getBranch());
  }, []);

  useEffect(() => {
    if (receive == undefined || receive.length == 0) {
      setReciveId("");
      setNote("");
      return;
    } else if (receive != undefined || receive.length != 0) {
      receive
        // .filter((filter) => filter.lezhna_id == lezhna_id)
        .map((item) => {
          console.log(item.note);
          setReciveId(item.id);
          setNote(item.note);
        });
    }
  }, [lezhna_id, receive]);

  useEffect(() => {
    branchs
      .filter((filter) => filter.id == locationId)
      .map((item) => {
        setBranchName(item.bName);
      });
  }, [location_id]);
  const [error, setError] = useState();
  const handleSend = (event) => {
    event.preventDefault();

    if (location_id == "" || location_id == null || location_id == undefined) {
      Swal.fire({
        icon: "error",
        title: "سەرکەوتوو نەبوو ",
        text: "تکایە لقێک هەڵبژێرە",
        timer: 2000,
      });
      return;
    }
    if (note == null || note == "") {
      setError("تکایە خانەکە پڕکەرەوەو");
      return;
    }

    dispatch(
      createReceiveAction(reciveId, location_id, lezhna_id, note, brnachName)
    );
    dispatch(getReciveAction(locationId, "", "", "", "", "", lezhna_id));
    dispatch(getReciveAction(locationId, "", "", "", "", "", lezhna_id));
  };
  return (
    <>
      {receive == undefined ? (
        <div className="flex flex-col">
          <img
            src={Logo}
            alt="Logo"
            className="w-56 m-auto animate-pulse mt-5 "
          />
          <div className="text-2xl text-center font-bold  ">
            <div className="flex justify-center gap-4">
              <p className="animate-charcter">Please Wait</p>
              <div class="container2">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>{" "}
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-3 w-[95%] m-auto">
          <div className="bg-blue-600  xl:w-2/12  rounded-md shadow-md mb-3  text-white m-auto py-2 px-4">
            <h1 className="text-center">گەڕانەوە لای دکتۆر</h1>
          </div>
          <form action="">
            <textarea
              name="note"
              // key={index}
              onChange={(event) => {
                setNote(event.target.value);
              }}
              placeholder="Note"
              className="inputLogin text-right mb-2"
              rows="5"
              value={note}
            ></textarea>

            <div>
              <p className="text-red-400 text-right animate-pulse">{error} </p>
            </div>
            <button
              onClick={handleSend}
              className="bg-blue-500 bg-opacity-70 duration-300 text-blue-700 px-4 py-1 rounded-md shadow-md hover:bg-opacity-100 hover:text-white group "
            >
              {" "}
              <RiSendPlaneFill className="group-hover:rotate-45 duration-300" />{" "}
            </button>
          </form>
        </div>
      )}
    </>
  );
};

export default ComeBack;
