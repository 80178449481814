import React, { useEffect, useState } from "react";
import "./css/sidebar.css";
import { NavLink } from "react-router-dom";

import { MdDashboard } from "react-icons/md";
import { AiOutlineClose, AiOutlineUser } from "react-icons/ai";

import { FcStatistics } from "react-icons/fc";

import Logo from "../../Image/Logo.jpg";
import { BiSolidUserDetail } from "react-icons/bi";

import { useDispatch, useSelector } from "react-redux";
// import { getUserAuth } from "../../Action/userRegister";
import { getBranch } from "../../Action/branch";
import { FaFile,  } from "react-icons/fa6";
import { FaUserCog } from "react-icons/fa";
import { IoTimer } from 'react-icons/io5'
import { ImProfile } from 'react-icons/im'

const Sidebar = ({ openModal, isOpenSidebar,users }) => {
  const pageAdminstrator = [
    {
      id: 14,
      name: "Roles",
      icon: <FaUserCog />,
      route: "roles",
    },

    {
      id: 11,
      name: "Administration",
      icon: <BiSolidUserDetail />,
      route: "Administrator",
    },
    {
      id: 13,
      name: "Statistics",
      icon: <FcStatistics />,
      route: "statistics",
    },
  ];

  const locationId = localStorage.getItem("locationId");
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (locationId == null || locationId == undefined || locationId == "") {
      return;
    } else {
      // dispatch(getUserAuth());
      dispatch(getBranch());
    }
  }, []);
  const [sectionId, setSectionId] = useState(null);
  useEffect(() => {
    if (users == undefined ||  users.length == 0) {
      return;
    } else {
      setSectionId(users.role.permission[0].section_id);
    }
  }, [sectionId, users]);

  return (
    <>
      {users == undefined ||
      users.length == 0 ||
      
      sectionId == null ? (
        ""
      ) : (
        <div className="poppins-black text-sm ">
          <div
            onClick={() => openModal(false)}
            className={
              isOpenSidebar
                ? "absolute bg-[#181C32] z-50  poppins-black  bg-opacity-30 duration-700 h-screen w-full noScrool"
                : "bg-opacity-0 duration-700"
            }
          ></div>

          <div
            className={
              isOpenSidebar
                ? "absolute duration-700 left-0   shadow-2xl noScrool text-[#c3cbcb]     h-screen  opacity-100 overflow-y-auto overflow-x-hidden z-50 bg-[#070F2B]  "
                : "absolute duration-700 -left-96 w-72 text-[#c3cbcb] shadow-2xl shadow-gray-700 h-screen z-50 bg-slate-900"
            }
          >
            <div className="headSidebar">
              <div
                className={
                  "duration-500 w-20 brightness-150 hover:animate-bounce cursor-pointer "
                }
              >
                <img className="brightness-150" src={Logo} />
              </div>
              <div
                className="cursor-pointer duration-500 hover:scale-150 text-white animate-pulse"
                onClick={() => openModal(false)}
              >
                <AiOutlineClose />
              </div>
            </div>
            <hr />

            <img src={Logo} className={isOpenSidebar ?"h-screen fixed bg-cover opacity-20 brightness-75 top-10 -left-[30rem] duration-700 ease-in-out -z-50":"h-screen fixed opacity-20 brightness-75 top-10 -left-[90rem] duration-700 -z-50"} alt="" />

            <nav className={"nav"}>
              <NavLink
                onClick={() => openModal(false)}
                to={"/"}
                className={({ isActive }) =>
                  isActive ? "navListActive" : "nav_list "
                }
              >
                <div>
                  {" "}
                  <MdDashboard />{" "}
                </div>
                <span className={""}>Dashboard </span>
              </NavLink>
              {/* end */}

              <NavLink
                to={"/user"}
                className={({ isActive }) =>
                  isActive ? "navListActive" : "nav_list "
                }
                onClick={() => openModal(false)}
              >
                <div>
                  {" "}
                  <AiOutlineUser />{" "}
                </div>
                <span>User</span>
              </NavLink>

              
              <NavLink
                to={"/your_profile"}
                className={({ isActive }) =>
                  isActive ? "navListActive" : "nav_list "
                }
                onClick={() => openModal(false)}
              >
                <div>
                  {" "}
                  <ImProfile />
                </div>
                <span>Your Profile</span>
              </NavLink>

              {/* end */}

              <NavLink
                to={"/EmployeeAttendance"}
                className={({ isActive }) =>
                  isActive ? "navListActive " : "nav_list "
                }
                onClick={() => openModal(false)}
              >
                <div>
                  {" "}
                  <IoTimer />{" "}
                </div>
                <span className="capitalize ">employee attendance data</span>
              </NavLink>

              <hr />

              {sectionId == 1 || sectionId == 8 ? (
                <div>
                  {pageAdminstrator.map((p, index) => {
                    return (
                      <>
                        {users.role.permission.map((permission, index) => {
                          return (
                            <>
                              {permission.page_id == p.id ||
                              (permission.page_id != p.id &&
                                permission.section_id == 8) ? (
                                <NavLink
                                  key={index}
                                  onClick={() => openModal(false)}
                                  to={p.route}
                                  className={({ isActive }) =>
                                    isActive ? "navListActive" : "nav_list "
                                  }
                                >
                                  <div> {p.icon}</div>
                                  <span>{p.name}</span>
                                </NavLink>
                              ) : (
                                ""
                              )}
                            </>
                          );
                        })}
                      </>
                    );
                  })}
                  <hr />
                </div>
              ) : (
                ""
              )}

              <NavLink
                onClick={() => openModal(false)}
                to={`/reciveFileAdminstrator`}
                className={({ isActive }) =>
                  isActive ? "navListActive" : "nav_list "
                }
              >
                <div>
                  <FaFile />
                </div>
                <span>Recive File Adminstrator</span>
              </NavLink>
              <div className="pt-2"></div>
            </nav>
          </div>
        </div>
      )}
    </>
  );
};

export default Sidebar;
