import Swal from "sweetalert2";
import { api } from "../../Api/api";
import { getToken } from "../login";

import {
  CREATE_ATTENDANCE_FAIL,
  CREATE_ATTENDANCE_REQUEST,
  CREATE_ATTENDANCE_SUCCESS,
  FETCH_ATTENDANCE_FAIL,
  FETCH_ATTENDANCE_REQUEST,
  FETCH_ATTENDANCE_SUCCESS,
  SHOW_ATTENDANCE_FAIL,
  SHOW_ATTENDANCE_REQUEST,
  SHOW_ATTENDANCE_SUCCESS,
  STORE_RECORD_ATTENDANCE_FAIL,
  STORE_RECORD_ATTENDANCE_REQUEST,
  STORE_RECORD_ATTENDANCE_SUCCESS,
  UPDATE_ATTENDANCE_FAIL,
  UPDATE_ATTENDANCE_REQUEST,
  UPDATE_ATTENDANCE_SUCCESS,
} from "../../ActionType/AttendanceType/AttendanceType";

export const createAttendanceAction = (fullname, userId) => (dispatch) => {
  dispatch({ type: CREATE_ATTENDANCE_REQUEST }); // Optional: Dispatch a loading action

  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };

  api
    .post("attendance/create", { fullname, user_id: userId }, config)
    .then((response) => {
      const { status } = response;
      console.log(response)
      if (status === 200) {
        dispatch({ type: CREATE_ATTENDANCE_SUCCESS, payload: response.data });
        Swal.fire({
          icon: "success",
          timer: 1500,
          title: "Good Job",
          text: "User Was Sent",
        });
      } else if (status === 500) {
        dispatch({ type: CREATE_ATTENDANCE_FAIL, payload: response.data });
        Swal.fire({
          icon: "error",
          title: "Something went wrong",
          text: "Please check your code",
          timer: 1500,
        });
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch({ type: CREATE_ATTENDANCE_FAIL, payload: error.message });
    });
};

export const storeRecordAttendanceAction = () => (dispatch) => {
  dispatch({ type: STORE_RECORD_ATTENDANCE_REQUEST }); // Optional: Dispatch a loading action

  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };

  api
    .get("attendance/createRecord", config)
    .then((response) => {
      console.log("respon",response)

      const { status } = response;
      
      if (status === 200) {
        dispatch({
          type: STORE_RECORD_ATTENDANCE_SUCCESS,
          payload: response.data,
        });
        Swal.fire({
          icon: "success",
          timer: 1500,
          title: "Good Job",
          text: "User get Attendance",
        });
      } else if (status === 500) {
        dispatch({
          type: STORE_RECORD_ATTENDANCE_FAIL,
          payload: response.data,
        });
        Swal.fire({
          icon: "error",
          title: "Something went wrong",
          text: "Please check your code",
          timer: 1500,
        });
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch({ type: STORE_RECORD_ATTENDANCE_FAIL, payload: error.message });
    });
};

export const getAttendanceAction =
  (
    perPage,
    page,
    from,
    to,
    fullname,
    actualCheckIn,
    approveCheckIn,
    approveCheckOut,
    actualCheckOut,
    approveTime,
    sectionId
  ) =>
  async (dispatch) => {
    dispatch({ type: FETCH_ATTENDANCE_REQUEST });
    const sectionArray = [];
    sectionId.map((section) => {
      sectionArray.push(section.value);
    });
    // console.log("from to  : ",from,to)
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      params: {
        perPage,
        page,
        from,
        to,
        fullname,
        actualCheckIn,
        approveCheckIn,
        approveCheckOut,
        actualCheckOut,
        approveTime,
        sectionId: sectionArray,
      },
    };

    await api
      .get("attendance", config)
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          dispatch({
            type: FETCH_ATTENDANCE_SUCCESS,
            payload: { data: data.data, total: data.total },
          });
        } else if (status === 500) {
          dispatch({ type: FETCH_ATTENDANCE_FAIL, payload: [] });
        }
      })
      .catch((error) => {
        console.error(error.message);
        dispatch({ type: FETCH_ATTENDANCE_FAIL, payload: [] });
      });
  };

export const showAttendanceAction =
  (id, perPage, page, from, to) => async (dispatch) => {
    dispatch({ type: SHOW_ATTENDANCE_REQUEST });
    // console.log("to : ",to)
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      params: {
        page,
        perPage,
        from,
        to,
      },
    };

    await api
      .get(`attendance/show/${id}`, config)
      .then((response) => {
        const { status, data } = response;
        // console.log("data : ",response.data)
        if (status === 200) {
          dispatch({
            type: SHOW_ATTENDANCE_SUCCESS,
            payload: {data:data.data,total:data.total},
          });
        } else if (status === 500) {
          dispatch({ type: SHOW_ATTENDANCE_FAIL, payload: [] });
        }
      })
      .catch((error) => {
        console.error(error.message);
        dispatch({ type: SHOW_ATTENDANCE_FAIL, payload: [] });
      });
  };

export const updateAttendanceAction = (id, check, time) => (dispatch) => {
  dispatch({ type: UPDATE_ATTENDANCE_REQUEST }); 
  Swal.showLoading();
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };

  api
    .put(`attendance/update/${id}`, { time, check }, config)
    .then((response) => {
      const { status } = response;

      if (status === 200) {
        dispatch({ type: UPDATE_ATTENDANCE_SUCCESS, payload: response.data });
        Swal.fire({
          icon: "success",
          timer: 1500,
          title: "Good Job",
          text: "User get Attendance",
        });
      } else if (status === 500) {
        dispatch({ type: UPDATE_ATTENDANCE_FAIL, payload: response.data });
        Swal.fire({
          icon: "error",
          title: "Something went wrong",
          text: "Please check your code",
          timer: 1500,
        });
      }
    })
    .catch((error) => {
      console.error(error.message);
      dispatch({ type: UPDATE_ATTENDANCE_FAIL, payload: error.message });
    });
};
