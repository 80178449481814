import Swal from "sweetalert2";
import { api } from "../../Api/api";
import { getToken } from "../login";
import {
  CREATE_TYPE_MEDICAL_FAIL,
  CREATE_TYPE_MEDICAL_REQUEST,
  CREATE_TYPE_MEDICAL_SUCCESS,
  FETCH_EXPORT_EXCEL_TYPE_MEDICAL_FAIL,
  FETCH_EXPORT_EXCEL_TYPE_MEDICAL_REQUEST,
  FETCH_EXPORT_EXCEL_TYPE_MEDICAL_SUCCESS,
  FETCH_TYPE_IMAGE_MEDICAL_FAIL,
  FETCH_TYPE_IMAGE_MEDICAL_REQUEST,
  FETCH_TYPE_IMAGE_MEDICAL_SUCCESS,
  FETCH_TYPE_MEDICAL_BY_VISIT_ID_FAIL,
  FETCH_TYPE_MEDICAL_BY_VISIT_ID_REQUEST,
  FETCH_TYPE_MEDICAL_BY_VISIT_ID_SUCCESS,
  FETCH_TYPE_MEDICAL_REQUEST,
  FETCH_TYPE_MEDICAL_SUCCESS,
} from "../../ActionType/TypeOfMedical/typemedical";

export const createTypeMedicalAction =
  (
    brnach_id,
    patient_id,
    type_of_help,
    place,
    hospital,
    date,
    type_of_test,
    total,
    visit_id
  ) =>
  (dispatch) => {
    api
      .post(
        "type_medical/create",
        {
          patient_id,
          type_of_help,
          place,
          hospital,
          date,
          type_of_test,
          total,
          brnach_id,
          visit_id,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        const { status, data } = response;
        dispatch({
          type: CREATE_TYPE_MEDICAL_REQUEST,
        });
        if (status == 200) {
          Swal.fire({
            icon: "success",
            title: "سەرکەوتوو بوو",
            text: "زانیاریەکە بە سەرکەوتووی نێردرا",
            timer: 1500,
          });
          dispatch({
            type: CREATE_TYPE_MEDICAL_SUCCESS,
            payload: data,
          });
        } else if (status == 404) {
          Swal.fire({
            icon: "error",
            title: "Query Was Not Correct",
            timer: 1500,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: CREATE_TYPE_MEDICAL_FAIL,
        });
      });
  };
export const getTypeMedicalAction =
  (
    brnach_id,
    page,
    perPage,
    fullname,
    type_of_help,
    place,
    hospital,
    type_of_test,
    total,
    date,
    from,
    to
  ) =>
  (dispatch) => {
    console.log(from,to)
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      params: {
        brnach_id,
        page,
        perPage,
        fullname,
        type_of_help,
        place,
        hospital,
        type_of_test,
        total,
        date,
        from,
        to
      },
    };

    api
      .get("type_medical", config)
      .then((response) => {
        const { data, status } = response;
        dispatch({
          type: FETCH_TYPE_MEDICAL_REQUEST,
        });
        if (status === 200) {
          dispatch({
            type: FETCH_TYPE_MEDICAL_SUCCESS,
            payload: { data: data.data, total: data.total },
            error: "",
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Please Check Your Request",
          error: error.message,
        });
      });
  };

export const getTypeMedicalImageAction =
  (typeMedicalId, patient_id) => (dispatch) => {
    dispatch({
      type: FETCH_TYPE_IMAGE_MEDICAL_REQUEST,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      params: {
        patient_id,
      },
    };

    api
      .get(`type_medical/show/${typeMedicalId}`, config)
      .then((response) => {
        const { status, data } = response;

        dispatch({
          type: FETCH_TYPE_IMAGE_MEDICAL_REQUEST,
        });
        if (status == 200) {
          dispatch({
            type: FETCH_TYPE_IMAGE_MEDICAL_SUCCESS,
            payload: data,
            error: "",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_TYPE_IMAGE_MEDICAL_FAIL,
          payload: [],
          error: "Please Check  Your Request",
        });
      });
  };

export const getTypeMedicalExcelAction = () => (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
    responseType: "blob", // Add this line for binary data (e.g., file downloads)
  };

  api
  .get("type_medical/excel", config)
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "exported_data.csv");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    })
    .catch((error) => console.error("Error exporting data:", error));
  // api
  //   .get("type_medical/excel", config)
  //   .then((response) => {
  //     const { data, status } = response;
  //     dispatch({
  //       type: FETCH_EXPORT_EXCEL_TYPE_MEDICAL_REQUEST,
  //     });
  //     console.log(data);
  //     if (status === 200) {
  //       dispatch({
  //         type: FETCH_EXPORT_EXCEL_TYPE_MEDICAL_SUCCESS,
  //         payload: data,
  //         error: "",
  //       });
  //     }
  //   })
  //   .catch((error) => {
  //     dispatch({
  //       type: FETCH_EXPORT_EXCEL_TYPE_MEDICAL_FAIL,
  //       payload: [],
  //       error: "Please Check Your Request",
  //     });
  //     Swal.fire({
  //       icon: "error",
  //       title: "Please Check Your Request",
  //       error: error.message,
  //     });
  //   });
};

export const getTypeMedicalByVisitAction = (visit_id) => (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
    params: {
      visit_id,
    },
  };

  // dispatch({
  //   type: FETCH_EXPORT_EXCEL_TYPE_MEDICAL_REQUEST,
  // });
  api
    .get("type_medical/showByVisit", config)
    .then((response) => {
      const { data, status } = response;
      dispatch({
        type: FETCH_TYPE_MEDICAL_BY_VISIT_ID_REQUEST,
      });

      if (status === 200) {
        dispatch({
          type: FETCH_TYPE_MEDICAL_BY_VISIT_ID_SUCCESS,
          payload: data,
          error: "",
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: FETCH_TYPE_MEDICAL_BY_VISIT_ID_FAIL,
        payload: [],
        error: "Please Check Your Request",
      });
      Swal.fire({
        icon: "error",
        title: "Please Check Your Request",
        error: error.message,
      });
    });
};
