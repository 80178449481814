import {
  CREATE_RECIVE_FILE_ADMINSTRATOR_FAIL,
  CREATE_RECIVE_FILE_ADMINSTRATOR_REQUEST,
  CREATE_RECIVE_FILE_ADMINSTRATOR_SUCCESS,
  FETCH_RECIVE_FILE_ADMINSTRATOR_FAIL,
  FETCH_RECIVE_FILE_ADMINSTRATOR_REQUEST,
  FETCH_RECIVE_FILE_ADMINSTRATOR_SUCCESS,
  SHOW_RECIVE_FILE_ADMINSTRATOR_FAIL,
  SHOW_RECIVE_FILE_ADMINSTRATOR_REQUEST,
  SHOW_RECIVE_FILE_ADMINSTRATOR_SUCCESS,
  UPDATE_RECIVE_FILE_ADMINSTRATOR_FAIL,
  UPDATE_RECIVE_FILE_ADMINSTRATOR_REQUEST,
  UPDATE_RECIVE_FILE_ADMINSTRATOR_SUCCESS,
} from "../../ActionType/ReciveFileAdminstrator/ReciveFileAdminstrator";

export const createReciveFilesAdminstratorReducer = (
  state = { loading: true, reciveFiles: [], error: "" },
  action
) => {
  switch (action.type) {
    case CREATE_RECIVE_FILE_ADMINSTRATOR_REQUEST:
      return {
        loading: true,
      };
    case CREATE_RECIVE_FILE_ADMINSTRATOR_SUCCESS:
      return {
        loading: false,
        reciveFiles: action.payload,
        error: "",
      };
    case CREATE_RECIVE_FILE_ADMINSTRATOR_FAIL:
      return {
        loading: false,
        reciveFiles: [],
        error: "Please Check Your Request",
      };
    default:
      return state;
  }
};

export const showReciveFilesAdminstratorReducer = (
    state = { loading: true, reciveFiles: [], error: "" },
    action
  ) => {
    switch (action.type) {
      case SHOW_RECIVE_FILE_ADMINSTRATOR_REQUEST:
        return {
          loading: true,
        };
      case SHOW_RECIVE_FILE_ADMINSTRATOR_SUCCESS:
        return {
          loading: false,
          reciveFiles: action.payload,
          error: "",
        };
      case SHOW_RECIVE_FILE_ADMINSTRATOR_FAIL:
        return {
          loading: false,
          reciveFiles: [],
          error: "Please Check Your Request",
        };
      default:
        return state;
    }
  };

  export const updateReciveFilesAdminstratorReducer = (
    state = { loading: true, reciveFiles: [], error: "" },
    action
  ) => {
    switch (action.type) {
      case UPDATE_RECIVE_FILE_ADMINSTRATOR_REQUEST:
        return {
          loading: true,
        };
      case UPDATE_RECIVE_FILE_ADMINSTRATOR_SUCCESS:
        return {
          loading: false,
          reciveFiles: action.payload,
          error: "",
        };
      case UPDATE_RECIVE_FILE_ADMINSTRATOR_FAIL:
        return {
          loading: false,
          reciveFiles: [],
          error: "Please Check Your Request",
        };
      default:
        return state;
    }
  };

  
  export const getReciveFilesAdminstratorReducer = (
    state = { loading: true, reciveFiles: [],total:0, error: "" },
    action
  ) => {
    switch (action.type) {
      case FETCH_RECIVE_FILE_ADMINSTRATOR_REQUEST:
        return {
          loading: true,
        };
      case FETCH_RECIVE_FILE_ADMINSTRATOR_SUCCESS:
        return {
          loading: false,
          reciveFiles: action.payload.data,
          total: action.payload.total,
          error: "",
        };
      case FETCH_RECIVE_FILE_ADMINSTRATOR_FAIL:
        return {
          loading: false,
          reciveFiles: [],
          error: "Please Check Your Request",
        };
      default:
        return state;
    }
  };
