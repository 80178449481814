import React, { useEffect, useState } from "react";
import { HiOutlineDocumentDownload } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import {
  // createAdminstratorAction,
  getAdminstratorAction,
} from "../../Action/AdminstratorAction/AdminstratorAction";
import {
  createSentFilesAdminstratorAction,
  getSentFileAdminstratorAction,
} from "../../Action/AdminstratorAction/SentFilesAction";
import { getUserAuth } from "../../Action/userRegister";

const AdminstratorModal = ({ closeModal }) => {
  const locationId = localStorage.getItem("locationId");
  useEffect(() => {
    dispatch(getUserAuth());
  }, []);
  const getUsers = useSelector((state) => state.getUser);
  const { users } = getUsers;
  const [document, setDocument] = useState(null);
  const dispatch = useDispatch();
  const [number, setNumber] = useState(null);
  const [location, setLocation] = useState(null);
  const [nameFile, setNameFile] = useState(null);

  const [error, setError] = useState({
    location: "",
    date: "",
    number: "",
    document: "",
  });
  const [date, setDate] = useState(null);
  const handleSubmit = (event) => {
    event.preventDefault();
    if (location == null) {
      setError({
        ...error,
        location: "تکایە خانەکە پڕکەرەوە",
        number: "",
        date: "",
        document: "",
      });
      return;
    }

    if (number == null) {
      setError({
        ...error,
        number: "تکایە خانەکە پڕکەرەوە",
        location: "",
        date: "",
        document: "",
      });
      return;
    }

    if (date == null) {
      setError({
        ...error,
        date: "تکایە خانەکە پڕکەرەوە",
        number: "",
        location: "",
        document: "",
      });
      return;
    }
    if (nameFile == null) {
      setError({
        ...error,
        document: "",
        number: "",
        date: "",
        location: "",
        nameFile: "تکایە خانەکە پڕکەرەوە",
      });
      return;
    }
    if (document == null) {
      setError({
        ...error,
        document: "تکایە خانەکە پڕکەرەوە",
        number: "",
        date: "",
        location: "",
      });
      return;
    }

    dispatch(
      createSentFilesAdminstratorAction(
        document,
        number,
        location,
        date,
        nameFile
      )
    );
    dispatch(getSentFileAdminstratorAction(1, 10, "", "", "", locationId));
    dispatch(getSentFileAdminstratorAction(1, 10, "", "", "", locationId));

    closeModal(false);
  };
  return (
    <>
      <div className="w-full h-full absolute z-40 top-0 left-0">
        <div
          onClick={() => closeModal(false)}
          className="w-full h-full absolute top-0 left-0 bg-slate-900 bg-opacity-40 "
        />
        <div className="bg-white px-3 py-3 xl:w-[40%] z-50 absolute top-[20%] left-[45%] -translate-x-[35%] rounded-md shadow-md">
          <div className="flex justify-between">
            <div className="">
              <p>Adminstrator</p>
            </div>
            <div>
              <p
                className="text-xl font-bold text-red-500 cursor-pointer "
                onClick={() => closeModal(false)}
              >
                X
              </p>
            </div>
          </div>

          <form action="">
            <div className="flex gap-3">
              <div className="flex w-full flex-col">
                <input
                  type="text"
                  onChange={(e) => setLocation(e.target.value)}
                  className="inputLogin text-right"
                  placeholder="شوێن"
                />
                <div>
                  <p className="text-red-500 text-right text-sm">
                    {error.location}
                  </p>
                </div>
              </div>

              <div className="flex flex-col w-full">
                <input
                  type="text"
                  onChange={(e) => setNumber(e.target.value)}
                  className="inputLogin text-right"
                  placeholder="ژمارە"
                />
                <div className="text-right text-sm mr-1">
                  <p className="text-red-400">{error.number}</p>
                </div>
              </div>
              <div className="flex flex-col">
                <input
                  type="date"
                  onChange={(e) => setDate(e.target.value)}
                  className="inputLogin text-right"
                  placeholder="date"
                />
                <div>
                  <p className="text-red-500 text-right text-sm">
                    {error.date}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              <input
                type="text"
                onChange={(e) => setNameFile(e.target.value)}
                className="inputLogin text-right"
                placeholder="ناوی فایل"
              />
              <div>
                <p className="text-sm text-red-500">{error.nameFile}</p>
              </div>
            </div>

            <div className="flex flex-col ">
              <div className=" py-2 w-full flex  items-center">
                <label
                  id="large_size"
                  className="p-4 flex w-full flex-col justify-center m-auto items-center gap-2  bg-teal-100 text-teal-500 rounded-lg hover:bg-teal-200 cursor-pointer"
                >
                  <p>
                    <HiOutlineDocumentDownload className="w-6 h-6" />
                  </p>

                  <input
                    onChange={(e) => {
                      setDocument(e.target.files[0]);
                      // console.log(e.target.files[0])
                    }}
                    // name={`image_${index}`}
                    id="large_size"
                    accept=".ppt, .pptx,.docx,.xlsx,.xls,.pdf,.jpg,.png,.jpeg"
                    type="file"
                    className="hidden"
                  />
                </label>
              </div>
              <div>
                <p className="text-red-500 text-right text-sm">
                  {error.document}
                </p>
              </div>
            </div>
            <button className="btn" onClick={(e) => handleSubmit(e)}>
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default AdminstratorModal;
