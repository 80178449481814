import Swal from "sweetalert2";
import {
  CREATE_SURGERY_FAIL,
  CREATE_SURGERY_REQUEST,
  CREATE_SURGERY_SUCCESS,
  FETCH_SURGERY_FAIL,
  FETCH_SURGERY_REQUEST,
  FETCH_SURGERY_SUCCESS,
  FETCH_TYPE_SURGERY_FAIL,
  FETCH_TYPE_SURGERY_REQUEST,
  FETCH_TYPE_SURGERY_SUCCESS,
} from "../../ActionType/Surgery/surgeryType";
import { api } from "../../Api/api";
import { getToken } from "../login";

export const getSurgeryAction = () => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
  // dispatch({ type: FETCH_SURGERY_REQUEST });
  api
    .get("surgery", config)
    .then((resposne) => {
      dispatch({ type: FETCH_SURGERY_REQUEST });
      const { status, surgry } = resposne.data;
      if (status === 200) {
        dispatch({ type: FETCH_SURGERY_SUCCESS, payload: surgry });
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch({
        type: FETCH_SURGERY_FAIL,
      });
    });
};

export const getTypeSurgeryAction = (id) => (dispatch) => {
  
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
  api
    .get(`surgery/getTypeSurgery/${id}`, config)
    .then((resposne) => {
      dispatch({ type: FETCH_TYPE_SURGERY_REQUEST });
      const { status, typeSurgery } = resposne.data;
      if (status === 200) {
        dispatch({ type: FETCH_TYPE_SURGERY_SUCCESS, payload: typeSurgery });
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch({
        type: FETCH_TYPE_SURGERY_FAIL,
      });
    });
};

export const createSurgeryAction = (surguryName) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      charset: "utf-8",
      Authorization: `Bearer ${getToken()}`,
    },
  };
  api
    .post(
      `surgery/create`,
      {
        surguryName: surguryName,
      },
      config
    )
    .then((resposne) => {
      dispatch({ type: CREATE_SURGERY_REQUEST });
      const { status } = resposne;
      console.log(resposne);

      if (status === 200) {
        dispatch({ type: CREATE_SURGERY_SUCCESS, payload: [] });
        Swal.fire({
          icon: "success",
          title: "Good Job ",
          text: "Surgery Was Saved",
        });
      }
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Request ",
        text: "Please Check Your Request",
      });
      dispatch({
        type: CREATE_SURGERY_FAIL,
      });
    });
};
