import axios from "axios";
import CryptoJS from "crypto-js";

import Swal from "sweetalert2";
import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
} from "../ActionType/userLoginType";
import { api } from "../Api/api";

export const login = (email, password) => (dispatch) => {
  // console.log(email ,": ",password)
  loginApi
    .post("/login", {
      email,
      password,
    })
    .then((response) => {
      dispatch({ type: USER_LOGIN_REQUEST });
      if (response.data.status === 200) {
        dispatch({
          type: USER_LOGIN_SUCCESS,
          payload: response.data.userInfo,
          error: "",
        });
        localStorage.setItem("token", response.data.token);
        const encData = encryptData("true", "8");
        localStorage.setItem("loggedIn", encData);
      } else if (response.data.status == 404) {
        
        localStorage.removeItem("token");
        localStorage.removeItem("locationId");
        localStorage.removeItem("notifi");
        localStorage.removeItem("loggedIn");
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.data.error,
          timer: 2000,
        });
      }
    })
    .catch(() => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Request Is Faild",
      });
    });
};
export const logOut = () => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
  api
    .post("/logout", {}, config)
    .then((response) => {
      if (response.data.status == 200) {
        const encData = encryptData("false", "8");
        localStorage.removeItem("token");
        localStorage.removeItem("locationId");
        localStorage.removeItem("notifi");
        localStorage.removeItem("loggedIn");
        window.location.reload();
      }
      // localStorage.removeItem("token");
      // localStorage.removeItem("loggedIn");
    })
    .catch((error) => {
      console.log(error);
    });
};
export const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};
const AppURL = "https://zadorg.site/app/api/zad_system";
export const loginApi = axios.create({
  // baseURL: "http://127.0.0.1:8000/api/zad_system",
  baseURL: AppURL,
  withCredentials: true,
  headers: {
    Accept: "application/json",
  },
});

const encryptData = (data, password) => {
  if (data == null) {
    return null;
  }
  const ciphertext = CryptoJS.AES.encrypt(data, password).toString();
  return ciphertext;
};

export const decryptData = () => {
  const isLoggedIn = localStorage.getItem("loggedIn");
  if (isLoggedIn == null || isLoggedIn == undefined) {
    return;
  }
  const bytes = CryptoJS.AES.decrypt(isLoggedIn, "8");
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};
