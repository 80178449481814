import "./css/dashboard.css";

import Logo from "../../Image/Logo.jpg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getUserAuth } from "../../Action/userRegister";
import { Link, useLocation } from "react-router-dom";
import { getBranch } from "../../Action/branch";

import ModalDashboard from "../../Modal/ModalDashboard/ModalDashboard";
import { storeRecordAttendanceAction } from "../../Action/AttendanceAction/AttendanceAction";

const Dashboard = () => {
  
  const page = [
    {
      id: 1,

      route: "commite",
    },
    {
      id: 2,
      route: "invoice",
    },

    {
      id: 3,

      route: "feedback",
    },
    {
      id: 4,

      route: "reportMonthly",
    },
    {
      id: 5,

      route: "whatsAppInfo",
    },
    {
      id: 6,

      route: "MedicalSupplies",
    },
    {
      id: 7,

      route: "receive",
    },
    {
      id: 8,

      route: "doctor",
    },
    {
      id: 9,

      route: "surgery",
    },
    {
      id: 10,
      route: "reject",
    },
    {
      id: 11,
      route: "Administrator",
    },
    {
      id: 12,
      route: "Administrator/sentFile",
    },
    {
      id: 13,
      route: "statistics",
    },
    {
      id: 14,
      route: "Administrator/patient",
    },
    {
      id: 15,
      route: "show_receive",
    },
    {
      id: 16,
      route: "showAllInfoMedia",
    },
    {
      id: 17,
      route: "works",
    },
  ];

  // const defaultPackage = localStorage.getItem("defaultPackage");
  const dispatch = useDispatch();
  const getUsers = useSelector((state) => state.getUser);
  const [isVisableModalDashboard, setIsVisiableDashboardModal] = useState(true);
  const { users } = getUsers;
  const getBranchs = useSelector((state) => state.getBranch);
  const { branchs, loader } = getBranchs;
  const location = useLocation();

  const defaultPackage = localStorage.getItem("defaultPackage");
  useEffect(() => {
    if (location.pathname == "/") {
      localStorage.removeItem("locationId");
      dispatch(getUserAuth());
    }
  }, []);

  // useEffect(()=>{
  //   dispatch(storeRecordAttendanceAction())
  // },[])
  useEffect(() => {
    if (location.pathname == "/") {
      dispatch(getBranch());
    }
  }, []);
  let [route, setRoute] = useState("");

  useEffect(() => {
    if (users == undefined || defaultPackage == null || users.length == 0) {
      return;
    } else {
      if (users.role.permission[0].section_id == 8) {
        setRoute("/Administrator");
        return;
      }
      page
        .filter((filter) => filter.id == users.role.permission[0].page_id)
        .map((page) => {
          setRoute(page.route);
        });
    }
  }, [route, users, defaultPackage]);

  return (
    <>
      {loader || users == undefined || users.length == 0 ? (
        <div className="flex flex-col">
          <img
            src={Logo}
            alt="Logo"
            className="w-56 m-auto animate-pulse mt-20 "
          />
          <div className="text-2xl text-center font-bold  ">
            <div className="flex justify-center gap-4">
              <p className="animate-charcter">Please Wait</p>
              <div class="container2">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>{" "}
            </div>
          </div>
        </div>
      ) : (
        <div className="px-3 xl:px-6 md:px-3 mt-2 z-50 ">
          <div className=" flex justify-between items-center">
            <div className="flex items-center gap-3 md:text-lg xl:text-3xl">
              <div></div>
              <p>Dashboard </p>
            </div>
            <div className="font-bold">
              <p>Welcome : {users.fullname_en} </p>
            </div>
          </div>
          {/* <div>{users.role.permission[0].page_id}</div> */}
          {users?.role?.permission[0]?.branch_id == 8 ? (
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-2 z-50">
              {branchs
                ?.filter((filter) => filter.id != 8)
                ?.map((element, index) => {
                  return (
                    <div key={index} className={"text-black text-center mt-2"}>
                      <Link
                        onClick={() =>
                          localStorage.setItem("locationId", element.id)
                        }
                        to={route}
                      >
                        <div className="bg-white shadow-xl rounded-md">
                          <img
                            className="w-full   object-cover"
                            src={Logo}
                            alt="Card"
                          />
                          <div className="p-2">
                            <h3 className="text-lg font-semibold">
                              {element.bName}
                            </h3>
                          </div>
                        </div>
                        {/* end Content */}
                      </Link>
                    </div>
                  );
                })}
            </div>
          ) : (
            <div className="containerDash z-50">
              {branchs
                ?.filter(
                  (filter) => filter.id == users?.role?.permission[0]?.branch_id
                )
                ?.map((element, index) => {
                  return (
                    <div key={index} className={"text-black text-center mt-2"}>
                      <Link
                        onClick={() =>
                          localStorage.setItem("locationId", element.id)
                        }
                        to={route}
                      >
                        <div className="bg-white shadow-xl rounded-md">
                          <img
                            className="w-full  h-40 object-cover"
                            src={Logo}
                            alt="Card"
                          />
                          <div className="p-2">
                            <h3 className="text-lg font-semibold">
                              {element.bName}
                            </h3>
                          </div>
                        </div>
                        {/* end Content */}
                      </Link>
                    </div>
                  );
                })}
            </div>
          )}

          <div className="pb-32"></div>
        </div>
      )}
      {/* {defaultPackage} */}
      {isVisableModalDashboard && defaultPackage == null && (
        <ModalDashboard users={users} onClose={setIsVisiableDashboardModal} />
      )}
    </>
  );
};

export default Dashboard;
