import Swal from "sweetalert2";
import {
  REJECT_PATIENT_FAIL,
  REJECT_PATIENT_REQUEST,
  REJECT_PATIENT_SUCCESS,
} from "../ActionType/patientType";
import {
  DISAPPROVED_REJECT_PATIENT_FAIL,
  DISAPPROVED_REJECT_PATIENT_REQUEST,
  DISAPPROVED_REJECT_PATIENT_SUCCESS,
  FETCH_PATIENT_VISIT_FAIL,
  FETCH_PATIENT_VISIT_REQUEST,
  FETCH_PATIENT_VISIT_SUCCESS,
  FETCH_REJECT_PATIENT_FAIL,
  FETCH_REJECT_PATIENT_REQUEST,
  FETCH_REJECT_PATIENT_SUCCESS,
  REJECT_PATIENT_WITH_VISIT_FAIL,
  REJECT_PATIENT_WITH_VISIT_REQUEST,
  REJECT_PATIENT_WITH_VISIT_SUCCESS,
  SHOW_REJECT_PATIENT_VISIT_FAIL,
  SHOW_REJECT_PATIENT_VISIT_REQUEST,
  SHOW_REJECT_PATIENT_VISIT_SUCCESS,
} from "../ActionType/rejectType";
import { api } from "../Api/api";
import { getToken } from "./login";

export const getRejectPatientAction =
  (
    locationId,
    currentPage,
    perPage,
    fullname,
    code,
    phone,
    age,
    gender,
    note,
    month
  ) =>
  (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    };
    
    month=month.slice(5, 7);

    // dispatch({ type: FETCH_REJECT_PATIENT_REQUEST });
    api
      .get(
        `reject/${locationId}?page=${currentPage}&perPage=${perPage}&fullname=${fullname}&code=${code}&phone=${phone}&age=${age}&gender=${gender}&note=${note}&month=${month}`,
        config
      )
      .then((response) => {
        dispatch({ type: FETCH_REJECT_PATIENT_REQUEST });
        const { data, total } = response.data;

        if (response.status == 200) {
          dispatch({
            type: FETCH_REJECT_PATIENT_SUCCESS,
            payload: data,
            total: total,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_REJECT_PATIENT_FAIL });
        console.log(error);
      });
  };

export const rejectPatientAction =
  (note, patient_id, visit_id) => (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    };

    api
      .post(
        "reject/create",
        {
          note,
          patient_id,
          visit_id,
        },
        config
      )
      .then((response) => {
        dispatch({ type: REJECT_PATIENT_REQUEST });
        const { status, reject } = response.data;
        if (status == 200) {
          dispatch({ type: REJECT_PATIENT_SUCCESS, payload: reject });
        }
      })
      .catch(() => {
        dispatch({ type: REJECT_PATIENT_FAIL });
      });
  };

export const disapprovedRejectPatientAction =
  (visit_id, reject_id, patient_id) => (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    };
    dispatch({ type: DISAPPROVED_REJECT_PATIENT_REQUEST });
    api
      .post(
        `reject/disapprovedReject/${visit_id}/${reject_id}/${patient_id}`,
        {},
        config
      )
      .then((response) => {
        console.log(response);
        dispatch({ type: DISAPPROVED_REJECT_PATIENT_REQUEST });
        const { status, data } = response;
        if (status == 200) {
          dispatch({ type: DISAPPROVED_REJECT_PATIENT_SUCCESS, payload: data });
          Swal.fire({
            icon: "success",
            title: "سەرکەوتوو بوو",
            text: "هۆکاری رەفزکردنەوەی سڕایەوە",
            timer: 2000,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: DISAPPROVED_REJECT_PATIENT_FAIL });
      });
  };

export const rejectPatientWithVisitAction = (patient_id) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
  dispatch({ type: REJECT_PATIENT_WITH_VISIT_REQUEST });
  api
    .get(`reject/patientWithVisit/${patient_id}`, config)
    .then((response) => {
      dispatch({ type: REJECT_PATIENT_WITH_VISIT_REQUEST });
      const { status, data } = response;

      if (status == 200) {
        dispatch({ type: REJECT_PATIENT_WITH_VISIT_SUCCESS, payload: data });
      }
    })
    .catch(() => {
      dispatch({ type: REJECT_PATIENT_WITH_VISIT_FAIL });
    });
};

export const rejectPatientVisitAction = (patient_id) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
  // dispatch({ type: FETCH_PATIENT_VISIT_REQUEST });
  api
    .get(`reject/show/${patient_id}`, config)
    .then((response) => {
      console.log(response);
      dispatch({ type: FETCH_PATIENT_VISIT_REQUEST });
      const { status, data } = response;
      if (status == 200) {
        dispatch({ type: FETCH_PATIENT_VISIT_SUCCESS, payload: data });
      }
    })
    .catch(() => {
      dispatch({ type: FETCH_PATIENT_VISIT_FAIL });
    });
};

export const showRejectPatientVisitAction = (visit_id) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
  dispatch({ type: SHOW_REJECT_PATIENT_VISIT_REQUEST });
  api
    .get(`reject/showRejectByVisit/${visit_id}`, config)
    .then((response) => {
      console.log(response.data);
      // dispatch({ type: SHOW_REJECT_PATIENT_VISIT_REQUEST });
      const { status, data } = response;
      if (status == 200) {
        dispatch({ type: SHOW_REJECT_PATIENT_VISIT_SUCCESS, payload: data });
      }
    })
    .catch(() => {
      dispatch({ type: SHOW_REJECT_PATIENT_VISIT_FAIL });
    });
};
