import React, { useEffect, useState } from "react";
import { AiFillCaretDown } from "react-icons/ai";

import { useDispatch, useSelector } from "react-redux";
import { createCommite } from "../Action/commiteAction";
import { editPatientAction, getPatient } from "../Action/patient";
import {
  getSurgeryAction,
  getTypeSurgeryAction,
} from "../Action/Surgery/surgeryAction";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import Logo from "../Image/Logo.jpg";
import {
  createTypeOfSurgery,
  getOrganLevelAction,
} from "../Action/SurgeryTypeAction/surgery";
import Swal from "sweetalert2";
import { getDoctorAction } from "../Action/Doctor/doctorAction";

const AssignModal = ({ closeModal, isOpenAssign, patientId }) => {
  const dispatch = useDispatch();
  const locationID = localStorage.getItem("locationId");
  const [openSelect, setOpenSelect] = useState(false);
  const [married, setMarried] = useState(null);

  // const getPatients = useSelector((state) => state.getPatients);
  const [memberOption, setMember] = useState([]);
  // const { patients,  total } = getPatients;
  const [isOpenSmoking, setIsOpenSmoking] = useState(false);
  const [openOrganLevel, setOpenOrganLevel] = useState(false);
  const [selectTypeOfHelp, setSelectTypeOfHelp] = useState([]);
  const [smoking, setSmoking] = useState(null);
  const [error, setError] = useState(null);

  const getSurgery = useSelector((state) => state.getSurgery);
  const { surgery } = getSurgery;

  const getTypeSurgery = useSelector((state) => state.getTypeSurgery);
  const { typeSurgery } = getTypeSurgery;
  const getEditPatient = useSelector((state) => state.editPatient);
  const { patients, loading } = getEditPatient;
  const getDoctor = useSelector((state) => state.getDoctor);
  const { doctors } = getDoctor;

  let doctorArray=[];
  
  doctors.map((doctor)=>{
    doctorArray.push({
      value:doctor.dr_name,
      label:doctor.dr_name,
    })
  })

  const option3 = [
    "نەشتەرگەری",
    "پێداویستی پزیشکی",
    "تیشک",
    "دەرمان",
    "پشکنینی خوێن",
    "غەسلی پلازما",
    "غەسلی گورچیلە",
    "مانەوەی لە نەخۆشخانە",
    "نازۆر",
    "نازۆری گەدە",
    "نازۆری کۆڵۆن",
    "لابردنی سۆندەی گورچیلە",
    "دانانی فیچولان",
    "دانانی سیڤیلاین",
  ];

  const getOrganLevel = useSelector((state) => state.getOrganLevel);
  const { organLevel } = getOrganLevel;

  const [organ, setOrgan] = useState([]);
  const [doctor,setDoctor]=useState([]);
  const amountOfDays = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30,
  ];

  useEffect(() => {
    dispatch(editPatientAction(patientId));
  }, []);
  useEffect(() => {
    dispatch(getDoctorAction("Active"));
  }, []);

  const surgeryArray = [];
  surgery.map((element) => {
    surgeryArray.push({
      value: element.id,
      label: element.surguryName,
    });
  });

  useEffect(() => {
    dispatch(getSurgeryAction());
  }, []);

  const handelSmoking = (e) => {
    e.preventDefault();
    setIsOpenSmoking(!isOpenSmoking);
  };

  const handelSelect = (e) => {
    e.preventDefault();
    setOpenSelect(openSelect ? false : true);
  };

  const [inputValue, setInputValue] = useState({
    hadSurgery:"",
    visit_id: "",
    occupation: "",
    nation: "",
    country: "",
    NoFamily: "",
    province: "",
    district: "",
    street: "",
    closetHome: "",
    secoundPhoneNo: "",
    card: "",
    timeAllot: "",
    SPID: "",
    member: "",
    noMember: "",
    typ: "",
    indiction: "",
    HPE: "",
    finalDiagnos: "",
    earlyPatient: "",
    earlySurgery: "",
    doctor: "",
    ZODoctor: "",
    medicalProvid: "",
    typeMedical: "",
    supplyLocation: "",
    helpingZO: "",
    note: "",
    literate: "",
  });

  const handelInputChange = (event) => {
    const { name, value } = event.target;
    setInputValue({ ...inputValue, [name]: value });
  };

  const reset = () => {
    setInputValue("");
  };

  const arrayTypeSurgery = [];

  const handelPatient = (event) => {
    for (let index = 0; index < type.length; index++) {
      const element = type[index].label;
      arrayTypeSurgery.push(element);
    }

    let visitId = null;
    event.preventDefault();
    if (patients == undefined) {
      return;
    } else {
      patients.map((patient) => {
        return patient.visit.map((element) => {
          console.log(element.id)
          if (element.complete == "un_complete") {

            visitId = element.id;
          } else {
            return;
          }
        });
      });
    }
    const {
      occupation,
      nation,
      country,
      NoFamily,
      province,
      district,
      street,
      closetHome,
      scoundPhoneNo,
      card,
      timeAllot,
      SPID,
      member,
      noMember,
      typ,
      HPE,
      finalDiagnos,
      earlyPatient,
      earlySurgery,
      // doctor,
      ZODoctor,
      medicalProvid,
      typeMedical,
      supplyLocation,
      helpingZO,
      indiction,
      visit_id,
    } = inputValue;

    closeModal(false);

    dispatch(
      createCommite(
        visitId,
        patientId,
        2,
        occupation,
        nation,
        country,
        married,
        NoFamily,
        province,
        district,
        street,
        closetHome,
        scoundPhoneNo,
        card,
        smoking,
        timeAllot,
        SPID,
        member,
        noMember,
        arrayTypeSurgery,
        indiction,
        HPE,
        finalDiagnos,
        earlyPatient,
        earlySurgery,
        doctor,
        ZODoctor,
        medicalProvid,
        typeMedical,
        supplyLocation,
        helpingZO,
        reset,
        selectTypeOfHelp,
        inputValue.note,
        inputValue.literate,
        inputValue.hadSurgery
      )
    );

    dispatch(getPatient(locationID, 1, 10, "", "", "", "", "", 1));
    dispatch(getPatient(locationID, 1, 10, "", "", "", "", "", 1));
  };
  const [surgeryArr, setSurgeryArr] = useState([]);
  const arraySurg = [];
  // const [visitId,setVisitId]=useState(null);
  const handelSave = (event) => {
    let visitId = null;
    event.preventDefault();
    patients.map((patient) => {
      return patient.visit.map((element) => {
        console.log(element.id)
        if (element.complete == "un_complete") {
          visitId = element.id;
        } else {
          return;
        }
      });
    });

    for (let index = 0; index < type.length; index++) {
      const element = type[index].label;
      arrayTypeSurgery.push(element);
    }


    
    const {
      occupation,
      nation,
      country,
      NoFamily,
      province,
      district,
      street,
      closetHome,
      scoundPhoneNo,
      card,
      timeAllot,
      SPID,
      member,
      noMember,
      typ,
      HPE,
      finalDiagnos,
      earlyPatient,
      earlySurgery,
      // doctor,
      ZODoctor,
      medicalProvid,
      typeMedical,
      supplyLocation,
      helpingZO,
      indiction,
      visit_id,
      literate,
    } = inputValue;
// console.log('dr',doctor)
    closeModal(false);

    dispatch(
      createCommite(
        visitId,
        patientId,
        1,
        occupation,
        nation,
        country,
        married,
        NoFamily,
        province,
        district,
        street,
        closetHome,
        scoundPhoneNo,
        card,
        smoking,
        timeAllot,
        surgeryArr,
        memberOption,
        organ,
        arrayTypeSurgery,
        indiction,
        HPE,
        finalDiagnos,
        earlyPatient,
        earlySurgery,
        doctor,
        ZODoctor,
        medicalProvid,
        typeMedical,
        supplyLocation,
        helpingZO,
        reset,
        selectTypeOfHelp,
        inputValue.note,
        literate,
        inputValue.hadSurgery
      )
    );
    // dispatch(get)
    dispatch(editPatientAction(patientId, visitId));
    dispatch(getPatient(locationID, 1, 10, "", "", "", "", "", 1));
    dispatch(getPatient(locationID, 1, 10, "", "", "", "", "", 1));
  };
  const surgeryTypeArray = [];

  const [length, setLength] = useState();
  const [idOfSurgery, setIdOfSurgery] = useState(null);

  useEffect(() => {
    if (surgeryArr.length == 0 || surgeryArr == undefined) {
      return;
    } else {
      for (let index = 0; index < surgeryArr.length; index++) {
        const element = surgeryArr[index].value;
        arraySurg.push(element);
      }

      setLength(arraySurg.length);
      setIdOfSurgery(arraySurg[0]);
      dispatch(getTypeSurgeryAction(arraySurg));
    }
  }, [surgeryArr]);

  typeSurgery.forEach((element) => {
    surgeryTypeArray.push({
      value: `${element.type_id}`,
      label: `${element.type_name_en}`,
    });
  });
  const memberArray = [];
  typeSurgery.forEach((element) => {
    memberArray.push({
      value: `${element.id}`,
      label: `${element.typeName}`,
    });
  });

  const [typeSurgeryId, setTypeSurgeryId] = useState([]);
  const [typeSurgeryKurdishId, setTypeSurgeryKurdishId] = useState([]);

  useEffect(() => {
    // const typeArrayId = [];
    const typeSurgeruKuId = [];
    typeSurgeryKurdishId.map((typeKurdish) => {
      typeSurgeruKuId.push(typeKurdish.value);
    });

    // typeSurgeryId.map((item) => {
    //   typeArrayId.push(item.value);
    // });
    if (typeSurgeruKuId.length <= 0) {
      return;
    } else {
      dispatch(getOrganLevelAction(typeSurgeruKuId));
    }
  }, [typeSurgeryKurdishId]);
  let organArray = [];
  organLevel.map((organ) => {
    organArray.push({
      label: organ.organ_level_name,
      value: organ.organ_level_name,
    });
  });

  const handleChangeSurgery = (selectedOptin) => {
    setSurgeryArr(selectedOptin);

    setInputValue({
      ...inputValue,
      SPID: selectedOptin,
    });
  };

  const handleChangeDoctor = (selectedOptin) => {
    
    
    setDoctor(selectedOptin);

    // setInputValue({
    //   ...inputValue,
    //   SPID: selectedOptin,
    // });
  };

  const [type, setType] = useState([]);

  const handelChange = (newValue, actionValue) => {
    const { action } = actionValue;

    setType(newValue);
    setTypeSurgeryId(newValue);

    if (action === "select-option") {
      return;
    } else if (action === "create-option") {
      if (length == 1) {
        dispatch(createTypeOfSurgery(newValue[0].value, idOfSurgery));
      } else {
        Swal.fire({
          icon: "error",
          text: "تکایە یەک جۆر نەشتەرگەری هەڵبـژێرە",
          timer: 1500,
        });
      }
    }
  };

  const handleChangeMember = (value) => {
    setMember(value);
    setTypeSurgeryKurdishId(value);
  };

  const toggleOptionTypeOfHelp = (value) => {
    if (selectTypeOfHelp.includes(value)) {
      setSelectTypeOfHelp(selectTypeOfHelp.filter((opt) => opt !== value));
    } else {
      setSelectTypeOfHelp([...selectTypeOfHelp, value]);
    }
  };

  return (
    <>
      {loading ? (
        <div className="absolute top-0 left-[47%] flex flex-col">
          <img
            src={Logo}
            alt="Logo"
            className="w-56 m-auto animate-pulse mt-20 "
          />
          <div className="text-2xl text-center font-bold  ">
            <div className="flex justify-center gap-4">
              <p className="animate-charcter">Please Wait</p>
              <div class="container2">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>{" "}
            </div>
          </div>
        </div>
      ) : (
        <div className={isOpenAssign ? " parentModal " : "closeParentModal"}>
          <div className="pt-20"></div>

          <div className="overlay" onClick={() => closeModal(false)}></div>
          <div className="childModal">
            <div className="flex items-center justify-between">
              <div
                className="text-3xl cursor-pointer text-red-600"
                onClick={() => {
                  organArray = [];
                  closeModal(false);
                }}
              >
                x
              </div>
            </div>
            {/* form */}

            <div>
              <div className="greenTitle">
                <p className=" lg:text-xl">فۆرمی زانیاری نەخۆش</p>
              </div>
            </div>
            <div></div>
            <form action="">
              <div className="parentInputGrid lg:py-4 ">
                <div className="w-full mt-2">
                  <div className="parentInputTextRight ">
                    <label htmlFor="province" className="lblDesign">
                      * پارێزگا{" "}
                    </label>
                  </div>
                  <input
                    type="text"
                    onChange={handelInputChange}
                    value={inputValue.province}
                    name="province"
                    id="province"
                    className="inputLogin w-full text-right"
                    placeholder="پارێزگا"
                  />
                </div>

                <div className="w-full mt-2">
                  <div className="w-full parentInputTextRight ">
                    <label className="lblDesign" htmlFor="country">
                      {" "}
                      * وڵات{" "}
                    </label>
                  </div>
                  <input
                    type="text"
                    name="country"
                    onChange={handelInputChange}
                    value={inputValue.country}
                    id="country"
                    className="inputLogin w-full text-right"
                    placeholder="وڵات"
                  />
                </div>

                {/* end parent Input & label */}
                <div className="w-full mt-2">
                  <div className="w-full parentInputTextRight ">
                    <label className="lblDesign" htmlFor="nation">
                      {" "}
                      *نەتەوە
                    </label>
                  </div>
                  <input
                    type="text"
                    name="nation"
                    onChange={handelInputChange}
                    value={inputValue.nation}
                    id="nation"
                    className="inputLogin w-full text-right"
                    placeholder="نەتەوە"
                  />
                </div>

                {/* end parent Input & label */}

                <div className="w-full mt-2">
                  <div className="w-full parentInputTextRight ">
                    <label className="lblDesign" htmlFor="occ">
                      {" "}
                      *پیشە
                    </label>
                  </div>
                  <input
                    type="text"
                    name="occupation"
                    onChange={handelInputChange}
                    value={inputValue.occupation}
                    id="occ"
                    className="inputLogin w-full text-right"
                    placeholder="پیشە"
                  />
                </div>
                {/* end parent Input & label */}
                <div></div>
                <div className="w-full mt-2">
                  <div className="parentInputTextRight ">
                    <label className="lblDesign" htmlFor="district">
                      * ناحیە/قەزا{" "}
                    </label>
                  </div>
                  <input
                    type="text"
                    name="district"
                    onChange={handelInputChange}
                    value={inputValue.district}
                    id="district"
                    className="inputLogin w-full text-right "
                    placeholder="ناحیە/قەزا"
                  />
                </div>

                <div className="w-full mt-2">
                  <div className="parentInputTextRight ">
                    <label className="lblDesign" htmlFor="street">
                      *شەقام گەڕەک{" "}
                    </label>
                  </div>
                  <input
                    type="text"
                    name="street"
                    onChange={handelInputChange}
                    value={inputValue.street}
                    id="street"
                    className="inputLogin w-full text-right"
                    placeholder="شەقام گەڕەک"
                  />
                </div>
                {/* end parent Input & label */}

                <div className="w-full mt-2">
                  <div className="parentInputTextRight ">
                    <label className="lblDesign" htmlFor="closetHome">
                      *نزیکترین شوێن لە ماڵیانەوە
                    </label>
                  </div>
                  <input
                    type="text"
                    name="closetHome"
                    onChange={handelInputChange}
                    value={inputValue.closetHome}
                    id="closetHome"
                    className="inputLogin w-full text-right"
                    placeholder="نزیکترین شوێن لە ماڵیانەوە"
                  />
                </div>
                {/* end parent Input & label */}
              </div>

              <div className="parentInputGrid">
                <div className="w-full mt-2">
                  <div className="parentInputTextRight ">
                    <label className="lblDesign" htmlFor="noFamily">
                      *ژمارەی خێزان
                    </label>
                  </div>
                  <input
                    type="text"
                    name="NoFamily"
                    onChange={handelInputChange}
                    value={inputValue.NoFamily}
                    id="noFamily"
                    className="inputLogin w-full text-right"
                    placeholder="ژمارەی خێزان"
                  />
                </div>
                <div className="w-full mt-2">
                  <div className="relative w-full z-30">
                    <div className="child ">
                      <div className="parentInputTextRight">
                        <label className="lblDesign" htmlFor="gender">
                          {" "}
                          * خێزاندارە{" "}
                        </label>
                      </div>
                      <div
                        onClick={handelSelect}
                        className="coustmSelect cursor-pointer"
                      >
                        <p>
                          {married == "" || married == null
                            ? "خێزاندارە .... "
                            : married}
                        </p>
                        <div
                          className={
                            openSelect ? "parentCaretDown" : "duration-300"
                          }
                        >
                          <AiFillCaretDown />
                        </div>
                      </div>
                      {openSelect && (
                        <div className="elementCoustmSelect cursor-pointer">
                          <div
                            onClick={() => {
                              setOpenSelect(false);
                              setMarried("بەڵێ");
                            }}
                            className="option"
                          >
                            بەڵێ
                          </div>

                          <div
                            className="mt-3 option"
                            onClick={() => {
                              setOpenSelect(false);
                              setMarried("نەخێر");
                            }}
                          >
                            نەخێر
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="w-full mt-2 ">
                  <div className="relative w-full ">
                    <div className="child ">
                      <div className="w-full parentInputTextRight">
                        <label className="lblDesign" htmlFor="smoke">
                          {" "}
                          * جگەرە کێشە
                        </label>
                      </div>
                      <div
                        onClick={handelSmoking}
                        className="coustmSelect cursor-pointer "
                      >
                        <p>
                          {smoking == "" || smoking == null
                            ? "Select Smoking .... "
                            : smoking}
                        </p>
                        <div
                          className={
                            isOpenSmoking ? "parentCaretDown" : "duration-300"
                          }
                        >
                          <AiFillCaretDown />
                        </div>
                      </div>
                      {isOpenSmoking && (
                        <div className=" elementCoustmSelect cursor-pointer">
                          <div
                            onClick={() => {
                              setIsOpenSmoking(false);
                              setSmoking("بەڵێ");
                            }}
                            className="option"
                          >
                            بەڵێ
                          </div>

                          <div
                            className="mt-3 option"
                            onClick={() => {
                              setIsOpenSmoking(false);
                              setSmoking("نەخێر");
                            }}
                          >
                            نەخێر
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="w-full mt-2">
                  <div className="w-full justify-end flex">
                    <label className="lblDesign" htmlFor="scoundPhoneNo">
                      *ژ.مۆبایلی دووەم
                    </label>
                  </div>
                  <input
                    type="text"
                    name="scoundPhoneNo"
                    onChange={handelInputChange}
                    value={inputValue.fullName}
                    id="scoundPhoneNo"
                    className="inputLogin w-full text-right "
                    placeholder="ژ.مۆبایلی دووەم"
                  />
                </div>
                {/* end parent Input & label */}
              </div>

              <div className="parentInputGrid py-4 ">
                <div></div>

                <div className="w-full mt-4">
                  <div className="parentInputTextRight ">
                    <label className="lblDesign" htmlFor="literate">
                      * خوێندەوارە
                    </label>
                  </div>

                  <select
                    name="literate"
                    onChange={handelInputChange}
                    value={inputValue.literate}
                    id="literate"
                    placeholder="کاتی دیاریکراو بۆ هەڵسەنگاندن"
                    className="select bg-white"
                  >
                    <option value="" selected>
                      خوێندەوارە
                    </option>
                    <option value="بەڵێ">بەڵێ</option>
                    <option value="نەخێر">نەخێر</option>
                  </select>
                </div>
                {/* end parent Input & label */}
                <div className="w-full mt-2">
                  <div className="parentInputTextRight ">
                    <label className="lblDesign" htmlFor="number">
                      ژ.کارتی نیشتمانی یاخود ناسنامەی *
                    </label>
                  </div>
                  <input
                    type="text"
                    name="card"
                    onChange={handelInputChange}
                    value={inputValue.phone}
                    id="number"
                    className="inputLogin w-full text-right"
                    placeholder="   ژ.کارتی نیشتمانی یاخود ناسنامەی "
                  />
                </div>

                <div className="w-full mt-4">
                  <div className="parentInputTextRight ">
                    <label className="lblDesign" htmlFor="timeAllot">
                      * کاتی دیاریکراو بۆ هەڵسەنگاندن{" "}
                    </label>
                  </div>

                  <select
                    name="timeAllot"
                    onChange={handelInputChange}
                    value={inputValue.timeAllot}
                    id="timeAllot"
                    placeholder="کاتی دیاریکراو بۆ هەڵسەنگاندن"
                    className="select"
                  >
                    <option value="-2" selected>
                      کاتی دیاریکراو هەڵبژێرە
                    </option>
                    {amountOfDays.map((amount, index) => {
                      return (
                        <option value={amount} key={index}>
                          {amount}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="text-center bg-teal-500 mt-4  py-2 rounded-md shadow-md text-white w-6/12 lg:w-3/12 m-auto">
                <p className="lg:text-lg">زانیاری پزیشکی</p>
              </div>

              <div className="w-full  relative ">
                <div className="relative   mt-3">
                  <label className="lblDesign text-right mr-2 " htmlFor="">
                    * جۆری هاوکاری{" "}
                  </label>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenOrganLevel(!openOrganLevel);
                    }} // Updated variable name
                    className="parentCoustmSelectMultipule"
                  >
                    {selectTypeOfHelp.length > 0
                      ? selectTypeOfHelp.join(", ")
                      : "Select options..."}
                  </button>
                  {openOrganLevel && (
                    <div className="optionMultiple  overflow-y-auto h-72 rounded-md">
                      {option3.map((opt) => (
                        <label
                          key={opt}
                          className="flex items-center px-4 py-2 cursor-pointer"
                        >
                          <input
                            type="checkbox"
                            value={opt}
                            checked={selectTypeOfHelp.includes(opt)}
                            onChange={() => toggleOptionTypeOfHelp(opt)}
                            className="mr-2 cursor-pointer"
                          />
                          {opt}
                        </label>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              {selectTypeOfHelp.map((select, index) => {
                return (
                  <>
                    {select == "نەشتەرگەری" ? (
                      <>
                        <div className="parentInputGrid">
                          <div className="w-full mt-3.5">
                            <label htmlFor="countries" className="lblDesign">
                              TYP *
                            </label>

                            <CreatableSelect
                              isMulti
                              options={surgeryTypeArray}
                              isClearable
                              onChange={handelChange}
                              isRtl={true}
                            />

                            <div>
                              <p className="text-lg text-red-400">{error}</p>
                            </div>
                          </div>
                          {/* end parent Input & label */}

                          <div className="w-full mt-3.5 text-right">
                            <label htmlFor="countries" className="lblDesign">
                              * (جۆری نەشتەرگەری) لەسەر ئاستی ئەندام
                            </label>

                            <Select
                              isMulti
                              options={organArray}
                              isClearable
                              onChange={(selectedOptin) => {
                                setOrgan(selectedOptin);
                              }}
                              isRtl={true}
                            />

                            <div>
                              <p className="text-lg text-red-400">{error}</p>
                            </div>
                          </div>

                          {/* end parent Input & label */}

                          <div className="w-full mt-3.5 text-right ">
                            <label htmlFor="name" className="lblDesign">
                              * (ئەندامی نەشتەرگەری لەسەرکراو) ئەندام
                            </label>

                            <Select
                              isMulti
                              options={memberArray}
                              isClearable
                              onChange={handleChangeMember}
                              isRtl={true}
                            />
                          </div>
                          {/* end parent Input & label */}

                          <div className="w-full mt-2">
                            <div className="parentInputTextRight ">
                              <label className="lblDesign" htmlFor="spid">
                                * پسپۆڕی جۆری نەخۆشی{" "}
                              </label>
                            </div>
                            <Select
                              placeholder="Select"
                              className="mt-2"
                              onChange={handleChangeSurgery}
                              isMulti
                              id="* نەخۆشی پێش وەخت"
                              // defaultValue={defaultValue[0]}
                              options={surgeryArray}
                            />
                          </div>
                        </div>

                        <div className="parentInputGrid">
                          <div className="w-full mt-2">
                            <label className="lblDesign" htmlFor="name">
                              Indiction *
                            </label>
                            <input
                              type="text"
                              name="indiction"
                              onChange={handelInputChange}
                              value={inputValue.indiction}
                              id="name"
                              className="inputLogin w-full  "
                              placeholder="Indiction"
                            />
                          </div>
                          {/* end parent Input & label */}

                          <div className="w-full ">
                            <div className="parentInputTextRight ">
                              <label
                                className="lblDesign"
                                htmlFor="earlySurgery"
                              >
                                * نەشتەرگەری پێشوەخت{" "}
                              </label>
                            </div>
                            <input
                              type="text"
                              name="earlySurgery"
                              onChange={handelInputChange}
                              value={inputValue.earlySurgery}
                              id="earlySurgery"
                              className="inputLogin w-full text-right "
                              placeholder="نەشتەرگەری پێشوەخت "
                            />
                          </div>
                          
                          {/* end parent Input & label */}
                          <div className="w-full ">
                            <div className="parentInputTextRight ">
                              <label
                                className="lblDesign"
                                htmlFor="earlyPatient"
                              >
                                * نەخۆشی پێش وەخت
                              </label>
                            </div>
                            <input
                              type="text"
                              name="earlyPatient"
                              onChange={handelInputChange}
                              value={inputValue.earlyPatient}
                              id="earlyPatient"
                              className="inputLogin w-full text-right "
                              placeholder="نەخۆشی پێش وەخت"
                            />
                          </div>
                          
                          {/* end parent Input & label */}
                          <div className="w-full ">
                            <div className="parentInputTextRight ">
                              <label
                                className="lblDesign"
                                htmlFor="hadSurg"
                              >
                                * نەشتەرگەری بۆ کراوە 
                              </label>
                            </div> 
                            <select name="hadSurgery" onChange={handelInputChange} className="select mt-2 py-0.5 bg-white" id="hadSurg">
                              <option value="">تکایە زانیاریەکە هەڵبژێرە</option>
                              <option value="بەڵێ">بەڵێ</option>
                              <option value="نەخێر">نەخێر</option>
                            </select>
                           
                          </div>
                        </div>

                        <div className="parentInputGrid">
                          <div className="w-full mt-2">
                            <label className="lblDesign" htmlFor="Final">
                              Final Diagnosis *
                            </label>
                            <input
                              type="text"
                              onChange={handelInputChange}
                              value={inputValue.finalDiagnos}
                              name="finalDiagnos"
                              id="Final"
                              className="inputLogin w-full "
                              placeholder="Final Diagnosis"
                            />
                          </div>

                          <div className="w-full ">
                            <div className="parentInputTextRight ">
                              <label className="lblDesign" htmlFor="ZODoctor">
                                {" "}
                                *پزیشکی هاوکاری ڕیکخراوی زادە
                              </label>
                            </div>

                            <select
                              name="ZODoctor"
                              value={inputValue.ZODoctor || ""}
                              className="select mt-2 bg-white"
                              onChange={handelInputChange}
                              id="ZODoctor"
                            >
                              <option value="">Select ..</option>
                              <option value="بەڵێ">بەڵێ</option>
                              <option value="نەخێر">نەخێر</option>
                            </select>
                          </div>
                          {/* end parent Input & label */}
                          <div className="w-full lg:mt-1">
                            <label
                              htmlFor="countries"
                              className="lblDesign text-right"
                            >
                              * هاوکاری رێکخراوی زادە
                            </label>
                            <select
                              id="countries"
                              className="select mt-3.5 bg-white"
                              name="helpingZO"
                              onChange={handelInputChange}
                              value={inputValue.helpingZO}
                            >
                              <option selected value={-1}>
                                هاوکاری رێکخراوی زادە
                              </option>

                              <option value="بەڵێ">بەڵێ</option>
                              <option value="نەخێر">نەخێر</option>
                            </select>
                            <div>
                              <p className="text-lg text-red-400">{error}</p>
                            </div>
                          </div>
                          {/* end parent Input & label */}
                          <div className="w-full mt-2.5 ">
                            <div className="parentInputTextRight ">
                              <label className="lblDesign" htmlFor="send">
                                {" "}
                                * پزیشکی نێردراو بۆ
                              </label>
                            </div>
                            
                            <Select
                              isMulti
                              options={doctorArray}
                              isClearable
                              onChange={(selectedOptin) => {
                                handleChangeDoctor(selectedOptin)
                                // setDoctor(selectedOptin);
                              }}
                              isRtl={true}
                            />
                            
                            {/* <select
                              name="doctor"
                              className="select bg-white mt-2"
                              onChange={handelInputChange}
                              id="send"
                            >
                              <option value="">Select Doctor ..</option>
                              {doctors.map((doctor, index) => {
                                return (
                                  <option key={index} value={doctor.dr_name}>
                                    {doctor.dr_name}
                                  </option>
                                );
                              })}
                            </select> */}
                          </div>
                          {/* end parent Input & label */}

                          <div className="w-full mt-2">
                            <label className="lblDesign" htmlFor="hpe">
                              H.P.E *
                            </label>
                            <input
                              type="text"
                              name="HPE"
                              onChange={handelInputChange}
                              value={inputValue.HPE}
                              id="hpe"
                              className="inputLogin w-full "
                              placeholder="H.P.E "
                            />
                          </div>

                          {/* end parent Input & label */}
                        </div>
                      </>
                    ) : select == "پێداویستی پزیشکی" ? (
                      <div className="parentInputGrid">
                        <div className="w-full mt-2">
                          <div className="parentInputTextRight ">
                            <label
                              className="lblDesign"
                              htmlFor="typeOfMedical"
                            >
                               * بۆ چی 
                            </label>
                          </div>
                          <input
                            type="text"
                            name="typeMedical"
                            onChange={handelInputChange}
                            value={inputValue.typeMedical}
                            id="typeOfMedical"
                            className="inputLogin w-full text-right "
                            placeholder="  بۆ چی "
                          />
                        </div>
                        {/* end parent Input & label */}
                        <div className="w-full mt-2">
                          <div className="parentInputTextRight ">
                            <label
                              className="lblDesign"
                              htmlFor="supplyLocation"
                            >
                              شوێنی دابینکردنی پێداویستی
                            </label>
                          </div>
                          <input
                            type="text"
                            name="supplyLocation"
                            onChange={handelInputChange}
                            value={inputValue.supplyLocation}
                            id="supplyLocation"
                            className="inputLogin w-full text-right"
                            placeholder="شوێنی دابینکردنی پێداویستی"
                          />
                        </div>

                        {/* end parent Input & label */}

                        <div className="w-full mt-2">
                          <div className="parentInputTextRight ">
                            <label
                              className="lblDesign"
                              htmlFor="medicalProvid"
                            >
                              * پێداویستی پزیشکی بۆ دابینکراوە{" "}
                            </label>
                          </div>
                          <select
                            name="medicalProvid"
                            id="medicalProvid"
                            value={inputValue.medicalProvid || ""}
                            className=" select mt-2 bg-white "
                            onChange={handelInputChange}
                          >
                            <option value="">Select ..</option>
                            <option value="بەڵێ">بەڵێ</option>
                            <option value="نەخێر">نەخێر</option>
                          </select>
                        </div>
                        {/* end parent Input & label */}
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                );
              })}
              <div>
                <textarea
                  name="note"
                  onChange={handelInputChange}
                  value={inputValue.note}
                  placeholder="تێبینی"
                  className="inputLogin w-full text-right"
                  rows="6"
                ></textarea>
              </div>
              <div className="flex items-center gap-4  mt-3">
                <div>
                  <button className="editBtn w-24" onClick={handelSave}>
                    Save
                  </button>
                </div>
                <div>
                  <button onClick={handelPatient} className="assignBtn">
                    Assigned
                  </button>
                </div>
              </div>
            </form>
          </div>

          <div className="md:pb-[65rem] xl:pb-[57rem]"></div>
        </div>
      )}
      {/* <div className="pb-32"></div> */}
    </>
  );
};

export default AssignModal;
