import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import "./css/staticstic.css";
import Logo from "../../Image/Logo.jpg";
import {
  PiDotsThreeOutlineBold,
  PiMicrosoftExcelLogoDuotone,
} from "react-icons/pi";

import {
  getStatisticsAction,
  handleDownload,
} from "../../Action/StatisticsAction/StatisticsAction";
import {
  getAllTypeSurgeryAction,
  getOrganLevelAction,
} from "../../Action/SurgeryTypeAction/surgery";
import { getSurgeryAction } from "../../Action/Surgery/surgeryAction";
import { getDoctorAction } from "../../Action/Doctor/doctorAction";

import { CiFilter } from "react-icons/ci";
import { ImCheckmark, ImCross } from "react-icons/im";
import { MdFilterAltOff } from "react-icons/md";

const Statistics = () => {
  const [fromUpdate, setFromUpdate] = useState(null);
  const [toUpdate, setToUpdate] = useState(null);
  const [count, setCount] = useState(null);
  const [reject, setReject] = useState(null);
  const getStatistics = useSelector((state) => state.getStatistics);
  const dispatch = useDispatch();
  const { data, loading, total } = getStatistics;
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(50);
  const [selectAll, setSelectAll] = useState(false);

  const [selectedOption, setSelectedOption] = useState("");
  const [indexx, setIndexx] = useState(null);
  const listData = [];
  const getSurgery = useSelector((state) => state.getSurgery);
  const { surgery } = getSurgery;
  const getDoctor = useSelector((state) => state.getDoctor);
  const { doctors } = getDoctor;

  const getAllTypeSurgery = useSelector((state) => state.getAllTypeSurgery);
  const { typeSurgery } = getAllTypeSurgery;

  const getOrganLevel = useSelector((state) => state.getOrganLevel);
  const { organLevel } = getOrganLevel;
  const [dateTime, setDateTme] = useState({
    from: "",
    to: "",
  });

  const [medicalProvidedSearch, setMedicalProvidedSearch] = useState(null);
  // const regex = /^(\d{4})(\d{3})(\d{4})$/;
  const initalState = {
    code: null,
    Pname: null,
    phone: null,
    gender: null,
    birthDate: null,
    occuption: null,
    nation: null,
    married: null,
    noFamily: null,
    province: null,
    district: null,
    street: null,
    closetHome: null,
    secoundHome: null,
    card: null,
    smoke: null,
    timeAllote: null,
    SITD: null,
    member: null,
    memberLevel: null,
    TYP: null,
    indiction: null,
    finalDiagnosis: null,
    earlyPatient: null,
    earlySurgery: null,
    country: null,
    Doctor: null,
    ZODoctor: null,
    medicalProvided: null,
    Typemedicalsupplies: null,
    supplyLocation: null,
    helpingZO: null,
    salaryEmploye: null,
    date: null,
    house: null,
    numberOfHouse: null,
    teanant: null,
    numberOfLand: null,
    poverty: null,
    Vehicle: null,
    alive: null,
    land: null,
    debt: null,
    govOrPriveteEmp: null,
    metters: null,
    NeighborhoodTypeselect: null,
    amountOfRent: null,
    typeofVehicle: null,
    typeofLandEstimatedPrice: null,
    monthlyIncome: null,
    amountofSalary: null,
    amountofLoan: null,
    haveOtherDiseases: null,
    Typeofdisease: null,
    numberofChildren: null,
    numberofStudent: null,
    monthlyIncomeFamily: null,
    PovertyRates: null,
    HMCabilitToSurgery: null,
    PMediaWork: null,
    relationship: null,
    Name: null,
    resonOfDebt: null,
    phoneNumber: null,
    typeOfDeasise: null,
    note: null,
    mercy: null,
  };
  const [isOpenColumns, setIsopenColumns] = useState(false);
  const [filterValues, setFilterValues] = useState(initalState);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [hadSurgery, setHadSurgery] = useState(null);

  useEffect(() => {
    dispatch(getDoctorAction());
    dispatch(getOrganLevelAction(0));
    dispatch(getSurgeryAction());
    dispatch(getAllTypeSurgeryAction());
  }, []);
  const columns = [
    { name: "code", label: "PatientID" },
    { name: "Pname", label: "Full Name" },

    { name: "phone", label: "Phone" },
    { name: "gender", label: "Gender" },

    { name: "birthDate", label: "Age" },
    { name: "occuption", label: "Occuption" },
    { name: "nation", label: "Nation" },
    { name: "country", label: "Country" },
    { name: "married", label: "Married" },
    { name: "noFamily", label: "Number Of Family" },
    { name: "province", label: "Province" },
    { name: "district", label: "District" },
    { name: "street", label: "Street" },
    { name: "closetHome", label: "Closet Home" },
    { name: "secoundHome", label: "Secound Phone" },
    { name: "card", label: "Card" },
    { name: "smoke", label: "Smoke" },
    { name: "timeAllote", label: "Time Allote" },
    { name: "SITD", label: "Disease Type Specialist" },
    { name: "member", label: "Member" },
    { name: "memberLevel", label: "Member Level" },

    { name: "TYP", label: "TYP" },
    { name: "indiction", label: "Indiction" },
    { name: "finalDiagnosis", label: "Final Diagnosis" },
    { name: "earlyPatient", label: "Early Patient" },
    { name: "Doctor", label: "Doctor" },
    { name: "ZODoctor", label: "Zad Organazation Doctor" },
    { name: "medicalProvided", label: "Medical Provided" },
    { name: "Typemedicalsupplies", label: "Type Medical Supplies" },
    { name: "supplyLocation", label: "Supply Location" },
    { name: "helpingZO", label: "Helping Zad Organazation" },

    { name: "literate", label: "literate" },

    { name: "alive", label: "alive" },
    { name: "salaryEmploye", label: "salaryEmploye" },
    { name: "house", label: "house" },
    { name: "numberOfHouse", label: "numberOfHouse" },
    { name: "teanant", label: "teanant" },
    { name: "numberOfLand", label: "numberOfLand" },
    { name: "poverty", label: "poverty" },
    { name: "Vehicle", label: "Vehicle" },
    { name: "land", label: "land" },
    { name: "debt", label: "debt" },
    { name: "govOrPriveteEmp", label: "govOrPriveteEmp" },
    { name: "metters", label: "metters" },
    { name: "NeighborhoodTypeselect", label: "NeighborhoodTypeselect" },
    // { name: "NeighborhoodTypeselect", label: "NeighborhoodTypeselect" },
    { name: "amountOfRent", label: "amountOfRent" },
    { name: "economicStatus", label: "economicStatus" },
    { name: "typeofVehicle", label: "typeofVehicle" },
    { name: "typeofLandEstimatedPrice", label: "typeofLandEstimatedPrice" },
    { name: "monthlyIncome", label: "monthlyIncome" },
    { name: "amountofSalary", label: "amountofSalary" },
    { name: "amountofLoan", label: "amountofLoan" },
    { name: "haveOtherDiseases", label: "haveOtherDiseases" },
    { name: "Typeofdisease", label: "Typeofdisease" },
    { name: "numberofChildren", label: "numberofChildren" },
    { name: "numberofStudent", label: "numberofStudent" },
    { name: "monthlyIncomeFamily", label: "monthlyIncomeFamily" },
    { name: "PovertyRates", label: "PovertyRates" },
    { name: "HMCabilitToSurgery", label: "HMCabilitToSurgery" },
    { name: "PMediaWork", label: "PMediaWork" },
    { name: "relationship", label: "relationship" },
    { name: "Name", label: "Name" },
    { name: "resonOfDebt", label: "resonOfDebt" },
    { name: "phoneNumber", label: "phoneNumber" },
    { name: "typeOfDeasise", label: "typeOfDeasise" },
    { name: "note", label: "note" },
    { name: "mercy", label: "mercy" },

    // ... Add more columns
  ];
  const [hiddenColumns, setHiddenColumns] = useState([
    "alive",
    "note",
    "salaryEmploye",
    "house",
    "numberOfHouse",
    "teanant",
    "numberOfLand",
    "poverty",
    "Vehicle",
    "land",
    "debt",
    "govOrPriveteEmp",
    "metters",
    "NeighborhoodTypeselect",
    "amountOfRent",
    "economicStatus",
    "typeofVehicle",
    "typeofLandEstimatedPrice",
    "monthlyIncome",
    "amountofSalary",
    "amountofLoan",
    "haveOtherDiseases",
    "Typeofdisease",
    "numberofChildren",
    "numberofStudent",
    "monthlyIncomeFamily",
    "PovertyRates",
    "HMCabilitToSurgery",
    "PMediaWork",
    "relationship",
    "Name",
    "resonOfDebt",
    "phoneNumber",
    "typeOfDeasise",
    "numberOfHouse",
    "teanant",
    "alive",
    "note",
    "mercy",
  ]);

  const {
    code,
    Pname,
    phone,
    gender,
    birthDate,
    occuption,
    nation,
    married,
    noFamily,
    province,
    district,
    street,
    closetHome,
    secoundHome,
    card,
    smoke,
    timeAllote,
    SITD,
    member,
    memberLevel,
    TYP,
    indiction,
    finalDiagnosis,
    earlyPatient,
    earlySurgery,
    country,
    Doctor,
    ZODoctor,
    medicalProvided,
    Typemedicalsupplies,
    supplyLocation,
    helpingZO,
    salaryEmploye,
    date,
    house,
    numberOfHouse,
    teanant,
    numberOfLand,
    poverty,
    Vehicle,
    alive,
    land,
    debt,
    govOrPriveteEmp,
    metters,
    NeighborhoodTypeselect,
    amountOfRent,
    typeofVehicle,
    typeofLandEstimatedPrice,
    monthlyIncome,
    amountofSalary,
    amountofLoan,
    haveOtherDiseases,
    Typeofdisease,
    numberofChildren,
    numberofStudent,
    monthlyIncomeFamily,
    PovertyRates,
    HMCabilitToSurgery,
    PMediaWork,
    relationship,
    Name,
    resonOfDebt,
    phoneNumber,
    typeOfDeasise,
    note,
    mercy,
  } = filterValues;
  useEffect(() => {
    if (hadSurgery == "") {
      setHadSurgery(null);
    }
    if (medicalProvidedSearch == "") {
      setMedicalProvidedSearch(null);
    }

    if (count == "") {
      setCount(null);
    }
    dispatch(
      getStatisticsAction(
        currentPage,
        perPage,
        selectedOption,
        code,
        Pname,
        phone,
        gender,
        birthDate,
        occuption,

        nation,

        country,
        married,
        noFamily,
        province,
        district,
        street,
        closetHome,
        secoundHome,
        card,
        smoke,
        timeAllote,
        SITD,
        member,
        memberLevel,
        TYP,
        indiction,
        finalDiagnosis,
        earlyPatient,
        earlySurgery,
        Doctor,
        ZODoctor,
        medicalProvided,
        Typemedicalsupplies,
        supplyLocation,
        helpingZO,
        salaryEmploye,
        date,
        house,
        numberOfHouse,
        teanant,
        numberOfLand,
        poverty,
        Vehicle,
        land,
        debt,
        govOrPriveteEmp,
        metters,
        NeighborhoodTypeselect,
        amountOfRent,
        typeofVehicle,
        typeofLandEstimatedPrice,
        monthlyIncome,
        amountofSalary,
        amountofLoan,
        haveOtherDiseases,
        Typeofdisease,
        numberofChildren,
        numberofStudent,
        monthlyIncomeFamily,
        PovertyRates,
        HMCabilitToSurgery,
        PMediaWork,
        relationship,
        Name,
        resonOfDebt,
        phoneNumber,
        typeOfDeasise,
        note,
        mercy,
        dateTime.from,
        dateTime.to,
        count,
        reject,
        alive,
        hadSurgery,
        medicalProvidedSearch,
        fromUpdate,
        toUpdate
      )
    );
  }, [perPage, selectedOption]);

  const handleSearch = () => {
    setCurrentPage(0);
    setIsOpenFilter(false);
    if (hadSurgery == "") {
      setHadSurgery(null);
    }
    if (medicalProvidedSearch == "") {
      setMedicalProvidedSearch(null);
    }

    if (count == "") {
      setCount(null);
    }

    dispatch(
      getStatisticsAction(
        currentPage,
        perPage,
        selectedOption,
        code,
        Pname,
        phone,
        gender,
        birthDate,
        occuption,

        nation,

        country,
        married,
        noFamily,
        province,
        district,
        street,
        closetHome,
        secoundHome,
        card,
        smoke,
        timeAllote,
        SITD,
        member,
        memberLevel,
        TYP,
        indiction,
        finalDiagnosis,
        earlyPatient,
        earlySurgery,
        Doctor,
        ZODoctor,
        medicalProvided,
        Typemedicalsupplies,
        supplyLocation,
        helpingZO,
        salaryEmploye,
        date,
        house,
        numberOfHouse,
        teanant,
        numberOfLand,
        poverty,
        Vehicle,
        land,
        debt,
        govOrPriveteEmp,
        metters,
        NeighborhoodTypeselect,
        amountOfRent,
        typeofVehicle,
        typeofLandEstimatedPrice,
        monthlyIncome,
        amountofSalary,
        amountofLoan,
        haveOtherDiseases,
        Typeofdisease,
        numberofChildren,
        numberofStudent,
        monthlyIncomeFamily,
        PovertyRates,
        HMCabilitToSurgery,
        PMediaWork,
        relationship,
        Name,
        resonOfDebt,
        phoneNumber,
        typeOfDeasise,
        note,
        mercy,
        dateTime.from,
        dateTime.to,
        count,
        reject,
        alive,
        hadSurgery,
        medicalProvidedSearch,
        fromUpdate,
        toUpdate
      )
    );
  };
  const handleClearSearch = (name) => {
    setIsOpenFilter(false);
    const updateFiltersValue = { ...filterValues };
    updateFiltersValue[name] = null;
    setFilterValues(updateFiltersValue);

    dispatch(
      getStatisticsAction(
        currentPage,
        perPage,
        selectedOption,
        updateFiltersValue.code,
        updateFiltersValue.Pname,
        updateFiltersValue.phone,
        updateFiltersValue.gender,
        updateFiltersValue.birthDate,
        updateFiltersValue.occuption,

        updateFiltersValue.nation,

        updateFiltersValue.country,
        updateFiltersValue.married,
        updateFiltersValue.noFamily,
        updateFiltersValue.province,
        updateFiltersValue.district,
        updateFiltersValue.street,
        updateFiltersValue.closetHome,
        updateFiltersValue.secoundHome,
        updateFiltersValue.card,
        updateFiltersValue.smoke,
        updateFiltersValue.timeAllote,
        updateFiltersValue.SITD,
        updateFiltersValue.member,
        updateFiltersValue.memberLevel,
        updateFiltersValue.TYP,
        updateFiltersValue.indiction,
        updateFiltersValue.finalDiagnosis,
        updateFiltersValue.earlyPatient,
        updateFiltersValue.earlySurgery,
        updateFiltersValue.Doctor,
        updateFiltersValue.ZODoctor,
        updateFiltersValue.medicalProvided,
        updateFiltersValue.Typemedicalsupplies,
        updateFiltersValue.supplyLocation,
        updateFiltersValue.helpingZO,
        updateFiltersValue.salaryEmploye,
        updateFiltersValue.date,
        updateFiltersValue.house,
        updateFiltersValue.numberOfHouse,
        updateFiltersValue.teanant,
        updateFiltersValue.numberOfLand,
        updateFiltersValue.poverty,
        updateFiltersValue.Vehicle,
        updateFiltersValue.land,
        updateFiltersValue.debt,
        updateFiltersValue.govOrPriveteEmp,
        updateFiltersValue.metters,
        updateFiltersValue.NeighborhoodTypeselect,
        updateFiltersValue.amountOfRent,
        updateFiltersValue.typeofVehicle,
        updateFiltersValue.typeofLandEstimatedPrice,
        updateFiltersValue.monthlyIncome,
        updateFiltersValue.amountofSalary,
        updateFiltersValue.amountofLoan,
        updateFiltersValue.haveOtherDiseases,
        updateFiltersValue.Typeofdisease,
        updateFiltersValue.numberofChildren,
        updateFiltersValue.numberofStudent,
        updateFiltersValue.monthlyIncomeFamily,
        updateFiltersValue.PovertyRates,
        updateFiltersValue.HMCabilitToSurgery,
        updateFiltersValue.PMediaWork,
        updateFiltersValue.relationship,
        updateFiltersValue.Name,
        updateFiltersValue.resonOfDebt,
        updateFiltersValue.phoneNumber,
        updateFiltersValue.typeOfDeasise,
        updateFiltersValue.note,
        updateFiltersValue.mercy,
        dateTime.from,
        dateTime.to,
        count,
        reject,
        alive,
        hadSurgery,
        medicalProvidedSearch,
        fromUpdate,
        toUpdate
      )
    );
  };

  const handleReset = () => {
    setIsOpenFilter(false);
    const updateFiltersValue = { ...filterValues };

    Object.keys(filterValues).map((key) => {
      updateFiltersValue[key] = null;
    });
    setFilterValues(updateFiltersValue);
    let had={...hadSurgery}
    had=null;
    setHadSurgery(had);
    setDateTme({
      ...dateTime,
      from: null,
      to: null,
    });
    setFromUpdate(null);
    setToUpdate(null);
    setMedicalProvidedSearch(null)
    setCount(null)
    setCurrentPage(0);
    setReject(null)
    

    dispatch(
      getStatisticsAction(
        currentPage,
        perPage,
        selectedOption,
        updateFiltersValue.code,
        updateFiltersValue.Pname,
        updateFiltersValue.phone,
        updateFiltersValue.gender,
        updateFiltersValue.birthDate,
        updateFiltersValue.occuption,

        updateFiltersValue.nation,

        updateFiltersValue.country,
        updateFiltersValue.married,
        updateFiltersValue.noFamily,
        updateFiltersValue.province,
        updateFiltersValue.district,
        updateFiltersValue.street,
        updateFiltersValue.closetHome,
        updateFiltersValue.secoundHome,
        updateFiltersValue.card,
        updateFiltersValue.smoke,
        updateFiltersValue.timeAllote,
        updateFiltersValue.SITD,
        updateFiltersValue.member,
        updateFiltersValue.memberLevel,
        updateFiltersValue.TYP,
        updateFiltersValue.indiction,
        updateFiltersValue.finalDiagnosis,
        updateFiltersValue.earlyPatient,
        updateFiltersValue.earlySurgery,
        updateFiltersValue.Doctor,
        updateFiltersValue.ZODoctor,
        updateFiltersValue.medicalProvided,
        updateFiltersValue.Typemedicalsupplies,
        updateFiltersValue.supplyLocation,
        updateFiltersValue.helpingZO,
        updateFiltersValue.salaryEmploye,
        updateFiltersValue.date,
        updateFiltersValue.house,
        updateFiltersValue.numberOfHouse,
        updateFiltersValue.teanant,
        updateFiltersValue.numberOfLand,
        updateFiltersValue.poverty,
        updateFiltersValue.Vehicle,
        updateFiltersValue.land,
        updateFiltersValue.debt,
        updateFiltersValue.govOrPriveteEmp,
        updateFiltersValue.metters,
        updateFiltersValue.NeighborhoodTypeselect,
        updateFiltersValue.amountOfRent,
        updateFiltersValue.typeofVehicle,
        updateFiltersValue.typeofLandEstimatedPrice,
        updateFiltersValue.monthlyIncome,
        updateFiltersValue.amountofSalary,
        updateFiltersValue.amountofLoan,
        updateFiltersValue.haveOtherDiseases,
        updateFiltersValue.Typeofdisease,
        updateFiltersValue.numberofChildren,
        updateFiltersValue.numberofStudent,
        updateFiltersValue.monthlyIncomeFamily,
        updateFiltersValue.PovertyRates,
        updateFiltersValue.HMCabilitToSurgery,
        updateFiltersValue.PMediaWork,
        updateFiltersValue.relationship,
        updateFiltersValue.Name,
        updateFiltersValue.resonOfDebt,
        updateFiltersValue.phoneNumber,
        updateFiltersValue.typeOfDeasise,
        updateFiltersValue.note,
        updateFiltersValue.mercy,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      )
    );
  };

  const toggleColumnVisibility = (columnName) => {
    if (hiddenColumns.includes(columnName)) {
      setHiddenColumns(hiddenColumns.filter((col) => col !== columnName));
    } else {
      setHiddenColumns([...hiddenColumns, columnName]);
    }
  };

  const handleOptionClick = (option) => {
    if (option == 1) {
      setSelectedOption("");

      // setIsOpen(false);
    } else {
      setSelectedOption(option);
      // setIsOpen(false);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setHiddenColumns([]);
    } else {
      setHiddenColumns(columns.map((column) => column.name));
    }
    setSelectAll(!selectAll);
  };
  const handleOpenFilter = (index) => {
    setIndexx(index);
    setIsOpenFilter(!isOpenFilter);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFilterValues({
      ...filterValues,
      [name]: value,
    });
  };
  useEffect(() => {
    // console.log(data)
  }, [data]);
  data?.map((element) => {
    const doctor = element?.commite.Doctor?.map((dr) => dr).join(", ");
    listData.push({
      code: element.commite.patients.code,
      Pname: element.commite.patients.Pname,
      phone: element.commite.patients.phone,
      gender: element.commite.patients.gender,
      birthDate: element.commite.patients.birthDate,
      occuption: element.commite.occuption,
      nation: element.commite.nation,
      country: element.commite.country,
      married: element.commite.married,
      noFamily: element.commite.noFamily,
      province: element.commite.province,
      district: element.commite.district,
      street: element.commite.street,
      closetHome: element.commite.closetHome,
      secoundHome: element.commite.secoundHome,
      card: element.commite.card,
      smoke: element.commite.smoke,
      timeAllote: element.commite.timeAllote,
      SITD: element.commite.SITD,
      member: element.commite.member,
      memberLevel: element.commite.memberLevel,
      TYP: element.commite.TYP,
      indiction: element.commite.indiction,

      HEP: element.commite.HEP,
      finalDiagnosis: element.commite.finalDiagnosis,
      earlyPatient: element.commite.earlyPatient,
      earlySurgery: element.commite.earlySurgery,
      Doctor: doctor,
      ZODoctor: element.commite.ZODoctor,
      medicalProvided: element.commite.medicalProvided,
      Typemedicalsupplies: element.commite.Typemedicalsupplies,
      member: element.commite.member,
      memberLevel: element.commite.memberLevel,
      supplyLocation: element.commite.supplyLocation,
      helpingZO: element.commite.helpingZO,
      literate: element.commite.literate,

      alive: element.alive,
      salaryEmploye: element.salaryEmploye,
      house: element.house,
      numberOfHouse: element.numberOfHouse,
      teanant: element.teanant,
      numberOfLand: element.numberOfLand,
      poverty: element.poverty,
      Vehicle: element.Vehicle,
      land: element.land,
      debt: element.debt,
      govOrPriveteEmp: element.govOrPriveteEmp,
      metters: element.metters,
      NeighborhoodTypeselect: element.NeighborhoodTypeselect,
      amountOfRent: element.amountOfRent,
      economicStatus: element.economicStatus,
      typeofVehicle: element.typeofVehicle,
      typeofLandEstimatedPrice: element.typeofLandEstimatedPrice,
      monthlyIncome: element.monthlyIncome,
      amountofSalary: element.amountofSalary,
      amountofLoan: element.amountofLoan,
      haveOtherDiseases: element.haveOtherDiseases,
      Typeofdisease: element.Typeofdisease,
      numberofChildren: element.numberofChildren,
      numberofStudent: element.numberofStudent,
      monthlyIncomeFamily: element.monthlyIncomeFamily,
      PovertyRates: element.PovertyRates,
      HMCabilitToSurgery: element.HMCabilitToSurgery,
      PMediaWork: element.PMediaWork,
      relationship: element.relationship,
      Name: element.Name,
      resonOfDebt: element.resonOfDebt,
      phoneNumber: element.phoneNumber,
      typeOfDeasise: element.typeOfDeasise,
      note: element.note,
      mercy: element.mercy,
    });
  });

  const getBranchs = useSelector((state) => state.getBranch);
  const { branchs } = getBranchs;

  return (
    <>
      {loading || data == undefined ? (
        <div className="flex flex-col">
          <img
            src={Logo}
            alt="Logo"
            className="w-56 m-auto animate-pulse mt-20 "
          />
          <div className="text-2xl text-center font-bold  ">
            <div className="flex justify-center gap-4">
              <p className="animate-charcter">Please Wait</p>
              <div class="container2">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>{" "}
            </div>
          </div>
        </div>
      ) : (
        <div className="parent">
          <div className="parent-head p-2">
            <div className="flex w-full gap-2">
              <div className="w-2/12">
                <select
                  name=""
                  className="inputUser text-black w-full"
                  value={selectedOption}
                  onChange={(e) => handleOptionClick(e.target.value)}
                  id=""
                >
                  <option value="">Select Branch</option>
                  {branchs
                    .filter((filter) => filter.id != 8)
                    .map((item) => (
                      <>
                        {item.bName == "Office" ? (
                          <option value={`${item.id}`}>All</option>
                        ) : (
                          <option value={`${item.id}`}>{item.bName}</option>
                        )}
                      </>
                    ))}
                </select>
              </div>

              <div className="flex w-full items-center gap-3 ">
                <div className="w-2/12">
                  <input
                    type="date"
                    onChange={(e) =>
                      setDateTme({
                        ...dateTime,
                        from: e.target.value,
                      })
                    }
                    value={dateTime.from}
                    className="inputUser text-black w-full mt-0"
                  />
                </div>
                <div>To</div>
                <div className="w-2/12">
                  <input
                    type="date"
                    onChange={(e) =>
                      setDateTme({
                        ...dateTime,
                        to: e.target.value,
                      })
                    }
                    value={dateTime.to}
                    className="inputUser text-black w-full mt-0"
                  />
                </div>
{/* 
                <div className=" w-32" title="Visits">
                  <select
                    name=""
                    onChange={(e) => setCount(e.target.value)}
                    value={count}
                    className="inputUser text-black w-full"
                    id=""
                  >
                    <option value="" selected>
                      All ...
                    </option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    
                  </select>
                </div> */}

                <div className=" w-32" title="Reject & Approve">
                  <select
                    name=""
                    onChange={(e) => setReject(e.target.value)}
                    value={reject}
                    className="inputUser text-black w-full"
                    id=""
                  >
                    <option value="" selected>
                      All ...
                    </option>
                    <option value="2">Approve</option>
                    <option value="1">Reject</option>

                    {/* <option value="11">11</option> */}
                  </select>
                </div>
              </div>
            </div>

            <div>
              <div className="flex space-x-4">
                <div
                  title="Show & hide Columns"
                  className="btn-primary px-2"
                  onClick={() => setIsopenColumns(!isOpenColumns)}
                >
                  <PiDotsThreeOutlineBold
                    onClick={() => setIsopenColumns(!isOpenColumns)}
                  />
                </div>

                <div
                  className="btn-danger px-2"
                  title="Clear Filter"
                  onClick={handleReset}
                >
                  <MdFilterAltOff />
                </div>

                <div
                  className={
                    isOpenColumns
                      ? "open-show-hide-column"
                      : "close-show-hide-column "
                  }
                >
                  <>
                    <div className="checkbox-wrapper-35 w-full pb-4">
                      <input
                        className="switch"
                        checked={selectAll}
                        onChange={handleSelectAll}
                        type="checkbox"
                        id="switch"
                        name="switch"
                        value="private"
                      />
                      <label htmlFor="switch">
                        <span className="switch-x-toggletext">
                          <span className="switch-x-unchecked">
                            <span className="switch-x-hiddenlabel">
                              Unchecked:{" "}
                            </span>
                            show
                          </span>
                          <span className="switch-x-checked">
                            <span className="switch-x-hiddenlabel">
                              Checked:{" "}
                            </span>
                            Hide All
                          </span>
                        </span>
                      </label>
                    </div>

                    <hr />
                  </>
                  <>
                    {columns.map((column, index) => (
                      <div class="checkbox-wrapper-42  ">
                        <input
                          id={`cbx-${index}`}
                          type="checkbox"
                          checked={!hiddenColumns.includes(column.name)}
                          onChange={() => toggleColumnVisibility(column.name)}
                        />
                        <label className="cbx" htmlFor={`cbx-${index}`}></label>
                        <label className="lbl" htmlFor={`cbx-${index}`}>
                          <span className="text-gray-700">{column.label}</span>
                        </label>
                      </div>
                    ))}
                  </>
                </div>
              </div>
            </div>
          </div>
          <div className="flex gap-4 items-center px-2">
            <div className="my-2">
              <label htmlFor="FromUpdate"></label>
              <input
                type="date"
                onChange={(e) => setFromUpdate(e.target.value)}
                className="inputUser text-black w-full"
                value={fromUpdate}
              />
            </div>
            <div className="my-2">
              <label htmlFor="toUpdate"></label>
              <input
                type="date"
                onChange={(e) => setToUpdate(e.target.value)}
                value={toUpdate}
                className="inputUser text-black w-full"
              />
            </div>

            <div className=" " title="نەشتەرگەری بۆ کراوە">
              <select
                name=""
                value={hadSurgery}
                onChange={(e) => {
                  setHadSurgery(e.target.value);
                }}
                className="inputUser text-black w-full"
                id=""
              >
                <option value={""} selected>
                  نەشتەرگەری بۆ کراوە
                </option>
                <option value="بەڵێ">بەڵێ</option>
                <option value="نەخێر">نەخێر</option>
              </select>
            </div>

            <div className="" title="پێداویستی پزیشکی">
              <select
                name=""
                value={medicalProvidedSearch}
                onChange={(e) => {
                  setMedicalProvidedSearch(e.target.value);
                }}
                className="inputUser text-black w-full"
                id=""
              >
                <option value={""} selected>
                  پێداویستی پزیشکی
                </option>
                <option value="بەڵێ">بەڵێ</option>
                <option value="نەخێر">نەخێر</option>
              </select>
            </div>
            <button
              className="btn-primary  flex gap-2 items-center"
              // onClick={handleDownload}
              onClick={handleSearch}
            >
              <p>Filter</p>
              <div>
                <CiFilter />
              </div>
            </button>
            <button
              className="btn-success  flex gap-2 items-center"
              onClick={() =>
                handleDownload(dateTime.from, dateTime.to, fromUpdate, toUpdate)
              }
            >
              <p>Export</p>
              <div>
                <PiMicrosoftExcelLogoDuotone />
              </div>
            </button>
          </div>
          <div
            className="parent-table "
            onClick={() => setIsopenColumns(false)}
          >
            <div className=" overflow-auto h-[98%]">
              <table>
                <thead className="sticky top-0 global-th  ">
                  <tr>
                    <th>#</th>
                    {columns.map(
                      (column, index) =>
                        !hiddenColumns.includes(column.name) && (
                          <th
                            key={index}
                            className="relative"
                            style={{
                              minWidth: "300px",
                              borderBottom: "solid 1px #e5e7eb",
                            }}
                          >
                            <div className="flex text-left justify-between items-center   ">
                              <p className="pl-1">{column.label}</p>
                              <div
                                className="globalFilterIcon"
                                onClick={() => handleOpenFilter(index)}
                              >
                                <CiFilter />
                              </div>
                            </div>
                            {isOpenFilter && index == indexx && (
                              <div className="globalParentInputSearch">
                                <div className="flex flex-col">
                                  <div>
                                    {column.label == "TYP" ? (
                                      <select
                                        value={filterValues[column.name]}
                                        name={column.name}
                                        onChange={handleInputChange}
                                        className="inputUser text-black w-full p-0 "
                                        id=""
                                      >
                                        <option value="" selected>
                                          زانیاریەکە هەڵبژێرە{" "}
                                        </option>
                                        {typeSurgery.map((type, index) => {
                                          return (
                                            <option
                                              value={type.type_name_en}
                                              key={index}
                                            >
                                              {type.type_name_en}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    ) : column.label == "Member" ? (
                                      <select
                                        value={filterValues[column.name]}
                                        name={column.name}
                                        onChange={handleInputChange}
                                        className="inputUser text-black w-full p-0 "
                                        id=""
                                      >
                                        <option value="" selected>
                                          تکایە زانیاریەکە هەڵبژێرە{" "}
                                        </option>
                                        {typeSurgery.map((type, index) => {
                                          return (
                                            <option
                                              value={type.typeName}
                                              key={index}
                                            >
                                              {type.typeName}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    ) : column.label ==
                                      "Disease Type Specialist" ? (
                                      <select
                                        name={column.name}
                                        value={filterValues[column.name]}
                                        onChange={handleInputChange}
                                        className="inputUser text-black w-full p-0 "
                                        id=""
                                      >
                                        <option value="" selected>
                                          زانیاریەکە هەڵبژێرە{" "}
                                        </option>
                                        {surgery.map((element, index) => {
                                          return (
                                            <option
                                              value={element.surguryName}
                                              key={index}
                                            >
                                              {element.surguryName}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    ) : column.label == "Member Level" ? (
                                      <select
                                        name={column.name}
                                        onChange={handleInputChange}
                                        className="inputUser text-black w-full p-0 "
                                        value={filterValues[column.name]}
                                        id=""
                                      >
                                        <option value="" selected>
                                          زانیاریەکە هەڵبژێرە{" "}
                                        </option>
                                        {organLevel.map((organ, index) => {
                                          return (
                                            <option
                                              value={organ.organ_level_name}
                                              key={index}
                                            >
                                              {organ.organ_level_name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    ) : column.label == "Doctor" ? (
                                      <select
                                        name={column.name}
                                        value={filterValues[column.name]}
                                        onChange={handleInputChange}
                                        className="inputUser text-black w-full p-0 "
                                        id=""
                                      >
                                        <option value="" selected>
                                          زانیاریەکە هەڵبژێرە{" "}
                                        </option>
                                        {doctors.map((doctor, index) => {
                                          return (
                                            <option
                                              value={doctor.dr_name}
                                              key={index}
                                            >
                                              {doctor.dr_name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    ) : column.label == "Gender" ? (
                                      <select
                                        name={column.name}
                                        value={filterValues[column.name]}
                                        onChange={handleInputChange}
                                        className="inputUser text-black w-full p-0 "
                                        id=""
                                      >
                                        <option value="" selected>
                                          زانیاریەکە هەڵبژێرە{" "}
                                        </option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                      </select>
                                    ) : (
                                      <input
                                        type="text"
                                        name={column.name}
                                        placeholder={`${column.label}`}
                                        onChange={handleInputChange}
                                        value={filterValues[column.name]}
                                        className={
                                          "inputUser text-black w-full p-1.5 rounded"
                                        }
                                      />
                                    )}
                                  </div>
                                </div>
                                <div className="footer-filter">
                                  <button
                                    className="btn-plus px-1.5"
                                    onClick={handleSearch}
                                  >
                                    <ImCheckmark />
                                  </button>
                                  <button
                                    className="btn-danger px-1.5"
                                    onClick={() =>
                                      handleClearSearch(column.name)
                                    }
                                  >
                                    <ImCross />
                                  </button>
                                </div>
                              </div>
                            )}
                          </th>
                        )
                    )}
                  </tr>
                </thead>
                <tbody>
                  {listData.map((item, index) => (
                    <tr key={index} className="global-tr py-2">
                      <td>{index + 1}</td>
                      {columns.map(
                        (column, index) =>
                          !hiddenColumns.includes(column.name) && (
                            <td
                              key={index}
                              className="global-td py-2"
                              // style={{
                              //   minWidth: "390px",
                              //   borderBottom: "solid 1px #e5e7eb",
                              // }}
                            >
                              <div className="flex gap-2">
                                {column.label == "Member Level"
                                  ? item[column.name].map((element) => {
                                      return (
                                        <p>
                                          {filterValues.memberLevel ==
                                          element ? (
                                            <p className="bg-yellow-300 bg-opacity-75 rounded-sm px-2 py-1 shadow-md">
                                              {element}
                                            </p>
                                          ) : (
                                            element
                                          )}
                                        </p>
                                      );
                                    })
                                  : item[column.name]}
                              </div>
                            </td>
                          )
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="gap-3 px-3 flex  mt-3 items-center justify-between m-auto py-3">
            <div className=" flex gap-4 items-center font-semibold">
              <div>
                <p className="text-xl ">Total Patient : </p>
              </div>

              <div className="bg-teal-600 px-2 py-0.5 text-white rounded-md shadow-md  font-bold ">
                <p> {total} </p>
              </div>

              {/* {hadSurgery ||
              medicalProvidedSearch ||
              reject ||
              count == null ? (
                <>
                  <div>
                    <p className="text-xl ">Total Visits : </p>
                  </div>
                  <div className="bg-blue-600 px-2 py-0.5 text-white rounded-md shadow-md  font-bold ">
                    <p> {totalVisit} </p>
                  </div>
                </>
              ) : (
                ""
              )} */}
            </div>
            <div className="flex items-center gap-3">
              <select
                // name=""
                className="select  w-20 p-0.5 "
                onChange={(e) => setPerPage(e.target.value)}
                value={perPage}
                id=""
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="50" selected>
                  50
                </option>
                <option value="100">100</option>
              </select>

              <ReactPaginate
                previousLabel="<"
                nextLabel={">"}
                forcePage={currentPage}
                // nextClassName='bg-blue-500  hover:bg-blue-600 text-white font-semibold px-2 py-2 rounded-md'
                breakLabel="..."
                className="flex  items-center   -space-x-px h-8 text-sm"
                previousClassName="flex items-center justify-center px-3 h-8 ml-0  border border-gray-300 rounded-l-lg "
                nextClassName="flex items-center justify-center px-3 h-8 leading-tight text-gray-500  border border-gray-300 rounded-r-lg "
                pageLinkClassName="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500  border border-gray-300  "
                breakClassName="w-8 py-2 text-center"
                pageCount={Math.ceil(total / perPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={(event) => {
                  let currentPage = event.selected + 1;
                  dispatch(
                    getStatisticsAction(
                      currentPage,
                      perPage,
                      selectedOption,
                      code,
                      Pname,
                      phone,
                      gender,
                      birthDate,
                      occuption,

                      nation,

                      country,
                      married,
                      noFamily,
                      province,
                      district,
                      street,
                      closetHome,
                      secoundHome,
                      card,
                      smoke,
                      timeAllote,
                      SITD,
                      member,
                      memberLevel,
                      TYP,
                      indiction,
                      finalDiagnosis,
                      earlyPatient,
                      earlySurgery,
                      Doctor,
                      ZODoctor,
                      medicalProvided,
                      Typemedicalsupplies,
                      supplyLocation,
                      helpingZO,
                      salaryEmploye,
                      date,
                      house,
                      numberOfHouse,
                      teanant,
                      numberOfLand,
                      poverty,
                      Vehicle,
                      land,
                      debt,
                      govOrPriveteEmp,
                      metters,
                      NeighborhoodTypeselect,
                      amountOfRent,
                      typeofVehicle,
                      typeofLandEstimatedPrice,
                      monthlyIncome,
                      amountofSalary,
                      amountofLoan,
                      haveOtherDiseases,
                      Typeofdisease,
                      numberofChildren,
                      numberofStudent,
                      monthlyIncomeFamily,
                      PovertyRates,
                      HMCabilitToSurgery,
                      PMediaWork,
                      relationship,
                      Name,
                      resonOfDebt,
                      phoneNumber,
                      typeOfDeasise,
                      note,
                      mercy,
                      dateTime.from,
                      dateTime.to,
                      count,
                      reject,
                      alive,
                      hadSurgery,
                      medicalProvidedSearch,
                      fromUpdate,
                      toUpdate
                    )
                  );

                  setCurrentPage(event.selected);
                }}
                activeLinkClassName="bg-black text-white flex  items-center  -space-x-px h-8 text-sm"
              />
            </div>
          </div>
        </div>
      )}
      <div className="bottom-space"></div>
    </>
  );
};

export default Statistics;
