import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSection } from "../../../Action/section";
import { getBranch } from "../../../Action/branch";
import {
  createPermissionPageAction,
  showPageAction,
  showPermissionPageAction,
} from "../../../Action/PageAction/PageAction";
import { useParams } from "react-router-dom";
import { showRolesAction } from "../../../Action/RolesAction/RolesAction";
import Logo from "../../../Image/Logo.jpg";
const EditRoles = () => {
  const { id } = useParams();
  const [branchId, setBrbranchId] = useState(null);
  // const [sectionId,setBrbranchId]=useState(null);
  const [permissionId, setPermissionId] = useState([]);
  const getSectionReducer = useSelector((state) => state.getSection);
  const { sections } = getSectionReducer;
  const getBranchReducer = useSelector((state) => state.getBranch);
  const { branchs } = getBranchReducer;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSection());
    dispatch(getBranch());
  }, []);
  const [sectionId, setSectionId] = useState(null);
  useEffect(() => {
    if (sectionId == null) {
      return;
    }
    dispatch(showPageAction(sectionId));
  }, [sectionId]);
  const showPage = useSelector((state) => state.showPage);
  const { pages } = showPage;
  const [pageId, setPageId] = useState([]);
  const handleInputChange = (index, e) => {
    const newPageId = [...pageId]; // Create a copy of the array
    const currentPageId = pages[index].id;

    if (e.target.checked) {
      // Add the page ID if checkbox is checked
      newPageId.push(currentPageId);
    } else {
      // Remove the page ID if checkbox is unchecked
      const indexToRemove = newPageId.indexOf(currentPageId);
      if (indexToRemove !== -1) {
        newPageId.splice(indexToRemove, 1);
      }
    }

    setPageId(newPageId);
  };

  const tempPageId = [];
  const handlesubmit = () => {
    if (pageId.length < 1 || (branchId == 8 && sectionId == 8)) {
      dispatch(
        createPermissionPageAction(id, [""], permissionId, branchId, sectionId)
      );
      return;
    }
    pageId.map((page) => {
      if (page) {
        tempPageId.push(page);
      }
    });

    dispatch(
      createPermissionPageAction(
        id,
        tempPageId,
        permissionId,
        branchId,
        sectionId
      )
    );
    dispatch(showPermissionPageAction(id));
    dispatch(showPermissionPageAction(id));
    // dispatch(showRolesAction(id));
    // dispatch(showRolesAction(id));
  };

  const showPermissionPage = useSelector((state) => state.showPermissionPage);
  const { permissions } = showPermissionPage;
  useEffect(() => {
    // console.log(permissions)
    dispatch(showPermissionPageAction(id));
  }, []);
  const showRoles = useSelector((state) => state.showRoles);
  const { roles, loading } = showRoles;
  useEffect(() => {
    dispatch(showRolesAction(id));
  }, []);

  useEffect(() => {
    if (permissions.length == 0) {
      setSectionId(null);
      setBrbranchId(null);
      return;
    } else if (pageId.length <= permissions.length) {
      setSectionId(permissions[0].section_id);
      setBrbranchId(permissions[0].branch_id);
    }
  }, [permissions]);

  useEffect(() => {
    const newValue = permissions.map((per) => per.page_id);
    const newValueId = permissions.map((per) => per.id);

    setPageId(newValue);
    console.log(pageId);
    setPermissionId(newValueId);
  }, [permissions]);
  return (
    <>
      {loading || roles == undefined || permissions == undefined ? (
        <div className="flex flex-col">
          <img
            src={Logo}
            alt="Logo"
            className="w-56 m-auto animate-pulse mt-20 "
          />
          <div className="text-2xl text-center font-bold  ">
            <div className="flex justify-center gap-4">
              <p className="animate-charcter">Please Wait</p>
              <div class="container2">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>{" "}
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="bg-white overflow-y-auto w-[95%] rounded-md py-2 px-3 shadow-md mx-auto">
            <div className="text-black text-lg font-bold">View Role</div>

            <div className="flex justify-between items-center gap-4">
              <label htmlFor="Role">Role Name *</label>
              <input
                type="text"
                className="inputLogin xl:w-8/12 rounded-sm"
                placeholder="Role Name"
                value={roles.name}
              />
            </div>

            <div className="flex justify-between items-center gap-10">
              <label htmlFor="Role">Section *</label>
              <select
                type="text"
                className="inputLogin xl:w-8/12 rounded-sm p-1"
                onChange={(e) => setSectionId(e.target.value)}
                value={sectionId}
              >
                <option value="0">Select Section ...</option>
                {sections.map((section, index) => {
                  return (
                    <option key={index} value={section.id}>
                      {section.sName}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="flex justify-between items-center gap-10">
              <label htmlFor="Role">Branch *</label>
              <select
                type="text"
                className="inputLogin xl:w-8/12 rounded-sm p-1"
                placeholder="Role Name"
                onChange={(e) => setBrbranchId(e.target.value)}
                value={branchId}
              >
                <option value="0">Select Branch ... </option>
                {branchs.map((branch, index) => {
                  return (
                    <option key={index} value={branch.id}>
                      {branch.bName}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="my-2 ">
              <p className="font-bold">Privileges:</p>
            </div>
            <hr />

            <div className="flex justify-between w-4/12 mt-2">
              <div>
                <p>Profiles</p>
              </div>
              <div className="checkbox-wrapper-34">
                <input className="tgl tgl-ios" id="toggle-34" type="checkbox" />
                <label className="tgl-btn" htmlFor="toggle-34"></label>
              </div>
            </div>
            <>
              {sectionId == null ? (
                ""
              ) : (
                <>
                  {pages.map((page, index) => {
                    return (
                      <>
                        <div
                          className="flex w-4/12 justify-between mt-2"
                          key={index}
                        >
                          <div>
                            <p>{page.page}</p>
                          </div>
                          <div
                            className={
                              pageId[index] == 1
                                ? "checkbox-wrapper-34"
                                : "checkbox-wrapper-34"
                            }
                          >
                            <input
                              onChange={(e) => handleInputChange(index, e)}
                              value={page.id}
                              className="tgl tgl-ios"
                              id={`toggle-${index + 1}`}
                              checked={pageId.includes(page.id)}
                              type="checkbox"
                            />

                            <label
                              className="tgl-btn"
                              htmlFor={`toggle-${index + 1}`}
                            ></label>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </>
              )}
            </>

            <div className="w-full   flex justify-end">
              <button onClick={handlesubmit} className="btnViolet">
                Submit
              </button>
            </div>
          </div>
          <div className="pb-32"></div>
        </>
      )}
    </>
  );
};

export default EditRoles;
