import {
  CREATE_SENT_FILES_ADMINSTRATOR_FAIL,
  CREATE_SENT_FILES_ADMINSTRATOR_REQUEST,
  CREATE_SENT_FILES_ADMINSTRATOR_SUCCESS,
  DELETE_SENT_FILES_ADMINSTRATOR_FAIL,
  DELETE_SENT_FILES_ADMINSTRATOR_REQUEST,
  DELETE_SENT_FILES_ADMINSTRATOR_SUCCESS,
  FETCH_SENT_FILES_ADMINSTRATOR_FAIL,
  FETCH_SENT_FILES_ADMINSTRATOR_REQUEST,
  FETCH_SENT_FILES_ADMINSTRATOR_SUCCESS,
  UPDATE_SENT_FILES_ADMINSTRATOR_FAIL,
  UPDATE_SENT_FILES_ADMINSTRATOR_REQUEST,
  UPDATE_SENT_FILES_ADMINSTRATOR_SUCCESS,
} from "../../ActionType/AdminstratorType/SentFilesType";

export const createSentFilesAdminstratorReducer = (
  state = { loading: true, admins: [], error: "" },
  action
) => {
  switch (action.type) {
    case CREATE_SENT_FILES_ADMINSTRATOR_REQUEST:
      return {
        loading: true,
      };
    case CREATE_SENT_FILES_ADMINSTRATOR_SUCCESS:
      return {
        loading: false,
        admins: action.payload,
        error: "",
      };
    case CREATE_SENT_FILES_ADMINSTRATOR_FAIL:
      return {
        loading: false,
        admins: [],
        error: "",
      };
    default:
      return state;
  }
};

export const getSentFilesAdminstratorReducer = (
  state = { loading: true, datas: [], total: "", error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_SENT_FILES_ADMINSTRATOR_REQUEST:
      return {
        loading: true,
      };
    case FETCH_SENT_FILES_ADMINSTRATOR_SUCCESS:
      return {
        loading: false,
        datas: action.payload.data,
        total: action.payload.total,
        error: "",
      };
    case FETCH_SENT_FILES_ADMINSTRATOR_FAIL:
      return {
        loading: false,
        datas: [],
        error: "",
      };
    default:
      return state;
  }
};

export const deleteSentFilesAdminstratorReducer = (
  state = { loading: true, admins: [], error: "" },
  action
) => {
  switch (action.type) {
    case DELETE_SENT_FILES_ADMINSTRATOR_REQUEST:
      return {
        loading: true,
      };
    case DELETE_SENT_FILES_ADMINSTRATOR_SUCCESS:
      return {
        loading: false,
        admins: action.payload,
        error: "",
      };
    case DELETE_SENT_FILES_ADMINSTRATOR_FAIL:
      return {
        loading: false,
        admins: [],
        error: "",
      };
    default:
      return state;
  }
};

export const updateSentFilesAdminstratorReducer = (
  state = { loading: true, admins: [], error: "" },
  action
) => {
  switch (action.type) {
    case UPDATE_SENT_FILES_ADMINSTRATOR_REQUEST:
      return {
        loading: true,
      };
    case UPDATE_SENT_FILES_ADMINSTRATOR_SUCCESS:
      return {
        loading: false,
        admins: action.payload,
        error: "",
      };
    case UPDATE_SENT_FILES_ADMINSTRATOR_FAIL:
      return {
        loading: false,
        admins: [],
        error: "",
      };
    default:
      return state;
  }
};
