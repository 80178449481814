export const CREATE_ROLE_REQUEST = "CREATE_ROLE_REQUEST";
export const CREATE_ROLE_SUCCESS = "CREATE_ROLE_SUCCESS";
export const CREATE_ROLE_FAIL = "CREATE_ROLE_FAIL";

export const FETCH_ROLE_REQUEST = "FETCH_ROLE_REQUEST";
export const FETCH_ROLE_SUCCESS = "FETCH_ROLE_SUCCESS";
export const FETCH_ROLE_FAIL = "FETCH_ROLE_FAIL";

export const SHOW_ROLE_REQUEST = "SHOW_ROLE_REQUEST";
export const SHOW_ROLE_SUCCESS = "SHOW_ROLE_SUCCESS";
export const SHOW_ROLE_FAIL = "SHOW_ROLE_FAIL";
