import React, {  useState } from "react";
import { useDispatch } from "react-redux";
import {
  getAttendanceAction,
  updateAttendanceAction,
} from "../../Action/AttendanceAction/AttendanceAction";

const AttendanceApprovedModal = ({
  isOpen,
  onClose,
  selectedTime,
  id,
  check,
  approveCheckIn,
}) => {
  const [time, setTime] = useState(
    approveCheckIn ? approveCheckIn : selectedTime
  );
  const dispatch = useDispatch();
  const handleSubmit = () => {
    dispatch(updateAttendanceAction(id, check, time));
    dispatch(getAttendanceAction(20, 1, "", "", "", "", "", "", "", "", []));
    dispatch(getAttendanceAction(20, 1, "", "", "", "", "", "", "", "", []));
    onClose(false);
  };


  

  return (
    <div
      className={`fixed ${
        isOpen ? "block" : "hidden"
      } inset-0 w-full h-full z-50 overflow-hidden`}
    >
      <div className="flex items-center justify-center min-h-screen p-4">
        <div className="modal-overlay absolute w-full h-full bg-gray-800 opacity-50"></div>
        <div className="modal-container bg-white  mx-auto rounded shadow-lg z-50 overflow-y-auto">
          <div className="modal-content py-4 text-left px-6">
            <h1 className="text-2xl font-bold mb-4">Time Attendance</h1>
            <p className="text-gray-600 font-bold mb-4">
              Actual Time:{" "}
              {selectedTime ? selectedTime.toString().split(" ")[1] : ""}
            </p>
            <div>
              <input
                type="datetime-local"
                className="inputUser w-full mb-4"
                value={time || ""}
                onChange={(e) => setTime(e.target.value)}
              />
            </div>
            <div className="flex justify-end gap-2">
              <button
                className="btn-plus"
                onClick={handleSubmit}
              >
                Submit
              </button>
              <button className="btn-danger" onClick={() => onClose(false)}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttendanceApprovedModal;
