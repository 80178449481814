export const CREATE_FEEDBACK_REQUEST="CREATE_FEEDBACK_REQUEST";
export const CREATE_FEEDBACK_SUCCESS="CREATE_FEEDBACK_SUCCESS";
export const CREATE_FEEDBACK_FAIL="CREATE_FEEDBACK_FAIL";


export const FETCH_FEEDBACK_REQUEST="FETCH_FEEDBACK_REQUEST";
export const FETCH_FEEDBACK_SUCCESS="FETCH_FEEDBACK_SUCCESS";
export const FETCH_FEEDBACK_FAIL="FETCH_FEEDBACK_FAIL";

export const CREATE_SAVE_FEEDBACK_REQUEST="CREATE_SAVE_FEEDBACK_REQUEST";
export const CREATE_SAVE_FEEDBACK_SUCCESS="CREATE_SAVE_FEEDBACK_SUCCESS";
export const CREATE_SAVE_FEEDBACK_FAIL="CREATE_SAVE_FEEDBACK_FAIL";


export const FETCH_EDIT_FEEDBACK_REQUEST="FETCH_EDIT_FEEDBACK_REQUEST";
export const FETCH_EDIT_FEEDBACK_SUCCESS="FETCH_EDIT_FEEDBACK_SUCCESS";
export const FETCH_EDIT_FEEDBACK_FAIL="FETCH_EDIT_FEEDBACK_FAIL";

export const UPDATE_FEEDBACK_REQUEST="UPDATE_FEEDBACK_REQUEST";
export const UPDATE_FEEDBACK_SUCCESS="UPDATE_FEEDBACK_SUCCESS";
export const UPDATE_FEEDBACK_FAIL="UPDATE_FEEDBACK_FAIL";

export const FETCH_FEEDBACK_IMAGE_REQUEST="FETCH_FEEDBACK_IMAGE_REQUEST";
export const FETCH_FEEDBACK_IMAGE_SUCCESS="FETCH_FEEDBACK_IMAGE_SUCCESS";
export const FETCH_FEEDBACK_IMAGE_FAIL="FETCH_FEEDBACK_IMAGE_FAIL";