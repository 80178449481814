export const CREATE_ADMINSTRATOR_REQUEST = "CREATE_ADMINSTRATOR_REQUEST";
export const CREATE_ADMINSTRATOR_SUCCESS = "CREATE_ADMINSTRATOR_SUCCESS";
export const CREATE_ADMINSTRATOR_FAIL = "CREATE_ADMINSTRATOR_FAIL";

export const FETCH_ADMINSTRATOR_REQUEST = "FETCH_ADMINSTRATOR_REQUEST";
export const FETCH_ADMINSTRATOR_SUCCESS = "FETCH_ADMINSTRATOR_SUCCESS";
export const FETCH_ADMINSTRATOR_FAIL = "FETCH_ADMINSTRATOR_FAIL";

export const DELETE_ADMINSTRATOR_REQUEST = "DELETE_ADMINSTRATOR_REQUEST";
export const DELETE_ADMINSTRATOR_SUCCESS = "DELETE_ADMINSTRATOR_SUCCESS";
export const DELETE_ADMINSTRATOR_FAIL = "DELETE_ADMINSTRATOR_FAIL";

export const UPDATE_ADMINSTRATOR_REQUEST = "UPDATE_ADMINSTRATOR_REQUEST";
export const UPDATE_ADMINSTRATOR_SUCCESS = "UPDATE_ADMINSTRATOR_SUCCESS";
export const UPDATE_ADMINSTRATOR_FAIL = "UPDATE_ADMINSTRATOR_FAIL";