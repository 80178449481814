import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCommiteWithVisitAction } from "../../Action/commiteAction";
import { useNavigate, useParams } from "react-router-dom";
import Logo from "../../Image/Logo.jpg";
import ProfileVisits from "./Profile/ProfileVisits";
import Visit from "./EachVisit/Visit";
const TabVisitCommite = () => {
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState(0);
  const getCommiteWithVisit = useSelector((state) => state.getCommiteWithVisit);
  const { patients, loading } = getCommiteWithVisit;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCommiteWithVisitAction(id));
  }, [id]);
  const tabs = [
    {
      label: "Profile", // Label for the first tab
      content: <ProfileVisits patients={patients} patientId={id} />, // Include ProfileVisits
    },
    ...patients.flatMap((patient, patientIndex) =>
      patient.visit.map((visit, visitIndex) => ({
        label: `${visit.created_at.toString().slice(0, 10)}`,
        content: (
          <div key={`${patientIndex}-${visitIndex}`}>
            <Visit
              patient={patients}
              activeTab={setActiveTab}
              visit_id={visit.id}
            />
          </div>
        ),
      }))
    ),
  ];

  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  return (
    <>
      {loading ? (
        <div className="flex flex-col">
          <img
            src={Logo}
            alt="Logo"
            className="w-56 m-auto animate-pulse mt-20 "
          />
          <div className="text-2xl text-center font-bold  ">
            <div className="flex justify-center gap-4">
              <p className="animate-charcter">Please Wait</p>
              <div class="container2">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>{" "}
            </div>
          </div>
        </div>
      ) : (
        <div className=" mx-auto p-4">
          <div className="border-b border-gray-300">
            <ul className="flex  ">
              <li
                onClick={() => navigate(-1)}
                className="bg-gradient-to-tr mr-3 cursor-pointer rounded-md  py-2 px-4  from-blue-500 to-purple-500 text-white"
              >
                Back
              </li>
              {tabs.map((tab, index) => (
                <li
                  key={index}
                  onClick={() => handleTabClick(index)}
                  className={`cursor-pointer py-2 px-4 ${
                    activeTab === index
                      ? "bg-gradient-to-tr from-blue-600 to-purple-500 text-white"
                      : "bg-white text-gray-700"
                  }  hover:from-purple-500 hover:to-blue-600 bg-gradient-to-tr transition duration-300 ease-in-out`}
                >
                  {tab.label}
                </li>
              ))}
            </ul>

            <div className="mt-4">
              {tabs[activeTab] && tabs[activeTab].content}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TabVisitCommite;
