import Swal from "sweetalert2";
import {
  CREATE_ROLE_FAIL,
  CREATE_ROLE_REQUEST,
  CREATE_ROLE_SUCCESS,
  FETCH_ROLE_FAIL,
  FETCH_ROLE_REQUEST,
  FETCH_ROLE_SUCCESS,
  SHOW_ROLE_FAIL,
  SHOW_ROLE_REQUEST,
  SHOW_ROLE_SUCCESS,
} from "../../ActionType/RolesType/RolesType";
import { api } from "../../Api/api";
import { getToken } from "../login";

export const createRolesAction = (name) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
  dispatch({
    type: CREATE_ROLE_REQUEST,
  });
  api
    .post("role/create", { name }, config)
    .then((response) => {
      const { status, data } = response;
      if (status === 200) {
        Swal.fire({
          icon: "success",
          timer: 1500,
          title: "سەرکەوتوو بوو",
          text: "زانیاریەکە داخل کرا",
        });
        dispatch({
          type: CREATE_ROLE_SUCCESS,
          payload: data,
        });
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: CREATE_ROLE_FAIL });
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Request Is Faild",
      });
    });
};

export const getRolesAction = (page, perPage) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    params: {
      page,
      perPage,
    },
  };
 
  api
    .get("role", config)
    .then((response) => {
        dispatch({
            type: FETCH_ROLE_REQUEST,
          });
      const { status, data } = response;
      if (status === 200) {
        dispatch({
          type: FETCH_ROLE_SUCCESS,
          payload: {data:data.data,total:data.total},
        });
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: FETCH_ROLE_FAIL });
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Request Is Faild",
      });
    });
};

export const showRolesAction = (id) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
  dispatch({
    type: SHOW_ROLE_REQUEST,
  });
  api
    .get(`role/show/${id}`, config)
    .then((response) => {
        dispatch({
            type: SHOW_ROLE_REQUEST,
          });
      const { status, data } = response;
      
      if (status === 200) {
        dispatch({
          type: SHOW_ROLE_SUCCESS,
          payload: data,
        });
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: SHOW_ROLE_FAIL });
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Request Is Faild",
      });
    });
};
