import { useDebugValue, useEffect, useState } from "react";
import { FaSquarePhoneFlip } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { RiLockPasswordFill, RiLockPasswordLine } from "react-icons/ri";

import LoginImg from "../../Image/login.png";
import { useDispatch, useSelector } from "react-redux";
import { getUserAuth, updateUserAction } from "../../Action/userRegister";
import Swal from "sweetalert2";

function EditProfile() {
  const getUsers = useSelector((state) => state.getUser);
  const { users, loading } = getUsers;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserAuth());
  }, []);
  const [error, setError] = useState({
    phone: "",
  });
  const [data, setData] = useState({
    id: "",
    fullname: "",
    email: "",
    phone: "",
    password: "",
    confirmPass: "",
  });

  const { password, confirmPass } = data;
  useEffect(() => {
    setData({
      ...data,
      id: users.id,
      fullname: users.fullname,
      email: users.email,
      phone: users.phone,
    });
  }, [loading]);
  const [styleInput, setStyleInput] = useState(false);
  useEffect(() => {
    if (confirmPass.length > 0) {
      if (password != confirmPass) {
        setError({
          ...error,
          phone: "Your Password Is Not Correct",
        });
      } else {
        setError({
          ...error,
          phone: "",
        });
      }
    }
  }, [password, confirmPass]);
  const handelInputChange = (event) => {
    const { name, value } = event.target;
    console.log(value);
    setData({
      ...data,
      [name]: value,
    });
  };
  const handleChangeUser = (event) => {
    event.preventDefault();
    const { password, confirmPass } = data;

    if (password != confirmPass) {
      setError({
        ...error,
        phone: "Your Password is Not correct",
      });
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        title: "Some thing Went Wrong",
      });
      // dispatch(updateUserAction(data.id, password));
      return;
    }
    dispatch(updateUserAction(data.id, password));
  };

  return (
    <div className=" w-[96%] lg:w-10/12 xl:w-7/12 shadow-lg rounded-md flex bg-white px-4 mt-10 py-5  m-auto">
      <img src={LoginImg} className="w-[60%] hidden md:block" alt="" />
      <form className=" mt-10  w-full   rounded ">
        <div></div>
        <div className="parentInputEditUser">
          <div className="flex -mr-px">
            <span className="iconInput">@</span>
          </div>
          <input
            type="text"
            className="inputUser"
            value={data.fullname}
            onChange={handelInputChange}
            placeholder="Username"
            name="fullname"
          />
        </div>

        <div className="parentInputEditUser">
          <div className="flex -mr-px">
            <span className="iconInput">
              <MdEmail />
            </span>
          </div>
          <input
            type="text"
            className="inputUser"
            value={data.email}
            onChange={handelInputChange}
            placeholder="Email"
            name="email"
          />
        </div>

        <div className="parentInputEditUser">
          <div className="flex -mr-px">
            <span className="iconInput">
              <FaSquarePhoneFlip classNameName="rotate-90" />
            </span>
          </div>
          <input
            type="text"
            className="inputUser"
            value={data.phone}
            onChange={handelInputChange}
            name="phone"
            placeholder="Phone Number"
          />
        </div>

        <div className="parentInputEditUser">
          <div className="flex -mr-px">
            <span className="iconInput">
              <RiLockPasswordLine />
            </span>
          </div>
          <input
            type="password"
            className="inputUser"
            placeholder=" Password"
            name="password"
            onBlur={handelInputChange}
          />
        </div>

        <div className="parentInputEditUser">
          <div className="flex -mr-px">
            <span className="iconInput">
              <RiLockPasswordFill />
            </span>
          </div>
          <input
            type="password"
            className="inputUser"
            placeholder="Confirm Password"
            name="confirmPass"
            onBlur={handelInputChange}
          />
        </div>
        <p className="text-red-400">{error.phone}</p>

        <div className="flex items-end justify-end">
          <button
            className="bg-indigo-500 duration-500 hover:bg-indigo-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="button"
            onClick={handleChangeUser}
          >
            Save Change
          </button>
        </div>
      </form>
    </div>
  );
}
export default EditProfile;
