import React, { useState } from "react";
import { FaUserDoctor } from "react-icons/fa6";
import { useDispatch } from "react-redux";
import { createDoctorAction, getDoctorAction } from "../../Action/Doctor/doctorAction";
const DoctorModal = ({ isOpenDoctorModal, setIsOpenDoctorModal }) => {
  const dispatch = useDispatch();
  const [error,setError]=useState(null);
  const [name, setName] = useState(null);
  const handleSave = (event) => {
    event.preventDefault();
    if(name!=null){
    dispatch(createDoctorAction(name));
    setIsOpenDoctorModal(false)
    setName("")
    dispatch(getDoctorAction());
    dispatch(getDoctorAction());
    }else {
      setError("Please Fill The Gaps")
      setIsOpenDoctorModal(true)
    }
  };
  return (
    <div
      className={
        isOpenDoctorModal
          ? "h-screen fixed w-full bg-gray-400 duration-500 opacity-100 bg-opacity-20 top-0 left-0 z-50  "
          : "h-screen fixed w-full bg-gray-400 -z-50 opacity-0 duration-500 bg-opacity-20 top-0 left-0 "
      }
    >
      <div
        className="   h-screen  w-full p-20  relative cursor-pointer  z-20"
        onClick={() => setIsOpenDoctorModal(false)}
      />
      <div className="bg-white w-11/12 xl:w-5/12 py-3 px-2 top-[35%] left-4 xl:left-[27%]  -translate-y-[70%] -translate-X-[90%] absolute rounded-md shadow-lg z-50 ">
        <div className="flex justify-between">
          <div className="flex items-center gap-4">
            <p className="text-xl text-teal-600">
              {" "}
              <FaUserDoctor />
            </p>
            <p>Doctors</p>
          </div>
          <div
            className="text-2xl hover:backdrop-blur-xl text-red-500  cursor-pointer"
            onClick={() => setIsOpenDoctorModal(false)}
          >
            x
          </div>
        </div>
        <hr />
        <form action="" className="w-full">
          <div className=" w-full flex flex-col mt-4 pb-4">
            <div>
              <label htmlFor="dr" className="ml-1 ">
                Doctor Name *
              </label>
            </div>
            <input
              onChange={(e) => setName(e.target.value)}
              type="text"
              id="dr"
              className="inputLogin "
              placeholder="Doctor Name"
            />
            
              <p className="text-red-500 ml-4 mt-1">{error}</p>
            
          </div>

          <hr />
          <div className="flex gap-3 mt-2 ml-1">
          <input
            type="submit"
            value="Save"
            onClick={handleSave}
            className="btnCoustmDesignGreen"
          />
          <input
            type="submit"
            value="Cancel"
            className="btnCoustmDesignRed"
            onClick={(e) => { 
              e.preventDefault();
              setIsOpenDoctorModal(false)
            }}
          />
          </div>
        </form>
      </div>
    </div>
  );
};

export default DoctorModal;
