import { FETCH_ALL_TYPE_SURGERY_FAIL } from "../../ActionType/SurgeryType/surgery";
import {
  CREATE_TYPE_MEDICAL_FAIL,
  CREATE_TYPE_MEDICAL_REQUEST,
  CREATE_TYPE_MEDICAL_SUCCESS,
  FETCH_EXPORT_EXCEL_TYPE_MEDICAL_FAIL,
  FETCH_EXPORT_EXCEL_TYPE_MEDICAL_REQUEST,
  FETCH_EXPORT_EXCEL_TYPE_MEDICAL_SUCCESS,
  FETCH_TYPE_IMAGE_MEDICAL_FAIL,
  FETCH_TYPE_IMAGE_MEDICAL_REQUEST,
  FETCH_TYPE_IMAGE_MEDICAL_SUCCESS,
  FETCH_TYPE_MEDICAL_BY_VISIT_ID_FAIL,
  FETCH_TYPE_MEDICAL_BY_VISIT_ID_REQUEST,
  FETCH_TYPE_MEDICAL_BY_VISIT_ID_SUCCESS,
  FETCH_TYPE_MEDICAL_REQUEST,
  FETCH_TYPE_MEDICAL_SUCCESS,
} from "../../ActionType/TypeOfMedical/typemedical";

export const createTypeMedicalReducer = (
  state = { loading: true, data: [], error: "" },
  action
) => {
  switch (action.type) {
    case CREATE_TYPE_MEDICAL_REQUEST:
      return {
        loading: true,
      };
    case CREATE_TYPE_MEDICAL_SUCCESS:
      return {
        loading: false,
        data: action.payload,
        error: "",
      };
    case CREATE_TYPE_MEDICAL_FAIL:
      return {
        loading: false,
        data: [],
        error: "Pleae Check Your Request",
      };
    default:
      return state;
  }
};

export const getTypeMedicalReducer = (
  state = { loading: true, data: [], total: 0, error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_TYPE_MEDICAL_REQUEST:
      return {
        loading: true,
      };
    case FETCH_TYPE_MEDICAL_SUCCESS:
      console.log(action.payload);
      return {
        loading: false,
        data: action.payload.data,
        total: action.payload.total,
        error: "",
      };
    case FETCH_ALL_TYPE_SURGERY_FAIL:
      return {
        loading: false,
        data: [],
        error: "Pleae Check Your Request",
      };
    default:
      return state;
  }
};

export const getImageTypeMedicalReducer = (
  state = { loading: true, data: [], total: 0, error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_TYPE_IMAGE_MEDICAL_REQUEST:
      return {
        loading: true,
      };
    case FETCH_TYPE_IMAGE_MEDICAL_SUCCESS:
      return {
        loading: false,
        data: action.payload,
        // total: action.payload.total,
        error: "",
      };
    case FETCH_TYPE_IMAGE_MEDICAL_FAIL:
      return {
        loading: false,
        data: [],
        error: "Pleae Check Your Request",
      };
    default:
      return state;
  }
};

export const getTypeMedicalExcelReducer = (
  state = { loading: true, excelData: [], error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_EXPORT_EXCEL_TYPE_MEDICAL_REQUEST:
      return {
        loading: true,
      };
    case FETCH_EXPORT_EXCEL_TYPE_MEDICAL_SUCCESS:
      return {
        loading: false,
        excelData: action.payload,
        
        error: "",
      };
    case FETCH_EXPORT_EXCEL_TYPE_MEDICAL_FAIL:
      return {
        loading: false,
        excelData: [],
        error: "Pleae Check Your Request",
      };
    default:
      return state;
  }
};


export const getTypeMedicalByVisitIdReducer = (
  state = { loading: true, typeMedicals: [], error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_TYPE_MEDICAL_BY_VISIT_ID_REQUEST:
      return {
        loading: true,
      };
    case FETCH_TYPE_MEDICAL_BY_VISIT_ID_SUCCESS:
      return {
        loading: false,
        typeMedicals: action.payload,
        error: "",
      };
    case FETCH_TYPE_MEDICAL_BY_VISIT_ID_FAIL:
      return {
        loading: false,
        typeMedicals: [],
        error: "Pleae Check Your Request",
      };
    default:
      return state;
  }
};