import React, { useEffect, useState } from "react";
import ComeBack from "./ComeBackFromDoctor/ComeBack";
import { useDispatch, useSelector } from "react-redux";
import { getBranch } from "../../Action/branch";
import { editFeedbackAction } from "../../Action/lezhnaAction";
import SurgeryWahtsApp from "./TypeOfHelp/SurgeryWahtsApp";
import TypeMedical from "./TypeOfMedical/TypeMedical";
import SendMedia from "./MediaForm/SendMedia";
// import { getSection } from "../../Action/section";

import Logo from "../../Image/Logo.jpg";
import { getReciveAction } from "../../Action/receiveAction";

const SendModalAllBranch = ({ lezhna_id, closeModal }) => {
  const [selected, setSelected] = useState(null);
  const [locationId, setLocationId] = useState(null);
  const dispatch = useDispatch();

  const [lezhnaId, setLezhnaId] = useState(null);
  const getBranchs = useSelector((state) => state.getBranch);
  const getReceive = useSelector((state) => state.getReceive);
  const { receive, total, loading } = getReceive;
  // useEffect(() => {
  //   dispatch(getReciveAction(locationId, "", "", "", "", "", lezhnaId));
  // }, [lezhnaId]);
  const { branchs } = getBranchs;
  const [visitId, setVisitId] = useState(null);
  const editFeedback = useSelector((state) => state.editFeedback);
  const { data, loader } = editFeedback;
  const [selectedOpts, setSelectedOpts] = useState([]);
  // const toggleOption = (value) => {
  //   if (selectedOpts.includes(value)) {
  //     setSelectedOpts(selectedOpts.filter((opt) => opt !== value));
  //   } else {
  //     setSelectedOpts([...selectedOpts, value]);
  //   }
  // };

  const [patientId, setPatientId] = useState(null);
  const [sectionId, setSectionId] = useState(null);
  const [opens, setOpens] = useState(false);
  const type_of_help = [];
  const typeOfHelpArray = ["نەشتەرگەری", "پێداویستی پزیشکی"];
  useEffect(() => {
    dispatch(getBranch());
    // dispatch(getSection());
  }, []);
  const getSections = useSelector((state) => state.getSection);
  // const { sections } = getSections;

  useEffect(() => {
    if (visitId == -1 || visitId == null) {
      return;
    }

    data.map((item) => {
      item.commites.map((commite) => {
        dispatch(editFeedbackAction(lezhna_id, visitId));
        commite.lezhnas
          .filter((filter) => filter.visit_id == visitId)
          .map((lezhna) => {
            setLezhnaId(lezhna.id);
          });
      });
    });
  }, [visitId]);
  useEffect(() => {
    if (selected == "come_back_from_doctor") {
      dispatch(getReciveAction(locationId, "", "", "", "", "", lezhnaId));
      return;
    } else if (selected == "media" || data == undefined) {
      return;
    } else {
      dispatch(editFeedbackAction(lezhna_id, visitId));
      if (data == undefined) {
        // console.log("undefined");
        return;
      }
      data.map((item) => {
        item.commites
          .filter((filter) => filter.visit_id == visitId)
          .map((type) => {
            setPatientId(type.PatientId);
            type.type_of_help.map((type) => {
              type_of_help.push(type);
              setSelectedOpts([...selectedOpts, ...type_of_help]);
            });
          });
      });
    }
  }, [selected, visitId]);

  return (
    <>
      {loader || data == undefined ? (
        <div className="absolute top-10 left-[42%] flex flex-col">
          <img
            src={Logo}
            alt="Logo"
            className="w-56 m-auto animate-pulse mt-20 "
          />
          <div className="text-2xl text-center font-bold  ">
            <div className="flex justify-center gap-4">
              <p className="animate-charcter">Please Wait</p>
              <div class="container2">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>{" "}
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-gray-400 overflow-y-auto pb-20 z-50 bg-opacity-40 w-full h-screen fixed top-0 left-0">
          <div
            className="fixed w-full h-screen top-0 left-0 -z-20 bg-slate-900 bg-opacity-40 "
            onClick={() => {
              closeModal(false);
            }}
          />
          <div className="bg-white w-11/12 m-auto mt-4 rounded-md shadow-md z-50 px-2 py-3">
            <div className="flex justify-between ">
              <div
                className="text-xl text-red-400 cursor-pointer"
                onClick={() => {
                  setSelectedOpts([]);
                  closeModal(false);
                }}
              >
                <p>x</p>
              </div>
              <div
                className={
                  visitId == -1 || visitId == null
                    ? "w-2/12 flex justify-between"
                    : "flex w-10/12 gap-3"
                }
              >
                <div className="w-full">
                  <select
                    name=""
                    className="select"
                    value={visitId}
                    onChange={(e) => {
                      if (visitId == -1) {
                        setSelectedOpts([]);
                      } else {
                        setSelectedOpts([]);
                        setVisitId(e.target.value);
                      }
                    }}
                    id=""
                  >
                    <option value="-1" selected disabled>
                      Select Visit..
                    </option>
                    {data.map((data, index) => {
                      return data.visit
                        .filter((filter) => filter.status == 1)
                        .map((visit, index) => {
                          return (
                            <>
                              <option key={index} value={visit.id}>
                                {visit.created_at.toString().slice(0, 10)}
                              </option>
                            </>
                          );
                        });
                    })}
                  </select>
                </div>
                {visitId == null || visitId == -1 ? (
                  ""
                ) : (
                  <>
                    <div className="w-7/12">
                      <select
                        name=""
                        value={selected}
                        onChange={(e) => {
                          setSelectedOpts([]);
                          setSelected(e.target.value);
                        }}
                        className="select"
                        id=""
                      >
                        <option value="-1" selected disabled>
                          .... ناردنی زانیاری
                        </option>
                        <option value="come_back_from_doctor">
                          گەڕانەوە لای دکتۆر
                        </option>
                        <option value="type_of_help"> جۆری هاوکاری</option>
                        <option value="media"> ڕاگەیاندن</option>
                      </select>
                    </div>
                    <div className="w-7/12">
                      <select
                        name=""
                        onChange={(e) => {
                          setLocationId(e.target.value);
                        }}
                        className="select"
                        id=""
                      >
                        <option value="-1" selected disabled>
                          Select Branch...
                        </option>
                        {branchs.map((branch, index) => {
                          return (
                            <option key={index} value={branch.id}>
                              {branch.bName}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </>
                )}

                {type_of_help.map((type, index) => {
                  return <div key={index}>{type.value}</div>;
                })}

                {visitId == null || visitId == -1 ? (
                  ""
                ) : (
                  <div className="relative  text-right w-full">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setOpens(!opens);
                      }} // Updated variable name
                      className="  border-2 shadow-md rounded-md bg-white  py-2 w-full px-4 text-left"
                    >
                      {selectedOpts.length > 0
                        ? selectedOpts.join(" , ")
                        : "Select options..."}
                    </button>

                    {opens && (
                      <div className="absolute z-40 bg-white w-full rounded-md shadow-lg  border border-gray-300 mt-2">
                        {typeOfHelpArray.map((opt) => (
                          <label key={opt} className="flex items-center  py-2">
                            {/* <input
                              type="checkbox"
                              value={opt}
                              checked={selectedOpts.includes(opt)}
                              onChange={() => {
                                toggleOption(opt);
                                // setTypeSurgeryId(opt.value);
                              }}
                              className="mr-2"
                            /> */}
                            {opt}
                          </label>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            {selected == "come_back_from_doctor" ? (
              <ComeBack
                receive={receive}
                lezhna_id={lezhnaId}
                location_id={locationId}
              />
            ) : selected == "type_of_help" ? (
              selectedOpts.map((item, index) => {
                return (
                  <div key={index}>
                    {item == "نەشتەرگەری" ? (
                      <SurgeryWahtsApp
                        location_id={locationId}
                        visit_id={visitId}
                        data={data}
                      />
                    ) : item == "پێداویستی پزیشکی" ? (
                      <TypeMedical
                        location_id={locationId}
                        visit_id={visitId}
                        data={data}
                        options={selectedOpts}
                        patientId={patientId}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                );
              })
            ) : selected == "media" ? (
              <SendMedia
                sectionId={sectionId}
                data={data}
                locationId={locationId}
                lezhna_id={lezhnaId}
                visitId={visitId}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default SendModalAllBranch;
