export const FETCH_PAGE_REQUEST = "FETCH_PAGE_REQUEST";
export const FETCH_PAGE_SUCCESS = "FETCH_PAGE_SUCCESS";
export const FETCH_PAGE_FAIL = "FETCH_PAGE_FAIL";

export const SHOW_PAGE_REQUEST = "SHOW_PAGE_REQUEST";
export const SHOW_PAGE_SUCCESS = "SHOW_PAGE_SUCCESS";
export const SHOW_PAGE_FAIL = "SHOW_PAGE_FAIL";

export const CREATE_PERMISSION_PAGE_REQUEST = "CREATE_PERMISSION_PAGE_REQUEST";
export const CREATE_PERMISSION_PAGE_SUCCESS = "CREATE_PERMISSION_PAGE_SUCCESS";
export const CREATE_PERMISSION_PAGE_FAIL = "CREATE_PERMISSION_PAGE_FAIL";

export const SHOW_PERMISSION_PAGE_REQUEST = "SHOW_PERMISSION_PAGE_REQUEST";
export const SHOW_PERMISSION_PAGE_SUCCESS = "SHOW_PERMISSION_PAGE_SUCCESS";
export const SHOW_PERMISSION_PAGE_FAIL = "SHOW_PERMISSION_PAGE_FAIL";
