import {
  REJECT_PATIENT_FAIL,
  REJECT_PATIENT_REQUEST,
  REJECT_PATIENT_SUCCESS,
} from "../ActionType/patientType";
import {
  DISAPPROVED_REJECT_PATIENT_FAIL,
  DISAPPROVED_REJECT_PATIENT_REQUEST,
  DISAPPROVED_REJECT_PATIENT_SUCCESS,
  FETCH_PATIENT_VISIT_FAIL,
  FETCH_PATIENT_VISIT_REQUEST,
  FETCH_PATIENT_VISIT_SUCCESS,
  FETCH_REJECT_PATIENT_FAIL,
  FETCH_REJECT_PATIENT_REQUEST,
  FETCH_REJECT_PATIENT_SUCCESS,
  REJECT_PATIENT_WITH_VISIT_FAIL,
  REJECT_PATIENT_WITH_VISIT_REQUEST,
  REJECT_PATIENT_WITH_VISIT_SUCCESS,
  SHOW_REJECT_PATIENT_VISIT_FAIL,
  SHOW_REJECT_PATIENT_VISIT_REQUEST,
  SHOW_REJECT_PATIENT_VISIT_SUCCESS,
} from "../ActionType/rejectType";

export const getRejectPatientReducer = (
  state = { loading: true, rejectPatient: [], total: 0, error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_REJECT_PATIENT_REQUEST:
      return {
        loading: true,
      };
    case FETCH_REJECT_PATIENT_SUCCESS:
      return {
        loading: false,
        rejectPatient: action.payload,
        error: "",
        total: action.total,
      };
    case FETCH_REJECT_PATIENT_FAIL:
      return {
        loading: false,
        rejectPatient: [],
        error: "Please Check Your Request",
      };
    default:
      return state;
  }
};

export const rejectPatientReducer = (
  state = { loading: true, reject: [], error: "" },
  action
) => {
  switch (action.type) {
    case REJECT_PATIENT_REQUEST:
      return {
        loading: true,
      };
    case REJECT_PATIENT_SUCCESS:
      return {
        loading: false,
        reject: action.payload,
        error: "",
      };
    case REJECT_PATIENT_FAIL:
      return {
        loading: false,
        reject: [],
        error: "Please Check Your Request",
      };
    default:
      return state;
  }
};
export const rejectPatientDisapprovedReducer = (
  state = { loading: true, reject: [], error: "" },
  action
) => {
  switch (action.type) {
    case DISAPPROVED_REJECT_PATIENT_REQUEST:
      return {
        loading: true,
      };
    case DISAPPROVED_REJECT_PATIENT_SUCCESS:
      return {
        loading: false,
        reject: action.payload,
        error: "",
      };
    case DISAPPROVED_REJECT_PATIENT_FAIL:
      return {
        loading: false,
        reject: [],
        error: "Please Check Your Request",
      };
    default:
      return state;
  }
};

export const rejectPatientWithVisitReducer = (
  state = { loading: true, reject: [], error: "" },
  action
) => {
  switch (action.type) {
    case REJECT_PATIENT_WITH_VISIT_REQUEST:
      return {
        loading: true,
      };
    case REJECT_PATIENT_WITH_VISIT_SUCCESS:
      return {
        loading: false,
        reject: action.payload,
        error: "",
      };
    case REJECT_PATIENT_WITH_VISIT_FAIL:
      return {
        loading: false,
        reject: [],
        error: "Please Check Your Request",
      };
    default:
      return state;
  }
};

export const rejectPatientVisitReducer = (
  state = { loading: true, rejects: [], error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_PATIENT_VISIT_REQUEST:
      return {
        loading: true,
      };
    case FETCH_PATIENT_VISIT_SUCCESS:
      return {
        loading: false,
        rejects: action.payload,
        error: "",
      };
    case FETCH_PATIENT_VISIT_FAIL:
      return {
        loading: false,
        rejects: [],
        error: "Please Check Your Request",
      };
    default:
      return state;
  }
};


export const showRejectPatientVisitReducer = (
  state = { loading: true, datas: [], error: "" },
  action
) => {
  switch (action.type) {
    case SHOW_REJECT_PATIENT_VISIT_REQUEST:
      return {
        loading: true,
      };
    case SHOW_REJECT_PATIENT_VISIT_SUCCESS:
      return {
        loading: false,
        datas: action.payload,
        error: "",
      };
    case SHOW_REJECT_PATIENT_VISIT_FAIL:
      return {
        loading: false,
        datas: [],
        error: "Please Check Your Request",
      };
    default:
      return state;
  }
};
