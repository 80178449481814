import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showReportMonthlyAction } from "../../Action/patient";
import ReactPaginate from "react-paginate";
import Logo from "../../Image/Logo.jpg";
import { FaFilter } from "react-icons/fa6";
import { getDoctorAction } from "../../Action/Doctor/doctorAction";
import {
  getAllTypeSurgeryAction,
  getOrganLevelAction,
} from "../../Action/SurgeryTypeAction/surgery";
const ReportMonthly = () => {
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const dispatch = useDispatch();
  const [select, setSelect] = useState(0);
  const showReportMonthly = useSelector((state) => state.showReportMonthly);
  const { report, loading, total } = showReportMonthly;
  const location_id = localStorage.getItem("locationId");
  const [currentPage, setCurrentPage] = useState(1);
  const getAllTypeSurgery = useSelector((state) => state.getAllTypeSurgery);
  const getOrganLevel = useSelector((state) => state.getOrganLevel);
  const { organLevel } = getOrganLevel;
  const { typeSurgery } = getAllTypeSurgery;
  const getDoctor = useSelector((state) => state.getDoctor);
  const { doctors } = getDoctor;
  useEffect(() => {
    dispatch(getOrganLevelAction(0));
    dispatch(getAllTypeSurgeryAction());
  }, []);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  };
  const [inputValue, setInputValue] = useState({
    from: "",
    to: "",
    code: "",
    fullname: "",
    phone: "",
    gender: "",
    age: "",
    doctor: "",
    organ: "",
    organLevel: "",
    type_of_help: "",
    type: "",
  });
  const [perPage, setPerPage] = useState(10);
  const { code, fullname, phone, gender, age, doctor, organ } = inputValue;
  useEffect(() => {
    dispatch(getDoctorAction());
  }, []);
  useEffect(() => {
    dispatch(
      showReportMonthlyAction(
        location_id,
        currentPage,
        perPage,
        inputValue.from,
        inputValue.to,
        select,
        code,
        fullname,
        phone,
        gender,
        age,
        doctor,
        organ,
        inputValue.organLevel,
        inputValue.type_of_help,
        inputValue.type
      )
    );
  }, [
    perPage,
    inputValue.from,
    inputValue.to,
    select,
    currentPage,
    code,
    fullname,
    phone,
    gender,
    age,
    doctor,
    organ,
    inputValue.organLevel,
    inputValue.type_of_help,
    inputValue.type,
  ]);

  return (
    <>
      {loading ? (
        <div className="flex flex-col">
          <img
            src={Logo}
            alt="Logo"
            className="w-56 m-auto animate-pulse mt-20 "
          />
          <div className="text-2xl text-center font-bold  ">
            <div className="flex justify-center gap-4">
              <p className="animate-charcter">Please Wait</p>
              <div class="container2">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>{" "}
            </div>
          </div>
        </div>
      ) : (
        <div className="relative bg-white pb-5 md:h-[18rem] xl:h-[30em]  3xl:h-[43rem]   w-[98%] m-auto  sm:rounded-lg  mt-4">
          <div className="bg-gray-200 pb-2 flex items-center px-4">
            <div className="">
              <div className="flex gap-2 items-center">
                <div className="flex flex-col">
                  <input
                    type="date"
                    onChange={(e) =>
                      setInputValue({
                        ...inputValue,
                        from: e.target.value,
                      })
                    }
                    value={inputValue.from}
                    id="from"
                    className="inputLogin"
                  />
                </div>
                <div>To</div>
                <div className="flex flex-col">
                  <input
                    type="date"
                    value={inputValue.to}
                    onChange={(e) =>
                      setInputValue({
                        ...inputValue,
                        to: e.target.value,
                      })
                    }
                    id="to"
                    className="inputLogin"
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-2   w-11/12 justify-end ">
              <div className="xl:w-1/12 ">
                <select
                  name=""
                  onChange={(e) => setSelect(e.target.value)}
                  value={select}
                  className="select w-full bg-white"
                  id=""
                >
                  <option value="">All</option>
                  <option value="2">Approve</option>
                  <option value="1">Reject</option>
                </select>
              </div>
            </div>
          </div>
          <div className="overflow-y-auto md:h-[63%] xl:h-[80%] rounded-md shadow-md bg-white">
            <table className="table w-[150%] overflow-x-auto">
              <thead>
                <tr>
                  <th>
                    <div
                      className="flex justify-between items-center"
                      onClick={() => setIsOpenFilter(!isOpenFilter)}
                    >
                      <p>Patient ID</p>
                      <div className="bg-teal-500 text-white  p-2 text-lg rounded-md shadow-md ">
                        <FaFilter />
                      </div>
                    </div>
                    {isOpenFilter && (
                      <div>
                        <input
                          type="text"
                          placeholder="Code"
                          name="code"
                          onBlur={handleInputChange}
                          className="inputLogin p-1.5 shadow-xl rounded  "
                        />
                      </div>
                    )}
                  </th>
                  <th>
                    <div
                      className="flex justify-between items-center"
                      onClick={() => setIsOpenFilter(!isOpenFilter)}
                    >
                      <p>Full Name</p>
                      <div className="bg-teal-500 text-white  p-2 text-lg rounded-md shadow-md ">
                        <FaFilter />
                      </div>
                    </div>
                    {isOpenFilter && (
                      <div>
                        <input
                          type="text"
                          placeholder="Full Name"
                          name="fullname"
                          onBlur={handleInputChange}
                          className="inputLogin p-1.5 shadow-xl rounded  "
                        />
                      </div>
                    )}
                  </th>
                  <th>
                    <div
                      className="flex justify-between items-center"
                      onClick={() => setIsOpenFilter(!isOpenFilter)}
                    >
                      <p>Phone</p>
                      <div className="bg-teal-500 text-white  p-2 text-lg rounded-md shadow-md ">
                        <FaFilter />
                      </div>
                    </div>
                    {isOpenFilter && (
                      <div>
                        <input
                          type="text"
                          placeholder="Phone"
                          name="phone"
                          onBlur={handleInputChange}
                          className="inputLogin p-1.5 shadow-xl rounded  "
                        />
                      </div>
                    )}
                  </th>
                  <th>
                    <div
                      className="flex justify-between items-center"
                      onClick={() => setIsOpenFilter(!isOpenFilter)}
                    >
                      <p>Gender</p>
                      <div className="bg-teal-500 text-white  p-2 text-lg rounded-md shadow-md ">
                        <FaFilter />
                      </div>
                    </div>
                    {isOpenFilter && (
                      <div>
                        <input
                          type="text"
                          placeholder="Gender"
                          name="gender"
                          onBlur={handleInputChange}
                          className="inputLogin p-1.5 shadow-xl rounded  "
                        />
                      </div>
                    )}
                  </th>
                  <th>
                    <div
                      className="flex justify-between items-center"
                      onClick={() => setIsOpenFilter(!isOpenFilter)}
                    >
                      <p>Birth Date</p>
                      <div className="bg-teal-500 text-white  p-2 text-lg rounded-md shadow-md ">
                        <FaFilter />
                      </div>
                    </div>
                    {isOpenFilter && (
                      <div>
                        <input
                          type="text"
                          placeholder="Birth Date"
                          name="age"
                          onBlur={handleInputChange}
                          className="inputLogin p-1.5 shadow-xl rounded  "
                        />
                      </div>
                    )}
                  </th>
                  <th>
                    <div
                      className="flex justify-between items-center"
                      onClick={() => setIsOpenFilter(!isOpenFilter)}
                    >
                      <p>Doctor</p>
                      <div className="bg-teal-500 text-white  p-2 text-lg rounded-md shadow-md ">
                        <FaFilter />
                      </div>
                    </div>
                    {isOpenFilter && (
                      <div>
                        <select
                          placeholder="Doctor"
                          name="doctor"
                          onChange={handleInputChange}
                          className="select mt-2 p-0"
                        >
                          <option value="" selected>
                            ناوی دکتۆر هەڵبژێرە
                          </option>
                          {doctors.map((element, index) => {
                            return (
                              <option value={element.dr_name} key={index}>
                                {element.dr_name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    )}
                  </th>
                  <th>
                    <div
                      className="flex justify-between items-center"
                      onClick={() => setIsOpenFilter(!isOpenFilter)}
                    >
                      <p>Organ</p>
                      <div className="bg-teal-500 text-white  p-2 text-lg rounded-md shadow-md ">
                        <FaFilter />
                      </div>
                    </div>
                    {isOpenFilter && (
                      <div>
                        <select
                          name="organ"
                          onChange={handleInputChange}
                          className="select mt-2 p-0"
                          id=""
                        >
                          <option value="" selected>
                            تکایە زانیاریەکە هەڵبژێرە
                          </option>
                          {typeSurgery.map((type, index) => {
                            return (
                              <option key={index} value={type.typeName}>
                                {type.typeName}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    )}
                  </th>
                  <th>
                    <div
                      className="flex justify-between items-center"
                      onClick={() => setIsOpenFilter(!isOpenFilter)}
                    >
                      <p>Organ Level</p>
                      <div className="bg-teal-500 text-white  p-2 text-lg rounded-md shadow-md ">
                        <FaFilter />
                      </div>
                    </div>
                    {isOpenFilter && (
                      <div>
                        <select
                          name="organLevel"
                          className="select mt-2 p-0"
                          onChange={handleInputChange}
                          // id=""
                        >
                          <option value="">تکایە زانیاریەکە هەڵبژێرە</option>
                          {organLevel.map((organ, index) => {
                            return (
                              <option
                                key={index}
                                value={organ.organ_level_name}
                              >
                                {" "}
                                {organ.organ_level_name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    )}
                  </th>

                  <th>
                    <div
                      className="flex justify-between items-center"
                      onClick={() => setIsOpenFilter(!isOpenFilter)}
                    >
                      <p>Type Of Help</p>
                      <div className="bg-teal-500 text-white  p-2 text-lg rounded-md shadow-md ">
                        <FaFilter />
                      </div>
                    </div>
                    {isOpenFilter && (
                      <div>
                        <select
                          name="type_of_help"
                          className="select mt-2 p-0"
                          onChange={handleInputChange}
                          // id=""
                        >
                          <option value="" selected>
                            تکایە زانیاریەکە هەڵبژێرە
                          </option>
                          <option value="نەشتەرگەری">نەشتەرگەری</option>
                          <option value="پێداویستی پزیشکی">
                            پێداویستی پزیشکی
                          </option>
                          <option value="دەرمان">دەرمان</option>
                          <option value="تیشک">تیشک</option>
                          <option value="دەرمان">دەرمان</option>
                          <option value="پشکنینی خوێن">پشکنینی خوێن</option>
                          <option value="غەسلی پلازما">غەسلی پلازما</option>
                          <option value="غەسلی گورچیلە">غەسلی گورچیلە</option>
                          <option value="مانەوە لە نەخۆشخانە">
                            مانەوە لە نەخۆشخانە
                          </option>
                        </select>
                      </div>
                    )}
                  </th>
                  <th>
                    <div
                      className="flex justify-between items-center"
                      onClick={() => setIsOpenFilter(!isOpenFilter)}
                    >
                      <p>Type</p>
                      <div className="bg-teal-500 text-white  p-2 text-lg rounded-md shadow-md ">
                        <FaFilter />
                      </div>
                    </div>
                    {isOpenFilter && (
                      <div>
                        <input
                          type="text"
                          placeholder="Type"
                          name="type"
                          onBlur={handleInputChange}
                          className="inputLogin p-1.5 shadow-xl rounded  "
                        />
                      </div>
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>
                {report.map((data, index) => {
                  return data.commites.map((commite, indexCommite) => {
                    return (
                      <tr className="tableRow" key={indexCommite}>
                        <td>{data.code}</td>
                        <td>{data.Pname}</td>
                        <td>{data.phone}</td>
                        <td>{data.gender}</td>
                        <td>{data.birthDate}</td>
                        <td>{commite.Doctor}</td>
                        <td>{commite.member}</td>

                        <td>{commite.memberLevel}</td>

                        <td>
                          {commite.type_of_help.map((type) => {
                            return <p>{type}</p>;
                          })}
                        </td>

                        <td>{commite.Typemedicalsupplies}</td>
                      </tr>
                    );
                  });
                })}
              </tbody>
            </table>
          </div>

          <div className="flex justify-between mt-2 px-4 items-center gap-4">
            <div className="flex  items-center gap-3  text-xl">
              <p className=" ">All:</p>
              <p className=" bg-teal-400 text-white font-semibold shadow-md text-sm  rounded-md p-0.5 px-3">
                {total}
              </p>
            </div>

            <div className="flex gap-2 items-center">
              <div>
                <select
                  name=""
                  className="mt-1  bg-white border w-20 focus:ring-2  focus:ring-slate-900 rounded-md   focus:outline-none"
                  onChange={(e) => setPerPage(e.target.value)}
                  value={perPage}
                  id=""
                >
                  <option value="10" selected>
                    10
                  </option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>

              <div className="flex justify-center   mt-1">
                <ReactPaginate
                  previousLabel="<"
                  nextLabel={">"}
                  // nextClassName='bg-blue-500  hover:bg-blue-600 text-white font-semibold px-2 py-2 rounded-md'
                  breakLabel="..."
                  className="flex  items-center   -space-x-px h-8 text-sm"
                  previousClassName="flex items-center justify-center px-3 h-8 ml-0  border border-gray-300 rounded-l-lg "
                  nextClassName="flex items-center justify-center px-3 h-8 leading-tight text-gray-500  border border-gray-300 rounded-r-lg "
                  pageLinkClassName="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500  border border-gray-300  "
                  breakClassName="w-8 py-2 text-center"
                  pageCount={Math.ceil(total / perPage)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={(event) => {
                    let currentPage = event.selected + 1;
                    dispatch(
                      showReportMonthlyAction(
                        location_id,
                        currentPage,
                        perPage,
                        inputValue.from,
                        inputValue.to,
                        select
                      )
                    );

                    setCurrentPage(currentPage);
                  }}
                  activeLinkClassName="bg-black text-white flex  items-center  -space-x-px h-8 text-sm"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ReportMonthly;
