import React, { useEffect, useState } from "react";
import { FaUserGear } from "react-icons/fa6";

import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { HiDocumentArrowUp } from "react-icons/hi2";
import Logo from "../../../Image/Logo.jpg";
import ReactPaginate from "react-paginate";
import { FaCheck, FaTrash } from "react-icons/fa";
import { MdSend } from "react-icons/md";

import AdminstratorModal from "../../../Modal/AdminstratorModal/AdminstratorModal";
import {

  getSentFileAdminstratorAction,
 
} from "../../../Action/AdminstratorAction/SentFilesAction";
import TransferSentFileAdminstratorModal from "../../../Modal/AdminstratorModal/TransferSentFileAdminstratorModal";

import { CiFilter } from "react-icons/ci";
import { RxCross1, RxUpdate } from "react-icons/rx";
import UpdateSentFileModal from "../../../Modal/AdminstratorModal/SentFileModal/UpdateSentFileModal";
import DeleteModal from "../../../Modal/DeleteModal/DeleteModal";
const SentFile = () => {
  const [isOpenAdminstrator, setIsOpenAdminstrator] = useState(false);
  const [isOpenUpdateModal, setIsOpenUpdateModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const getSentFilesAdminstrator = useSelector(
    (state) => state.getSentFilesAdminstrator
  );
  const [id, setId] = useState(null);
  const locationId = localStorage.getItem("locationId");
  const [fileId, setFileId] = useState(null);
  const { datas, loading, total } = getSentFilesAdminstrator;
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const [searchParams, setSearchParams] = useState({
    location: "",
    month: "",
    number: "",
    from: "",
    to: "",
    document: "",
  });

  const [idOfFilter, setIdOfFilter] = useState(null);
  const [isOpenFilter, setIsOpenFilter] = useState(false);

  useEffect(() => {
    dispatch(
      getSentFileAdminstratorAction(
        searchParams.from,
        searchParams.to,
        currentPage,
        perPage,
        searchParams.location,
        searchParams.month,
        searchParams.number,
        searchParams.document,
        locationId
      )
    );
  }, [perPage]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSearchParams({ ...searchParams, [name]: value });
  };

  const hadnleSearch = () => {
    dispatch(
      getSentFileAdminstratorAction(
        searchParams.from,
        searchParams.to,
        currentPage,
        perPage,
        searchParams.location,
        searchParams.month,
        searchParams.number,
        searchParams.document,
        locationId
      )
    );
    setIdOfFilter(null);
    setIsOpenFilter(false);
  };

  const handleClearSearch = (key) => {
    const updateParams = { ...searchParams };
    updateParams[key] = "";
    setSearchParams((prev) => {
      const clearParams = { ...prev };
      clearParams[key] = "";
      return clearParams;
    });
    dispatch(
      getSentFileAdminstratorAction(
        searchParams.from,
        searchParams.to,
        currentPage,
        perPage,
        updateParams.location,
        updateParams.month,
        updateParams.number,
        updateParams.document,
        locationId
      )
    );

    setIdOfFilter(null);
    setIsOpenFilter(false);
  };

  const [isOpenTransferSentFileModal, setIsOpenTransferSentFileModal] =
    useState(false);

  const th = [
    {
      id: 1,
      label: "Location",
      value: "location",
    },
    {
      id: 2,
      label: "Number",
      value: "number",
    },
    {
      id: 3,
      label: "Date",
      value: "month",
    },
    {
      id: 4,
      label: "Documents",
      value: "document",
    },
  ];

  return (
    <>
      {loading || datas == undefined ? (
        <div className="flex flex-col">
          <img
            src={Logo}
            alt="Logo"
            className="w-56 m-auto animate-pulse mt-20 "
          />
          <div className="text-2xl text-center font-bold  ">
            <div className="flex justify-center gap-4">
              <p className="animate-charcter">Please Wait</p>
              <div class="container2">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>{" "}
            </div>
          </div>
        </div>
      ) : (
        <div className="  w-[95%]  m-auto">

          <div className="flex gap-2 items-center">
            <div>
              <input
                type="date"
                name="from"
                onChange={handleInputChange}
                value={searchParams.from}
                className="inputUser w-full"
              />
            </div>
            <p>To</p>
            <div>
              <input
                type="date"
                name="to"
                value={searchParams.to}
                onChange={handleInputChange}
                className="w-full inputUser"
              />
            </div>
            <div>
              <button className="btn-plus" onClick={hadnleSearch}>
                <CiFilter />
              </button>
            </div>
          </div>
          <div className="bg-white  w-full mt-2">
            <div className="parent-head items-center py-2 w-full px-3">
              <div className="flex gap-2">
                <p className="text-blue-500 text-xl">
                  <FaUserGear />
                </p>
                <div>
                  <p className="font-bold">Administrator</p>
                </div>
              </div>

              <div>
                <button
                  onClick={() => setIsOpenAdminstrator(true)}
                  className="btn-primary"
                >
                  Administrator
                </button>
              </div>
            </div>
            <div className="parent-table w-full ">
              <table className="table w-full">
                <thead className="global-th sticky top-0">
                  <tr>
                    <th className="text-left global-th">#</th>
                    {th.map((th, index) => {
                      return (
                        <th key={index} className="relative">
                          <div className="flex justify-between gap-2 items-center">
                            <div>{th.label}</div>
                            <div
                              className="globalFilterIcon"
                              onClick={() => {
                                setIdOfFilter(th.id);
                                setIsOpenFilter(!isOpenFilter);
                              }}
                            >
                              <p>
                                <CiFilter />
                              </p>
                            </div>
                          </div>
                          {isOpenFilter && idOfFilter == th.id && (
                            <div className="absolute top-7 w-full bg-white/30 rounded-sm backdrop-blur-3xl p-2">
                              {th.id == 3 ? (
                                <input
                                  type="date"
                                  className="inputUser w-full text-black"
                                  onChange={handleInputChange}
                                  name={th.value}
                                  value={searchParams[th.value]}
                                  placeholder={th.label}
                                />
                              ) : (
                                <input
                                  type="text"
                                  className="inputUser w-full text-black"
                                  onChange={handleInputChange}
                                  name={th.value}
                                  value={searchParams[th.value]}
                                  placeholder={th.label}
                                />
                              )}

                              <div className="flex gap-2 mt-2">
                                <button
                                  className="btn-primary p-2"
                                  onClick={hadnleSearch}
                                >
                                  <FaCheck />
                                </button>
                                <button
                                  className="btn-danger p-2"
                                  onClick={() => handleClearSearch(th.value)}
                                >
                                  {" "}
                                  <RxCross1 />
                                </button>
                              </div>
                            </div>
                          )}
                        </th>
                      );
                    })}
                    <th className="text-left w-[20%]">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {datas.map((data, index) => {
                    return (
                      <tr key={index} className="tableRow global-td">
                        <td className="global-td py-2">{index + 1}</td>
                        <td className="global-td">{data.location}</td>
                        <td className="global-td">{data.number}</td>
                        <td className="global-td">{data.date}</td>
                        <td className="text-xl  text-blue-500">
                          {
                            <Link
                              to={`${data.document}`}
                              target="_blank"
                              className="flex items-center gap-2"
                            >
                              <p>{data.name_file}</p>
                              <HiDocumentArrowUp />{" "}
                            </Link>
                          }
                        </td>

                        <td className="global-td">
                          <div className="flex gap-2 items-center justify-center">
                            <button
                              className="btn-primary"
                              onClick={() => {
                                setIsOpenUpdateModal(true);
                                setId(data.id);
                              }}
                            >
                              <RxUpdate />
                            </button>

                            <button
                              className="btn-danger"
                              onClick={() => {
                                setIsOpenDeleteModal(true);
                                setId(data.id);
                              }}
                            >
                              <FaTrash />
                            </button>

                            <button
                              title="Send"
                              onClick={() => {
                                setFileId(data.id);

                                setIsOpenTransferSentFileModal(true);
                              }}
                              className="btn-plus"
                            >
                              <MdSend />
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {isOpenAdminstrator && (
              <AdminstratorModal closeModal={setIsOpenAdminstrator} />
            )}
            {isOpenTransferSentFileModal && (
              <TransferSentFileAdminstratorModal
                id={fileId}
                closeModal={setIsOpenTransferSentFileModal}
              />
            )}
            {isOpenUpdateModal && (
              <UpdateSentFileModal
                id={id}
                closeModal={() => setIsOpenUpdateModal(false)}
              />
            )}
            {isOpenDeleteModal && (
              <DeleteModal
                id={id}
                closeModal={() => setIsOpenDeleteModal(false)}
              />
            )}
            <div className="gap-3 px-3 flex justify-between items-center  pt-2  ">
              <div className="flex items-center gap-4 ">
                <p className="text-xl ">Total: </p>
                <div
                  className={
                    "bg-teal-600 px-4 py-0.5 text-white rounded-md shadow-md "
                  }
                >
                  {total}
                </div>
              </div>
              <div className="flex items-center gap-4">
                <div>
                  <select
                    name=""
                    className="mt-1  bg-white border w-20 focus:ring-2  focus:ring-slate-900 rounded-md   focus:outline-none"
                    onChange={(e) => setPerPage(e.target.value)}
                    value={perPage}
                    id=""
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>

                <div className="flex justify-center   mt-1">
                  <ReactPaginate
                    previousLabel="<"
                    nextLabel={">"}
                    // nextClassName='bg-blue-500  hover:bg-blue-600 text-white font-semibold px-2 py-2 rounded-md'
                    breakLabel="..."
                    className="flex  items-center   -space-x-px h-8 text-sm"
                    previousClassName="flex items-center justify-center px-3 h-8 ml-0  border border-gray-300 rounded-l-lg "
                    nextClassName="flex items-center justify-center px-3 h-8 leading-tight text-gray-500  border border-gray-300 rounded-r-lg "
                    pageLinkClassName="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500  border border-gray-300  "
                    breakClassName="w-8 py-2 text-center"
                    pageCount={Math.ceil(total / perPage)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    forcePage={currentPage}
                    onPageChange={(event) => {
                      let currentPage = event.selected + 1;
                      dispatch(
                        getSentFileAdminstratorAction(
                          searchParams.from,
                          searchParams.to,
                          currentPage,
                          perPage,
                          searchParams.location,
                          searchParams.month,
                          searchParams.number,
                          searchParams.document,
                          locationId
                        )
                      );
                      setCurrentPage(event.selected);
                    }}
                    activeLinkClassName="bg-black text-white flex  items-center  -space-x-px h-8 text-sm"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="bottom-space"></div>
    </>
  );
};

export default SentFile;
