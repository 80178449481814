import axios from "axios";
const AppURL="https://zadorg.site/app/api/zad_system/"
export const api = axios.create({
  // baseURL: "http://127.0.0.1:8000/api/zad_system/",
  baseURL:AppURL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
