import React, { useEffect, useState } from "react";
import "../../Pages/Global.css";
import { useDispatch } from "react-redux";
import {
  getAttendanceAction,
  showAttendanceAction,
  updateAttendanceAction,
} from "../../Action/AttendanceAction/AttendanceAction";
const ExtraHourModal = ({ closeModal, id, extraHour,idShowAttendance }) => {
  const [hour, setHour] = useState("");
  const [minute, setMinute] = useState("");
  // const [time,setTime]=useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    if(!extraHour){
      return
    }
    setHour(extraHour ? extraHour.toString().split(":")[0] : "");
    setMinute(extraHour ? extraHour.toString().split(":")[1] : "");
  }, [extraHour]);
  const handleHourChange = (e) => {
    const input = e.target.value;
    // Enforce two-digit limit
    if (input.length == 1 && /^\d*$/.test(input)) {
      
      const firstInput=`0${input}`;
      setHour(firstInput);
      
    }
   else if (input.length <= 2 && /^\d*$/.test(input)) {
      setHour(input);
    }

    
  };

  const handleMinuteChange = (e) => {
    const inputMinute =
      e.target.value !== "" ? e.target.value.padStart(2, "0") : "";
    setMinute(inputMinute.slice(0, 2)); // Ensure only two digits are accepted
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Combine hour and minute to form the time
    const selectedTime = `${hour !== "" ? hour : "00"}:${
      minute !== "" ? minute : "00"
    }`;

    console.log(selectedTime)
    
    dispatch(updateAttendanceAction(id, "time", selectedTime));
    if(idShowAttendance){
      dispatch(showAttendanceAction(idShowAttendance, 100, 1, "", ""));
      dispatch(showAttendanceAction(idShowAttendance, 100, 1, "", ""));
    }
    dispatch(getAttendanceAction(100, 1, "", "", "", "", "", "", "", "", [id]));
    dispatch(getAttendanceAction(100, 1, "", "", "", "", "", "", "", "", [id]));
    closeModal(false);
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="flex justify-end w-full">
          <button
            onClick={() => closeModal(false)}
            className="text-gray-600 hover:text-gray-800 focus:outline-none transition duration-300 ease-in-out"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <form>
          <div className="flex gap-2 w-full">
            <div>
              <label htmlFor="hourInput">Hour:</label>
              <input
                type="number"
                id="hourInput"
                className="inputUser w-full"
                min="1"
                max={12}
                value={hour || ""}
                
                onChange={handleHourChange}
                required
              />
            </div>
            <div>
              <label htmlFor="minuteInput">Minute:</label>
              <input
                type="number"
                id="minuteInput"
                className="inputUser w-full"
                min="0"
                max="59"
                value={minute || ""}
                onChange={handleMinuteChange}
                required
              />
            </div>
          </div>
          <div className="flex gap-2" >
            <button onClick={handleSubmit} className="btn-primary mt-2">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ExtraHourModal;
