export const CREATE_RECEIVED_REQUEST = "CREATE_RECEIVED_REQUEST";
export const CREATE_RECEIVED_SUCCESS = "CREATE_RECEIVED_SUCCESS";
export const CREATE_RECEIVED_FAIL = "CREATE_RECEIVED_FAIL";

export const FETCH_RECEVIE_REQUEST = "FETCH_RECEVIE_REQUEST";
export const FETCH_RECEIVE_SUCCESS = "FETCH_RECEIVE_SUCCESS";
export const FETCH_RECEIVE_FAIL = "FETCH_RECEIVE_FAIL";

export const FETCH_NOTIFICATION_REQUEST = "FETCH_NOTIFICATION_REQUEST";
export const FETCH_NOTIFICATION_SUCCESS = "FETCH_NOTIFICATION_SUCCESS";
export const FETCH_NOTIFICATION_FAIL = "FETCH_NOTIFICATION_FAIL";

export const SEEN_NOTIFICATION_REQUEST = "SEEN_NOTIFICATION_REQUEST";
export const SEEN_NOTIFICATION_SUCCESS = "SEEN_NOTIFICATION_SUCCESS";
export const SEEN_NOTIFICATION_FAIL = "SEEN_NOTIFICATION_FAIL";

export const SHOW_ALL_RECEVIE_REQUEST = "SHOW_ALL_RECEVIE_REQUEST";
export const SHOW_ALL_RECEIVE_SUCCESS = "SHOW_ALL_RECEIVE_SUCCESS";
export const SHOW_ALL_RECEIVE_FAIL = "SHOW_ALL_RECEIVE_FAIL";
