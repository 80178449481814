import React from "react";
import Logo from "../../Image/Logo.jpg";
const Footer = () => {
  return (
    <div class=" bg-white py-2 z-0 shadow-2xl shadow-black fixed bottom-0 w-full px-10 flex items-center flex-row-reverse ">
      <marquee behavior="" direction="">
        <div className="flex  items-center">
        <img src={Logo} className="w-10 animate-pulse" alt="" />
          <p className="animate-charcter">زاد بۆ ژیانەوەی ئادەمیزاد</p>
          <img src={Logo} className="w-10 animate-pulse" alt="" />
        </div>
      </marquee>
      
    </div>
  );
};

export default Footer;
