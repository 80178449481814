import Swal from "sweetalert2";
import { api } from "../../Api/api";
import { getToken } from "../login";
import {
  CREATE_SURGURY_TYPE_REQUEST,
  CREATE_SURGURY_TYPE_FAIL,
  CREATE_SURGURY_TYPE_SUCCESS,
  FETCH_ALL_TYPE_SURGERY_REQUEST,
  FETCH_ALL_TYPE_SURGERY_SUCCESS,
  FETCH_ALL_TYPE_SURGERY_FAIL,
  FETCH_ORGAN_LEVEL_REQUEST,
  FETCH_ORGAN_LEVEL_SUCCESS,
  FETCH_ORGAN_LEVEL_FAIL,
} from "../../ActionType/SurgeryType/surgery";
import {
  CREATE_ORGAN_LEVEL_FAIL,
  CREATE_ORGAN_LEVEL_REQUEST,
  CREATE_ORGAN_LEVEL_SUCCESS,
} from "../../ActionType/Surgery/surgeryType";
export const createTypeOfSurgery =
  (typeName, valueTypeNameSurgeryEn, surgery_id) => (dispatch) => {
    Swal.fire({
      icon: "info",
      title: "Please Wait",
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    };
    
    api
      .post(
        "surgery/createType",
        {
          typeName,
          type_name_en: valueTypeNameSurgeryEn,
          surgery_id,
        },
        config
      )
      .then((response) => {
        const { status, typeOfSurgery } = response.data;
        console.log(response);

        dispatch({ type: CREATE_SURGURY_TYPE_REQUEST });
        if (status == 200) {
          dispatch({
            type: CREATE_SURGURY_TYPE_SUCCESS,
            payload: typeOfSurgery,
          });
          Swal.fire({
            icon: "success",
            title: "Good Job",
            text: "Type Of surgery was created",
            timer: 1000,
          });
        }
      })
      .catch(() => {
        dispatch({
          type: CREATE_SURGURY_TYPE_FAIL,
          error: "please Check Your Request",
        });
      });
  };

export const createOrganLevelAction =
  (type_surgery_id, organ_level_name) => (dispatch) => {
    Swal.fire({
      icon: "info",
      title: "Please Wait",
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    };
    api
      .post(
        "surgery/createOrganLevel",
        {
          type_surgery_id,
          organ_level_name,
        },
        config
      )
      .then((response) => {
        const { status, typeOfSurgery } = response;
        console.log(response);
        dispatch({ type: CREATE_ORGAN_LEVEL_REQUEST });
        if (status == 200) {
          dispatch({
            type: CREATE_ORGAN_LEVEL_SUCCESS,
            // payload: typeOfSurgery,
          });
          Swal.fire({
            icon: "success",
            title: "Good Job",
            text: "Organ Level Was Save",
            timer: 1500,
          });
        }
      })
      .catch(() => {
        dispatch({
          type: CREATE_ORGAN_LEVEL_FAIL,
          error: "please Check Your Request",
        });
      });
  };

export const getAllTypeSurgeryAction = () => (dispatch) => {
  
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
  api
    .get("surgery/getAllTypeSurgery", config)
    .then((response) => {
      dispatch({
        type: FETCH_ALL_TYPE_SURGERY_REQUEST,
      });
      const { status, data } = response;
      if (status == 200) {
        dispatch({
          type: FETCH_ALL_TYPE_SURGERY_SUCCESS,
          payload: data,
          error: "",
        });
      } else {
        Swal.fire({
          icon: "error",
          titleText: "Some thing went Wrong",
          timer: 1500,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: FETCH_ALL_TYPE_SURGERY_FAIL,
        payload: [],
        error: "Please Check Your request",
      });
    });
};

export const getOrganLevelAction = (id) => (dispatch) => {

  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
  api
    .get("surgery/show/" + id, config)
    .then((response) => {
      dispatch({
        type: FETCH_ORGAN_LEVEL_REQUEST,
      });
      const { status, data } = response;
      
      if (status == 200) {
        dispatch({
          type: FETCH_ORGAN_LEVEL_SUCCESS,
          payload: data,
          error: "",
        });
      } else {
        Swal.fire({
          icon: "error",
          titleText: "Some thing went Wrong",
          timer: 1500,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: FETCH_ORGAN_LEVEL_FAIL,
        payload: [],
        error: "Please Check Your request",
      });
    });
};
