export const CREATE_COMMITE_REQUEST="CREATE_COMMITE_REQUEST";
export const CREATE_COMMITE_SUCCESS="CREATE_COMMITE_SUCCESS";
export const CREATE_COMMITE_FAIL="CREATE_COMMITE_FAIL";

export const FETCH_COMMITE_REQUEST="FETCH_COMMITE_REQUEST";
export const FETCH_COMMITE_SUCCESS="FETCH_COMMITE_SUCCESS";
export const FETCH_COMMITE_FAIL="FETCH_COMMITE_FAIL";


export const FETCH_COMMITE_INFO_REQUEST="FETCH_COMMITE_REQUEST";
export const FETCH_COMMITE_INFO_SUCCESS="FETCH_COMMITE_INFO_SUCCESS";
export const FETCH_COMMITE_INFo_FAIL="FETCH_COMMITE_INFo_FAIL";

export const FETCH_COMMITE_WITH_VISIT_REQUEST="FETCH_COMMITE_WITH_VISIT_REQUEST";
export const FETCH_COMMITE_WITH_VISIT_SUCCESS="FETCH_COMMITE_WITH_VISIT_SUCCESS";
export const FETCH_COMMITE_WITH_VISIT_FAIL="FETCH_COMMITE_WITH_VISIT_FAIL";
