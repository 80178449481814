import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../Pages/Branchs/css/invoice.css";
import { getCommite } from "../../Action/commiteAction";
import ViewModal from "../../Modal/ViewModal";
import AssignModalCommite from "../../Modal/AssignCommiteModal";
import EditModalCommite from "../../Modal/EditModalCommite";

import RejectModal from "../../Modal/RejectModal";
import { getPatient } from "../../Action/patient";
import ReactPaginate from "react-paginate";
import Logo from "../../Image/Logo.jpg";
import { FaFilter } from "react-icons/fa";
import { AiFillEye, AiOutlineEdit } from "react-icons/ai";
import { TbPlayerEject } from "react-icons/tb";
import { MdAssignmentReturn } from "react-icons/md";
import { FaArrowsToEye } from "react-icons/fa6";
import { HiOutlineUserGroup } from "react-icons/hi";
import { Link } from "react-router-dom";
const Commite = () => {
  const location_id = localStorage.getItem("locationId");
  const [isOpenRejectModal, setIsOpenRejectModal] = useState(false);

  const dispatch = useDispatch();
  // const getUsers = useSelector((state) => state.getUser);

  const getPatients = useSelector((state) => state.getPatients);

  const { patients, loading, total } = getPatients;
  // const getCommites = useSelector((state) => state.getCommite);
  const [isOpenViewModal, setIsOpenViewModal] = useState(false);
  const [isOpenAssignCommiteModal, setisOpenAssignCommiteModal] =
    useState(false);

  const [commiteId, setCommiteId] = useState(null);

  const [patientId, setPatientId] = useState();
  // const { data, loading, total } = getCommites;
  // const { users, loading } = getUsers;
  const [patient_id, setPatient_id] = useState("");
  const handelViewModal = (id, patientId) => {
    setPatient_id(patientId);
    setIsOpenViewModal(true);
    setCommiteId(id);
  };
  // const handelAssign = (id, patientId) => {
  //   setisOpenAssignCommiteModal(true);
  //   setPatient_id(patientId)
  //   setCommiteId(id)
  //   // setPatientId(id);
  // };
  const regex = /^(\d{4})(\d{3})(\d{4})$/;
  const [perPage, setPerPage] = useState(10);
  const [searchParams, setSearchParams] = useState({
    fullname: "",
    phone: "",
    code: "",
    age: "",
    gender: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const handelReject = (event, id) => {
    event.preventDefault();
    setPatientId(id);
    setIsOpenRejectModal(true);
    // const { fullname, phone, code, age, gender } = searchParams;
    // console.log(id);
    // dispatch(
    //   getPatient(
    //     location_id,
    //     currentPage,
    //     perPage,
    //     code,
    //     fullname,
    //     phone,
    //     gender,
    //     age,
    //     1
    //   )
    // );
  };
  useEffect(() => {
    const { fullname, phone, code, age, gender } = searchParams;
    // dispatch(
    //   getCommite(location_id, currentPage, perPage, fullname, phone, code, age, gender)
    // );
    dispatch(
      getPatient(
        location_id,
        currentPage,
        perPage,
        code,
        fullname,
        phone,
        gender,
        age,
        1
      )
    );
  }, [
    loading,
    currentPage,
    searchParams.age,
    searchParams.fullname,
    searchParams.code,
    searchParams.phone,
    searchParams.gender,
    perPage,
  ]);

  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const handleOpenFilter = () => {
    setIsOpenFilter(!isOpenFilter);
  };
  const handleSearchParams = (event) => {
    const { name, value } = event.target;
    setSearchParams({
      ...searchParams,
      [name]: value,
    });
  };

  return (
    <>
      {loading ? (
        <div className="flex flex-col">
          <img
            src={Logo}
            alt="Logo"
            className="w-56 m-auto animate-pulse mt-20 "
          />
          <div className="text-2xl text-center font-bold  ">
            <div className="flex justify-center gap-4">
              <p className="animate-charcter">Please Wait</p>
              <div class="container2">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>{" "}
            </div>
          </div>
        </div>
      ) : (
        <div className="relative bg-white md:h-[24rem] lg:h-[20rem] xl:h-[30rem]  3xl:h-[43rem]   w-[98%] m-auto  sm:rounded-lg  mt-4">
          <div className="flex gap-2 items-center bg-gray-100 px-4 border-b py-4 rounded-md shadow-xl  ">
            <p className="text-xl text-teal-600">
              <HiOutlineUserGroup />
            </p>
            <div>
              <h1 className="text-xl">Committe</h1>
            </div>
          </div>
          <div></div>

          <div className="overflow-x-auto  h-[70%]  xl:h-[75%] 3xl:h-[45rem]  m-auto">
            <table className="w-full text-sm  overflow-y-auto m-auto   text-left text-gray-900 ">
              <thead className="sticky top-0">
                <tr>
                  <th>
                    <div className="flex justify-between items-center">
                      <p className="pl-1">Patient ID</p>
                      <div className="filterIcon" onClick={handleOpenFilter}>
                        <FaFilter />
                      </div>
                    </div>
                    <div>
                      <div className="w-full">
                        <input
                          onBlur={handleSearchParams}
                          name="code"
                          type="text"
                          placeholder="Code"
                          className={
                            isOpenFilter
                              ? "inputLogin p-1.5 shadow-xl rounded  "
                              : "hidden"
                          }
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="flex justify-between items-center">
                      <p className="pl-1">Full Name</p>
                      <div className="filterIcon" onClick={handleOpenFilter}>
                        <FaFilter />
                      </div>
                    </div>
                    <div>
                      <div className="w-full">
                        <input
                          onBlur={handleSearchParams}
                          name="fullname"
                          type="text"
                          placeholder="Full Name"
                          className={
                            isOpenFilter
                              ? "inputLogin p-1.5 shadow-xl rounded  "
                              : "hidden"
                          }
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="flex justify-between items-center">
                      <p className="pl-1">Phone</p>
                      <div className="filterIcon" onClick={handleOpenFilter}>
                        <FaFilter />
                      </div>
                    </div>
                    <div>
                      <div className="w-full">
                        <input
                          onBlur={handleSearchParams}
                          name="phone"
                          type="text"
                          placeholder="Phone Number"
                          className={
                            isOpenFilter
                              ? "inputLogin p-1.5 shadow-xl rounded  "
                              : "hidden"
                          }
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    {" "}
                    <div className="flex justify-between items-center">
                      <p className="pl-1">Gender</p>
                      <div className="filterIcon" onClick={handleOpenFilter}>
                        <FaFilter />
                      </div>
                    </div>
                    <div>
                      <div className="w-full">
                        <input
                          onBlur={handleSearchParams}
                          name="gender"
                          type="text"
                          placeholder="Gender"
                          className={
                            isOpenFilter
                              ? "inputLogin p-1.5 shadow-xl rounded  "
                              : "hidden"
                          }
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    {" "}
                    <div className="flex justify-between items-center">
                      <p className="pl-1">Birth Date</p>
                      <div className="filterIcon" onClick={handleOpenFilter}>
                        <FaFilter />
                      </div>
                    </div>
                    <div>
                      <div className="w-full">
                        <input
                          onBlur={handleSearchParams}
                          name="age"
                          type="text"
                          placeholder="Birth Date"
                          className={
                            isOpenFilter
                              ? "inputLogin p-1.5 shadow-xl rounded  "
                              : "hidden"
                          }
                        />
                      </div>
                    </div>
                  </th>
                  <th>Date</th>
                  <th>Visits</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {patients.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      className={item.commites.map((commite, index) => {
                        return commite.color == "2"
                          ? "tableRowSave"
                          : commite.color == "3"
                          ? "tableRowSave"
                          : commite.reject == "2"
                          ? "tableRowRject"
                          : "tableRow";
                      })}
                    >
                      <td>
                        {" "}
                        <Link to={`/tabsVisitCommite/${item.id}`}>
                          {item.code}
                        </Link>{" "}
                      </td>
                      <td>
                        <Link to={`/tabsVisitCommite/${item.id}`}>
                          {item.Pname}
                        </Link>
                      </td>

                      <td>
                        <Link to={`/tabsVisitCommite/${item.id}`}>
                          {item.phone.replace(regex, "$1-$2-$3")}
                        </Link>
                      </td>
                      <td>
                        <Link to={`/tabsVisitCommite/${item.id}`}>
                          {item.gender}
                        </Link>
                      </td>
                      <td>
                        <Link to={`/tabsVisitCommite/${item.id}`}>
                          {item.birthDate}
                        </Link>
                      </td>
                      <td>
                        <Link to={`/tabsVisitCommite/${item.id}`}>
                          {item.created_at.toString().slice(0, 10)}
                        </Link>
                      </td>
                      <td className="text-lg text-red-500 font-bold font-sans underline cursor-pointer">
                        <Link to={`/tabsVisitCommite/${item.id}`}>
                          {item.visits_with_reject_count}
                        </Link>
                      </td>

                      <td className="w-20">
                        <div className="flex flex-col md:flex-row   lg:gap-2 ">
                          <div data-te-toggle="tooltip" title="View">
                            <button
                              className={"viewBtn"}
                              onClick={(e) => {
                                e.preventDefault();

                                handelViewModal(item.idCommit, item.id);
                              }}
                            >
                              <AiFillEye />
                            </button>
                          </div>

                          {item.color != "2" ? (
                            <button
                              className="rejectBtn"
                              onClick={(event) => {
                                handelReject(event, item.id);
                              }}
                            >
                              <TbPlayerEject />
                            </button>
                          ) : null}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="gap-3 px-3 flex items-center justify-end py-2 m-auto">
            
            <div className="w-20">
              <select
                name=""
                className=" bg-white border  w-full focus:ring-2   focus:ring-slate-900 rounded-md   focus:outline-none"
                onChange={(e) => setPerPage(e.target.value)}
                id=""
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
            <div className="flex justify-center  ">
              <ReactPaginate
                previousLabel="<"
                nextLabel={">"}
                // nextClassName='bg-blue-500  hover:bg-blue-600 text-white font-semibold px-2 py-2 rounded-md'
                breakLabel="..."
                className="flex  items-center   -space-x-px h-8 text-sm"
                previousClassName="flex items-center justify-center px-3 h-8 ml-0  border border-gray-300 rounded-l-lg "
                nextClassName="flex items-center justify-center px-3 h-8 leading-tight text-gray-500  border border-gray-300 rounded-r-lg "
                pageLinkClassName="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500  border border-gray-300  "
                breakClassName="w-8 py-2 text-center"
                pageCount={Math.ceil(total / perPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={(event) => {
                  let currentPage = event.selected + 1;
                  dispatch(
                    getPatient(
                      location_id,
                      currentPage,
                      perPage,
                      "",
                      "",
                      "",
                      "",
                      "",
                      1
                    )
                  );
                  setCurrentPage(currentPage);
                }}
                activeLinkClassName="bg-black text-white flex  items-center  -space-x-px h-8 text-sm"
              />
            </div>
          </div>

          {isOpenViewModal && (
            <ViewModal
              closeModal={setIsOpenViewModal}
              patientId={patient_id}
              commiteId={commiteId}
            />
          )}
          {/* {isOpenAssignCommiteModal && (
              <AssignModalCommite
                closeModal={setisOpenAssignCommiteModal}
                locationId={location_id}
                patientId={patient_id}
                commite_id={commiteId}
              />
            )} */}
          {/* {isOpenEditModal && (
              <EditModalCommite
                closeModal={setIsOpenEditModal}
                locationId={location_id}
                patientId={patientId}
              />
            )} */}
          {isOpenRejectModal && (
            <RejectModal
              closeModal={setIsOpenRejectModal}
              patientId={patientId}
            />
          )}
        </div>
      )}
    </>
  );
};

export default Commite;
