import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSection } from "../../Action/section";
import { Link } from "react-router-dom";
import { sendTokenAction } from "../../Action/SendToken/SendTokenAction";

const ModalDashboard = ({ users, onClose }) => {
  const getSectionReducer = useSelector((state) => state.getSection);
  const { sections, loadingSection } = getSectionReducer;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSection());
  }, []);

  const sendMessageToReceiver = () => {
    const receiverWindow =
      document.getElementById("receiver-iframe").contentWindow;
    const targetOrigin = "http://localhost:3000";

    var token = localStorage.getItem("token");
    var loggedIn = localStorage.getItem("loggedIn");
    var defaultPackage = localStorage.getItem("defaultPackage");

    var messageData = {
      token: token,
      loggedIn: loggedIn,
      defaultPackage: defaultPackage,
    };

    // Use postMessage to send the messageData to the target window
    receiverWindow.postMessage(messageData, targetOrigin);
  };

  const sectionArray = [
    {
      id: 1,
      name: "Administrator",
      route: "https://administrator.zadorg.site/",
    },

    {
      id: 5,
      name: "Medical",
      route: "https://zadorg.site/",
    },

    {
      id: 2,
      name: "Accounting",
      route: "/",
    },
    {
      id: 3,
      name: "Development",
      route: "/",
    },
    {
      id: 4,
      name: "Supply Chain",
      route: "/",
    },

    {
      id: 6,
      name: "Media",
      route: "/",
    },
  ];

  return (
    <>
      {loadingSection ||
      sections == undefined ||
      sections.length == 0 ||
      users == undefined ||
      users.length == 0 ? (
        ""
      ) : (
        <div className="fixed inset-0 z-50 flex items-center w-full justify-center">
          <iframe
            className="hidden"
            id="receiver-iframe"
            src="https://zadorg.site/"
            title="Receiver Iframe"
          ></iframe>

          <div className="fixed inset-0 bg-black opacity-50"></div>
          <div className="relative z-50 bg-white  rounded-md shadow-md  w-full   pb-4 max-w-md ">
            <div className="bg-gray-200 py-2 p-3  ">
              <p className="text-lg font-bold">Choose Your Default Package</p>
            </div>
            {users.role.permission[0].section_id == 8 ? (
              <div className="m-auto w-full ">
                {sectionArray
                  .filter((filter) => filter.id != 8)
                  .map((section, index) => {
                    return (
                      <Link
                        to={section.route}
                        onClick={() => {
                          sendTokenAction(section.route)
                          localStorage.setItem("defaultPackage", section.name);
                        }}
                      >
                        <button
                          key={index}
                          className="ml-5 bg-purple-500 w-11/12 duration-300 hover:bg-purple-700 text-white font-bold py-2 px-4 mt-4   rounded"
                        >
                          <div className="flex gap-1 justify-center">
                            <p>Zad</p>
                            <p>{section.name}</p>
                          </div>
                        </button>
                      </Link>
                    );
                  })}
              </div>
            ) : (
              sectionArray
                .filter(
                  (filter) => filter.id == users.role.permission[0].section_id
                )
                .map((section, index) => {
                  return (
                    <Link
                      to={section.route}
                      onClick={() => {
                        sendTokenAction(section.route);
                        localStorage.setItem("defaultPackage", section.name);
                      }}
                    >
                      <button
                        key={index}
                        className="ml-5 bg-purple-500 w-11/12 duration-300 hover:bg-purple-700 text-white font-bold py-2 px-4 mt-4   rounded"
                      >
                        <div className="flex gap-1 justify-center">
                          <p>Zad</p>
                          <p>{section.name}</p>
                        </div>
                      </button>
                    </Link>
                  );
                })
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ModalDashboard;
