export const REJECT_PATIENT_REQUEST = "REJECT_PATIENT_REQUEST";
export const REJECT_PATIENT_SUCCESS = "REJECT_PATIENT_SUCCESS";
export const REJECT_PATIENT_FAIL = "REJECT_PATIENT_FAIL";

export const FETCH_REJECT_PATIENT_REQUEST = "FETCH_REJECT_PATIENT_REQUEST";
export const FETCH_REJECT_PATIENT_SUCCESS = "FETCH_REJECT_PATIENT_SUCCESS";
export const FETCH_REJECT_PATIENT_FAIL = "FETCH_REJECT_PATIENT_FAIL";

export const DISAPPROVED_REJECT_PATIENT_REQUEST =
  "DISAPPROVED_REJECT_PATIENT_REQUEST";
export const DISAPPROVED_REJECT_PATIENT_SUCCESS =
  "DISAPPROVED_REJECT_PATIENT_SUCCESS";
export const DISAPPROVED_REJECT_PATIENT_FAIL =
  "DISAPPROVED_REJECT_PATIENT_FAIL";

export const REJECT_PATIENT_WITH_VISIT_REQUEST =
  "REJECT_PATIENT_WITH_VISIT_REQUEST";
export const REJECT_PATIENT_WITH_VISIT_SUCCESS =
  "REJECT_PATIENT_WITH_VISIT_SUCCESS";
  
export const REJECT_PATIENT_WITH_VISIT_FAIL = "REJECT_PATIENT_WITH_VISIT_FAIL";

export const FETCH_PATIENT_VISIT_REQUEST = "FETCH_PATIENT_VISIT_REQUEST";
export const FETCH_PATIENT_VISIT_SUCCESS = "FETCH_PATIENT_VISIT_SUCCESS";
export const FETCH_PATIENT_VISIT_FAIL = "FETCH_PATIENT_VISIT_FAIL";

export const SHOW_REJECT_PATIENT_VISIT_REQUEST = "SHOW_REJECT_PATIENT_VISIT_REQUEST";
export const SHOW_REJECT_PATIENT_VISIT_SUCCESS = "SHOW_REJECT_PATIENT_VISIT_SUCCESS";
export const SHOW_REJECT_PATIENT_VISIT_FAIL = "SHOW_REJECT_PATIENT_VISIT_FAIL";
