import Swal from "sweetalert2";
import {
  CREATE_PERMISSION_PAGE_FAIL,
  CREATE_PERMISSION_PAGE_REQUEST,
  CREATE_PERMISSION_PAGE_SUCCESS,
  FETCH_PAGE_FAIL,
  FETCH_PAGE_REQUEST,
  FETCH_PAGE_SUCCESS,
  SHOW_PAGE_FAIL,
  SHOW_PAGE_REQUEST,
  SHOW_PAGE_SUCCESS,
  SHOW_PERMISSION_PAGE_FAIL,
  SHOW_PERMISSION_PAGE_REQUEST,
  SHOW_PERMISSION_PAGE_SUCCESS,
} from "../../ActionType/PageType/PageType";
import { api } from "../../Api/api";
import { getToken } from "../login";

export const getPageAction = () => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };

  api
    .get("page", config)
    .then((response) => {
      dispatch({ type: FETCH_PAGE_REQUEST });
      if (response.status === 200) {
        dispatch({
          type: FETCH_PAGE_SUCCESS,
          payload: response.data,
        });
      }
    })
    .catch((error) => {
      dispatch({ type: FETCH_PAGE_FAIL });
      console.log(error);
    });
};

export const showPageAction = (id) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };

  api
    .get(`page/show/${id}`, config)
    .then((response) => {
      // console.log("resp : ",response.data)
      dispatch({ type: SHOW_PAGE_REQUEST });
      if (response.status === 200) {
        dispatch({
          type: SHOW_PAGE_SUCCESS,
          payload: response.data,
        });
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_PAGE_FAIL });
      console.log(error);
    });
};

export const showPermissionPageAction = (role_id) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
  
  api
    .get(`page/showPermission/${role_id}`, config)
    .then((response) => {
      

      if (response.status === 200) {
        dispatch({
          type: SHOW_PERMISSION_PAGE_SUCCESS,
          payload: response.data,
        });
      }
    })
    .catch((error) => {
      dispatch({ type: SHOW_PERMISSION_PAGE_FAIL });
      console.log(error);
    });
};

export const createPermissionPageAction =
  (roleId, pageId, permission_id, branchId,sectionId) => (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    };
    // console.log(pageId)

    api
      .post(
        `page/createPermission`,
        {
          role_id: roleId,
          page_id: pageId,
          permission_id,
          branch_id: branchId,
          section_id:sectionId
        },
        config
      )
      .then((response) => {
        dispatch({ type: CREATE_PERMISSION_PAGE_REQUEST });
        // window.location.reload();
       
        if (response.status === 200) {
          // dispatch(showPermissionPageAction(roleId))
          dispatch({
            type: CREATE_PERMISSION_PAGE_SUCCESS,
            payload: response.data,
          });
        }
        Swal.fire({
          icon: "success",
          title: "سەرکەوتوو بوو",
          text: "زانیاریەکە داخل کرا",
          timer: 1500,
        });
      })
      .catch((error) => {
        dispatch({ type: CREATE_PERMISSION_PAGE_FAIL });
        console.log(error);
      });
  };
