import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  showSentFileAction,
  updateSentFilesAdminstratorAction,
} from "../../../Action/AdminstratorAction/SentFilesAction";
import Logo from "../../../Image/Logo.jpg";
const UpdateSentFileModal = ({ id, closeModal }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(showSentFileAction(id));
  }, []);
  const [inputValue, setInputValue] = useState({
    location: "",
    number: "",
    date: "",
    nameFile: "",
    oldNameFile:"",
  });
  const [document, setDocument] = useState(null);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  };

  const showSentFilesAdminstrator = useSelector(
    (state) => state.showSentFilesAdminstrator
  );
  const { sentFile, loading } = showSentFilesAdminstrator;
  useEffect(()=>{
  
    if(!sentFile){
        return
    }
    setInputValue({
        nameFile:sentFile.name_file,
        oldNameFile:sentFile.name_file,
        date:sentFile.date,
        number:sentFile.number,
        location:sentFile.location,
    });
  },[sentFile])
  const handleUpdate = (event) => {
    event.preventDefault();
    const { nameFile, location, number, date } = inputValue;
    
    dispatch(
      updateSentFilesAdminstratorAction(
        id,
        nameFile,
        date,
        number,
        location,
        document,
        inputValue.oldNameFile
      )
    );
    closeModal();
  };
  return (
    <>
      {loading || sentFile == undefined ? (
        <div className="flex flex-col absolute top-20 left-[50%] -translate-x-[50%] z-50">
          <img
            src={Logo}
            alt="Logo"
            className="w-56 m-auto animate-pulse mt-20 "
          />
          <div className="text-2xl text-center font-bold  ">
            <div className="flex justify-center gap-4">
              <p className="animate-charcter">Please Wait</p>
              <div class="container2">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>{" "}
            </div>
          </div>
        </div>
      ) : (
        <div className="modal">
          <div className="modal-content px-2 lg:w-5/12">
            {/* {id} */}
            <div className="flex justify-between items-center">
              <p className="font-bold">Sent File Update </p>
            </div>

            <form action="" className="w-full">
              <div className="flex w-full gap-2">
                <div className="parentInput w-full ">
                  <label htmlFor="location">Location *</label>
                  <input
                    type="text"
                    onChange={handleInputChange}
                    value={inputValue.location}
                    name="location"
                    placeholder="Location"
                    className="inputUser w-full"
                  />
                </div>
                <div className="parentInput w-full ">
                  <label htmlFor="number">Number *</label>
                  <input
                    type="number"
                    value={inputValue.number}
                    onChange={handleInputChange}
                    name="number"
                    placeholder="Number"
                    className="inputUser w-full"
                  />
                </div>
              </div>

              <div className="flex w-full gap-2 mt-2">
                <div className="parentInput w-full  ">
                  <label htmlFor="Date">Date *</label>
                  <input
                    type="date"
                    value={inputValue.date}
                    onChange={handleInputChange}
                    name="date"
                    placeholder="Date"
                    className="inputUser w-full"
                  />
                </div>
                <div className="parentInput w-full ">
                  <label htmlFor="Name File">Name File *</label>
                  <input
                    type="text"
                    value={inputValue.nameFile}
                    onChange={handleInputChange}
                    name="nameFile"
                    placeholder="Name File"
                    className="inputUser w-full"
                  />
                </div>
              </div>
              <div className="w-full ">
                <label
                  htmlFor="fileInput"
                  className="block text-sm font-medium text-gray-700"
                >
                  Upload File
                </label>
                <input
                  onChange={(e) => {
                    setDocument(e.target.files[0]);
                  }}
                  id="fileInput"
                  name="document"
                  type="file"
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>

              <div className="flex gap-2 mt-2">
                <button className="btn-danger p-2" onClick={closeModal}>
                  Cancel{" "}
                </button>
                <button className="btn-primary p-2" onClick={handleUpdate}>
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default UpdateSentFileModal;
