import { api } from "../Api/api";
import { getToken } from "../Action/login";
import {
  CREATE_PATIENT_REQUEST,
  CREATE_PATIENT_SUCCESS,
  EDIT_PATIENT_FAIL,
  EDIT_PATIENT_REQUEST,
  EDIT_PATIENT_SUCCESS,
  FETCH_ALL_INFO_PATIENT_FAIL,
  FETCH_ALL_INFO_PATIENT_REQUEST,
  FETCH_ALL_INFO_PATIENT_SUCCESS,
  FETCH_PATIENT_FAIL,
  FETCH_PATIENT_REQUEST,
  FETCH_PATIENT_SUCCESS,
  FETCH_REPORT_MONTHLY_FAIL,
  FETCH_REPORT_MONTHLY_REQUEST,
  FETCH_REPORT_MONTHLY_SUCCESS,
  REJECT_PATIENT_FAIL,
  REJECT_PATIENT_REQUEST,
  REJECT_PATIENT_SUCCESS,
  UPDATE_PATIENT_FAIL,
  UPDATE_PATIENT_REQUEST,
  UPDATE_PATIENT_SUCCESS,
} from "../ActionType/patientType";
import Swal from "sweetalert2";
import {
  CREATE_VISIT_FAIL,
  CREATE_VISIT_REQUEST,
  CREATE_VISIT_SUCCESS,
  FETCH_VISIT_FAIL,
  FETCH_VISIT_REQUEST,
  FETCH_VISIT_SUCCESS,
  UPDATE_VISIT_FAIL,
  UPDATE_VISIT_REQUEST,
  UPDATE_VISIT_SUCCESS,
} from "../ActionType/Visits/VisitsType";

export const createPatient =
  (fullName, phone, gender, birth, image, locationId, reset) => (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    };

    const formData = new FormData();
    formData.append("fullName", fullName);
    formData.append("phone", phone);
    formData.append("gender", gender);
    formData.append("birth", birth);
    // formData.append("created_at", created_at);
    formData.append("location_id", locationId);
    api
      .post("patient/create", formData, config)
      .then((response) => {
        const { id } = response.data.patient;

        uploadImage(id, image);

        dispatch(createVisitAction(id));
        dispatch({ type: CREATE_PATIENT_REQUEST });

        if (response.data.status === 200) {
          const { total } = response.data;
          dispatch({
            type: CREATE_PATIENT_SUCCESS,
            payload: response.data.patient,
            total: total,
          });
          Swal.fire({
            icon: "success",
            title: "Good Job",
            text: "Patient Was Assign",
            timer: 1500,
          });
          reset();
        } else if (response.data.status === 404) {
          // console.log(response);

          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Pleas Fill all the gaps',
          });
          reset();
        }
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Request Is Faild",
        });
      });
  };

  export const createPatientAdminstratorAction =
  (fullName, phone, gender, birth, image, locationId, reset,created_at) => (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    };

    const formData = new FormData();
    formData.append("fullName", fullName);
    formData.append("phone", phone);
    formData.append("gender", gender);
    formData.append("birth", birth);
    formData.append("created_at", created_at);
    formData.append("location_id", locationId);
    api
      .post("patient/storeAdminstratorPatient", formData, config)
      .then((response) => {
        const { id } = response.data.patient;

        uploadImage(id, image);

        dispatch(createVisitAction(id));
        dispatch({ type: CREATE_PATIENT_REQUEST });

        if (response.data.status === 200) {
          const { total } = response.data;
          dispatch({
            type: CREATE_PATIENT_SUCCESS,
            payload: response.data.patient,
            total: total,
          });
          Swal.fire({
            icon: "success",
            title: "Good Job",
            text: "Patient Was Assign",
            timer: 1500,
          });
          reset();
        } else if (response.data.status === 404) {
          // console.log(response);

          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Pleas Fill all the gaps',
          });
          reset();
        }
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Request Is Faild",
        });
      });
  };

export const uploadImage = (patientId, image,location='invoice') => {
  console.log(location.pathname)
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
  // console.log(patientId, " : img : ", image);
  const formData = new FormData();
  image.map((img) => {
    formData.append("image[]", img);
  });

  formData.append("patientId", patientId);
  api
    .post("image/uploadImage", formData, config)
    .then((response) => {
      const { status, image } = response.data;
      if (status == 200) {
        if(location.pathname==`/tabsVisitCommite/${patientId}`){
            Swal.fire({
              icon:"success",
              title:"سەرکەوتوو بوو",
              text:"بە سەرکەوتووی رەسمەکە داخل کرا",
              timer:1500
            })
        }
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getPatient =
  (
    branchId,
    cuurentPage,
    perPage,
    code,
    fullname,
    phone,
    gender,
    age,
    select
  ) =>
  (dispatch) => {
    const locationId = localStorage.getItem("locationId");

    // dispatch({ type: FETCH_PATIENT_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      params: {
        page: cuurentPage,
        perPage: perPage,
        code: code,
        fullname: fullname,
        age: age,
        gender: gender,
        phone: phone,
        select,
      },
    };

    if (branchId == 1000) {
      branchId = locationId;
    }

    api
      .get(`patient/${branchId}`, config)
      .then((response) => {
        dispatch({ type: FETCH_PATIENT_REQUEST });
        // console.log(response.data);
        // console.log(response.data)
        if (response.data.status === 200) {
          const { count } = response.data;
          const data = response.data.paginate.data;
          dispatch({
            type: FETCH_PATIENT_SUCCESS,
            payload: data,
            total: count,
          });
        }
      })
      .catch(() => {
        dispatch({ type: FETCH_PATIENT_FAIL, payload: [] });
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Request Is Faild",
        });
      });
  };

export const rejectPatient = (id, visit_id) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
  api
    .post("patient/reject", { id: id, visit_id }, config)
    .then((response) => {
      dispatch({ type: REJECT_PATIENT_REQUEST });
      if (response.data.status === 200) {
        dispatch({ type: REJECT_PATIENT_SUCCESS, payload: response.data });
        Swal.fire("Reject Patient!", "Patient Was Reject", "success");
      }
    })
    .catch(() => {
      dispatch({ type: REJECT_PATIENT_FAIL });
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Request Is Faild",
      });
    });
};

export const getAllInfoPatient =
  (id, locationId, patientId, visit_id) => (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      params: {
        visit_id,
      },
    };

    dispatch({ type: FETCH_ALL_INFO_PATIENT_REQUEST });
    api
      .get(`patient/getAllInfoPatient/${id}/${locationId}/${patientId}`, config)
      .then((response) => {
        const { image } = response.data;

        dispatch({
          type: FETCH_ALL_INFO_PATIENT_SUCCESS,
          payload: response.data.allInfo,
          visit: response.data.visit,
          image: image,
        });
      })
      .catch(() => {
        dispatch({
          type: FETCH_ALL_INFO_PATIENT_FAIL,
          payload: [],
          visit: [],
          image: [],
        });
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Request Is Faild",
        });
      });
  };

export const editPatientAction = (id, visit_id) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    params: {
      visit_id,
    },
  };
  dispatch({ type: EDIT_PATIENT_REQUEST });
  await api
    .get(`patient/edit/${id}`, config)
    .then((response) => {
      dispatch({ type: EDIT_PATIENT_REQUEST });
      const { status, data } = response;
      if (status === 200) {
        dispatch({ type: EDIT_PATIENT_SUCCESS, payload: data });
      } else if (status == 404) {
      }
    })
    .catch(() => {
      dispatch({ type: EDIT_PATIENT_FAIL, payload: [] });
    });
};

export const updatePatientAction =
  (
    visit_id,
    commiteId,
    patientId,
    assign,
    occupation,
    nation,
    country,
    married,
    NoFamily,
    province,
    district,
    street,
    closetHome,
    scoundPhoneNo,
    card,
    smoke,
    timeAllot,
    SPID,
    member,
    noMember,
    typ,
    indiction,
    HPE,
    finalDiagnos,
    earlyPatient,
    earlySurgery,
    doctor,
    ZODoctor,
    medicalProvid,
    typeMedical,
    supplyLocation,
    helpingZO,
    reset,
    type_of_help,
    note,
    literate,
    had_surgery
  ) =>
  (dispatch) => {
    // console.log(closetHome);
    // console.log(doctor)
    if (assign == 2) {
      dispatch(updateVisitAction(visit_id));
    }
    // console.log(patientId);
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    api
      .put(
        "patient/update/" + patientId,
        {
          visit_id,
          commiteId,
          patientId,
          assign,
          occupation,
          nation,
          married,
          country,
          NoFamily,
          province,
          district,
          street,
          closetHome,
          scoundPhoneNo,
          card,
          smoke,

          timeAllot,
          SPID,
          member,
          noMember,
          typ,
          indiction,
          HPE,
          finalDiagnos,
          earlyPatient,
          earlySurgery,
          doctor,
          ZODoctor,
          medicalProvid,
          typeMedical,
          supplyLocation,
          helpingZO,
          type_of_help,
          note,
          literate,
          had_surgery,
        },
        config
      )
      .then((response) => {
        console.log(response);
        dispatch({ type: UPDATE_PATIENT_REQUEST });
        if (response.data.status == 200) {
          const update = response.data.update;
          dispatch({ type: UPDATE_PATIENT_SUCCESS, payload: update });
          Swal.fire("Good job!", "Patient Was Assigned", "success");
        } else if (response.data.status == 404) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Some thing went wrong",
          });
        }
      })
      .catch(() => {
        dispatch({ type: UPDATE_PATIENT_FAIL });
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Request Is Faild",
        });
      });
  };

export const createVisitAction = (patient_id) => (dispatch) => {
  console.log(patient_id);
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
  api
    .post("visits/create", { patient_id }, config)
    .then((response) => {
      const { status, data } = response;

      dispatch({
        type: CREATE_VISIT_REQUEST,
      });
      if (status == 201) {
        dispatch({
          type: CREATE_VISIT_SUCCESS,
          payload: data,
          error: "",
        });

        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "success",
          title: "Visit Was Add",
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: CREATE_VISIT_FAIL,
        error: "Please Check your Request",
        payload: [],
      });
    });
};

export const getVisitAction = () => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
  api
    .get("visits", config)
    .then((response) => {
      const { status, data } = response;

      dispatch({
        type: FETCH_VISIT_REQUEST,
      });
      if (status == 200) {
        dispatch({
          type: FETCH_VISIT_SUCCESS,
          payload: data,
          error: "",
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: FETCH_VISIT_FAIL,
        error: "Please Check your Request",
        payload: [],
      });
    });
};

export const updateVisitAction = (visit_id) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
  api
    .put("visits/update", { visit_id }, config)
    .then((response) => {
      const { status, data } = response;

      dispatch({
        type: UPDATE_VISIT_REQUEST,
      });
      if (status == 200) {
        dispatch({
          type: UPDATE_VISIT_SUCCESS,
          payload: data,
          error: "",
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: UPDATE_VISIT_FAIL,
        error: "Please Check your Request",
        payload: [],
      });
    });
};

export const showReportMonthlyAction =
  (
    location_id,
    page,
    perPage,
    from,
    to,
    reject,
    code,
    fullname,
    phone,
    gender,
    age,
    doctor,
    member,
    memberLevel,
    type_of_help,
    type
  ) =>
  (dispatch) => {
    // console.log(reject)
    // dispatch({
    //   type: FETCH_REPORT_MONTHLY_REQUEST,
    // });
    // console.log(type_of_help)
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      params: {
        page,
        location_id,
        perPage,
        from,
        to,
        reject,
        code,
        fullname,
        phone,
        gender,
        age,
        Doctor: doctor,
        member,
        memberLevel,
        type_of_help,
        type,
      },
    };

    api
      .get("patient/reportMonthly", config)
      .then((response) => {
        const { status, data } = response;
        dispatch({
          type: FETCH_REPORT_MONTHLY_REQUEST,
        });

        if (status === 200) {
          console.log(data);
          dispatch({
            type: FETCH_REPORT_MONTHLY_SUCCESS,
            payload: { data: data.data, total: data.total },
            error: "",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_REPORT_MONTHLY_FAIL,
          payload: [],
          error: "",
        });
      });
  };
