import React, { useEffect, useState } from "react";
import "./css/navbar.css";

import { BiLogOut } from "react-icons/bi";
import { HiOutlineBellAlert } from "react-icons/hi2";
import { useDispatch, useSelector } from "react-redux";
// import { getUserAuth } from "../../Action/userRegister";

import Sidebar from "./Sidebar";
import { MdKeyboardDoubleArrowRight, MdManageAccounts } from "react-icons/md";
import { decryptData, logOut } from "../../Action/login";
import { Link, useLocation } from "react-router-dom";

import { getNotificationAction } from "../../Action/receiveAction";
import Footer from "./Footer";
import { FaClinicMedical } from "react-icons/fa";
// import { FaStore } from "react-icons/fa6";
import { sendTokenAction } from "../../Action/SendToken/SendTokenAction";
import { IoIosArrowDown, IoIosKey } from "react-icons/io";
import { IoHomeOutline } from "react-icons/io5";

const Navbar = ({ users }) => {
  const location = useLocation();
  const pathName = location.pathname;
  const [isOpenLogout, setIsOpenLogout] = useState(false);
  const getNotification = useSelector((state) => state.getNotification);
  const { notifi } = getNotification;
  const dispatch = useDispatch();
  // const getUsers = useSelector((state) => state.getUser);
  // const { users } = getUsers;
  // const notifi = localStorage.getItem("notifi");
  const locationID = localStorage.getItem("locationId");
  let first = [];
  useEffect(() => {
    if (locationID == "" || locationID == null || locationID == undefined) {
      return;
    } else {
      dispatch(getNotificationAction(locationID));
    }
  }, [locationID]);
  const [firstCharchter, setFirstCharecter] = useState([]);
  const [sectionId, setSectionId] = useState(null);
  useEffect(() => {
    if (users?.fullname_en == undefined || users?.fullname_en == null) {
      return;
    }
    first.push(users?.fullname_en);
    setSectionId(users?.role?.permission[0]?.section_id);

    setFirstCharecter(first[0]?.charAt(0));
  }, [firstCharchter, users]);

  const handelLogoutAccount = () => {
    dispatch(logOut());
  };
  const handelLogout = () => {
    setIsOpenLogout(isOpenLogout ? false : true);
  };
  const [isOpenSideBar, setIsOpenSideBar] = useState(false);
  const handelSideBarOpen = () => {
    setIsOpenSideBar(true);
  };
  const isLoggedIn = localStorage.getItem("loggedIn");
  if (isLoggedIn == null || isLoggedIn == undefined) {
    return null;
  }

  const decrypteData = decryptData(isLoggedIn, "8");
  return (
    <>
      {users == undefined ? (
        ""
      ) : (
        <div className={decrypteData ? "flex" : "hidden"}>
          <iframe
            className="hidden"
            id="receiver-iframe"
            src="https://zadorg.site"
            title="Receiver Iframe"
          ></iframe>
          <div>
            {" "}
            <Sidebar
              isOpenSidebar={isOpenSideBar}
              openModal={setIsOpenSideBar}
              users={users}
            />
          </div>
          <div className="containerNav  bg-[#f0f0f0] border-b-2  text-black">
            {/* <hr /> */}
            <div className="flex items-center lg:gap-5">
              {pathName == "/" ? (
                ""
              ) : pathName == "/editProfile" ? (
                ""
              ) : (
                <div
                  className="cursor-pointer  text-[1.9rem] animate-pulse"
                  onClick={handelSideBarOpen}
                >
                  <MdKeyboardDoubleArrowRight />
                </div>
              )}
              <div>
                <p className="text-sm lg:text-2xl ">ZAD Organization</p>
              </div>
            </div>
            <div
              className=" text-xl tracking-wider  cursor-pointer"
              // onClick={handelLogout}
            >
              <div className="flex gap-4 items-center">
                <div title="Home">
                  <Link to={"/"}>
                    <IoHomeOutline />
                  </Link>
                </div>
                {pathName == "/" ? (
                  ""
                ) : (
                  <>
                    {users?.section_id == 5 && users?.rule != "Staff" ? (
                      <Link to={`receive`}>
                        <div className="relative top-[2px]">
                          <p className=" ">
                            <HiOutlineBellAlert className="animate-pulse text-md " />
                          </p>

                          <div className="absolute  text-white -top-4 -right-2 text-sm text-center w-5 h-5 rounded-full bg-red-700">
                            <span>{notifi}</span>
                          </div>
                        </div>
                      </Link>
                    ) : (
                      ""
                    )}
                  </>
                )}
                <div onClick={handelLogout} className="flex items-center ">
                  <p className="  px-2   text-black">
                    {users?.fullname_en?.toString().split(" ")[0]}
                  </p>
                  <div>
                    <p className={isOpenLogout ?"rotate-180 duration-500":" duration-500"}>
                      <IoIosArrowDown />
                    </p>
                  </div>
                </div>
              </div>

              <div
                // onClick={handelLogoutAccount}
                className={
                  isOpenLogout
                    ? "absolute top-10 right-2 bg-[#fff] text-gray-900  shadow-xl rounded-md    duration-400   text-base "
                    : " absolute right-2  bg-[#fff] text-base  shadow-2xl  rounded-md opacity-0  duration-500 hidden   "
                }
              >
                {/* <Link to={"/"} onClick={handelLogout}>
                  <div className="pb-2 flex items-center gap-2">
                  <p className="text-black text-xl">
                      {" "}
                      <RiAccountPinCircleFill />{" "}
                    </p>
                    <p>{users.fullname_en}</p>
                    
                  </div>
                </Link> */}
                {/* 
                <hr /> */}

                <Link to={`editProfile`} onClick={handelLogout}>
                  <div className="py-2 flex gap-3 items-center hover:bg-indigo-600 rounded-md hover:text-white px-2 duration-500 ">
                    <div className="text-gray-800 text-xl">
                      <IoIosKey />
                    </div>
                    <p>Change Password</p>
                  </div>
                </Link>

                <hr />

                {sectionId == 8 ? (
                  <Link
                    to={`https://zadorg.site/ `}
                    onClick={() => {
                      sendTokenAction("https://zadorg.site/");
                    }}
                  >
                    <div className="py-2 flex gap-3 items-center hover:bg-indigo-600 rounded-md hover:text-white px-2 duration-500 ">
                      <div className=" text-xl">
                        <FaClinicMedical />
                      </div>
                      <p>Go To Zad Medical</p>
                    </div>
                    <hr />
                  </Link>
                ) : (
                  ""
                )}

                <hr />

                <button
                  onClick={handelLogoutAccount}
                  className="py-2 flex gap-3 items-center hover:bg-indigo-600 rounded-md hover:text-white px-2 duration-500 w-full "
                >
                  <div className="rotate-180">
                    <BiLogOut className=" text-xl" />
                  </div>
                  <p>Sign Out</p>
                </button>
              </div>
            </div>
          </div>
          <hr />
          <Footer />
        </div>
      )}
    </>
  );
};

export default Navbar;
