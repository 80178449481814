import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  createOrganLevelAction,
  getAllTypeSurgeryAction,
} from "../../Action/SurgeryTypeAction/surgery";
import { BsFillTrashFill } from "react-icons/bs";

const OrganLevelSurgery = ({ isOpenModal, closeModal }) => {
  const dispatch = useDispatch();
  const [typeSurgeryId, setTypeSurgeryId] = useState(null);
  const getAllTypeSurgery = useSelector((state) => state.getAllTypeSurgery);
  const { typeSurgery } = getAllTypeSurgery;
  const [error, setError] = useState();
  let typeSurgeryArray = [];
  useEffect(() => {
    if (isOpenModal) {
      dispatch(getAllTypeSurgeryAction());
    }
    return;
  }, [isOpenModal]);

  typeSurgery.map((item) => {
    typeSurgeryArray.push({
      value: item.id,
      label: item.typeName,
    });
  });
  const handleChangeOption = (selectedOption) => {
    setTypeSurgeryId(selectedOption.value);
  };
  const [errorInput, setErrorInput] = useState(null);
  const [inputs, setInputs] = useState([]); // Initial input field

  const addInput = () => {
    setInputs([...inputs, ""]);
  };

  const removeInput = (index) => {
    const newInputs = [...inputs];
    newInputs.splice(index, 1);
    setInputs(newInputs);
  };

  const handleInputChange = (index, value) => {
    // if (value.length < 0) {
    //     setErrorInput("Pleas Enter Organ Level")
    //     return;
    // }
    const newInputs = [...inputs];
    newInputs[index] = value;
    setInputs(newInputs);
  };

  const handleSave = (event) => {
    event.preventDefault();

    if (inputs.every((input) => input.trim() !== "")) {
      setInputs([...inputs, ""]);
    } else {
      setErrorInput("Please Fill The Gaps");
      return;
    }
    if (typeSurgeryId == null) {
      setError("Please Check Your type Surgery");
      return;
    }
    if (inputs.length < 1) {
      setError("Please Enter Organ Level");
      return;
    }
    dispatch(createOrganLevelAction(typeSurgeryId, inputs));

    setInputs([""]);
    closeModal(false);
  };

  return (
    <div
      className={
        isOpenModal
          ? "h-screen fixed w-full bg-gray-400 duration-500 opacity-100 bg-opacity-20 top-0 left-0 z-50  "
          : "h-screen fixed w-full bg-gray-400   -z-50 opacity-0 duration-500 bg-opacity-20 top-0 left-0 "
      }
    >
      <div
        className="   h-screen  w-full p-20  relative cursor-pointer  z-20"
        onClick={() => closeModal(false)}
      />

      <div
        className={
          inputs.length <= 5
            ? "bg-white rounded-md shadow-md w-full xl:w-[40%] z-50 px-3 py-4 absolute top-10 left-[48%] -translate-x-[45%]"
            : "bg-white rounded-md shadow-md h-[90%] w-full xl:w-[40%] z-50 px-3 py-4 absolute top-10 left-[48%] -translate-x-[45%] overflow-y-auto"
        }
      >
        <div className="flex justify-between mb-2">
          <div className="flex items-center gap-4">
            <p>(ئەندامی نەشتەرگەری لەسەر کراو) ئەندام </p>
          </div>
          <div
            className="text-2xl hover:backdrop-blur-xl  cursor-pointer"
            onClick={() => closeModal(false)}
          >
            x
          </div>
        </div>
        <hr />
        <div className="flex flex-col gap-2">
          <label htmlFor="typ" className="text-right">
          * (ئەندامی نەشتەرگەری لەسەرکراو) ئەندام 
          </label>
          <Select
            // isMulti
            options={typeSurgeryArray}
            onChange={handleChangeOption}
            isRtl
            // value={}
            //   isRtl={true}
          />
          <div className="text-red-500 text-lg">{error}</div>
        </div>

        <div className="App w-full">
          <button
            className="btnCoustmDesignBlue  mt-2 px-3 flex justify-end"
            onClick={addInput}
          >
            +
          </button>
          {inputs.map((input, index) => (
            <div
              key={index}
              className=" flex items-center flex-row-reverse gap-4"
            >
              <input
                type="text"
                placeholder=" (جۆری نەشتەرگەری) لەسەر ئاستی ئەندام"
                className="inputLogin w-11/12 text-right"
                value={input}
                onChange={(e) => handleInputChange(index, e.target.value)}
              />

              <button
                className="btnCoustmDesignRed p-2 mt-2"
                onClick={() => removeInput(index)}
              >
                <BsFillTrashFill />
              </button>
            </div>
          ))}
          <div className="text-lg text-red-500">{errorInput}</div>
          <button
            onClick={handleSave}
            className="btnCoustmDesignGreen mt-2 ml-1"
          >
            {" "}
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrganLevelSurgery;
