export const reciveMessageAction = (event , params) => {
  // console.log("hello")
  console.log(event.origin)
  if (event.origin == params) {
    
    localStorage.setItem("token", event.data.token);
    localStorage.setItem("loggedIn", event.data.loggedIn);
    localStorage.setItem("defaultPackage", event.data.defaultPackage);
    
  }
  
};


export const sendTokenAction = (params) => {
  // console.log(params)
    // const sendMessageToReceiver = () => {
    const receiverWindow =
      document.getElementById("receiver-iframe").contentWindow;
  
    // Specify the target origin (the origin of the receiving window or iframe)
    const targetOrigin = params;
  
    var token = localStorage.getItem("token");
    var loggedIn = localStorage.getItem("loggedIn");
    var defaultPackage = localStorage.getItem("defaultPackage");
  
    // Create an object to hold both token and loggedIn status
    var messageData = {
      token: token,
      loggedIn: loggedIn,
      defaultPackage: defaultPackage,
    };
  
    // Use postMessage to send the messageData to the target window
    receiverWindow.postMessage(messageData, targetOrigin);
  };
  