import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { getTypeSurgeryAction } from '../../Action/Surgery/surgeryAction';

const ViewSurgeryModal = ({ closeModal, isOpenModal, id }) => {
    const dispatch = useDispatch();
    const getTypeSurgery = useSelector(state => state.getTypeSurgery)
    const { typeSurgery, loading } = getTypeSurgery
    useEffect(() => {
        if (id == null || id == undefined || id == "") {
            return;
        } else {
            dispatch(getTypeSurgeryAction(id));
        }
    }, [id]);
    return (

        <>
            
                 <div
                    className={
                        isOpenModal
                            ? "h-screen fixed w-full bg-gray-400 duration-500 opacity-100 bg-opacity-20 top-0 left-0 z-50  "
                            : "h-screen fixed w-full bg-gray-400   -z-50 opacity-0 duration-500 bg-opacity-20 top-0 left-0 "
                    }
                >
                    <div
                        className="h-screen w-full p-20  relative cursor-pointer  z-20"
                        onClick={() => closeModal(false)}
                    />




                    <div className="bg-white overflow-y-auto z-50 h-auto w-11/12  xl:w-[70%] py-3 px-2 absolute top-[10%] left-4 md:left-8 xl:left-[16%]  -translate-X-[10%]  rounded-md shadow-lg  ">

                        <div class="relative overflow-x-auto h-full">
                            <table className="w-full m-auto">
                                <thead className="sticky top-0 ">
                                    <tr>
                                        <th className="text-base   font-bold">#</th>
                                        <th className="text-base font-bold">Surgery Name </th>
                                        <th className="text-base font-bold">Type Surgery Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        typeSurgery.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.surguryName}</td>
                                                    <td>{item.typeName}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>

                            </table>
                        </div>
                    </div>

                </div>
            

        </>
    )
}

export default ViewSurgeryModal