export const FETCH_SURGERY_REQUEST="FETCH_SURGERY_REQUEST";
export const FETCH_SURGERY_SUCCESS="FETCH_SURGERY_SUCCESS";
export const FETCH_SURGERY_FAIL="FETCH_SURGERY_FAIL";

export const CREATE_SURGERY_REQUEST="CREATE_SURGERY_REQUEST";
export const CREATE_SURGERY_SUCCESS="CREATE_SURGERY_SUCCESS";
export const CREATE_SURGERY_FAIL="CREATE_SURGERY_FAIL";


export const FETCH_TYPE_SURGERY_REQUEST="FETCH_TYPE_SURGERY_REQUEST";
export const FETCH_TYPE_SURGERY_SUCCESS="FETCH_TYPE_SURGERY_SUCCESS";
export const FETCH_TYPE_SURGERY_FAIL="FETCH_TYPE_SURGERY_FAIL";


export const CREATE_ORGAN_LEVEL_REQUEST="CREATE_ORGAN_LEVEL_REQUEST";
export const CREATE_ORGAN_LEVEL_SUCCESS="CREATE_ORGAN_LEVEL_SUCCESS";
export const CREATE_ORGAN_LEVEL_FAIL="CREATE_ORGAN_LEVEL_FAIL";

