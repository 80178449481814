export const CREATE_PATIENT_REQUEST = "CREATE_PATIENT_REQUEST";
export const CREATE_PATIENT_SUCCESS = "CREATE_PATIENT_SUCCESS";
export const CREATE_PATIENT_FAIL = "CREATE_PATIENT_FAIL";

export const CREATE_PATIENT_ADMINSTRATOR_REQUEST = "CREATE_PATIENT_ADMINSTRATOR_REQUEST";
export const CREATE_PATIENT_ADMINSTRATOR_SUCCESS = "CREATE_PATIENT_ADMINSTRATOR_SUCCESS";
export const CREATE_PATIENT_ADMINSTRATOR_FAIL = "CREATE_PATIENT_ADMINSTRATOR_FAIL";

export const FETCH_PATIENT_REQUEST = "FETCH_PATIENT_REQUEST";
export const FETCH_PATIENT_SUCCESS = "FETCH_PATIENT_SUCCESS";
export const FETCH_PATIENT_FAIL = "FETCH_PATIENT_FAIL";

export const FETCH_ALL_INFO_PATIENT_REQUEST = "FETCH_ALL_INFO_PATIENT_REQUEST";
export const FETCH_ALL_INFO_PATIENT_SUCCESS = "FETCH_ALL_INFO_PATIENT_SUCCESS";
export const FETCH_ALL_INFO_PATIENT_FAIL = "FETCH_ALL_INFO_PATIENT_FAIL";

export const REJECT_PATIENT_REQUEST = "REJECT_PATIENT_REQUEST";
export const REJECT_PATIENT_SUCCESS = "REJECT_PATIENT_SUCCESS";
export const REJECT_PATIENT_FAIL = "REJECT_PATIENT_FAIL";

export const EDIT_PATIENT_REQUEST = "EDIT_PATIENT_REQUEST";
export const EDIT_PATIENT_SUCCESS = "EDIT_PATIENT_SUCCESS";
export const EDIT_PATIENT_FAIL = "EDIT_PATIENT_FAIL";

export const UPDATE_PATIENT_REQUEST = "UPDATE_PATIENT_REQUEST";
export const UPDATE_PATIENT_SUCCESS = "UPDATE_PATIENT_SUCCESS";
export const UPDATE_PATIENT_FAIL = "UPDATE_PATIENT_FAIL";

export const FETCH_REPORT_MONTHLY_REQUEST = "FETCH_REPORT_MONTHLY_REQUEST";
export const FETCH_REPORT_MONTHLY_SUCCESS = "FETCH_REPORT_MONTHLY_SUCCESS";
export const FETCH_REPORT_MONTHLY_FAIL = "FETCH_REPORT_MONTHLY_FAIL";
