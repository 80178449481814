import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import { decryptData } from "./Action/login";

const ProtectedRoute = () => {
 
  // const isLoggedIn = localStorage.getItem("loggedIn");
  const token = localStorage.getItem("token");
  const decryptedDate = decryptData();
//   if (token == null && decryptedDate !== "true") {
//     return <Navigate to="/login" />;
//   }

  return decryptedDate =='true'  ?<Outlet/> :<Navigate to={'/login'}/>
  
  
};

export default ProtectedRoute;
