import {
  FETCH_INFO_WHATS_APP_BY_VISIT_FAIL,
  FETCH_INFO_WHATS_APP_BY_VISIT_REQUEST,
  FETCH_INFO_WHATS_APP_BY_VISIT_SUCCESS,
  FETCH_INFO_WHATS_APP_EXCEL_FAIL,
  FETCH_INFO_WHATS_APP_EXCEL_REQUEST,
  FETCH_INFO_WHATS_APP_EXCEL_SUCCESS,
  FETCH_INFO_WHATS_APP_FAIL,
  FETCH_INFO_WHATS_APP_REQUEST,
  FETCH_INFO_WHATS_APP_SUCCESS,
  SEND_INFO_WHATS_APP_FAIL,
  SEND_INFO_WHATS_APP_REQUEST,
  SEND_INFO_WHATS_APP_SUCCESS,
} from "../../ActionType/WhatsApp/WhatsApp";

export const sendWhatsAppReducer = (
  state = { loading: true, send: [], error: [] },
  action
) => {
  switch (action.type) {
    case SEND_INFO_WHATS_APP_REQUEST:
      return {
        loading: true,
      };
    case SEND_INFO_WHATS_APP_SUCCESS: {
      return {
        loading: false,
        send: action.payload,
        error: "",
      };
    }
    case SEND_INFO_WHATS_APP_FAIL:
      return {
        loading: false,
        send: [],
        error: action.error,
      };

    default:
      return state;
  }
};

export const getWhatsUpInfoReducer = (
  state = { loading: true, data: [], total: 0, error: [] },
  action
) => {
  switch (action.type) {
    case FETCH_INFO_WHATS_APP_REQUEST:
      return {
        loading: true,
      };
    case FETCH_INFO_WHATS_APP_SUCCESS: {
      return {
        loading: false,
        data: action.payload.data,
        total: action.payload.total,
        error: "",
      };
    }
    case FETCH_INFO_WHATS_APP_FAIL:
      return {
        loading: false,
        data: [],
        error: action.error,
      };

    default:
      return state;
  }
};

export const getWhatsAppInByVisitReducer = (
  state = { loading: true, whatsAppInfo: [], error: [] },
  action
) => {
  switch (action.type) {
    case FETCH_INFO_WHATS_APP_BY_VISIT_REQUEST:
      return {
        loading: true,
      };
    case FETCH_INFO_WHATS_APP_BY_VISIT_SUCCESS: {
      return {
        loading: false,
        whatsAppInfo: action.payload,
        // total:action.payload.total,
        error: "",
      };
    }
    case FETCH_INFO_WHATS_APP_BY_VISIT_FAIL:
      return {
        loading: false,
        whatsAppInfo: [],
        error: action.error,
      };

    default:
      return state;
  }
};

export const getWhatsAppInfoExcelReducer = (
  state = { loading: true, whatsAppInfo: [], error: [] },
  action
) => {
  switch (action.type) {
    case FETCH_INFO_WHATS_APP_EXCEL_REQUEST:
      return {
        loading: true,
      };
    case FETCH_INFO_WHATS_APP_EXCEL_SUCCESS: {
      return {
        loading: false,
        whatsAppInfo: action.payload,
        // total:action.payload.total,
        error: "",
      };
    }
    case FETCH_INFO_WHATS_APP_EXCEL_FAIL:
      return {
        loading: false,
        whatsAppInfo: [],
        error: action.error,
      };

    default:
      return state;
  }
};
