import React, { useState } from "react";
import "./RolesModal.css";
import { useDispatch, useSelector } from "react-redux";
import { createRolesAction, getRolesAction } from "../../Action/RolesAction/RolesAction";
const RolesModal = ({ closeModal }) => {
  const [roles, setRoles] = useState(null);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const createRoles=useSelector(state=>state.createRoles)
  const {loading}=createRoles
  const handleSubmit = (event) => {
    event.preventDefault();
    if (roles == null) {
      setError("تکایە خانەکە پڕکەرەوە");
      return;
    }
    setError("");
    dispatch(createRolesAction(roles));
    dispatch(getRolesAction(1, 10));
    dispatch(getRolesAction(1, 10));
    closeModal(false)
  };
  return (
    <div className="parentModalPrimary">
      <div
        className="overlayModalPrimary"
        onClick={() => closeModal(false)}
      ></div>
      <div className="containerModalPrimary">
        {/* Your modal content goes here */}
        <p className="text-xl font-bold">Add Roles</p>
        <form action="">
          <div>
            <input
              type="text"
              onChange={(e) => setRoles(e.target.value)}
              className="inputLogin"
              placeholder="Roles"
            />
            <div>
              <p className="text-right text-red-500 mr-2 font-bold animate-pulse">
                {error}
              </p>
            </div>
          </div>
          <div className="flex gap-2 mt-2">
            <button className="btnViolet" onClick={(e) => handleSubmit(e)}>
              Save
            </button>
            <button className="btnRed" onClick={() => closeModal(false)}>
              Close
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RolesModal;
