import {
  APPROVED_MEDIA_WORK_FAIL,
  APPROVED_MEDIA_WORK_REQUEST,
  APPROVED_MEDIA_WORK_SUCCESS,
  FETCH_ALL_INFO_MEDIA_FAIL,
  FETCH_ALL_INFO_MEDIA_REQUEST,
  FETCH_ALL_INFO_MEDIA_SUCCESS,
  FETCH_MEDIA_RECIVE_VISIT_FAIL,
  FETCH_MEDIA_RECIVE_VISIT_REQUEST,
  FETCH_MEDIA_RECIVE_VISIT_SUCCESS,
  FETCH_PATIENT_MEDIA_FAIL,
  FETCH_PATIENT_MEDIA_REQUEST,
  FETCH_PATIENT_MEDIA_SUCCESS,
  FETCH_SORTING_MEDIA_WORK_FAIL,
  FETCH_SORTING_MEDIA_WORK_REQUEST,
  FETCH_SORTING_MEDIA_WORK_SUCCESS,
  GET_ALL_INFO_PATIENT_MEDIA_FAIL,
  GET_ALL_INFO_PATIENT_MEDIA_REQUEST,
  GET_ALL_INFO_PATIENT_MEDIA_SUCCESS,
  GET_MEDIA_WORK_FAIL,
  GET_MEDIA_WORK_REQUEST,
  GET_MEDIA_WORK_SUCCESS,
  RECEIVE_PATIENT_INFO_MEDIA_FAIL,
  RECEIVE_PATIENT_INFO_MEDIA_REQUEST,
  RECEIVE_PATIENT_INFO_MEDIA_SUCCESS,
  REJECT_MEDIA_RECIVE_FAIL,
  REJECT_MEDIA_RECIVE_REQUEST,
  REJECT_MEDIA_RECIVE_SUCCESS,
  SEEN_MEDIA_RECIVE_FAIL,
  SEEN_MEDIA_RECIVE_REQUEST,
  SEEN_MEDIA_RECIVE_SUCCESS,
  SEND_PATIENT_INFO_MEDIA_FAIL,
  SEND_PATIENT_INFO_MEDIA_REQUEST,
  SEND_PATIENT_INFO_MEDIA_SUCCESS,
  STORE_ALL_INFO_PATIENT_MEDIA_FAIL,
  STORE_ALL_INFO_PATIENT_MEDIA_REQUEST,
  STORE_ALL_INFO_PATIENT_MEDIA_SUCCESS,
  UPDATE_SECTION_MEDIA_WORK_FAIL,
  UPDATE_SECTION_MEDIA_WORK_REQUEST,
  UPDATE_SECTION_MEDIA_WORK_SUCCESS,
} from "../../ActionType/MediaType/sendMediaType";

export const sendInfoPatientReducer = (
  state = { loading: true, send: [], error: "" },
  action
) => {
  switch (action.type) {
    case SEND_PATIENT_INFO_MEDIA_REQUEST:
      return {
        loading: true,
      };
    case SEND_PATIENT_INFO_MEDIA_SUCCESS:
      return {
        loading: false,
        send: action.payload,
        error: "",
      };
    case SEND_PATIENT_INFO_MEDIA_FAIL:
      return {
        loading: false,
        send: [],
        error: "Please Check Your Request",
      };
    default:
      return state;
  }
};

export const receivePatientInfoMeidaReducer = (
  state = { loading: true, receive: [], error: "" },
  action
) => {
  switch (action.type) {
    case RECEIVE_PATIENT_INFO_MEDIA_REQUEST:
      return {
        loading: true,
      };
    case RECEIVE_PATIENT_INFO_MEDIA_SUCCESS:
      return {
        loading: false,
        receive: action.payload,
        error: "",
      };
    case RECEIVE_PATIENT_INFO_MEDIA_FAIL:
      return {
        loading: false,
        receive: [],
        error: "Please Chekc Your Request",
      };
    default:
      return state;
  }
};

export const storeAllInfoPatientReducer = (
  state = { loading: true, storeAllInfo: [], error: "" },
  action
) => {
  switch (action.type) {
    case STORE_ALL_INFO_PATIENT_MEDIA_REQUEST:
      return {
        loading: true,
      };
    case STORE_ALL_INFO_PATIENT_MEDIA_SUCCESS:
      return {
        loading: false,
        storeAllInfo: action.payload,
        error: "",
      };
    case STORE_ALL_INFO_PATIENT_MEDIA_FAIL:
      return {
        loading: false,
        storeAllInfo: [],
        error: "Please Check Your Request",
      };
    default:
      return state;
  }
};

export const getCoustmMediaInfoReducer = (
  state = { loading: true, total: 0, getMediaInfo: [], error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_PATIENT_MEDIA_REQUEST:
      return {
        loading: true,
      };
    case FETCH_PATIENT_MEDIA_SUCCESS:
      return {
        loading: false,
        getMediaInfo: action.payload,
        total: action.total,
        error: "",
      };
    case FETCH_PATIENT_MEDIA_FAIL:
      return {
        loading: false,
        getMediaInfo: [],
        error: "Please Check Your Request",
      };
    default:
      return state;
  }
};

export const getInfoFromLezhnaTbaleReducer = (
  state = { loading: true, total: 0, datas: [], error: "" },
  action
) => {
  switch (action.type) {
    case GET_MEDIA_WORK_REQUEST:
      return {
        loading: true,
      };
    case GET_MEDIA_WORK_SUCCESS:
      return {
        loading: false,
        datas: action.payload,
        // total: action.total,
        error: "",
      };
    case GET_MEDIA_WORK_FAIL:
      return {
        loading: false,
        datas: [],
        error: "Please Check Your Request",
      };
    default:
      return state;
  }
};

export const getAllInfoPatientMedaReducer = (
  state = { loading: true, allInfo: [], total: 0, error: "" },
  action
) => {
  switch (action.type) {
    case GET_ALL_INFO_PATIENT_MEDIA_REQUEST:
      return {
        loading: true,
      };
    case GET_ALL_INFO_PATIENT_MEDIA_SUCCESS:
      return {
        loading: false,
        allInfo: action.payload.data,
        total: action.payload.total,
        error: "",
      };
    case GET_ALL_INFO_PATIENT_MEDIA_FAIL:
      return {
        loading: false,
        allInfo: [],
        error: "Please Check Your Request",
      };
    default:
      return state;
  }
};

export const getSortingWorkMediaReducer = (
  state = { loading: true, sortingWork: [], total: 0, error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_SORTING_MEDIA_WORK_REQUEST:
      return {
        loading: true,
      };
    case FETCH_SORTING_MEDIA_WORK_SUCCESS:
      return {
        loading: false,
        sortingWork: action.payload,
        total: action.total,
        error: "",
      };
    case FETCH_SORTING_MEDIA_WORK_FAIL:
      return {
        loading: false,
        sortingWork: [],
        error: "Please Check Your Request",
      };
    default:
      return state;
  }
};

export const updateSortingWorkMediaReducer = (
  state = { loading: true, sortingWork: [], error: "" },
  action
) => {
  switch (action.type) {
    case UPDATE_SECTION_MEDIA_WORK_REQUEST:
      return {
        loading: true,
      };
    case UPDATE_SECTION_MEDIA_WORK_SUCCESS:
      return {
        loading: false,
        sortingWork: action.payload,
        error: "",
      };
    case UPDATE_SECTION_MEDIA_WORK_FAIL:
      return {
        loading: false,
        sortingWork: [],
        error: "Please Check Your Request",
      };
    default:
      return state;
  }
};

export const approvedMediaWorkReducer = (
  state = { loading: true, approved: [], error: "" },
  action
) => {
  switch (action.type) {
    case APPROVED_MEDIA_WORK_REQUEST:
      return {
        loading: true,
      };
    case APPROVED_MEDIA_WORK_SUCCESS:
      return {
        loading: false,
        approved: action.payload,
        error: "",
      };
    case APPROVED_MEDIA_WORK_FAIL:
      return {
        loading: false,
        approved: [],
        error: "Please Check Your Request",
      };
    default:
      return state;
  }
};

export const seenReciveMediaReducer = (
  state = { loading: true, total: 0, datas: [], error: "" },
  action
) => {
  switch (action.type) {
    case SEEN_MEDIA_RECIVE_REQUEST:
      return {
        loading: true,
      };
    case SEEN_MEDIA_RECIVE_SUCCESS:
      return {
        loading: false,
        datas: action.payload,
        // total: action.total,
        error: "",
      };
    case SEEN_MEDIA_RECIVE_FAIL:
      return {
        loading: false,
        datas: [],
        error: "Please Check Your Request",
      };
    default:
      return state;
  }
};

export const getReciveMediaByVisitReducer = (
  state = { loading: true, receiveByVisit: [], error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_MEDIA_RECIVE_VISIT_REQUEST:
      return {
        loading: true,
      };
    case FETCH_MEDIA_RECIVE_VISIT_SUCCESS:
      return {
        loading: false,
        receiveByVisit: action.payload,
        error: "",
      };
    case FETCH_MEDIA_RECIVE_VISIT_FAIL:
      return {
        loading: false,
        receiveByVisit: [],
        error: "Please Check Your Request",
      };
    default:
      return state;
  }
};

export const rejectReciveMediaReducer = (
  state = { loading: true, rejectMedia: [], error: "" },
  action
) => {
  switch (action.type) {
    case REJECT_MEDIA_RECIVE_REQUEST:
      return {
        loading: true,
      };
    case REJECT_MEDIA_RECIVE_SUCCESS:
      return {
        loading: false,
        receiveByVisit: action.payload,
        error: "",
      };
    case REJECT_MEDIA_RECIVE_FAIL:
      return {
        loading: false,
        receiveByVisit: [],
        error: "Please Check Your Request",
      };
    default:
      return state;
  }
};

export const getAllInfoMediaReducer = (
  state = { loading: true, allInfo: [], error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_ALL_INFO_MEDIA_REQUEST:
      return {
        loading: true,
      };
    case FETCH_ALL_INFO_MEDIA_SUCCESS:
      return {
        loading: false,
        allInfo: action.payload,
        error: "",
      };
    case FETCH_ALL_INFO_MEDIA_FAIL:
      return {
        loading: false,
        allInfo: [],
        error: "Please Check Your Request",
      };
    default:
      return state;
  }
};

