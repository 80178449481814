import React from 'react';

const DangerAlert = () => {
  return (
    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mt-2" role="alert">
      <strong className="font-bold">Alert!</strong>
      <span className="block sm:inline"> Please Check All Fild</span>
    </div>
  );
};

export default DangerAlert;
