export const FETCH_VISIT_REQUEST="FETCH_VISIT_REQUEST"
export const FETCH_VISIT_SUCCESS="FETCH_VISIT_SUCCESS"
export const FETCH_VISIT_FAIL="FETCH_VISIT_FAIL"

export const CREATE_VISIT_REQUEST="CREATE_VISIT_REQUEST"
export const CREATE_VISIT_SUCCESS="CREATE_VISIT_SUCCESS"
export const CREATE_VISIT_FAIL="CREATE_VISIT_FAIL"

export const UPDATE_VISIT_REQUEST="UPDATE_VISIT_REQUEST"
export const UPDATE_VISIT_SUCCESS="UPDATE_VISIT_SUCCESS"
export const UPDATE_VISIT_FAIL="UPDATE_VISIT_FAIL"