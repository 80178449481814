import {
  CREATE_PATIENT_FAIL,
  CREATE_PATIENT_REQUEST,
  CREATE_PATIENT_SUCCESS,
  EDIT_PATIENT_FAIL,
  EDIT_PATIENT_REQUEST,
  EDIT_PATIENT_SUCCESS,
  FETCH_ALL_INFO_PATIENT_FAIL,
  FETCH_ALL_INFO_PATIENT_REQUEST,
  FETCH_ALL_INFO_PATIENT_SUCCESS,
  FETCH_PATIENT_FAIL,
  FETCH_PATIENT_REQUEST,
  FETCH_PATIENT_SUCCESS,
  FETCH_REPORT_MONTHLY_FAIL,
  FETCH_REPORT_MONTHLY_REQUEST,
  FETCH_REPORT_MONTHLY_SUCCESS,
  REJECT_PATIENT_FAIL,
  REJECT_PATIENT_REQUEST,
  REJECT_PATIENT_SUCCESS,
  UPDATE_PATIENT_FAIL,
  UPDATE_PATIENT_REQUEST,
  UPDATE_PATIENT_SUCCESS,
} from "../ActionType/patientType";

export const patientCreateReducer = (
  state = { loading: true, patient: [], error: "" },
  action
) => {
  switch (action.type) {
    case CREATE_PATIENT_REQUEST:
      return {
        loading: true,
      };
    case CREATE_PATIENT_SUCCESS:
      return {
        loading: false,
        patient: action.payload,

        error: "",
      };
    case CREATE_PATIENT_FAIL:
      return {
        loading: false,
        patient: [],
        error: "Please Check Your request",
      };
    default:
      return state;
  }
};

export const createPatientAdminstratorReducer = (
  state = { loading: true, patient: [], error: "" },
  action
) => {
  switch (action.type) {
    case CREATE_PATIENT_REQUEST:
      return {
        loading: true,
      };
    case CREATE_PATIENT_SUCCESS:
      return {
        loading: false,
        patient: action.payload,

        error: "",
      };
    case CREATE_PATIENT_FAIL:
      return {
        loading: false,
        patient: [],
        error: "Please Check Your request",
      };
    default:
      return state;
  }
};
export const getPatientReducer = (
  state = { loading: true, patients: [], total: 0, error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_PATIENT_REQUEST:
      return {
        loading: true,
      };
    case FETCH_PATIENT_SUCCESS:
      return {
        loading: false,
        patients: action.payload,
        total: action.total,
        // links:action.links,
        error: "",
      };
    case FETCH_PATIENT_FAIL:
      return {
        loading: false,
        patient: [],
        error: "Please Check Your request",
      };
    default:
      return state;
  }
};

export const allInfoPatientReducer = (
  state = { loading: true, allInfo: [],visit:[],image:[], error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_ALL_INFO_PATIENT_REQUEST:
      return {
        loading: true,
        // allInfo:[]
      };
    case FETCH_ALL_INFO_PATIENT_SUCCESS:
      
      return {
        loading: false,
        allInfo: action.payload,
        visit:action.visit,
        image:action.image,
        error: "",
      };
    case FETCH_ALL_INFO_PATIENT_FAIL:
      return {
        loading: false,
        // allInfo: [],
        // visit:[],
        // image:[],
        error: "Please check Your Request",
      };
    default:
      return state;
  }
};

export const rejectPatientReducer = (
  state = { loading: false, data: [], error: "" },
  action
) => {
  switch (action.type) {
    case REJECT_PATIENT_REQUEST:
      return {
        loading: true,
      };
    case REJECT_PATIENT_SUCCESS:
      return {
        loading: false,
        data: action.payload,
        error: "",
      };
    case REJECT_PATIENT_FAIL:
      return {
        loading: false,
        data: [],
        error: "Please Check Your Request",
      };
    default:
      return state;
  }
};

export const editPatientReducer = (
  state = { loading: true, patients: [], error: "" },
  action
) => {
  switch (action.type) {
    case EDIT_PATIENT_REQUEST:
      return {
        loading: true,
      };
    case EDIT_PATIENT_SUCCESS:
      return {
        loading: false,
        patients: action.payload,
        error: "",
      };
    case EDIT_PATIENT_FAIL:
      return {
        loading: false,
        patients: [],
        error: "Please Check Your Request",
      };
    default:
      return state;
  }
};

export const updatePatientReducer = (
  state = { loading: true, update: [], error: "" },
  action
) => {
  switch (action.type) {
    case UPDATE_PATIENT_REQUEST:
      return {
        loading: true,
      };
    case UPDATE_PATIENT_SUCCESS:
      return {
        loading: false,
        update: action.payload,
      };
    case UPDATE_PATIENT_FAIL:
      return {
        loading: false,
        update: [],
        error: "Please Check Your Request",
      };
    default:
      return state;
  }
};

export const showReportMonthlyReducer = (state ={loading:true,report:[],total:0,error:""} , action) => {
  switch (action.type) {
    case FETCH_REPORT_MONTHLY_REQUEST:
      return {
        loading:true,
      }
    case FETCH_REPORT_MONTHLY_SUCCESS:
      return{
        loading:false,
        report:action.payload.data,
        total:action.payload.total,
        error:"",
      }
    case FETCH_REPORT_MONTHLY_FAIL:
      return {
        loading:false,
        report:[],
        total:"",
        error:"Please Check Youre Request"
      }
    default:
      return state
  }
}