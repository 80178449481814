import Swal from "sweetalert2";
import {
  FETCH_ALL_INFO_MEDIA_FAIL,
  FETCH_ALL_INFO_MEDIA_REQUEST,
  FETCH_ALL_INFO_MEDIA_SUCCESS,
  FETCH_MEDIA_RECIVE_VISIT_FAIL,
  FETCH_MEDIA_RECIVE_VISIT_REQUEST,
  FETCH_MEDIA_RECIVE_VISIT_SUCCESS,
  FETCH_PATIENT_MEDIA_FAIL,
  FETCH_PATIENT_MEDIA_REQUEST,
  FETCH_PATIENT_MEDIA_SUCCESS,
  GET_ALL_INFO_PATIENT_MEDIA_FAIL,
  GET_ALL_INFO_PATIENT_MEDIA_REQUEST,
  GET_ALL_INFO_PATIENT_MEDIA_SUCCESS,
  GET_MEDIA_WORK_FAIL,
  GET_MEDIA_WORK_REQUEST,
  GET_MEDIA_WORK_SUCCESS,
  RECEIVE_PATIENT_INFO_MEDIA_FAIL,
  RECEIVE_PATIENT_INFO_MEDIA_REQUEST,
  RECEIVE_PATIENT_INFO_MEDIA_SUCCESS,
  REJECT_MEDIA_RECIVE_FAIL,
  REJECT_MEDIA_RECIVE_REQUEST,
  REJECT_MEDIA_RECIVE_SUCCESS,
  SEND_PATIENT_INFO_MEDIA_FAIL,
  SEND_PATIENT_INFO_MEDIA_REQUEST,
  SEND_PATIENT_INFO_MEDIA_SUCCESS,
  STORE_ALL_INFO_PATIENT_MEDIA_FAIL,
  STORE_ALL_INFO_PATIENT_MEDIA_REQUEST,
  STORE_ALL_INFO_PATIENT_MEDIA_SUCCESS,
} from "../../ActionType/MediaType/sendMediaType";
import { api } from "../../Api/api";
import { getToken } from "../login";

export const sendMediaAction =
  (
    id,
    location_id,
    section_id,
    pName,
    dName,
    typeSurgery,
    hospName,
    timeOfSurgery,
    visit_id
  ) =>
  (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    };
    api
      .post(
        "receiveMedia/create",
        {
          id,
          branch_id: location_id,
          section_id,
          pName,
          dName,
          typeSurgery,
          hospName,
          timeOfSurgery,
          visit_id,
          status:0
        },
        config
      )
      .then((response) => {
        dispatch({ type: SEND_PATIENT_INFO_MEDIA_REQUEST });
        const { send } = response.data;
        const { status } = response;
        
        if (status === 200) {
          dispatch({ type: SEND_PATIENT_INFO_MEDIA_SUCCESS, payload: send });
          Swal.fire("Good job!", "Information Was Send To Media", "success");
        }
      })
      .catch(() => {
        dispatch({ type: SEND_PATIENT_INFO_MEDIA_FAIL });
        // Swal.fire({
        //   icon: "error",
        //   title: "Oops...",
        //   text: "Request Is Faild",
        // });
      });
  };

export const receivePatientInfoMeidaAction =
  (locationId, lezhnaId) => (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    };
    api
      .get(`receiveMedia/${locationId}/${lezhnaId}`, config)
      .then((response) => {
        dispatch({ type: RECEIVE_PATIENT_INFO_MEDIA_REQUEST });
        const { status, receive } = response.data;
        if (status === 200) {
          dispatch({
            type: RECEIVE_PATIENT_INFO_MEDIA_SUCCESS,
            payload: receive,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: RECEIVE_PATIENT_INFO_MEDIA_FAIL, payload: [] });
      });
  };

export const storeAllInfoPatientAction =
  (lezhna_id, section_id) => (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    };

    api
      .post(
        "receiveMedia/storeAllInfo",
        {
          lezhna_id,
          section_id,
        },
        config
      )
      .then((response) => {
        dispatch({ type: STORE_ALL_INFO_PATIENT_MEDIA_REQUEST });
        const { status, storeAllInfo } = response.data;
        if (status == 200) {
          dispatch({
            type: STORE_ALL_INFO_PATIENT_MEDIA_SUCCESS,
            payload: storeAllInfo,
          });
          Swal.fire("Good job!", "Information Was Send To Media", "success");
        } else if (status == 400) {
          console.log(response.data);
        }
      })
      .catch((error) => {
        dispatch({ type: STORE_ALL_INFO_PATIENT_MEDIA_FAIL });
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Request Is Faild",
        });
      });
  };

export const getCoustmMediaInfoAction =
  (
    locationId,
    currentPage,
    perPage,
    fullname,
    doctor,
    typeSurgery,
    hospitalName,
    timeOfSurgery
  ) =>
  (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      params: {
        page: currentPage,
        perPage: perPage,
        fullname: fullname,
        doctor: doctor,
        typeSurgery: typeSurgery,
        hospitalName: hospitalName,
        timeOfSurgery: timeOfSurgery,
      },
    };
    api
      .get(`receiveMedia/getCoustmInfo/${locationId}`, config)
      .then((response) => {
        dispatch({ type: FETCH_PATIENT_MEDIA_REQUEST });

        const { status, getMediaInfo, total } = response.data;

        if (status === 200) {
          dispatch({
            type: FETCH_PATIENT_MEDIA_SUCCESS,
            payload: getMediaInfo.data,
            total: total,
          });
        }
      })
      .catch(() => {
        dispatch({ type: FETCH_PATIENT_MEDIA_FAIL });
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Request Is Faild",
        });
      });
  };

export const getAllInfoMediaAction =
  (
    page,
    perPage,
    locationId,
    code,
    Pname,
    phone,
    gender,
    birthDate,
    occuption,
    nation,
    country,
    noFamily,
    province,
    district,
    street,
    closetHome,
    secoundHome,
    card,
    smoke,
    timeAllote,
    SITD,
    member,
    memberLevel,
    type_of_help,
    TYP,
    HEP,
    finalDiagnosis,
    earlyPatient,
    earlySurgery,
    Doctor,
    ZODoctor,
    medicalProvided,
    supplyLocation,
    Typemedicalsupplies,
    helpingZO,
    alive,
    salaryEmploye,
    numberOfHouse,
    house,
    teanant,
    numberOfLand,
    poverty,
    Vehicle,
    land,
    debt,
    govOrPriveteEmp,
    metters,
    NeighborhoodTypeselect,
    amountOfRent,
    economicStatus,
    typeofVehicle,
    typeofLandEstimatedPrice,
    monthlyIncome,
    amountofSalary,
    amountofLoan,
    haveOtherDiseases,
    Typeofdisease,
    numberofChildren,
    numberofStudent,
    monthlyIncomeFamily,
    PovertyRates,
    HMCabilitToSurgery,
    PMediaWork,
    relationship,
    Name,
    resonOfDebt,
    phoneNumber,
    note
  ) =>
  (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      params: {
        page,
        perPage,
        code,
        Pname,
        phone,
        gender,
        birthDate,
        occuption,
        nation,
        country,
        noFamily,
        province,
        district,
        street,
        closetHome,
        secoundHome,
        card,
        smoke,
        timeAllote,
        SITD,
        member,
        memberLevel,
        type_of_help,
        TYP,
        HEP,
        finalDiagnosis,
        earlyPatient,
        earlySurgery,
        Doctor,
        ZODoctor,
        medicalProvided,
        supplyLocation,
        Typemedicalsupplies,
        helpingZO,
        alive,
        salaryEmploye,
        numberOfHouse,
        house,
        teanant,
        numberOfLand,
        poverty,
        Vehicle,
        land,
        debt,
        govOrPriveteEmp,
        metters,
        NeighborhoodTypeselect,
        amountOfRent,
        economicStatus,
        typeofVehicle,
        typeofLandEstimatedPrice,
        monthlyIncome,
        amountofSalary,
        amountofLoan,
        haveOtherDiseases,
        Typeofdisease,
        numberofChildren,
        numberofStudent,
        monthlyIncomeFamily,
        PovertyRates,
        HMCabilitToSurgery,
        PMediaWork,
        relationship,
        Name,
        resonOfDebt,
        phoneNumber,
        note,
      },
    };
    api
      .get(`receiveMedia/showAllInfo/${locationId}`, config)
      .then((response) => {
        dispatch({ type: GET_ALL_INFO_PATIENT_MEDIA_REQUEST });

        const { status, data } = response;

        if (status === 200) {
          dispatch({
            type: GET_ALL_INFO_PATIENT_MEDIA_SUCCESS,
            payload: { data: data.data, total: response.data.total },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: GET_ALL_INFO_PATIENT_MEDIA_FAIL });
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Request Is Faild",
        });
      });
  };

export const getInfoFromLezhnaTbaleAction = (id) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
  // console.log(id)
  dispatch({ type: GET_MEDIA_WORK_REQUEST });
  api
    .get(`receiveMedia/show/${id}`, config)
    .then((response) => {
      dispatch({ type: GET_MEDIA_WORK_REQUEST });

      const { status, data } = response;
      console.log('get media1  : ',data)
      if (status === 200) {
        dispatch({
          type: GET_MEDIA_WORK_SUCCESS,
          payload: data,
        });
      }
    })
    .catch(() => {
      dispatch({ type: GET_MEDIA_WORK_FAIL, payload: [] });
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Request Is Faild",
      });
    });
};

export const seenMediaReciveAction = (id) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
  api
    .post(`receiveMedia/seen/${id}`, {}, config)
    .then((response) => {
      const { status, data } = response;
      if (status == 200) {
        Swal.fire({
          icon: "success",
          title: "سەرکەوتوو بوو ",
          text: "زانیاریەکە بینرا",
          timer: 2000,
        });
      }
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Your Request",
        text: "Please Check Youre Request",
        timer: 1500,
      });
    });
};

export const getReciveMediaByVisitAction = (visit_id) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    params: {
      visit_id,
    },
  };
  dispatch({ type: FETCH_MEDIA_RECIVE_VISIT_REQUEST });
  api
    .get(`receiveMedia/showReciveByVisit`, config)
    .then((response) => {
      dispatch({ type: FETCH_MEDIA_RECIVE_VISIT_REQUEST });

      const { status, data } = response;
      // console.log("dsad ",data);
      if (status === 200) {
        // console.log(data);
        dispatch({
          type: FETCH_MEDIA_RECIVE_VISIT_SUCCESS,
          payload: data,
        });
      }
    })
    .catch(() => {
      dispatch({ type: FETCH_MEDIA_RECIVE_VISIT_FAIL, payload: [] });
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Request Is Faild",
      });
    });
};

export const rejectMediaReciveAction = (id) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
  dispatch({ type: REJECT_MEDIA_RECIVE_REQUEST });
  api
    .post(`receiveMedia/rejectRecive/${id}`, {}, config)
    .then((response) => {
      dispatch({ type: REJECT_MEDIA_RECIVE_REQUEST });
      const { status, data } = response;
      if (status === 200) {
        Swal.fire({
          icon: "success",
          title: "سەرکەوتوو بوو",
          text: "زانیاریەکە رەفز کرایەوە",
        });
        dispatch({
          type: REJECT_MEDIA_RECIVE_SUCCESS,
          payload: data,
        });
      }
    })
    .catch(() => {
      dispatch({ type: REJECT_MEDIA_RECIVE_FAIL, payload: [] });
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Request Is Faild",
      });
    });
};



export const getAllInfoMediaFormAction = (id) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
  
  dispatch({ type: FETCH_ALL_INFO_MEDIA_REQUEST });
  api
    .get(`receiveMedia/showAllInfoMedia/${id}`, config)
    .then((response) => {
      dispatch({ type: FETCH_ALL_INFO_MEDIA_REQUEST });
      const { status, data } = response;
      console.log(response)
      console.log(status)
      if (status == 200) {
        dispatch({
          type: FETCH_ALL_INFO_MEDIA_SUCCESS,
          payload: data,
        });
      }
    })
    .catch(() => {
      dispatch({ type: FETCH_ALL_INFO_MEDIA_FAIL, payload: [] });
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Request Is Faild",
      });
    });
};
