import React, { useEffect, useState } from "react";
import {
  createTypeMedicalAction,
  getTypeMedicalByVisitAction,
} from "../../../Action/TypeMedicalAction/typeMedicalAction";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import ShowImageFeedback from "../../ShowImageFeedbackModal/ShowImageFeedback";
import { FaImages } from "react-icons/fa6";
import { AiOutlineSend } from "react-icons/ai";
const TypeMedical = ({ location_id, patientId, options, visit_id, data }) => {
  const option3 = [
    "نەشتەرگەری",
    "پێداویستی پزیشکی",
    "تیشک",
    "دەرمان",
    "پشکنینی خوێن",
    "غەسلی پلازما",
    "غەسلی گورچیلە",
    "مانەوەی لە نەخۆشخانە",
    "نازۆر",
    "نازۆری گەدە",
    "نازۆری کۆڵۆن",
    "لابردنی سۆندەی گورچیلە",
    "دانانی فیچولان",
    "دانانی سیڤیلاین",
  ];
  // const locationId = localStorage.getItem("locationId");
  const [selectTypeOfProvided, setSelectTypeOfProvided] = useState([]);
  const [openTypeOfProvided, setOpenTypeOfProvided] = useState(false);
  const [inputValue, setInputValue] = useState({
    id: "",
    place: "",
    hospital: "",
    date: "",
    type_of_test: "",
    type_of_radio: "",
    type_of_drug: "",
    total: "",
    stayInHospital: "",
  });

  const getTypeMedicalByVisit = useSelector(
    (state) => state.getTypeMedicalByVisit
  );
  const { typeMedicals } = getTypeMedicalByVisit;
  useEffect(() => {
    dispatch(getTypeMedicalByVisitAction(visit_id));
  }, [visit_id]);
  let typeOfTestTemp = "";
  let typeOfRadioTemp = "";
  useEffect(() => {
    typeMedicals.map((medical) => {
      if (medical.type_of_test == "خوێن" || medical.type_of_test == "جیناد") {
        typeOfTestTemp = medical.type_of_test;
      }
      if (
        medical.type_of_test == "X-Ray" ||
        medical.type_of_test == "MRI" ||
        medical.type_of_test == "CT" ||
        medical.type_of_test == "PET SCAN"
      ) {
        typeOfRadioTemp = medical.type_of_test;
      }

      setInputValue({
        id: medical.id,
        place: medical.place,
        hospital: medical.hospital,
        type_of_test: typeOfTestTemp,
        type_of_drug: medical.type_of_help,
        date: medical.date,
        type_of_radio: typeOfRadioTemp,
        total: medical.total,
      });
    });
  }, [visit_id, typeMedicals]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  };

  const dispatch = useDispatch();

  const handleRadio = () => {
    
    if (location_id == null || location_id == "") {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "تکایە لقێک هەڵبژێرە",
        timer: 2000,
      });
      return;
    }
    dispatch(
      createTypeMedicalAction(
        location_id,
        patientId,
        "تیشک",
        inputValue.place,
        inputValue.hospital,
        inputValue.date,
        inputValue.type_of_radio,
        inputValue.total,
        visit_id
      )
    );
  };

  const handleTest = () => {
    if (location_id == null || location_id == "") {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "تکایە لقێک هەڵبژێرە",
        timer: 2000,
      });
      return;
    }
    dispatch(
      createTypeMedicalAction(
        location_id,
        patientId,
        "پشکنینی خوێن",
        inputValue.place,
        inputValue.hospital,
        inputValue.date,
        inputValue.type_of_test,
        inputValue.total,
        visit_id
      )
    );
  };

  const handleDaylasis = () => {
    if (location_id == null || location_id == "") {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "تکایە لقێک هەڵبژێرە",
        timer: 2000,
      });
      return;
    }
    dispatch(
      createTypeMedicalAction(
        location_id,
        patientId,
        "غەسلی گورچیلە",
        inputValue.place,
        inputValue.hospital,
        inputValue.date,
        "null",
        inputValue.total,
        visit_id
      )
    );
  };

  const handlePlasma = () => {
    if (location_id == null || location_id == "") {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "تکایە لقێک هەڵبژێرە",
        timer: 2000,
      });
      return;
    }
    dispatch(
      createTypeMedicalAction(
        location_id,
        patientId,
        "غەغسلی پلازما",
        inputValue.place,
        inputValue.hospital,
        inputValue.date,
        "null",
        inputValue.total,
        visit_id
      )
    );
  };
  const handleStayInHospital = () => {
    if (location_id == null || location_id == "") {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "تکایە لقێک هەڵبژێرە",
        timer: 2000,
      });
      return;
    }
    dispatch(
      createTypeMedicalAction(
        location_id,
        patientId,
        "مانەوەی لە نەخۆشخانە",
        inputValue.place,
        inputValue.hospital,
        inputValue.date,
        inputValue.stayInHospital,
        inputValue.total,
        visit_id
      )
    );
  };
  const handleDrug = () => {
    if (location_id == null || location_id == "") {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "تکایە لقێک هەڵبژێرە",
        timer: 2000,
      });
      return;
    }
    dispatch(
      createTypeMedicalAction(
        location_id,
        patientId,
        "دەرمان",
        inputValue.place,
        inputValue.hospital,
        inputValue.date,
        "null",
        inputValue.total,
        visit_id
      )
    );
  };
  const handleNazor = () => {
    if (location_id == null || location_id == "") {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "تکایە لقێک هەڵبژێرە",
        timer: 2000,
      });
      return;
    }
    dispatch(
      createTypeMedicalAction(
        location_id,
        patientId,
        "نازۆر",
        inputValue.place,
        inputValue.hospital,
        inputValue.date,
        "null",
        inputValue.total,
        visit_id
      )
    );
  };
  const handlelabrdneSondayGwrchila = () => {
    if (location_id == null || location_id == "") {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "تکایە لقێک هەڵبژێرە",
        timer: 2000,
      });
      return;
    }
    dispatch(
      createTypeMedicalAction(
        location_id,
        patientId,
        "لابردنی سۆندەی گورچیلە",
        inputValue.place,
        inputValue.hospital,
        inputValue.date,
        "null",
        inputValue.total,
        visit_id
      )
    );
  };
  const handlelNazorColon = () => {
    if (location_id == null || location_id == "") {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "تکایە لقێک هەڵبژێرە",
        timer: 2000,
      });
      return;
    }
    dispatch(
      createTypeMedicalAction(
        location_id,
        patientId,
        "نازۆری کۆڵۆن",
        inputValue.place,
        inputValue.hospital,
        inputValue.date,
        "null",
        inputValue.total,
        visit_id
      )
    );
  };
  const handlelNazorGada = () => {
    if (location_id == null || location_id == "") {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "تکایە لقێک هەڵبژێرە",
        timer: 2000,
      });
      return;
    }
    dispatch(
      createTypeMedicalAction(
        location_id,
        patientId,
        "نازۆری گەدە",
        inputValue.place,
        inputValue.hospital,
        inputValue.date,
        "null",
        inputValue.total,
        visit_id
      )
    );
  };
  const handlelCvLine = () => {
    if (location_id == null || location_id == "") {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "تکایە لقێک هەڵبژێرە",
        timer: 2000,
      });
      return;
    }
    dispatch(
      createTypeMedicalAction(
        location_id,
        patientId,
        "سیڤیلاین",
        inputValue.place,
        inputValue.hospital,
        inputValue.date,
        "null",
        inputValue.total,
        visit_id
      )
    );
  };
  const handleFutureLine = () => {
    if (location_id == null || location_id == "") {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "تکایە لقێک هەڵبژێرە",
        timer: 2000,
      });
      return;
    }
    dispatch(
      createTypeMedicalAction(
        location_id,
        patientId,
        "فیچولان",
        inputValue.place,
        inputValue.hospital,
        inputValue.date,
        "null",
        inputValue.total,
        visit_id
      )
    );
  };

  useEffect(() => {
    setSelectTypeOfProvided([...selectTypeOfProvided, ...options]);
  }, [options]);
  const toggleOptionTypeOfHelp = (value) => {
    if (selectTypeOfProvided.includes(value)) {
      setSelectTypeOfProvided(
        selectTypeOfProvided.filter((opt) => opt !== value)
      );
    } else {
      setSelectTypeOfProvided([...selectTypeOfProvided, value]);
    }
  };
  const [isOpenFeedbackImageModal, setIsOpenFeedbackImageModal] =
    useState(false);
  return (
    <div className="mt-2 ">
      <div className="greenTitle xl:w-2/12">
        <h1 className="text-center">پێداویستی پزیشکی</h1>
      </div>
      <form action="" className="grid grid-cols-3 w-full text-right gap-4 mt-4">
        <div className="w-full">
          <label className="pr-2" htmlFor="date">
            * بەروار{" "}
          </label>
          <input
            type="date"
            name="date"
            onChange={handleInputChange}
            value={inputValue.date}
            id="date"
            className="inputLogin "
          />
        </div>
        <div className="w-full">
          <div className="w-full text-right  relative">
            <div className="relative mt-2">
              <label className="pr-2" htmlFor="type">
                * جۆری پێداویستی پزیشکی
              </label>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTypeOfProvided(!openTypeOfProvided);
                }} // Updated variable name
                className="  border-2 shadow-md px-2 rounded-md bg-white  py-1 w-full  text-left"
              >
                {selectTypeOfProvided.length > 0
                  ? selectTypeOfProvided.join(", ")
                  : "Select options..."}
              </button>

              {openTypeOfProvided && (
                <div className="absolute hover: z-10 bg-white  w-64 border rounded-md border-gray-300 mt-2">
                  {option3.map((opt) => (
                    <div className="w-full hover:bg-blue-600 hover:text-white rounded-md hover:bg-opacity-90 cursor-pointer duration-300">
                      <label
                        key={opt}
                        className="flex text-right cursor-pointer items-center  py-2"
                      >
                        <input
                          type="checkbox"
                          value={opt}
                          checked={selectTypeOfProvided.includes(opt)}
                          onChange={() => toggleOptionTypeOfHelp(opt)}
                          className="mr-2"
                        />
                        {opt}
                      </label>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="w-full">
          <label className="pr-2" htmlFor="full">
            * ناوی نەخۆش
          </label>
          <input
            type="text"
            placeholder="ناوی نەخۆش"
            value={data[0].Pname}
            id="full"
            className="inputLogin text-right"
          />
        </div>
        <div className="w-full">
          <label className="pr-2" htmlFor="total">
            * بڕی تێجوو
          </label>
          <input
            type="text"
            name="total"
            onChange={handleInputChange}
            value={inputValue.total}
            placeholder="بڕی تێچوو"
            id="total"
            className="inputLogin text-right"
          />
        </div>
        <div className="w-full">
          <label className="pr-2" htmlFor="hosp">
            * ناوی نەخۆشخانە
          </label>
          <input
            type="text"
            name="hospital"
            onChange={handleInputChange}
            value={inputValue.hospital}
            placeholder="ناوی نەخۆشخانە"
            id="hosp"
            className="inputLogin text-right"
          />
        </div>
        <div className="w-full">
          <label className="pr-2" htmlFor="place">
            * شاری نیشتەجێبوون
          </label>
          <input
            type="text"
            placeholder="شاری نیشتەجێبوون"
            name="place"
            onChange={handleInputChange}
            value={inputValue.place}
            id="place"
            className="inputLogin text-right"
          />
        </div>
      </form>
      {selectTypeOfProvided.map((select, index) => {
        return (
          <>
            {select == "دەرمان" ? (
              <div key={index}>
                <div className="greenTitle w-2/12">
                  <h1 className="text-center">دەرمان</h1>
                </div>
                <div className="flex gap-2">
                  {inputValue.id == "" && (
                    <button
                      onClick={handleDrug}
                      className="btnCoustmDesignGreen mt-2"
                    >
                      <AiOutlineSend />
                    </button>
                  )}

                  <button
                    onClick={() => setIsOpenFeedbackImageModal(true)}
                    className="btnCoustmDesignBlue mt-2"
                  >
                    <FaImages />
                  </button>
                </div>
              </div>
            ) : select == "تیشک" ? (
              <div>
                <div className="title">
                  <h1 className="text-center">تیشک</h1>
                </div>
                <div className="w-full text-right">
                  <label className="pr-2 " htmlFor="hospital">
                    * جۆری تیشک
                  </label>
                  <select
                    name="type_of_radio"
                    onChange={handleInputChange}
                    value={inputValue.type_of_radio}
                    className="select "
                    id="hospital"
                  >
                    <option value="-1"> ... تیشک</option>
                    <option value="X-Ray"> X-Ray</option>
                    <option value="MRI"> MRI</option>
                    <option value="CT"> CT</option>
                    <option value="PET SCAN">PET SCAN</option>
                  </select>
                </div>
                <div className="flex gap-2">
                  <button
                    onClick={handleRadio}
                    className="btnCoustmDesignGreen mt-2"
                  >
                    <AiOutlineSend />
                  </button>
                  <button
                    onClick={() => setIsOpenFeedbackImageModal(true)}
                    className="btnCoustmDesignBlue mt-2"
                  >
                    <FaImages />
                  </button>
                </div>
              </div>
            ) : select == "پشکنینی خوێن" ? (
              <div>
                <div className="title w-2/12">
                  <h1 className="text-center">پشکنینی خوێن</h1>
                </div>
                <div className="w-full text-right">
                  <label className="pr-2 " htmlFor="hospital">
                    * پشکنینی خوێن
                  </label>
                  <select
                    name="type_of_test"
                    onChange={handleInputChange}
                    value={inputValue.type_of_test}
                    className="select "
                    id="hospital"
                  >
                    <option value="-1"> ... پشکنینی خوێن</option>
                    <option value="جیناد"> جیناد</option>
                    <option value="خوێن"> خوێن</option>
                  </select>
                </div>
                <div className="flex gap-2">
                  <button
                    onClick={handleTest}
                    className="btnCoustmDesignGreen mt-2"
                  >
                    <AiOutlineSend />
                  </button>
                  <button
                    onClick={() => setIsOpenFeedbackImageModal(true)}
                    className="btnCoustmDesignBlue mt-2"
                  >
                    <FaImages />
                  </button>
                </div>
              </div>
            ) : select == "غەسلی پلازما" ? (
              <div key={index}>
                <div className="greenTitle w-2/12">
                  <h1 className="text-center">غەسلی پلازما</h1>
                </div>
                <div className="flex gap-2">
                  {inputValue.id == "" && (
                    <button
                      onClick={handlePlasma}
                      className="btnCoustmDesignGreen mt-2"
                    >
                      <AiOutlineSend />
                    </button>
                  )}
                  <button
                    onClick={() => setIsOpenFeedbackImageModal(true)}
                    className="btnCoustmDesignBlue mt-2"
                  >
                    <FaImages />
                  </button>
                </div>
              </div>
            ) : select == "غەسلی گورچیلە" ? (
              <div key={index}>
                <div className="title w-2/12">
                  <h1 className="text-center">غەسلی گورچیلە</h1>
                </div>
                <div className="flex gap-2">
                  {inputValue.id == "" && (
                    <button
                      onClick={handleDaylasis}
                      className="btnCoustmDesignGreen mt-2"
                    >
                      <AiOutlineSend />
                    </button>
                  )}
                  <button
                    onClick={() => setIsOpenFeedbackImageModal(true)}
                    className="btnCoustmDesignBlue mt-2"
                  >
                    <FaImages />
                  </button>
                </div>
              </div>
            ) : select == "مانەوەی لە نەخۆشخانە" ? (
              <div key={index}>
                <div className="greenTitle w-2/12">
                  <h1 className="text-center">مانەوەی لە نەخۆشخانە</h1>
                </div>
                <div className="w-full text-right">
                  <label className="pr-2 " htmlFor="hospital">
                    * چەن ڕۆژ ماوەتەوە
                  </label>
                  <input
                    type="text"
                    className="inputLogin text-right"
                    name="stayInHospital"
                    onChange={handleInputChange}
                    placeholder="چەن ڕۆژ ماوەتەوە"
                  />
                </div>
                <div className="flex gap-2">
                  <button
                    onClick={handleStayInHospital}
                    className="btnCoustmDesignGreen mt-2"
                  >
                    <AiOutlineSend />
                  </button>
                  <button
                    onClick={() => setIsOpenFeedbackImageModal(true)}
                    className="btnCoustmDesignBlue mt-2"
                  >
                    <FaImages />
                  </button>
                </div>
              </div>
            ) : select == "نازۆر" ? (
              <div key={index}>
                <div className="title w-2/12">
                  <h1 className="text-center">نازۆر</h1>
                </div>
                <div className="flex gap-2">
                  {inputValue.id == "" && (
                    <button
                      onClick={handleNazor}
                      className="btnCoustmDesignGreen mt-2"
                    >
                      <AiOutlineSend />
                    </button>
                  )}

                  <button
                    onClick={() => setIsOpenFeedbackImageModal(true)}
                    className="btnCoustmDesignBlue mt-2"
                  >
                    <FaImages />
                  </button>
                </div>
              </div>
            ) : select == "نازۆری گەدە" ? (
              <div key={index}>
                <div className="greenTitle w-2/12">
                  <h1 className="text-center">نازۆری گەدە</h1>
                </div>
                <div className="flex gap-2">
                  {inputValue.id == "" && (
                    <button
                      onClick={handlelNazorGada}
                      className="btnCoustmDesignGreen mt-2"
                    >
                      <AiOutlineSend />
                    </button>
                  )}

                  <button
                    onClick={() => setIsOpenFeedbackImageModal(true)}
                    className="btnCoustmDesignBlue mt-2"
                  >
                    <FaImages />
                  </button>
                </div>
              </div>
            ) : select == "نازۆری کۆڵۆن" ? (
              <div key={index}>
                <div className="title w-2/12">
                  <h1 className="text-center">نازۆری کۆڵۆن</h1>
                </div>
                <div className="flex gap-2">
                  {inputValue.id == "" && (
                    <button
                      onClick={handlelNazorColon}
                      className="btnCoustmDesignGreen mt-2"
                    >
                      <AiOutlineSend />
                    </button>
                  )}

                  <button
                    onClick={() => setIsOpenFeedbackImageModal(true)}
                    className="btnCoustmDesignBlue mt-2"
                  >
                    <FaImages />
                  </button>
                </div>
              </div>
            ) : select == "لابردنی سۆندەی گورچیلە" ? (
              <div key={index}>
                <div className="greenTitle w-2/12">
                  <h1 className="text-center">لابردنی سۆندەی گورچیلە</h1>
                </div>
                <div className="flex gap-2">
                  {inputValue.id == "" && (
                    <button
                      onClick={handlelabrdneSondayGwrchila}
                      className="btnCoustmDesignGreen mt-2"
                    >
                      <AiOutlineSend />
                    </button>
                  )}

                  <button
                    onClick={() => setIsOpenFeedbackImageModal(true)}
                    className="btnCoustmDesignBlue mt-2"
                  >
                    <FaImages />
                  </button>
                </div>
              </div>
            ) : select == "دانانی فیچولان" ? (
              <div key={index}>
                <div className="title w-2/12">
                  <h1 className="text-center">دانانی فیچولان</h1>
                </div>
                <div className="flex gap-2">
                  {inputValue.id == "" && (
                    <button
                      onClick={handleFutureLine}
                      className="btnCoustmDesignGreen mt-2"
                    >
                      <AiOutlineSend />
                    </button>
                  )}

                  <button
                    onClick={() => setIsOpenFeedbackImageModal(true)}
                    className="btnCoustmDesignBlue mt-2"
                  >
                    <FaImages />
                  </button>
                </div>
              </div>
            ) : select == "دانانی سیڤیلاین" ? (
              <div key={index}>
                <div className="greenTitle w-2/12">
                  <h1 className="text-center">دانانی سیڤیلاین</h1>
                </div>
                <div className="flex gap-2">
                  {inputValue.id == ""   && (
                    <button
                      onClick={handlelCvLine}
                      className="btnCoustmDesignGreen mt-2"
                    >
                      <AiOutlineSend />
                    </button>
                  )}

                  <button
                    onClick={() => setIsOpenFeedbackImageModal(true)}
                    className="btnCoustmDesignBlue mt-2"
                  >
                    <FaImages />
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
          </>
        );
      })}

      {isOpenFeedbackImageModal && (
        <ShowImageFeedback
          patientId={patientId}
          closeModal={setIsOpenFeedbackImageModal}
        />
      )}
    </div>
  );
};

export default TypeMedical;
