import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPatient, rejectPatient } from "../Action/patient";
import {
  rejectPatientAction,
  rejectPatientWithVisitAction,
} from "../Action/rejectPatientAction";
import Logo from "../Image/Logo.jpg";
import { getCommite } from "../Action/commiteAction";

const RejectModal = ({ closeModal, patientId }) => {
  const dispatch = useDispatch();
  const [visitId, setVisitId] = useState(null);
  const locationId = localStorage.getItem("locationId");
  const [note, setNote] = useState(null);
  const [error, setError] = useState(null);
  const rejectPatientWithVisit = useSelector(
    (state) => state.rejectPatientWithVisit
  );
  const { reject, loading } = rejectPatientWithVisit;
 

  const handelReject = (event) => {
    event.preventDefault();

    if (note == null || note == "") {
      setError("تکایە هۆکاری رەفز کردنەوەکەی بڵێ");
      return;
    }
    dispatch(rejectPatientAction(note, patientId, visitId));
    dispatch(rejectPatient(patientId, visitId));
    dispatch(getPatient(locationId, 1, 10, "", "", "", "", "", 1));
    dispatch(getPatient(locationId, 1, 10, "", "", "", "", "", 1));
    dispatch(getCommite(locationId, 0, 10, "", "", "", "", ""));
    dispatch(getCommite(locationId, 0, 10, "", "", "", "", ""));
    closeModal(false);
    setVisitId(null);
  };
  useEffect(() => {
    dispatch(rejectPatientWithVisitAction(patientId));
  }, [visitId]);
  return (
    <>
      {loading || reject == undefined ? (
        <div className="flex flex-col absolute top-[15%] left-[40%]">
          <img src={Logo} alt="Logo" className="w-56 m-auto animate-pulse  " />
          <div className="text-2xl text-center font-bold  ">
            <div className="flex justify-center gap-4">
              <p className="animate-charcter">Please Wait</p>
              <div class="container2">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>{" "}
            </div>
          </div>
        </div>
      ) : (
        <div
          className={
            closeModal
              ? "absolute left-0 w-full h-screen top-0 z-50"
              : "absolute top-0"
          }
        >
          <div
            className="h-screen w-full  bg-slate-500 opacity-20"
            onClick={() => {
              closeModal(false);
              setVisitId(null);
            }}
          ></div>
          <div className="modalChildReject ">
            <div className="flex justify-between items-center">
              <div>
                <select
                  name=""
                  onChange={(e) => setVisitId(e.target.value)}
                  className="select"
                  id=""
                  value={visitId}
                >
                  <option value="-1" selected disabled>
                    تکایە رۆژی سەردانیکردن دیاری بکە
                  </option>
                  {reject.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.created_at.toString().slice(0, 10)}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div
                className="text-3xl cursor-pointer text-red-600"
                onClick={() => {
                  closeModal(false);
                  setVisitId(null);
                }}
              >
                x
              </div>
            </div>
            {/* form */}

            {visitId == null ? (
              ""
            ) : (
              <>
                <div className="text-center pt-2">
                  <p>* دڵنیای لەوەی کە دەتەوێ ئەم نەخۆشە رەفز کەیتەوە</p>
                </div>
                <form action="">
                  <div className="mt-4">
                    <div className="w-full flex justify-end">
                      <label
                        htmlFor="message"
                        class="block mb-2 text-sm font-medium text-gray-900 "
                      >
                        هۆکاری رەفز کردنەکەی
                      </label>
                    </div>
                    <textarea
                      id="message"
                      rows="4"
                      class="block p-2.5 w-full text-sm focus:outline-none text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 text-right"
                      placeholder=".... هۆکار"
                      onChange={(e) => {
                        setNote(e.target.value);
                      }}
                    ></textarea>
                    <p className="text-red-400  text-right">{error}</p>
                  </div>

                  <div className="flex justify-center gap-2 mt-4 pb-2">
                    <button
                      onClick={() => closeModal(false)}
                      className="rejectBtn"
                    >
                      No,Cancel
                    </button>
                    <button
                      className="assignBtn"
                      onClick={(event) => {
                        handelReject(event);
                        // handelRejectNote(event);
                      }}
                    >
                      Yes, I'm sure
                    </button>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default RejectModal;
