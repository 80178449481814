import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../../Image/Logo.jpg";

import {
  getNotificationAction,
  getReciveAction,
  seenNotificationAction,
} from "../../Action/receiveAction";
import ReactPaginate from "react-paginate";
import { FaFilter } from "react-icons/fa6";
import { AiFillEye } from "react-icons/ai";
import ViewReciveModal from "../../Modal/ViewModal/ViewReciveModal";
import SendModalAllBranch from "../../Modal/SendModalAllBrnach/SendModalAllBranch";
import { getDoctorAction } from "../../Action/Doctor/doctorAction";

const Receive = () => {
  const locationID = localStorage.getItem("locationId");
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState();
  const dispatch = useDispatch();
  const getDoctor = useSelector((state) => state.getDoctor);
  const { doctors } = getDoctor;
  // const [edit, setEdit] = useState(false);
  useEffect(() => {
    dispatch(getDoctorAction());
  }, []);
  const [searchParams, setSearchParams] = useState({
    patientFrom: "",
    fullname: "",
    code: "",
    phone: "",
    gender: "",
    doctor: "",
  });
  const { patientFrom, fullname, code, phone, gender } = searchParams;
  const [openFilter, setOpenFilter] = useState(false);
  const handleSearchParams = (e) => {
    const { name, value } = e.target;
    setSearchParams({
      ...searchParams,
      [name]: value,
    });
  };
  const [isOpenViewRecive, setIsOpenViewRecive] = useState(false);
  const [reciveId, setReciveId] = useState(null);
  const handelSeen = (id) => {
    dispatch(seenNotificationAction(id));
    dispatch(seenNotificationAction(id));

    dispatch(getNotificationAction(locationID));
    dispatch(getNotificationAction(locationID));

    dispatch(
      getReciveAction(locationID, "", "", "", "", "", "", currentPage, perPage)
    );
    dispatch(
      getReciveAction(locationID, "", "", "", "", "", "", currentPage, perPage)
    );
  };
  const [isOpenSendModal, setIsOpenSendModal] = useState(false);
  const getReceive = useSelector((state) => state.getReceive);
  const { receive, total, loading } = getReceive;
  const [lezhnaId, setLezhnaId] = useState(null);
  useEffect(() => {
    dispatch(
      getReciveAction(
        locationID,
        patientFrom,
        code,
        fullname,
        gender,
        phone,
        "",
        currentPage,
        perPage,
        searchParams.doctor
      )
    );
  }, [currentPage, perPage, searchParams]);
  return (
    <div>
      {loading ? (
        <div className="flex flex-col">
          <img
            src={Logo}
            alt="Logo"
            className="w-56 m-auto animate-pulse mt-20 "
          />
          <div className="text-2xl text-center font-bold  ">
            <div className="flex justify-center gap-4">
              <p className="animate-charcter">Loading</p>
              <div class="container2">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>{" "}
            </div>
          </div>
        </div>
      ) : (
        <div className="relative bg-white md:h-[18rem] pb-3 xl:h-[30rem]  3xl:h-[43rem]   w-[98%] m-auto  sm:rounded-lg  mt-4">
          <div className="overflow-y-auto w-full h-[75%] xl:h-[90%]">
            <table className="w-full">
              <thead className="sticky top-0 ">
                <tr>
                  <th className="py-4 pl-5 border">#</th>

                  <th className="thead  ">
                    <div className="flex items-center justify-between">
                      Patient From
                      <div
                        className="text-base rounded-md shadow-md  p-2 bg-teal-600 hover:bg-teal-600 text-white"
                        onClick={() => setOpenFilter(!openFilter)}
                      >
                        <FaFilter />
                      </div>
                    </div>
                    {openFilter && (
                      <input
                        onBlur={handleSearchParams}
                        type="text"
                        name="patientFrom"
                        className="inputLogin p-1.5 shadow-xl rounded "
                        placeholder="Patient From"
                      />
                    )}
                  </th>
                  <th className="thead  ">
                    <div className="flex items-center justify-between">
                      Doctor
                      <div
                        className="text-base rounded-md shadow-md  p-2 bg-teal-600 hover:bg-teal-600 text-white"
                        onClick={() => setOpenFilter(!openFilter)}
                      >
                        <FaFilter />
                      </div>
                    </div>
                    {openFilter && (
                      <select
                        onBlur={handleSearchParams}
                        type="text"
                        name="doctor"
                        className="inputLogin p-0 shadow-xl rounded "
                        placeholder="Doctor"
                      >
                        <option value="">Select Doctor ...</option>
                        {doctors.map((doctor, index) => {
                          return (
                            <option key={index} value={doctor.dr_name}>
                              {doctor.dr_name}
                            </option>
                          );
                        })}
                      </select>
                    )}
                  </th>
                  <th className="thead  ">
                    <div className="flex items-center justify-between">
                      Code
                      <div
                        className="text-base rounded-md shadow-md  p-2 bg-teal-600 hover:bg-teal-600 text-white"
                        onClick={() => setOpenFilter(!openFilter)}
                      >
                        <FaFilter />
                      </div>
                    </div>
                    {openFilter && (
                      <input
                        onBlur={handleSearchParams}
                        type="text"
                        name="code"
                        className="inputLogin p-1.5 shadow-xl rounded "
                        placeholder="Code"
                      />
                    )}
                  </th>
                  <th className="thead">
                    <div className="flex items-center justify-between">
                      Full Name
                      <div
                        className="text-base rounded-md shadow-md  p-2 bg-teal-600 hover:bg-teal-600 text-white"
                        onClick={() => setOpenFilter(!openFilter)}
                      >
                        <FaFilter />
                      </div>
                    </div>
                    {openFilter && (
                      <input
                        type="text"
                        name="fullname"
                        onBlur={handleSearchParams}
                        className="inputLogin p-1.5 shadow-xl rounded "
                        placeholder="Full Name"
                      />
                    )}
                  </th>
                  <th className="thead">
                    <div className="flex items-center justify-between">
                      Phone
                      <div
                        className="text-base rounded-md shadow-md  p-2 bg-teal-600 hover:bg-teal-600 text-white"
                        onClick={() => setOpenFilter(!openFilter)}
                      >
                        <FaFilter />
                      </div>
                    </div>
                    {openFilter && (
                      <input
                        type="text"
                        name="phone"
                        onBlur={handleSearchParams}
                        className="inputLogin p-1.5 shadow-xl rounded "
                        placeholder="Phone"
                      />
                    )}
                  </th>
                  <th className="thead">
                    <div className="flex items-center justify-between">
                      Gender
                      <div
                        className="text-base rounded-md shadow-md  p-2 bg-teal-600 hover:bg-teal-600 text-white"
                        onClick={() => setOpenFilter(!openFilter)}
                      >
                        <FaFilter />
                      </div>
                    </div>
                    {openFilter && (
                      <input
                        type="text"
                        name="gender"
                        onBlur={handleSearchParams}
                        className="inputLogin p-1.5 shadow-xl rounded "
                        placeholder="Gender"
                      />
                    )}
                  </th>
                  <th className="thead">
                    <div className="flex items-center justify-between">
                      Note
                      <div
                        className="text-base rounded-md shadow-md  p-2 bg-teal-600 hover:bg-teal-600 text-white"
                        onClick={() => setOpenFilter(!openFilter)}
                      >
                        <FaFilter />
                      </div>
                    </div>
                    {openFilter && (
                      <input
                        type="text"
                        name="note"
                        onBlur={handleSearchParams}
                        className="inputLogin p-1.5 shadow-xl rounded "
                        placeholder="Note "
                      />
                    )}
                  </th>
                  <th>Action</th>
                  {/* <th  className="">Send</th> */}
                </tr>
              </thead>
              <tbody className="">
                {receive.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      className={item.seen == 2 ? "tableRowSave" : "tableRow"}
                    >
                      <td>{index + 1}</td>
                      <td>{item.branch_name}</td>
                      <td>{item.lezhna.commite.Doctor}</td>
                      <td>{item.lezhna.commite.patients.code}</td>
                      <td>{item.lezhna.commite.patients.Pname}</td>
                      <td>{item.lezhna.commite.patients.phone}</td>
                      <td>{item.lezhna.commite.patients.gender}</td>

                      <td>{item.note}</td>
                      <td className=" ">
                        <div className="flex gap-3 justify-center">
                          <button
                            disabled={item.seen == 2 ? true : false}
                            className={
                              item.seen == 2
                                ? "btnCoustmDesignPurple cursor-not-allowed"
                                : "btnCoustmDesignPurple"
                            }
                            onClick={(e) => {
                              e.preventDefault();

                              handelSeen(item.id);
                            }}
                          >
                            {item.seen == 2 ? "seen" : "see"}
                          </button>
                          <button
                            title="View"
                            onClick={() => {
                              setIsOpenViewRecive(true);
                              setReciveId(item.id);
                            }}
                            className="btnCoustmDesignGreen"
                          >
                            {" "}
                            <AiFillEye />
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="gap-3 px-3 flex justify-end items-center pt-2  pb-3">
            <div>
              <select
                name=""
                className="mt-1  bg-white border w-20 focus:ring-2  focus:ring-slate-900 rounded-md   focus:outline-none"
                onChange={(e) => setPerPage(e.target.value)}
                id=""
              >
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>

            <div className="flex justify-center  mt-1">
              <ReactPaginate
                previousLabel="<"
                nextLabel={">"}
                // nextClassName='bg-blue-500  hover:bg-blue-600 text-white font-semibold px-2 py-2 rounded-md'
                breakLabel="..."
                className="flex  items-center  -space-x-px h-8 text-sm"
                previousClassName="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500  border border-gray-300 rounded-l-lg "
                nextClassName="flex items-center justify-center px-3 h-8 leading-tight text-gray-500  border border-gray-300 rounded-r-lg "
                pageLinkClassName="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500  border border-gray-300  "
                breakClassName="w-8 py-2 text-center"
                pageCount={Math.ceil(total / perPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={(event) => {
                  let currentPage = event.selected + 1;
                  dispatch(
                    getReciveAction(
                      locationID,
                      patientFrom,
                      code,
                      fullname,
                      gender,
                      phone,
                      "",
                      currentPage,
                      perPage
                    )
                  );
                  setCurrentPage(currentPage);
                }}
                activeLinkClassName="bg-black text-white flex  items-center  -space-x-px h-8 text-sm"
              />
            </div>
          </div>
        </div>
      )}

      {isOpenViewRecive && (
        <ViewReciveModal
          recive_id={reciveId}
          closeModal={setIsOpenViewRecive}
        />
      )}
      {isOpenSendModal && (
        <SendModalAllBranch
          lezhna_id={lezhnaId}
          closeModal={setIsOpenSendModal}
        />
      )}
    </div>
  );
};

export default Receive;
