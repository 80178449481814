import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { createSurgeryAction, getSurgeryAction } from '../../Action/Surgery/surgeryAction';

const SurgeryModal = ({ isOpenModal, closeModal }) => {
    const [surgerName, setSurgeryName] = useState(null);
    const [error,setError]=useState(null);
    const dispatch = useDispatch();
    const handelSave = (event) => {
        event.preventDefault();
        if (surgerName !== null) {
            dispatch(createSurgeryAction(surgerName));
            dispatch(getSurgeryAction());
            dispatch(getSurgeryAction());
            closeModal(false)
        }else{
            setError("Please Fill The Gaps")
        }

    }
    return (
        <div
            className={
                isOpenModal
                    ? "h-screen fixed w-full bg-gray-400 duration-500 opacity-100 bg-opacity-20 top-0 left-0 z-50  "
                    : "h-screen fixed w-full bg-gray-400   -z-50 opacity-0 duration-500 bg-opacity-20 top-0 left-0 "
            }
        >
            <div
                className="h-screen w-full p-20  relative cursor-pointer  z-20"
                onClick={() => closeModal(false)}
            />
            <div className={"bg-white overflow-y-auto z-50 h-auto w-11/12 xl:w-[30%] md:w-7/12 py-3 px-2 absolute top-[10%] left-4 md:left-[22%] xl:left-[35%]  -translate-X-[90%]  rounded-md shadow-lg  "}>
                <div className="flex justify-between">
                    <div className="flex items-center ">
                        <p className="text-xl text-teal-600">
                            {" "}
                            {/* <FaUserDoctor /> */}
                        </p>
                        <p>Surgery</p>
                    </div>
                    <div
                        className="text-2xl hover:backdrop-blur-xl  cursor-pointer"
                        onClick={() => closeModal(false)}
                    >
                        x
                    </div>
                </div>
                <hr />
                <form action="" className="w-full pt-2">
                    <div className="flex flex-col">
                        <label htmlFor="sur" className='ml-1'>Surgery Name *</label>
                        <input type="text" onChange={(e) => setSurgeryName(e.target.value)} placeholder='Surgery Name' className='inputLogin' />
                    </div>
                    <div>
                        <p className='text-red-400'>{error}</p>
                    </div>

                    <div className="mt-1 ml-1 pt-2" onClick={handelSave}>
                        <button className='btnCoustmDesignGreen'> Save </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default SurgeryModal