export const CREATE_SURGURY_TYPE_REQUEST = "CREATE_SURGURY_TYPE_REQUEST";
export const CREATE_SURGURY_TYPE_SUCCESS = "CREATE_SURGURY_TYPE_SUCCESS";
export const CREATE_SURGURY_TYPE_FAIL = "CREATE_SURGURY_TYPE_FAIL";

export const FETCH_ALL_TYPE_SURGERY_REQUEST = "FETCH_ALL_TYPE_SURGERY_REQUEST";
export const FETCH_ALL_TYPE_SURGERY_SUCCESS = "FETCH_ALL_TYPE_SURGERY_SUCCESS";
export const FETCH_ALL_TYPE_SURGERY_FAIL = "FETCH_ALL_TYPE_SURGERY_FAIL";

export const FETCH_ORGAN_LEVEL_REQUEST = "FETCH_ORGAN_LEVEL_REQUEST";
export const FETCH_ORGAN_LEVEL_SUCCESS = "FETCH_ORGAN_LEVEL_SUCCESS";
export const FETCH_ORGAN_LEVEL_FAIL = "FETCH_ORGAN_LEVEL_FAIL";
