import React, { useEffect, useRef, useState } from "react";
import { AiFillCaretDown } from "react-icons/ai";

import {
  getSurgeryAction,
  getTypeSurgeryAction,
} from "../Action/Surgery/surgeryAction";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../Image/Logo.jpg";
import {
  editPatientAction,
  getPatient,
  updatePatientAction,
} from "../Action/patient";
import ReactToPrint from "react-to-print";
import "../Pages/user_profile/css/add_user.css";
import { getDoctorAction } from "../Action/Doctor/doctorAction";
import { getOrganLevelAction } from "../Action/SurgeryTypeAction/surgery";
import FeedbackPrint from "../Print/FeedbackPrint";
import { IoMdPrint } from 'react-icons/io'

const AssignModal = ({ closeModal, openEditModal, patientId }) => {
  const [surgryKuId, setSurgeryKuId] = useState([]);
  const [visitId, setVisitId] = useState(null);
  const dispatch = useDispatch();
  const locationID = localStorage.getItem("locationId");
  const [openSelect, setOpenSelect] = useState(false);
  const [married, setMarried] = useState(null);
  const [smoking, setSmoking] = useState(null);
  const getEditPatient = useSelector((state) => state.editPatient);
  const { patients, loading } = getEditPatient;
  const getSurgery = useSelector((state) => state.getSurgery);
  const { surgery } = getSurgery;
  const getTypeSurgery = useSelector((state) => state.getTypeSurgery);
  const getOrganLevel = useSelector((state) => state.getOrganLevel);
  const { organLevel } = getOrganLevel;
  const { typeSurgery } = getTypeSurgery;
  useEffect(() => {
    dispatch(getSurgeryAction());
  }, []);
  const surgeryArray = [];

  useEffect(() => {
    dispatch(editPatientAction(patientId, visitId));

    // dispatch(getPatient(patientId,1,1,"","","","",""));
  }, [visitId]);
  const [selectedOpts, setSelectedOpts] = useState([]);
  const [selectedOptTypeSurgery, setSelectedOptTypeSurgery] = useState([]);
  const [selectMember, setSelectMember] = useState([]);
  const [selectOrganLevel, setSelectOrganLevel] = useState([]);
  const [selectTypeOfHelp, setSelectTypeOfHelp] = useState([]);
  const getDoctor = useSelector((state) => state.getDoctor);
  const { doctors } = getDoctor;
  useEffect(() => {
    dispatch(getDoctorAction("Active"));
  }, []);
  const [typeSurgeryId, setTypeSurgeryId] = useState(null);
  const [openTypeSurgery, setOpenTypeSurgery] = useState(false);
  const [openMember, setOpenMemeber] = useState(false);
  const [openOrganLevel, setOpenOrganLevel] = useState(false);
  const [openTypeOfHelp, setOpenTypeOfHelp] = useState(false);
  useEffect(() => {
    if (typeSurgeryId == null) {
      return;
    } else {
      dispatch(getTypeSurgeryAction(typeSurgeryId));
    }
  }, [typeSurgeryId]);

  const [organLevelId, setOrganLevelId] = useState([]);
  useEffect(() => {
    if (surgryKuId == null || surgryKuId.length == 0) {
      return;
    } else {
      dispatch(getOrganLevelAction(surgryKuId));
    }
  }, [surgryKuId]);

  const [isOpenSmoking, setIsOpenSmoking] = useState(false);

  const handelSmoking = (e) => {
    e.preventDefault();
    setIsOpenSmoking(!isOpenSmoking);
  };

  const handelSelect = (e) => {
    e.preventDefault();
    setOpenSelect(openSelect ? false : true);
  };

  const componentRef = useRef();

  const [inputValue, setInputValue] = useState({
    visit_id: "",
    fullname: "",
    phone: "",
    gender: "",
    birthDate: "",
    assign: "",
    commiteId: "",
    occupation: "",
    nation: "",
    country: "",
    NoFamily: "",
    province: "",
    district: "",
    street: "",
    closetHome: "",
    secoundPhoneNo: "",
    card: "",
    timeAllot: "",
    SPID: "",
    member: "",
    noMember: "",
    typ: "",
    indiction: "",
    HPE: "",
    finalDiagnos: "",
    earlyPatient: "",
    earlySurgery: "",
    doctor: "",
    ZODoctor: "",
    medicalProvid: "",
    typeMedical: "",
    supplyLocation: "",
    helpingZO: "",
    note: "",
    literate: "",
    hadSurgery: "",
  });
  const amountOfDays = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30,
  ];

  const handelInputChange = (event) => {
    const { name, value } = event.target;
    setInputValue({ ...inputValue, [name]: value });
  };

  const reset = () => {
    setInputValue("");
  };
  let surgeryTypeArray = [];

  typeSurgery.forEach((element) => {
    surgeryTypeArray.push({
      value: `${element.type_id}`,
      label: `${element.type_name_en}`,
    });
  });

  let memberArray = [];

  typeSurgery.forEach((element) => {
    memberArray.push({
      value: `${element.id}`,
      label: `${element.typeName}`,
    });
  });
  const organArray = [];
  organLevel.map((organ) => {
    organArray.push({
      label: organ.organ_level_name,
      value: organ.organ_level_name,
    });
  });

  surgery.forEach((surg) => {
    surgeryArray.push({
      value: surg.id,
      label: surg.surguryName,
    });
  });

  let doctorArray = [];

  doctors.map((doctor) => {
    doctorArray.push({
      value: doctor.dr_name,
      label: doctor.dr_name,
    });
  });

  const arrayType = [];
  const arrayMember = [];
  let fetchSurgeryArray = [];
  let arrayTypeOfHelp = [];
  const [typeArr, setTypeArr] = useState([]);

  const [lengthOfType, setLengthOfType] = useState(null);
  const arrayOrganLevel = [];
  const [doctor, setDoctor] = useState([]);
  const fetchDoctorArray = [];
  useEffect(() => {
    patients.forEach((patient) => {
      if (visitId == null || visitId == -1) {
        return;
      } else {
        patient.commite
          .filter((filter) => filter.visit_id == visitId)
          .map((element) => {
            const length = element.TYP.length;
            const leng = element.SITD.length;
            const lengMember = element.member.length;
            const lengMemberLevel = element.memberLevel.length;
            const lengTypeOfHelo = element.type_of_help.length;
            const lengDoctor = element.Doctor.length;

            for (let index = 0; index < lengTypeOfHelo; index++) {
              if (arrayTypeOfHelp.length < lengTypeOfHelo) {
                arrayTypeOfHelp.push(element.type_of_help[index]);
                setSelectTypeOfHelp([...selectTypeOfHelp, ...arrayTypeOfHelp]);
              } else {
                setSelectTypeOfHelp([]);
                return null;
              }
            }

            for (let index = 0; index < length; index++) {
              if (arrayType.length < length) {
                setTypeArr({
                  ...typeArr,
                  value: element.TYP,
                  label: element.TYP,
                });

                setLengthOfType(length);
              } else {
                return null;
              }
            }

            for (let index = 0; index < lengMember; index++) {
              if (arrayMember.length < length) {
                arrayMember.push(element.member[index]);
                setSelectMember([...selectMember, ...arrayMember]);
              } else {
                setSelectMember([]);
                return null;
              }
            }

            for (let index = 0; index < lengMemberLevel; index++) {
              if (arrayOrganLevel.length < lengMemberLevel) {
                arrayOrganLevel.push(element.memberLevel[index]);
                setSelectOrganLevel([...selectOrganLevel, ...arrayOrganLevel]);
              } else {
                setSelectOrganLevel([]);
                return null;
              }
            }

            for (let index = 0; index < length; index++) {
              if (arrayType.length < length) {
                arrayType.push(element.TYP[index]);

                setSelectedOptTypeSurgery([
                  ...selectedOptTypeSurgery,
                  ...arrayType,
                ]);
              } else {
                setSelectedOptTypeSurgery([]);

                return null;
              }
            }

            if (leng == 0) {
              setSelectedOpts([]);
            } else {
              for (let index = 0; index < leng; index++) {
                fetchSurgeryArray.push(element.SITD[index]);
                setSelectedOpts([...selectedOpts, ...fetchSurgeryArray]);
              }
            }

            if (lengDoctor == 0) {
              setDoctor([]);
            } else {
              for (let index = 0; index < lengDoctor; index++) {
                fetchDoctorArray.push(element.Doctor[index]);
                setDoctor([...doctor, ...fetchDoctorArray]);
              }
            }
          });
      }
    });
  }, [visitId]);

  useEffect(() => {
    patients.forEach((element) => {
      if (visitId == null || visitId == -1) {
        return;
      } else {
        element.commite
          .filter((filter) => filter.visit_id == visitId)
          .map((element) => {
            setSmoking(element.smoke);
            setMarried(element.married);
            setInputValue({
              visit_id: element.visit_id,
              assign: element.assign,
              commiteId: element.id,
              occupation: element.occuption,
              nation: element.nation,
              country: element.country,
              NoFamily: element.noFamily,
              province: element.province,
              district: element.district,
              street: element.street,
              closetHome: element.closetHome,
              secoundPhoneNo: element.secoundHome,
              card: element.card,
              timeAllot: element.timeAllote,
              SPID: element.SITD,
              member: element.member,
              noMember: element.memberLevel,
              typ: element.TYP,
              indiction: element.indiction,
              HPE: element.HEP,

              finalDiagnos: element.finalDiagnosis,
              earlyPatient: element.earlyPatient,
              earlySurgery: element.earlySurgery,
              doctor: element.Doctor,
              ZODoctor: element.ZODoctor,
              medicalProvid: element.medicalProvided,
              typeMedical: element.Typemedicalsupplies,
              supplyLocation: element.supplyLocation,
              helpingZO: element.helpingZO,
              note: element.note,
              literate: element.literate,
              hadSurgery: element.had_surgery,
            });
          });
      }
    });
  }, [visitId]);

  const handelPatient = (event) => {
    event.preventDefault();

    const {
      commiteId,
      occupation,
      nation,
      country,
      NoFamily,
      province,
      district,
      street,
      closetHome,
      secoundPhoneNo,
      card,
      timeAllot,
      SPID,
      member,
      noMember,
      typ,
      HPE,
      finalDiagnos,
      earlyPatient,
      earlySurgery,
      // doctor,
      ZODoctor,
      medicalProvid,
      typeMedical,
      supplyLocation,
      helpingZO,
      indiction,
      visit_id,
    } = inputValue;

    dispatch(
      updatePatientAction(
        visit_id,
        commiteId,
        patientId,
        2,
        occupation,
        nation,
        country,
        married,
        NoFamily,
        province,
        district,
        street,
        closetHome,
        secoundPhoneNo,
        card,
        smoking,
        timeAllot,
        selectedOpts,
        selectMember,
        selectOrganLevel,
        selectedOptTypeSurgery,
        indiction,
        HPE,
        finalDiagnos,
        earlyPatient,
        earlySurgery,
        doctor,
        ZODoctor,
        medicalProvid,
        typeMedical,
        supplyLocation,
        helpingZO,
        reset,
        selectTypeOfHelp,
        inputValue.note,
        inputValue.literate,
        inputValue.hadSurgery
      )
    );
    dispatch(editPatientAction(patientId, visitId));
    dispatch(getPatient(locationID, 1, 10, "", "", "", "", "", 1));
    dispatch(getPatient(locationID, 1, 10, "", "", "", "", "", 1));

    closeModal(false);
    setInputValue("");
  };

  const handelSave = (event) => {
    event.preventDefault();
    const {
      commiteId,
      occupation,
      nation,
      country,
      NoFamily,
      province,
      district,
      street,
      closetHome,
      secoundPhoneNo,
      card,
      timeAllot,
      SPID,
      member,
      noMember,
      typ,
      HPE,
      finalDiagnos,
      earlyPatient,
      earlySurgery,
      // doctor,
      ZODoctor,
      medicalProvid,
      typeMedical,
      supplyLocation,
      helpingZO,
      indiction,
      visit_id,
    } = inputValue;

    dispatch(
      updatePatientAction(
        visit_id,
        commiteId,
        patientId,
        1,
        occupation,
        nation,
        country,
        married,
        NoFamily,
        province,
        district,
        street,
        closetHome,
        secoundPhoneNo,
        card,
        smoking,
        timeAllot,
        selectedOpts,
        selectMember,
        selectOrganLevel,
        selectedOptTypeSurgery,
        indiction,
        HPE,
        finalDiagnos,
        earlyPatient,
        earlySurgery,
        doctor,
        ZODoctor,
        medicalProvid,
        typeMedical,
        supplyLocation,
        helpingZO,
        reset,
        selectTypeOfHelp,
        inputValue.note,
        inputValue.literate,
        inputValue.hadSurgery
      )
    );
    setSelectMember([]);
    setSelectOrganLevel([]);
    setSelectedOpts([]);
    setDoctor([]);
    dispatch(editPatientAction(patientId, visitId));
    dispatch(getPatient(locationID, 1, 10, "", "", "", "", "", 1));
    dispatch(getPatient(locationID, 1, 10, "", "", "", "", "", 1));
    closeModal(false);
    setInputValue("");
    // }
  };
  const [openDoctor, setOpenDoctor] = useState(false);
  // const regex = /^(\d{4})(\d{3})(\d{4})$/;

  const [opens, setOpens] = useState(false); // Updated variable name

  const toggleOption = (value) => {
    if (selectedOpts.includes(value)) {
      setSelectedOpts(selectedOpts.filter((opt) => opt !== value));
    } else {
      setSelectedOpts([...selectedOpts, value]);
    }
  };

  const toggleOptionTypeSurgery = (value, type_id) => {
    setOrganLevelId([...organLevelId, type_id]);
    if (selectedOptTypeSurgery.includes(value)) {
      setSelectedOptTypeSurgery(
        selectedOptTypeSurgery.filter((opt) => opt !== value)
      );
    } else {
      setSelectedOptTypeSurgery([...selectedOptTypeSurgery, value]);
    }
  };

  const toggleOptionMember = (value, id) => {
    setSurgeryKuId(id);

    if (selectMember.includes(value)) {
      setSelectMember(selectMember.filter((opt) => opt !== value));
    } else {
      setSelectMember([...selectMember, value]);
    }
  };

  const toggleOptionTypeOfHelp = (value) => {
    if (selectTypeOfHelp.includes(value)) {
      setSelectTypeOfHelp(selectTypeOfHelp.filter((opt) => opt !== value));
    } else {
      setSelectTypeOfHelp([...selectTypeOfHelp, value]);
    }
  };

  const toggleOptionDoctor = (value) => {
    if (doctor.includes(value)) {
      setDoctor(doctor.filter((opt) => opt !== value));
    } else {
      setDoctor([...doctor, value]);
    }
  };

  const option3 = [
    "نەشتەرگەری",
    "پێداویستی پزیشکی",
    "تیشک",
    "دەرمان",
    "پشکنینی خوێن",
    "غەسلی پلازما",
    "غەسلی گورچیلە",
    "مانەوەی لە نەخۆشخانە",
    "نازۆر",
    "نازۆری گەدە",
    "نازۆری کۆڵۆن",
    "لابردنی سۆندەی گورچیلە",
    "دانانی فیچولان",
    "دانانی سیڤیلاین",
  ];

  const toggleOptionOrganLevel = (value) => {
    if (selectOrganLevel.includes(value)) {
      setSelectOrganLevel(selectOrganLevel.filter((opt) => opt !== value));
    } else {
      setSelectOrganLevel([...selectOrganLevel, value]);
    }
  };

  const handelTypeOfHelpSelect = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue === "-1") {
      setSelectedOpts([]); // Set your selected options array to an empty array
      setSelectedOptTypeSurgery([]);
      setSelectMember([]);
      setSelectOrganLevel([]);
      setSelectTypeOfHelp([]);
      setVisitId(null); // Set visitId to null
    } else {
      setSelectedOpts([]); // Reset selected options array if needed
      setSelectedOptTypeSurgery([]); // Reset other state variables if needed
      setSelectMember([]);
      setSelectOrganLevel([]);
      setSelectTypeOfHelp([]);
      setVisitId(selectedValue); // Set visitId to the selected value
    }
  };
  return (
    <div className={openEditModal ? " parentModal" : "closeParentModal"}>
      <div
        className="overlay"
        onClick={() => {
          closeModal(false);
          setSmoking(undefined);
          setMarried(undefined);
        }}
      ></div>

      <div className="childModal">
        <div className="flex justify-between flex-row-reverse ">
          <>
            {patients == undefined ? (
              ""
            ) : (
              <div className="flex gap-2">
                {patients.map((patient, patientIndex) => {
                  return (
                    <select
                      key={patientIndex}
                      name=""
                      value={visitId}
                      onChange={handelTypeOfHelpSelect}
                      className="select"
                      id=""
                    >
                      <option value="-1">Select Visitation</option>
                      {patient.visit.map((visit, index) => {
                        return (
                          <option value={visit.id} key={index}>
                            {visit.created_at.toString().slice(0, 10)}
                          </option>
                        );
                      })}
                    </select>
                  );
                })}
              </div>
            )}
          </>
          <div
            className="text-3xl cursor-pointer text-red-600"
            onClick={() => {
              closeModal(false);
              setSelectOrganLevel([]);
            }}
          >
            x
          </div>
        </div>
        {/* form */}

        {loading || patients == undefined ? (
          <img src={Logo} className="w-48 animate-bounce m-auto mt-5" />
        ) : (
          <>
            {visitId == null || visitId == -1 ? (
              ""
            ) : (
              <form action="">
                <div>
                  <div className="greenTitle">
                    <p className=" lg:text-xl">فۆرمی زانیاری نەخۆش</p>
                  </div>
                </div>
                <div className="parentInputGrid ">
                  <div className="w-full mt-4">
                    <div className="flex justify-end ">
                      <label htmlFor="province" className="lblDesing">
                        * پارێزگا{" "}
                      </label>
                    </div>
                    <input
                      type="text"
                      onChange={handelInputChange}
                      defaultValue={inputValue.province}
                      name="province"
                      className="inputUser text-right w-full"
                      placeholder=" پارێزگا"
                    />
                  </div>
                  {/* end parent Input & label */}
                  <div className="w-full mt-2">
                    <div className="w-full flex justify-end ">
                      <label htmlFor="nation" className="lblDesign">
                        {" "}
                        *نەتەوە
                      </label>
                    </div>
                    <input
                      type="text"
                      name="nation"
                      onChange={handelInputChange}
                      defaultValue={inputValue.nation}
                      id="nation"
                      className="inputUser text-right w-full"
                      placeholder="نەتەوە"
                    />
                  </div>
                  {/* end parent Input & label */}

                  <div className="w-full mt-2">
                    <div className="w-full flex justify-end ">
                      <label className="lblDesign" htmlFor="country">
                        {" "}
                        * وڵات{" "}
                      </label>
                    </div>
                    <input
                      type="text"
                      name="country"
                      onChange={handelInputChange}
                      defaultValue={inputValue.country}
                      id="country"
                      className="inputUser text-right w-full"
                      placeholder="وڵات"
                    />
                  </div>
                  {/* end parent Input & label */}

                  <div className="w-full mt-2">
                    <div className="w-full flex justify-end ">
                      <label htmlFor="name" className="lblDesign">
                        {" "}
                        *پیشە
                      </label>
                    </div>
                    <input
                      type="text"
                      name="occupation"
                      onChange={handelInputChange}
                      defaultValue={inputValue.occupation || null}
                      // defaultValue={inputValue.occupation || null}
                      id="name"
                      className="inputUser text-right w-full "
                      placeholder="پیشە"
                    />
                  </div>
                  {/* end parent Input & label */}
                </div>
                <div className="parentInputGrid">
                  <div></div>
                  <div className="w-full mt-2">
                    <div className="flex justify-end ">
                      <label htmlFor="street" className="lblDesign">
                        *شەقام گەڕەک{" "}
                      </label>
                    </div>
                    <input
                      type="text"
                      name="street"
                      onChange={handelInputChange}
                      defaultValue={inputValue.street}
                      id="street"
                      className="inputUser text-right w-full"
                      placeholder="شەقام گەڕەک"
                    />
                  </div>
                  {/* end parent Input & label */}

                  <div className="w-full mt-2">
                    <div className="flex justify-end ">
                      <label htmlFor="closetHome" className="lblDesign">
                        *نزیکترین شوێن لە ماڵیانەوە
                      </label>
                    </div>
                    <input
                      type="text"
                      name="closetHome"
                      onChange={handelInputChange}
                      defaultValue={inputValue.closetHome}
                      id="closetHome"
                      className="inputUser text-right w-full"
                      placeholder="نزیکترین شوێن لە ماڵیانەوە"
                    />
                  </div>
                  {/* end parent Input & label */}

                  <div className="w-full mt-2">
                    <div className="flex justify-end ">
                      <label htmlFor="disc" className="lblDesign">
                        * ناحیە/قەزا{" "}
                      </label>
                    </div>
                    <input
                      type="text"
                      name=" ناحیە/قەزا"
                      onChange={handelInputChange}
                      defaultValue={inputValue.district}
                      id="disc"
                      className="inputUser text-right w-full "
                      placeholder="District"
                    />
                  </div>
                  {/* end parent Input & label */}
                </div>

                <div className="parentInputGrid">
                  {/* end parent Input & label */}

                  <div className="w-full mt-2">
                    <div className="w-full justify-end flex">
                      <label htmlFor="scoundPhoneNo" className="lblDesign">
                        *ژ.مۆبایلی دووەم
                      </label>
                    </div>
                    <input
                      type="text"
                      name="secoundPhoneNo"
                      onChange={handelInputChange}
                      defaultValue={inputValue.secoundPhoneNo}
                      id="scoundPhoneNo"
                      className="inputUser text-right w-full "
                      placeholder="ژ.مۆبایلی دووەم"
                    />
                  </div>

                  {/* end parent Input & label */}

                  <div className="w-full mt-2">
                    <div className="flex justify-end ">
                      <label htmlFor="number" className="lblDesign">
                        * ژ.کارتی نیشتمانی یاخود ناسنامەی
                      </label>
                    </div>
                    <input
                      type="text"
                      name="card"
                      onChange={handelInputChange}
                      defaultValue={inputValue.card}
                      id="number"
                      className="inputUser text-right w-full"
                      placeholder="ژ.کارتی نیشتمانی یاخود ناسنامەی"
                    />
                  </div>
                  <div className="w-full mt-2">
                    <div className="flex justify-end ">
                      <label htmlFor="noFamily" className="lblDesign">
                        *ژمارەی خێزان
                      </label>
                    </div>
                    <input
                      type="text"
                      name="NoFamily"
                      onChange={handelInputChange}
                      defaultValue={inputValue.NoFamily}
                      id="noFamily"
                      className="inputUser text-right w-full"
                      placeholder="ژمارەی خێزان"
                    />
                  </div>

                  {/* end parent Input & label */}

                  <div className="w-full ">
                    <div className="relative w-full ">
                      <div className="child ">
                        <div className="flex justify-end ">
                          <label htmlFor="gender" className="lblDesign">
                            {" "}
                            * خێزاندارە{" "}
                          </label>
                        </div>
                        <div onClick={handelSelect} className="coustmSelect cursor-pointer">
                          <p>
                            {married == "" || married == null
                              ? "Select Married .... "
                              : married}
                          </p>
                          <div
                            className={
                              openSelect ? "parentCaretDown" : "duration-300"
                            }
                          >
                            <AiFillCaretDown />
                          </div>
                        </div>
                        {openSelect && (
                          <div className="elementCoustmSelect z-50">
                            <div
                              onClick={() => {
                                setOpenSelect(false);
                                setMarried("بەڵێ");
                              }}
                              className="option"
                            >
                              بەڵێ
                            </div>

                            <div
                              className="option"
                              onClick={() => {
                                setOpenSelect(false);
                                setMarried("نەخێر");
                              }}
                            >
                              نەخێر
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="parentInputGrid ">
                  {/* end parent Input & label */}
                  <div></div>
                  <div className="w-full mt-4">
                    <div className="flex justify-end ">
                      <label htmlFor="literate" className="lblDesign">
                        * خوێندەوارە{" "}
                      </label>
                    </div>
                    <select
                      type="text"
                      name="literate"
                      onChange={handelInputChange}
                      value={inputValue.literate}
                      id="literate"
                      placeholder="خوێندەوارە"
                      className="inputUser bg-white w-full"
                    >
                      <option value="" selected>
                        {" "}
                        خوێندەوارە
                      </option>
                      <option value="بەڵێ"> بەڵێ</option>
                      <option value="نەخێر"> نەخێر</option>
                    </select>
                  </div>
                  {/* end parent Input & label */}
                  <div className="w-full mt-2 ">
                    <div className="relative w-full ">
                      <div className="child ">
                        <div className="w-full flex justify-end ">
                          <label htmlFor="smoke" className="lblDesign">
                            {" "}
                            *جگەرە کێشە
                          </label>
                        </div>
                        <div
                          onClick={handelSmoking}
                          className="coustmSelect z-50 "
                        >
                          <p>
                            {smoking == "" || smoking == null
                              ? "Select Smoking .... "
                              : smoking}
                          </p>
                          <div
                            className={
                              isOpenSmoking ? "parentCaretDown" : "duration-300"
                            }
                          >
                            <AiFillCaretDown />
                          </div>
                        </div>
                        {isOpenSmoking && (
                          <div className="elementCoustmSelect z-50">
                            <div
                              onClick={() => {
                                setIsOpenSmoking(false);
                                setSmoking("بەڵێ");
                              }}
                              className="option"
                            >
                              بەڵێ
                            </div>

                            <div
                              className="option"
                              onClick={() => {
                                setIsOpenSmoking(false);
                                setSmoking("نەخێر");
                              }}
                            >
                              نەخێر
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="w-full mt-4">
                    <div className="flex justify-end ">
                      <label htmlFor="timeAllot" className="lblDesign">
                        * کاتی دیاریکراو بۆ هەڵسەنگاندن{" "}
                      </label>
                    </div>
                    <select
                      type="text"
                      name="timeAllot"
                      onChange={handelInputChange}
                      value={inputValue.timeAllot}
                      id="timeAllot"
                      placeholder="کاتی دیاریکراو بۆ هەڵسەنگاندن"
                      className="inputUser bg-white w-full  "
                    >
                      <option value="-1"> کاتی دیاریکراو هەڵبژێرە</option>
                      {amountOfDays.map((amount, index) => {
                        return (
                          <option value={amount} key={index}>
                            {amount}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {/* end parent Input & label */}
                </div>

                <div className="greenTitle">
                  <p className="lg:text-lg">زانیاری پزیشکی</p>
                </div>
                <div className="w-full  relative">
                  <div className="relative mt-3 text-right ">
                    <label htmlFor="" className="lblDesign mr-2">
                      * جۆری هاوکاری{" "}
                    </label>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenOrganLevel(!openOrganLevel);
                      }} // Updated variable name
                      className=" parentCoustmSelectMultipule"
                    >
                      {selectTypeOfHelp.length > 0
                        ? selectTypeOfHelp.join(", ")
                        : "Select options..."}
                    </button>
                    {openOrganLevel && (
                      <div className="optionMultiple h-72 overflow-y-auto">
                        {option3.map((opt) => (
                          <label
                            key={opt}
                            className="flex items-center px-4 py-2 cursor-pointer"
                          >
                            <input
                              type="checkbox"
                              value={opt}
                              checked={selectTypeOfHelp.includes(opt)}
                              onChange={() => toggleOptionTypeOfHelp(opt)}
                              className="mr-2 cursor-pointer"
                            />
                            {opt}
                          </label>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                {selectTypeOfHelp.map((select, index) => {
                  return (
                    <div className="" key={index}>
                      {select == "نەشتەرگەری" ? (
                        <>
                          <div className="parentInputGrid">
                            <div className="relative mt-3">
                              <label htmlFor="" className="lblDesign">
                                TYP *
                              </label>
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  setOpenTypeSurgery(!openTypeSurgery);
                                }} // Updated variable name
                                className="  border-2  rounded-r-md bg-white  py-1.5 w-full px-4 text-left"
                              >
                                {selectedOptTypeSurgery.length > 0
                                  ? selectedOptTypeSurgery.join(", ")
                                  : "Select options..."}
                              </button>
                              {openTypeSurgery && (
                                <div className="optionMultiple h-72 overflow-y-auto">
                                  {surgeryTypeArray.map((opt) => (
                                    <label
                                      key={opt.label}
                                      className="flex items-center px-4 py-2"
                                    >
                                      <input
                                        type="checkbox"
                                        value={opt.value}
                                        checked={selectedOptTypeSurgery.includes(
                                          opt.label
                                        )}
                                        onChange={() =>
                                          toggleOptionTypeSurgery(
                                            opt.label,
                                            opt.value
                                          )
                                        }
                                        className="mr-2"
                                      />
                                      {opt.label}
                                    </label>
                                  ))}
                                </div>
                              )}
                            </div>
                            <div className="relative mt-3 text-right">
                              <label
                                htmlFor=""
                                className="text-right lblDesign"
                              >
                                * (جۆری نەشتەرگەری) لەسەر ئاستی ئەندام
                              </label>
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  setOpenTypeOfHelp(!openTypeOfHelp);
                                }} // Updated variable name
                                className="  border-2  rounded-r-md bg-white  py-1.5 w-full px-4 text-left"
                              >
                                {selectOrganLevel.length > 0
                                  ? selectOrganLevel.join(", ")
                                  : "Select options..."}
                              </button>
                              {openTypeOfHelp && (
                                <div className="optionMultiple h-72 overflow-y-auto">
                                  {organArray.map((opt) => (
                                    <label
                                      key={opt.label}
                                      className="flex items-center px-4 py-2"
                                    >
                                      <input
                                        type="checkbox"
                                        value={opt.value}
                                        checked={selectOrganLevel.includes(
                                          opt.label
                                        )}
                                        onChange={() =>
                                          toggleOptionOrganLevel(opt.label)
                                        }
                                        className="mr-2"
                                      />
                                      {opt.label}
                                    </label>
                                  ))}
                                </div>
                              )}
                            </div>

                            <div className="relative mt-3 text-right">
                              <label
                                htmlFor=""
                                className="lblDesign text-right"
                              >
                                * (ئەندامی نەشتەرگەری لەسەرکراو) ئەندام
                              </label>
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  setOpenMemeber(!openMember);
                                }} // Updated variable name
                                className="  border-2  rounded-r-md bg-white  py-1.5 w-full px-4 text-left"
                              >
                                {selectMember.length > 0
                                  ? selectMember.join(", ")
                                  : "Select options..."}
                              </button>
                              {openMember && (
                                <div className="optionMultiple h-72 overflow-y-auto">
                                  {memberArray.map((opt) => (
                                    <label
                                      key={opt.label}
                                      className="flex items-center px-4 py-2"
                                    >
                                      <input
                                        type="checkbox"
                                        value={opt.value}
                                        checked={selectMember.includes(
                                          opt.label
                                        )}
                                        onChange={() => {
                                          toggleOptionMember(
                                            opt.label,
                                            opt.value
                                          );
                                        }}
                                        className="mr-2"
                                      />
                                      {opt.label}
                                    </label>
                                  ))}
                                </div>
                              )}
                            </div>

                            {/* <p className="text-red-600 ">
                      {
                        patients.map((patient)=>{
                          return (
                            patient.commite.map((commite,index)=>{
                              return (

                                <p key={index}> {commite.SITD} :{commite.occupation} : {commite.TYP} </p>
                              )
                            })
                          )
                        })
                      }

                   
                  </p> */}

                            <div className="relative mt-3 text-right w-full">
                              <label
                                htmlFor="spi"
                                className="text-right lblDesign"
                              >
                                * پسپۆری جۆری نەخۆشی
                              </label>
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  setOpens(!opens);
                                }} // Updated variable name
                                className="  border-2  rounded-r-md bg-white  py-1.5 w-full px-4 text-left"
                              >
                                {selectedOpts.length > 0
                                  ? selectedOpts.join(" , ")
                                  : "Select options..."}
                              </button>

                              {opens && (
                                <div className="optionMultiple h-72 overflow-y-auto">
                                  {surgeryArray.map((opt) => (
                                    <label
                                      key={opt.label}
                                      className="flex items-center px-4 py-2"
                                    >
                                      <input
                                        type="checkbox"
                                        value={opt.value}
                                        checked={selectedOpts.includes(
                                          opt.label
                                        )}
                                        onChange={() => {
                                          toggleOption(opt.label);
                                          setTypeSurgeryId(opt.value);
                                        }}
                                        className="mr-2"
                                      />
                                      {opt.label}
                                    </label>
                                  ))}
                                </div>
                              )}
                            </div>

                            {/* end parent Input & label */}

                            {/* end parent Input & label */}
                          </div>

                          <div className="parentInputGrid">
                            <div className="w-full ">
                              <label htmlFor="Indiction" className="lblDesign">
                                Indiction *
                              </label>
                              <input
                                type="text"
                                name="indiction"
                                onChange={handelInputChange}
                                defaultValue={inputValue.indiction}
                                id="Indiction"
                                className="inputUser text-right w-full "
                                placeholder="Indiction"
                              />
                            </div>
                            {/* end parent Input & label */}

                            <div className="w-full ">
                              <div className="flex justify-end ">
                                <label
                                  htmlFor="earlySurg"
                                  className="lblDesign"
                                >
                                  * نەشتەرگەری پێشوەخت{" "}
                                </label>
                              </div>
                              <input
                                type="text"
                                name="earlySurgery"
                                onChange={handelInputChange}
                                defaultValue={inputValue.earlySurgery}
                                id="earlySurg"
                                className="inputUser text-right w-full "
                                placeholder="نەشتەرگەری پێشوەخت"
                              />
                            </div>
                            {/* end parent Input & label */}

                            <div className="w-full ">
                              <div className="flex justify-end ">
                                <label htmlFor="ea" className="lblDesign">
                                  * نەخۆشی پێش وەخت
                                </label>
                              </div>
                              <input
                                type="text"
                                name="earlyPatient"
                                onChange={handelInputChange}
                                defaultValue={inputValue.earlyPatient}
                                id="ea"
                                className="inputUser text-right w-full "
                                placeholder="نەخۆشی پێش وەخت"
                              />
                            </div>
                            {/* end parent Input & label */}
                            <div className="w-full ">
                              <div className="flex justify-end ">
                                <label htmlFor="ea" className="lblDesign">
                                  * نەشتەرگەری بۆ کراوە
                                </label>
                              </div>
                              <select
                                name="hadSurgery"
                                onChange={handelInputChange}
                                value={inputValue.hadSurgery}
                                className="inputUser bg-white w-full "
                                id=""
                              >
                                <option value="">
                                  تکایە زانیاریەکە هەڵبژێرە
                                </option>
                                <option value="بەڵێ">بەڵێ</option>
                                <option value="نەخێر">نەخێر</option>
                              </select>
                              {/* <input
                                type="text"
                                name="earlyPatient"
                                onChange={handelInputChange}
                                defaultValue={inputValue.earlyPatient}
                                id="ea"
                                className="inputUser text-right w-full "
                                placeholder="نەشتەرگەری بۆ کراوە"
                              /> */}
                            </div>
                            {/* end parent Input & label */}
                          </div>

                          <div className="parentInputGrid">
                            <div className="w-full mt-2 ">
                              <label htmlFor="H.P.E" className="lblDesign">
                                H.P.E *
                              </label>
                              <input
                                type="text"
                                name="HPE"
                                onChange={handelInputChange}
                                defaultValue={inputValue.HPE}
                                id="H.P.E"
                                className="inputUser text-right w-full"
                                placeholder="H.P.E "
                              />
                            </div>

                            {/* end parent Input & label */}

                            <div className="w-full mt-2 ">
                              <div className="flex justify-end ">
                                <label
                                  htmlFor="doctorSend"
                                  className="lblDesign"
                                >
                                  {" "}
                                  * پزیشکی نێردراو بۆ
                                </label>
                              </div>
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  setOpenDoctor(!openDoctor);
                                }} // Updated variable name
                                className="  border-2 shadow-md rounded-r-sm bg-white  py-1.5 w-full px-4 text-left"
                              >
                                {doctor.length > 0
                                  ? doctor.join(" , ")
                                  : "Select options..."}
                              </button>

                              {openDoctor && (
                                <div className="optionMultiple  h-72 overflow-y-auto">
                                  {doctorArray.map((opt) => (
                                    <label
                                      key={opt.label}
                                      className="flex items-center px-4 py-2"
                                    >
                                      <input
                                        type="checkbox"
                                        value={opt.value}
                                        checked={doctor.includes(opt.label)}
                                        onChange={() => {
                                          toggleOptionDoctor(opt.label);
                                          // toggleOption(opt.label);
                                          // setTypeSurgeryId(opt.value);
                                        }}
                                        className="mr-2"
                                      />
                                      {opt.label}
                                    </label>
                                  ))}
                                </div>
                              )}
                            </div>

                            {/* end parent Input & label */}

                            <div className="w-full mt-2">
                              <div className="flex justify-end ">
                                <label htmlFor="ZODoctor" className="lblDesign">
                                  {" "}
                                  *پزیشکی هاوکاری ڕیکخراوی زادە
                                </label>
                              </div>

                              <select
                                name="ZODoctor"
                                value={inputValue.ZODoctor || ""}
                                className=" inputUser w-full bg-white "
                                onChange={handelInputChange}
                                id="ZODoctor"
                              >
                                <option value="">Select ..</option>
                                <option value="بەڵێ">بەڵێ</option>
                                <option value="نەخێر">نەخێر</option>
                              </select>
                            </div>
                            {/* end parent Input & label */}

                            <div className="w-full mt-2">
                              <div className="flex justify-end ">
                                <label
                                  htmlFor="helpingZO"
                                  className="lblDesign"
                                >
                                  * هاوکاری رێکخراوی زادە
                                </label>
                              </div>

                              <select
                                name="helpingZO"
                                id="helpingZO"
                                value={inputValue.helpingZO || ""}
                                className="inputUser bg-white w-full  "
                                onChange={handelInputChange}
                              >
                                <option value="">Select ..</option>
                                <option value="بەڵێ">بەڵێ</option>
                                <option value="نەخێر">نەخێر</option>
                              </select>
                            </div>
                            {/* end parent Input & label */}
                            <div className="w-full mt-4">
                              <label htmlFor="Final" className="lblDesign">
                                Final Diagnosis *
                              </label>
                              <input
                                type="text"
                                onChange={handelInputChange}
                                defaultValue={inputValue.finalDiagnos}
                                name="finalDiagnos"
                                id="Final"
                                className="inputUser text-right w-full"
                                placeholder="Final Diagnosis"
                              />
                            </div>
                            {/* end parent Input & label */}
                          </div>
                        </>
                      ) : select == "پێداویستی پزیشکی" ? (
                        <div className="parentInputGrid">
                          <div></div>
                          <div className="w-full mt-2">
                            <div className="flex justify-end ">
                              <label
                                htmlFor="typeMedical"
                                className="lblDesign"
                              >
                                * بۆ چی
                              </label>
                            </div>
                            <input
                              type="text"
                              name="typeMedical"
                              onChange={handelInputChange}
                              defaultValue={inputValue.typeMedical}
                              id="typeMedical"
                              className="inputUser text-right w-full "
                              placeholder="بۆ چی"
                            />
                          </div>
                          {/* end parent Input & label */}
                          <div className="w-full mt-2">
                            <div className="flex justify-end ">
                              <label
                                htmlFor="supplyLocation"
                                className="lblDesign"
                              >
                                * شوێنی دابینکردنی پێداویستی
                              </label>
                            </div>
                            <input
                              type="text"
                              name="supplyLocation"
                              onChange={handelInputChange}
                              defaultValue={inputValue.supplyLocation}
                              id="supplyLocation"
                              className="inputUser text-right w-full"
                              placeholder="شوێنی دابینکردنی پێداویستی"
                            />
                          </div>
                          <div className="w-full mt-2">
                            <div className="flex justify-end ">
                              <label
                                htmlFor="medicalProvid"
                                className="lblDesign"
                              >
                                * پێداویستی پزیشکی بۆ دابینکراوە{" "}
                              </label>
                            </div>

                            <select
                              name="medicalProvid"
                              id="medicalProvid"
                              value={inputValue.medicalProvid || ""}
                              className=" inputUser w-full bg-white "
                              onChange={handelInputChange}
                            >
                              <option value="">Select ..</option>
                              <option value="بەڵێ">بەڵێ</option>
                              <option value="نەخێر">نەخێر</option>
                            </select>
                          </div>
                          {/* end parent Input & label */}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })}
                <div className="mt-2">
                  <textarea
                    name="note"
                    onChange={handelInputChange}
                    value={inputValue.note}
                    className="w-full inputUser text-right"
                    placeholder="تێبینی"
                    rows="6"
                  ></textarea>
                </div>
                {patients.map((patient) => {
                  return patient.visit
                    .filter((filter) => filter.id == visitId)
                    .map((visit, index) => {
                      return (
                        <>
                          {visit.complete == "complete" ? (
                            ""
                          ) : (
                            <div className="flex items-center justify-between gap-3  mt-3">
                              <div className="flex gap-2" key={index}>
                                <button
                                  className="editBtn w-20"
                                  onClick={handelSave}
                                >
                                  Save
                                </button>
                                <div>
                                <button
                                  onClick={handelPatient}
                                  className="assignBtn"
                                >
                                  Assign
                                </button>
                              </div>
                              </div>

                             

                              <div className="" title="Print">
                                <ReactToPrint
                                
                                  trigger={() => (

                                    <div className="btnViolet cursor-pointer ">
                                      <IoMdPrint />
                                    </div>
                                  )}
                                  documentTitle="Print"
                                  content={() => componentRef.current}
                                />
                              </div>
                            </div>
                          )}
                        </>
                      );
                    });
                })}

                <div ref={componentRef} className="table-print">
                  <FeedbackPrint patients={patients} />
                </div>
              </form>
            )}
          </>
        )}
      </div>

    </div>
  );
};

export default AssignModal;
