import Swal from "sweetalert2";
import { api } from "../../Api/api";
import { getToken } from "../login";
import {
  CREATE_RECIVE_FILE_ADMINSTRATOR_FAIL,
  CREATE_RECIVE_FILE_ADMINSTRATOR_REQUEST,
  CREATE_RECIVE_FILE_ADMINSTRATOR_SUCCESS,
  FETCH_RECIVE_FILE_ADMINSTRATOR_FAIL,
  FETCH_RECIVE_FILE_ADMINSTRATOR_REQUEST,
  FETCH_RECIVE_FILE_ADMINSTRATOR_SUCCESS,
  SHOW_RECIVE_FILE_ADMINSTRATOR_FAIL,
  SHOW_RECIVE_FILE_ADMINSTRATOR_REQUEST,
  SHOW_RECIVE_FILE_ADMINSTRATOR_SUCCESS,
  UPDATE_RECIVE_FILE_ADMINSTRATOR_FAIL,
  UPDATE_RECIVE_FILE_ADMINSTRATOR_REQUEST,
  UPDATE_RECIVE_FILE_ADMINSTRATOR_SUCCESS,
} from "../../ActionType/ReciveFileAdminstrator/ReciveFileAdminstrator";

export const createReciveFilesAdminstratorAction =
  (branchId, sectionId, sentFileId,userNameValue) => (dispatch) => {
    // console.log(userNameValue)
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    };
    const arrBranchTemp = [];
    branchId.map((brnach) => {
      arrBranchTemp.push(brnach.label);
    });
    const arrUserTemp = [];
    userNameValue.map((user) => {
      arrUserTemp.push(user);
    });

    const arrSectionTemp = [];
    sectionId.map((section) => {
      arrSectionTemp.push(section.label);
    });
    // console.log(userNameValue)
    dispatch({
      type: CREATE_RECIVE_FILE_ADMINSTRATOR_REQUEST,
    });
    api
      .post(
        "reciveFilesAdminstrator/create",
        {
          branch_id: arrBranchTemp,
          sent_file_id: sentFileId,
          section_id: arrSectionTemp,
          user:arrUserTemp
        },
        config
      )
      .then((response) => {
        const { status, data } = response;
        console.log(response)
        if (status == 200) {
          dispatch({
            type: CREATE_RECIVE_FILE_ADMINSTRATOR_SUCCESS,
            payload: data,
          });
          Swal.fire({
            icon: "success",
            timer: 2000,
            title: "سەرکەوتو بوو",
            text: "زانیاریەکە بە سەرکەوتووی نێردرا",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: CREATE_RECIVE_FILE_ADMINSTRATOR_FAIL,
        });
      });
  };

export const getReciveFilesAdminstratorAction = (branch_id,section_id,userId) => (dispatch) => {
  // console.log(userId)
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    params:{
      branch_id,
      section_id,
      user_id:userId
    }
  };

  dispatch({
    type: FETCH_RECIVE_FILE_ADMINSTRATOR_REQUEST,
  });
  api
    .get("reciveFilesAdminstrator", config)
    .then((response) => {
      const { status, data } = response;
      // console.log('res : ',response)
      if (status == 200) {
        dispatch({
          type: FETCH_RECIVE_FILE_ADMINSTRATOR_SUCCESS,
          payload: { data: data.data, total: data.total },
        });
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: FETCH_RECIVE_FILE_ADMINSTRATOR_FAIL,
      });
    });
};

export const updateReciveFilesAdminstratorAction =
  (branchId, sectionId, id,userNameValue) => (dispatch) => {
    
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    };
    const arrBranchTemp = [];
    branchId.map((brnach) => {
      arrBranchTemp.push(brnach.label);
    });

    const arrUserTemp = [];
    userNameValue.map((user) => {
      arrUserTemp.push(user);
    });

    const arrSectionTemp = [];
    sectionId.map((section) => {
      arrSectionTemp.push(section.label);
    });

    dispatch({
      type: UPDATE_RECIVE_FILE_ADMINSTRATOR_REQUEST,
    });
    api
      .put(
        `reciveFilesAdminstrator/update/${id}`,
        {
          branch_id: arrBranchTemp,
          section_id: arrSectionTemp,
          user:arrUserTemp
        },
        config
      )
      .then((response) => {
        const { status, data } = response;
        if (status == 200) {
          dispatch({
            type: UPDATE_RECIVE_FILE_ADMINSTRATOR_SUCCESS,
            payload: data,
          });
          Swal.fire({
            icon: "success",
            timer: 2000,
            title: "سەرکەوتو بوو",
            text: "زانیاریەکە گۆڕدرا",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: UPDATE_RECIVE_FILE_ADMINSTRATOR_FAIL,
        });
      });
  };

export const showReciveFilesAdminstratorAction = (sentFileId) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };

  dispatch({
    type: SHOW_RECIVE_FILE_ADMINSTRATOR_REQUEST,
  });
  api
    .get(`reciveFilesAdminstrator/show/${sentFileId}`, config)
    .then((response) => {
      const { status, data } = response;

      if (status == 200) {
        dispatch({
          type: SHOW_RECIVE_FILE_ADMINSTRATOR_SUCCESS,
          payload: data,
        });
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: SHOW_RECIVE_FILE_ADMINSTRATOR_FAIL,
      });
    });
};
