import { FETCH_STATISTICS_FAIL, FETCH_STATISTICS_REQUEST, FETCH_STATISTICS_SUCCESS } from "../../ActionType/StatisticsType/StatisticsType"

export const getStatisticsReducer = (state ={loading:true,data:[],totalVisit:0,total:0,error:""} , action) => {
    switch (action.type) {
        case FETCH_STATISTICS_REQUEST:
            return {
                loading:true,
                
            }
        case FETCH_STATISTICS_SUCCESS:
            return {
                loading:false,
                data:action.payload.data,
                total:action.payload.total,
                // totalVisit:action.payload.totalVisit,
                error:""
            }
        case FETCH_STATISTICS_FAIL:
            return {
                loading:false,
                data:[],
                error:"Please Check Your Request"
            }
        default:
            return state
    }
}

export const exportStatisticsReducer = (state ={loading:true,data:[],error:""} , action) => {
    switch (action.type) {
        case FETCH_STATISTICS_REQUEST:
            return {
                loading:true,
                
            }
        case FETCH_STATISTICS_SUCCESS:
            return {
                loading:false,
                data:action.payload.data,
                error:""
            }
        case FETCH_STATISTICS_FAIL:
            return {
                loading:false,
                data:[],
                error:"Please Check Your Request"
            }
        default:
            return state
    }
}