export const CREATE_USER_REQUEST = "CREATE_USER_REQUEST";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_FAIL = "CREATE_USER_FAIL";

export const FETCH_USER_REQUEST = "FETCH_USER_REQUEST";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAIL = "FETCH_USER_FAIL";

export const FETCH_ALL_USER_REQUEST = "FETCH_ALL_USER_REQUEST";
export const FETCH_ALL_USER_SUCCESS = "FETCH_ALL_USER_SUCCESS";
export const FETCH_ALL_USER_FAIL = "FETCH_ALL_USER_FAIL";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

export const SHOW_USER_REQUEST = "SHOW_USER_REQUEST";
export const SHOW_USER_SUCCESS = "SHOW_USER_SUCCESS";
export const SHOW_USER_FAIL = "SHOW_USER_FAIL";

export const SHOW_USER_ADMINSTRATOR_REQUEST = "SHOW_USER_ADMINSTRATOR_REQUEST";
export const SHOW_USER_ADMINSTRATOR_SUCCESS = "SHOW_USER_ADMINSTRATOR_SUCCESS";
export const SHOW_USER_ADMINSTRATOR_FAIL = "SHOW_USER_ADMINSTRATOR_FAIL";

export const UPDATE_USER_INFORMATION_REQUEST = "UPDATE_USER_INFORMATION_REQUEST";
export const UPDATE_USER_INFORMATION_SUCCESS = "UPDATE_USER_INFORMATION_SUCCESS";
export const UPDATE_USER_INFORMATION_FAIL = "UPDATE_USER_INFORMATION_FAIL";


// USER CV UPLOAD
export const CREATE_USER_CV_REQUEST = "CREATE_USER_CV_REQUEST";
export const CREATE_USER_CV_SUCCESS = "CREATE_USER_CV_SUCCESS";
export const CREATE_USER_CV_FAIL = "CREATE_USER_CV_FAIL";

export const DELETE_USER_CV_REQUEST = "DELETE_USER_CV_REQUEST";
export const DELETE_USER_CV_SUCCESS = "DELETE_USER_CV_SUCCESS";
export const DELETE_USER_CV_FAIL = "DELETE_USER_CV_FAIL";

export const ADD_USER_CV_REQUEST = "ADD_USER_CV_REQUEST";
export const ADD_USER_CV_SUCCESS = "ADD_USER_CV_SUCCESS";
export const ADD_USER_CV_FAIL = "ADD_USER_CV_FAIL";

