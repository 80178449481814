import {
  CREATE_RECEIVED_FAIL,
  CREATE_RECEIVED_REQUEST,
  CREATE_RECEIVED_SUCCESS,
  FETCH_NOTIFICATION_FAIL,
  FETCH_NOTIFICATION_REQUEST,
  FETCH_NOTIFICATION_SUCCESS,
  FETCH_RECEIVE_FAIL,
  FETCH_RECEIVE_SUCCESS,
  FETCH_RECEVIE_REQUEST,
  SEEN_NOTIFICATION_FAIL,
  SEEN_NOTIFICATION_REQUEST,
  SEEN_NOTIFICATION_SUCCESS,
  SHOW_ALL_RECEIVE_FAIL,
  SHOW_ALL_RECEIVE_SUCCESS,
  SHOW_ALL_RECEVIE_REQUEST,

} from "../ActionType/receivedType";

export const getReceiveReducer = (
  state = { loading: true, receive: [],total:0, error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_RECEVIE_REQUEST:
      return {
        loading: true,
      };
    case FETCH_RECEIVE_SUCCESS:
      
      return {
        loading: false,
        receive: action.payload.data,
        total:action.payload.total,
        error: "",
      };
    case FETCH_RECEIVE_FAIL:
      return {
        loading: false,
        receive: [],
        error: "Please Check Your Request",
      };
    default:
      return state;
  }
};

export const showReceiveReducer = (
  state = { loading: true, receive: [], error: "" },
  action
) => {
  switch (action.type) {
    case SHOW_ALL_RECEVIE_REQUEST:
      return {
        loading: true,
      };
    case SHOW_ALL_RECEIVE_SUCCESS:
      return {
        loading: false,
        receive: action.payload,
        error: "",
      };
    case SHOW_ALL_RECEIVE_FAIL :
      return {
        loading: false,
        receive: [],
        error: "Please Check Your Request",
      };
    default:
      return state;
  }
};
export const getNotificationReucer = (
  state = { loading: true, notifi: [], error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_NOTIFICATION_REQUEST:
      return {
        loading: true,
      };
    case FETCH_NOTIFICATION_SUCCESS:
      return {
        loading: false,
        notifi: action.payload,
        error: "",
      };
    case FETCH_NOTIFICATION_FAIL:
      return {
        loading: false,
        notifi: [],
        error: "Please Check Your Request",
      };
    default:
      return state;
  }
};

export const seeNotificationReducer = (
  state = { loading: true, seen: [], error: "" },
  action
) => {
  switch (action.type) {
    case SEEN_NOTIFICATION_REQUEST:
      return {
        loading: true,
      };
    case SEEN_NOTIFICATION_SUCCESS:
      return {
        loading: false,
        seen: action.payload,
        error: "",
      };
    case SEEN_NOTIFICATION_FAIL:
      return {
        loading: false,
        seen: [],
        error: "Please Check Your Request",
      };
    default:
      return state;
  }
};

export const createReceivedReducer = (
  state = { loading: true, receive: [], error: "" },
  action
) => {
  switch (action.type) {
    case CREATE_RECEIVED_REQUEST:
      return {
        loading: true,
      };
    case CREATE_RECEIVED_SUCCESS:
      return {
        loading: false,
        receive: action.payload,
        error: "",
      };
    case CREATE_RECEIVED_FAIL:
      return {
        loading: false,
        receive: [],
        error: "Please Check Your Request",
      };
    default:
      return state;
  }
};
