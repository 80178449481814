import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAttendanceAction,
  updateAttendanceAction,
} from "../../Action/AttendanceAction/AttendanceAction";
import AttendanceApprovedModal from "../../Modal/Attendance/AttendanceApprovedModal";
import "../../Pages/Global.css";
import Logo from "../../Image/Logo.jpg";
import ReactPaginate from "react-paginate";
import { CiFilter } from "react-icons/ci";
import { FaCheck, FaLeaf } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import Select from "react-select";
import { getSection } from "../../Action/section";
import { useNavigate } from "react-router-dom";
import ExtraHourModal from "../../Modal/AdminstratorModal/ExtraHourModal";

const EmployeeAttendance = () => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  // const [last30day,setLast30Day]=useState(null);
  const [isOpenExtraHour, setIsOpenExtraHour] = useState(false);
  const [extraHour, setExtraHour] = useState(null);
  
  const dispatch = useDispatch();
  const getAttendance = useSelector((state) => state.getAttendance);
  const { attendances, loading, total } = getAttendance;
  const [time, setTime] = useState(null);
  const [approveCheckIn, setApproveCheckIn] = useState(null);
  const [perPage, setPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const [searchParams, setSearchParams] = useState({
    from: "",
    to: "",
    fullname: "",
    actualCheckIn: "",
    approveCheckIn: "",
    approveCheckOut: "",
    actualCheckOut: "",
    approveTime: "",
  });
  const [selectedTab, setSelectedTab] = useState("");

  const getSectionReduer = useSelector((state) => state.getSection);
  const { sections } = getSectionReduer;
  const {
    from,
    to,
    fullname,
    actualCheckIn,
    approveCheckOut,
    actualCheckOut,
    approveTime,
  } = searchParams;
  useEffect(() => {
    // setSelectedTab("")
    dispatch(getSection());
    dispatch(
      getAttendanceAction(
        perPage,
        page,
        from,
        to,
        fullname,
        actualCheckIn,
        searchParams.approveCheckIn,
        approveCheckOut,
        actualCheckOut,
        approveTime,
        selectedOptions
      )
    );
  }, [perPage, selectedOptions, selectedTab]);
  const [circule, setCircule] = useState(false);
  const [id, setId] = useState(null);
  const [check, setCheck] = useState(null);
  const [approvedTime, setApprovedTime] = useState([]);

  function getHourDifference(startTime, endTime) {
    const [startHours, startMinutes, startSeconds] = startTime
      .split(":")
      .map(Number);
    const [endHours, endMinutes, endSeconds] = endTime.split(":").map(Number);

    const totalMilliseconds =
      (endHours - startHours) * 3600000 +
      (endMinutes - startMinutes) * 60000 +
      (endSeconds - startSeconds) * 1000;

    const totalHours = totalMilliseconds / (1000 * 60 * 60);
    return formatTime(totalHours);
  }

  function formatTime(totalHours) {
    const hours = Math.floor(totalHours);
    const minutes = Math.floor((totalHours % 1) * 60);
    const seconds = Math.floor((((totalHours % 1) * 60) % 1) * 60);

    return [
      String(hours).padStart(2, "0"),
      String(minutes).padStart(2, "0"),
      String(seconds).padStart(2, "0"),
    ].join(":");
  }
  useEffect(() => {
    if (attendances === undefined) {
      return;
    }

    const hourDifferencesArray = attendances.map((att, index) => {
      // setSelect(att.extra_hour);

      // Check if approve_checkin and approve_checkout are not null
      if (att.approve_checkin && att.approve_checkout) {
        return getHourDifference(
          att.approve_checkin.toString().split(" ")[1],
          att.approve_checkout.toString().split(" ")[1]
        );
      } else {
        // Handle the case where either approve_checkin or approve_checkout is null
        return null; // or return a default value or handle it as needed
      }
    });

    // Filter out null values if needed
    const filteredHourDifferencesArray = hourDifferencesArray.filter(
      (value) => value !== null
    );

    setApprovedTime(filteredHourDifferencesArray);
  }, [attendances]);

  const [contextMenuVisible, setContextMenuVisible] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({
    x: 0,
    y: 0,
  });

  const navigate = useNavigate();

  const handleContextMenu = (event, id) => {
    event.preventDefault();
    setId(id);
    setContextMenuPosition({
      x: event.pageX,
      y: event.pageY,
    });

    setContextMenuVisible(true);
  };

  const handleMenuItemClick = (action) => {
    switch (action) {
      case "attendance":
        navigate("/EmployeeAttendance/" + id);
        break;
      default:
        break;
    }

    // Hide the context menu after performing the action
    setContextMenuVisible(false);
  };

  const hideContextMenu = () => {
    setContextMenuVisible(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSearchParams({
      ...searchParams,
      [name]: value,
    });
  };
  const formatDuration = (durationString) => {
    if (!durationString) {
      return ""; // Handle undefined or null values
    }

    const [hours, minutes] = durationString.split(":").map(Number);

    let formattedDuration = "";

    if (hours > 0) {
      formattedDuration += `${hours}h `;
    }

    if (minutes >= 0) {
      formattedDuration += `${minutes}min`;
    }

    return formattedDuration.trim(); // Remove extra space at the end
  };

  const handleSearch = () => {
    // console.log(searchParams)

    const {
      from,
      to,
      fullname,
      actualCheckIn,
      approveCheckIn,
      approveCheckOut,
      actualCheckOut,
      approveTime,
    } = searchParams;
    // console.log("from : ", from, "to : ".to)
    dispatch(
      getAttendanceAction(
        perPage,
        page,
        from,
        to,
        fullname,
        actualCheckIn,
        approveCheckIn,
        approveCheckOut,
        actualCheckOut,
        approveTime,
        selectedOptions
      )
    );
    setFilterIsOpen(false);
  };
  const handleClearFilter = (name) => {
    setFilterIsOpen(false);
    // setFilterIsOpen(false);

    setSearchParams((prevSearchParams) => {
      // Create a copy of the previous state
      const updatedSearchParams = { ...prevSearchParams, [name]: "" };

      // Destructure the properties from the updated state
      const {
        from,
        to,
        fullname,
        actualCheckIn,
        approveCheckIn,
        approveCheckOut,
        actualCheckOut,
        approveTime,
      } = updatedSearchParams;

      // Dispatch the action with the updated state
      dispatch(
        getAttendanceAction(
          perPage,
          page,
          from,
          to,
          fullname,
          actualCheckIn,
          approveCheckIn,
          approveCheckOut,
          actualCheckOut,
          approveTime
        )
      );

      // Return the updated state
      return updatedSearchParams;
    });
  };


  const thead = [
    {
      id: 1,
      label: "#",
      value: "#",
    },

    {
      id: 2,
      label: "Full Name ",
      value: "fullname",
    },

    {
      id: 3,
      label: "Actual check in",
      value: "actualCheckIn",
    },

    {
      id: 4,
      label: "approve check in",
      value: "approveCheckIn",
    },

    {
      id: 5,
      label: "approve check out",
      value: "approveCheckOut",
    },

    {
      id: 6,
      label: "Actual check out",
      value: "actualCheckOut",
    },

    {
      id: 7,
      label: "approve time",
      value: "approveTime",
    },
    {
      id: 8,
      label: "Daily Wage",
      value: "approveTime",
    },

    {
      id:9,
      label:"Hourly Wage",
      value:"HourlyWage"

    },
    {
      id: 10,
      label: "Extra Wage",
      value: "extrahour",
    },
    {
      id: 11,
      label: "Extra Hour",
      value: "extrahour",
    },
  ];

  const sectionArray = [];

  sections.map((section) => {
    sectionArray.push({
      value: section.id,
      label: section.sName,
    });
  });
  // console.log("sections Array : ",sections)
  const [indexx, setIndexx] = useState(null);
  const [filterIsOpen, setFilterIsOpen] = useState(false);

  const handleSelectChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
  };

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
    // You can perform additional actions based on the selected tab if needed
  };

  const getToday = () => {
    const currentDate = new Date();
    const today = new Date(currentDate);
    today.setDate(currentDate.getDate() - 1);

    const formattedToday = today.toISOString().split("T")[0];
    const formattedDateNow = currentDate.toISOString().split("T")[0];

    setSearchParams({
      ...searchParams,
      from: formattedToday,
      to: formattedDateNow,
    });
  };
  const getLast30DaysDate = () => {
    const currentDate = new Date();
    const lastThirtyDays = new Date(currentDate);
    lastThirtyDays.setDate(currentDate.getDate() - 30);

    const formattedLastThirtyDays = lastThirtyDays.toISOString().split("T")[0];
    const formattedDateNow = currentDate.toISOString().split("T")[0];

    setSearchParams({
      ...searchParams,
      from: formattedLastThirtyDays,
      to: formattedDateNow,
    });
  };

  const getLast7DaysDate = () => {
    const currentDate = new Date();
    const lastSevenDays = new Date(currentDate);
    lastSevenDays.setDate(currentDate.getDate() - 7);

    const formattedLastSevenDays = lastSevenDays.toISOString().split("T")[0];
    const formattedDateNow = currentDate.toISOString().split("T")[0];

    setSearchParams({
      ...searchParams,
      from: formattedLastSevenDays,
      to: formattedDateNow,
    });
  };

  return (
    <>
      {loading || attendances == undefined ? (
        <div className="flex flex-col ">
          <img
            src={Logo}
            alt="Logo"
            className="w-56 m-auto animate-pulse mt-20 "
          />
          <div className="text-2xl text-center font-bold  ">
            <div className="flex justify-center gap-4">
              <p className="animate-charcter">Please Wait</p>
              <div class="container2">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>{" "}
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="flex justify-between  items-center  gap-3  w-[95%] m-auto relative  pb-2  ">
            {/* date Search */}
            <div className="flex justify-between gap-2  items-center ">
              <div className="">
                <input
                  type="date"
                  value={searchParams.from}
                  onChange={handleInputChange}
                  name="from"
                  className="inputUser w-full"
                />
              </div>
              <div>
                <p>To </p>
              </div>
              <div className="">
                <input
                  type="date"
                  value={searchParams.to}
                  onChange={handleInputChange}
                  name="to"
                  className="inputUser w-full"
                />
              </div>
              <div>
                <button onClick={handleSearch} className="btn-plus ">
                  <p className="font-bold  text-xl">
                    <CiFilter />
                  </p>
                </button>
              </div>
            </div>

            {/* section Search */}
            <div className="w-2/12">
              <Select
                value={selectedOptions}
                onChange={handleSelectChange}
                options={sectionArray}
                isMulti
              />
            </div>

            {/* {getLast30DaysDate} */}
          </div>
          <div className="w-[95%] m-auto">
          <div className="">
            <div className="flex ">
              <button
                className={`p-2 ${
                  selectedTab === "today"
                    ? "bg-indigo-600 text-white rounded-none"
                    : "bg-white backdrop-blur-3xl "
                }`}
                onClick={() => {
                  handleTabChange("today");
                  getToday();
                }}
              >
                Today
              </button>
              <button
                className={`p-2 ${
                  selectedTab === "last7days"
                  ? "bg-indigo-600 text-white rounded-none"
                    : "bg-white backdrop-blur-3xl "
                }`}
                onClick={() => {
                  handleTabChange("last7days");
                  getLast7DaysDate();
                }}
              >
                Last 7 Days
              </button>
              <button
                className={`p-2 ${
                  selectedTab === "last30days"
                  ? "bg-indigo-600 text-white rounded-none"
                    : "bg-white backdrop-blur-3xl "
                }`}
                onClick={() => {
                  handleTabChange("last30days");
                  getLast30DaysDate();
                }}
              >
                Last 30 Days
              </button>
            </div>
            {/* {renderContent()}  */}
          </div>
          </div>
          <div className="h-[90vh] bg-white w-[95%] shadow-md rounded-sm m-auto">
            <div className="overflow-auto h-[90%]">
              <table className="w-full   overflow-y-auto ">
                <thead className="sticky  top-0">
                  <tr>
                    {thead.map((thead) => {
                      return (
                        <th
                          className={"global-th text-sm  relative"}
                          key={thead.id}
                        >
                          <div className="flex gap-2 justify-between pl-1 items-center">
                            {thead.label}
                            <p
                              className="globalFilterIcon "
                              onClick={() => {
                                setFilterIsOpen(!filterIsOpen);
                                setIndexx(thead.id);
                              }}
                            >
                              <CiFilter />
                            </p>
                          </div>

                          {thead.id == indexx && filterIsOpen ? (
                            <div className="globalParentInputSearch shadow-2xl ">
                              {/* {searchParams[thead.value]} */}
                              {thead.value == "fullname" ? (
                                <input
                                  type="text"
                                  className="inputUser w-full text-black"
                                  value={searchParams[thead.value]}
                                  onChange={handleInputChange}
                                  name={thead.value}
                                  placeholder={`${thead.label}`}
                                />
                              ) : (
                                <input
                                  type="date"
                                  className="inputUser w-full text-black"
                                  value={searchParams[thead.value]}
                                  onChange={handleInputChange}
                                  name={thead.value}
                                  placeholder={`${thead.label}`}
                                />
                              )}

                              <div className="flex gap-2 mt-2">
                                <button
                                  className="btn-plus px-2"
                                  onClick={handleSearch}
                                >
                                  {" "}
                                  <FaCheck />{" "}
                                </button>

                                <button
                                  className="btn-danger px-2"
                                  onClick={() => {
                                    handleClearFilter(thead.value);
                                  }}
                                >
                                  {" "}
                                  <RxCross2 />{" "}
                                </button>
                              </div>
                            </div>
                          ) : null}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {attendances.map((atten, index) => {
                    return (
                      <tr
                        key={index}
                        className="global-tr"
                        onContextMenu={(e) => {
                          handleContextMenu(e, atten.users.id);
                        }}
                        onMouseDown={hideContextMenu}
                      >
                        <td className="global-td px-4">{index + 1}</td>
                        <td className="global-td">
                          {atten.users.user_cvs.map((cv, index) => {
                            return (
                              <div key={index}>
                                {cv.profile == "true" ? (
                                  <img
                                    src={cv.cv}
                                    className="h-14 w-14 object-cover m-auto bg-top rounded-full"
                                    alt=""
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            );
                          })}
                          <p>{atten.users.fullname_en}</p>
                        </td>
                        <td className="global-td">
                          <p className="text-lg tracking-wider font-black ">
                            {atten.checkin
                              ? atten.checkin.toString().split(" ")[1]
                              : ""}
                          </p>
                          <p className="text-sm">
                            {atten.checkin
                              ? atten.checkin.toString().split(" ")[0]
                              : ""}
                          </p>
                        </td>
                        <td
                          onClick={() => {
                            setTime(atten.checkin);
                            setApproveCheckIn(atten.approve_checkin);
                            setCircule(true);
                            setId(atten.id);
                            setCheck("in");
                          }}
                          className={
                            atten.approve_checkin
                              ? " text-center font-bold bg-blue-600 bg-opacity-70 px-0 cursor-pointer text-sm  text-gray-100"
                              : "global-td bg-gray-100  px-0 cursor-pointer text-sm font-bold"
                          }
                        >
                          Click to Approve Check In
                          <p>
                            {atten.approve_checkin
                              ? atten.approve_checkin.toString().split(" ")[1]
                              : "-"}
                          </p>
                        </td>
                        <td
                          className={
                            atten.approve_checkout
                              ? " text-center font-bold border-2 bg-blue-600 bg-opacity-70 px-0 cursor-pointer text-sm  text-gray-100"
                              : "global-td bg-gray-100 px-0 cursor-pointer text-sm font-bold"
                          }
                          onClick={() => {
                            setTime(atten.checkout);
                            setApproveCheckIn(atten.approve_checkout);
                            setCircule(true);
                            setId(atten.id);
                            setCheck("out");
                          }}
                        >
                          Click to Approve Check Out
                          <p>
                            {atten.approve_checkout
                              ? atten.approve_checkout.toString().split(" ")[1]
                              : "-"}
                          </p>
                        </td>

                        <td className="global-td font-bold">
                          <p className="text-lg  ">
                            {atten.checkout
                              ? atten.checkout.toString().split(" ")[1]
                              : ""}
                          </p>
                          <p className="text-sm">
                            {atten.checkout
                              ? atten.checkout.toString().split(" ")[0]
                              : ""}
                          </p>
                        </td>

                        <td className=" global-td   ">
                          {formatDuration(approvedTime[index])}
                        </td>

                        <td className="font-extrabold  global-td">
                          {" "}
                          {(approvedTime[index] &&
                            parseFloat(
                              approvedTime[index]
                                .split(":")
                                .reduce(
                                  (acc, val, i) =>
                                    acc + val / (i === 0 ? 1 : 60),
                                  0
                                )
                            ) * atten.users.hourly_wage) ||
                            0}
                        </td>
                        <td className="font-extrabold  global-td">
                        {atten.users.hourly_wage}
                        </td>

                        <td className="font-extrabold  global-td">
                        {atten.users.extra_wage}
                        </td>
                        <td
                          onClick={() => {
                            setIsOpenExtraHour(true);
                            setId(atten.id);
                            setExtraHour(atten.extra_hour);
                          }}
                          className="global-td font-bold cursor-pointer hover:bg-gray-200 duration-500 bg-gray-50 -z-10"
                        >
                          Extra Hour
                          <div>
                            <p>{atten.extra_hour ? atten.extra_hour : "-"}</p>
                          </div>
                        </td>


                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="gap-3 px-3 flex justify-between items-center  pt-2  ">
              <div className="flex items-center gap-4 ">
                <p className="text-xl ">Total: </p>
                <div
                  className={
                    "bg-teal-600 px-4 py-0.5 text-white rounded-md shadow-md "
                  }
                >
                  {total}
                </div>
              </div>
              <div className="flex items-center gap-4">
                <div>
                  <select
                    name=""
                    className="mt-1  bg-white border w-20 focus:ring-2  focus:ring-slate-900 rounded-md   focus:outline-none"
                    onChange={(e) => setPerPage(e.target.value)}
                    value={perPage}
                    id=""
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>

                <div className="flex justify-center mt-1">
                  <ReactPaginate
                    forcePage={page}
                    previousLabel="<"
                    nextLabel=">"
                    breakLabel="..."
                    className="flex items-center -space-x-px h-8 text-sm"
                    previousClassName="flex items-center justify-center px-3 h-8 ml-0 border border-gray-300 rounded-l-lg"
                    nextClassName="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 border border-gray-300 rounded-r-lg"
                    pageLinkClassName="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 border border-gray-300"
                    breakClassName="w-8 py-2 text-center"
                    pageCount={Math.ceil(total / perPage)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={(event) => {
                      const currentPage = event.selected + 1;
                      // console.log(event.selected);
                      dispatch(getAttendanceAction(perPage, currentPage));
                      setPage(event.selected); // Use the selected page directly
                    }}
                    activeLinkClassName="bg-black text-white flex items-center -space-x-px h-8 text-sm"
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <div>
        {contextMenuVisible && (
          <ul
            style={{
              position: "absolute",
              left: contextMenuPosition.x,
              top: contextMenuPosition.y,
              backgroundColor: "#fff",
              border: "1px solid #ccc",
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
              zIndex: 1,
              listStyleType: "none",
              // padding: "8px",
              cursor: "pointer",
            }}
            className=""
          >
            <li
              className="hover:bg-[#6e60ff] py-1  text-lg duration-500 hover:text-[#eeeeee] px-2"
              onClick={() => handleMenuItemClick("attendance")}
            >
              Employment Attendance
            </li>
          </ul>
        )}
      </div>
      {circule && (
        <AttendanceApprovedModal
          isOpen={circule}
          onClose={setCircule}
          selectedTime={time}
          id={id}
          approveCheckIn={approveCheckIn}
          check={check}
        />
      )}
      {/* {isOpenExtraHour} */}
      {isOpenExtraHour && (
        <ExtraHourModal
          closeModal={()=>setIsOpenExtraHour(false)}
          id={id}
          extraHour={extraHour}
        />
      )}
      <div className="pb-20"></div>
    </>
  );
};

export default EmployeeAttendance;
