import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTypeMedicalImageAction } from "../../Action/TypeMedicalAction/typeMedicalAction";

import Logo from "../../Image/Logo.jpg";
const TypeMedicalModal = ({ patientId, typeMedcaliId, closeModal }) => {
  console.log(patientId)
  const dispatch = useDispatch();
  const getImageTypeMedical = useSelector((state) => state.getImageTypeMedical);
  const { data, loading } = getImageTypeMedical;
  useEffect(() => {
    dispatch(getTypeMedicalImageAction(typeMedcaliId, patientId));
  }, [patientId, typeMedcaliId]);
  return (
    <>
      {loading ? (
        <div className="flex flex-col absolute top-28 left-[42%]  ">
          <img
            src={Logo}
            alt="Logo"
            className="w-56 m-auto animate-pulse  z-50"
          />
          <div className="text-2xl text-center font-bold  ">
            <div className="flex justify-center gap-4">
              <p className="animate-charcter">Please Wait</p>
              <div class="container2">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>{" "}
            </div>
          </div>
        </div>
      ) : (
        <div className="parentModal">
          <div className="overlay" onClick={() => closeModal(false)}></div>
          <div className="childModal">
            <div className="flex justify-between items-center">
              <div
                className="text-red-500 cursor-pointer"
                onClick={() => closeModal(false)}
              >
                <p>X</p>
              </div>
              <div className="">
                <p>Patient </p>
              </div>
            </div>
            <div>
              {data.map((data, index) => {
                return (
                  <div
                    className="flex justify-center items-center flex-row gap-10  flex-wrap"
                    key={index}
                  >
                    {data.patient_cv.map((patientCv, medicalIndex) => {
                      return (
                        <a
                          target="_blank"
                          href={patientCv.image}
                          className="flex flex-row flex-wrap"
                          key={medicalIndex}
                        >
                          <img
                            className="w-96 object-cover border-2 rounded-md shadow-xl shadow-gray-400"
                            src={patientCv.image}
                            alt=""
                          />
                        </a>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TypeMedicalModal;
