import React, { useEffect, useState } from "react";
import {
  rejectPatientVisitAction,
  showRejectPatientVisitAction,
} from "../../Action/rejectPatientAction";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../../Image/Logo.jpg";
import { AiFillCaretDown } from "react-icons/ai";
const ViewRejectModal = ({ data_id, closeModal }) => {
  const dispatch = useDispatch();
  const rejectPatientVisit = useSelector((state) => state.rejectPatientVisit);
  const { rejects, loading } = rejectPatientVisit;
  const [visitId, setVisitId] = useState(null);
  const showRejectPatientVisit = useSelector(
    (state) => state.showRejectPatientVisit
  );
  const { datas } = showRejectPatientVisit;
  useEffect(() => {
    if (data_id == null) {
      return;
    }
    dispatch(rejectPatientVisitAction(data_id));
    console.log(datas);
  }, [data_id]);

  useEffect(() => {
    if (visitId == null) {
      return;
    }
    dispatch(showRejectPatientVisitAction(visitId));
  }, [visitId]);

  return (
    <>
      {loading ? (
        <div className="flex flex-col">
          <img
            src={Logo}
            alt="Logo"
            className="w-56 m-auto animate-pulse mt-20 "
          />
          <div className="text-2xl text-center font-bold  ">
            <div className="flex justify-center gap-4">
              <p className="animate-charcter">Please Wait</p>
              <div class="container2">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>{" "}
            </div>
          </div>
        </div>
      ) : (
        <div
          className={
            closeModal ? "modalContainerAssign w-full  " : "closeModalContainer"
          }
        >
          <div
            className="overlay"
            onClick={() => {
              closeModal(false);
              setVisitId(null);
            }}
          ></div>
          <div className="childModal ">
            <div className="flex justify-between ">
              <div className="flex items-center gap-3 flex-row-reverse">
                <select
                  name=""
                  onChange={(e) => setVisitId(e.target.value)}
                  className="select"
                  id=""
                >
                  <option value="-1">Select Visitation</option>
                  {rejects.map((reject, index) => {
                    return (
                      <option key={index} value={reject.id}>
                        {reject.created_at.toString().slice(0, 10)}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div
                className="text-3xl cursor-pointer text-red-600"
                onClick={() => {
                  closeModal(false);
                  setVisitId(null);
                }}
              >
                x
              </div>
            </div>
            <>
              {datas == undefined || visitId == null ? (
                ""
              ) : (
                <form action="">
                  {datas.map((data, index) => {
                    return (
                      <div key={index}>
                        <div className="parentInputGrid">
                          {/* end parent Input & label */}

                          <div className="w-full mt-2">
                            <div className="w-full flex justify-end">
                              <label
                                className="lblDesign"
                                htmlFor="phoneNumber"
                              >
                                {" "}
                                *رەقەم مۆبایل
                              </label>
                            </div>
                            <input
                              type="text"
                              name="closetHome"
                              readOnly
                              defaultValue={data.patient.phone}
                              id="phoneNumber"
                              className="inputLogin w-full text-right"
                              placeholder="رەقەم مۆبایلرەقەم مۆبایل"
                            />
                          </div>
                          {/* end parent Input & label */}
                          <div className="w-full mt-2">
                            <div className="w-full  ">
                              <div className="relative w-full ">
                                <div className="child ">
                                  <div className="w-full flex justify-end">
                                    <label
                                      className="lblDesign"
                                      htmlFor="gender"
                                    >
                                      *ڕەگەز{" "}
                                    </label>
                                  </div>
                                  <div className="coustmSelect ">
                                    <p>
                                      {data.patient.gender == "" ||
                                      data.patient.gender == null
                                        ? ".. ڕەگەز "
                                        : data.patient.gender}
                                    </p>
                                    <div className={"parentCaretDown"}>
                                      <AiFillCaretDown />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* end parent Input & label */}

                          <div className="w-full mt-2">
                            <div className="w-full flex justify-end">
                              <label className="lblDesign" htmlFor="birth">
                                * بەرواری لە دایکبوون
                              </label>
                            </div>
                            <input
                              type="text"
                              name="card"
                              readOnly
                              value={data.patient.birthDate}
                              id="birth"
                              className="inputLogin w-full text-right"
                              placeholder="بەرواری لە دایکبوون"
                            />
                          </div>
                          {/* end of label and inpt */}

                          <div className="w-full mt-2">
                            <div className="w-full flex justify-end">
                              <label className="lblDesign" htmlFor="name">
                                *ناوی نەخۆش
                              </label>
                            </div>
                            <input
                              type="text"
                              name="name"
                              readOnly
                              value={data.patient.Pname}
                              id="number"
                              className="inputLogin w-full text-right"
                              placeholder="ناوی نەخۆش"
                            />
                          </div>
                          {/* end parent Input & label */}
                        </div>

                        {/* maping commite */}
                        {data.patient.commites.map((infoCommite, index) => {
                          return (
                            <>
                              <div className="parentInputGrid" key={index}>
                                <div className="w-full mt-2">
                                  <div className="flex justify-end ">
                                    <label
                                      className="lblDesign"
                                      htmlFor="province"
                                    >
                                      * پارێزگا{" "}
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    name="province"
                                    readOnly
                                    id="province"
                                    value={infoCommite.province}
                                    className="inputLogin w-full text-right"
                                    placeholder="پارێزگا"
                                  />
                                </div>
                                {/* end parent Input & label */}

                                <div className="w-full mt-2">
                                  <div className="w-full flex justify-end ">
                                    <label
                                      className="lblDesign"
                                      htmlFor="nation"
                                    >
                                      {" "}
                                      *نەتەوە
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    name="nation"
                                    readOnly
                                    defaultValue={infoCommite.nation}
                                    id="nation"
                                    className="inputLogin w-full text-right"
                                    placeholder="نەتەوە"
                                  />
                                </div>
                                {/* end parent Input & label */}

                                <div className="w-full mt-2">
                                  <div className="w-full flex justify-end ">
                                    <label
                                      className="lblDesign"
                                      htmlFor="country"
                                    >
                                      {" "}
                                      * وڵات{" "}
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    name="country"
                                    readOnly
                                    value={infoCommite.country}
                                    id="country"
                                    className="inputLogin w-full text-right"
                                    placeholder="وڵات"
                                  />
                                </div>
                                {/* end parent Input & label */}

                                <div className="w-full mt-2">
                                  <div className="w-full flex justify-end ">
                                    <label className="lblDesign" htmlFor="occ">
                                      {" "}
                                      *پیشە
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    name="occupation"
                                    readOnly
                                    value={infoCommite.occuption}
                                    id="occ"
                                    className="inputLogin w-full  text-right"
                                    placeholder="پیشە"
                                  />
                                </div>
                                {/* end parent Input & label */}
                              </div>
                              <div className="parentInputGrid">
                                {/* end parent Input & label */}

                                <div></div>
                                <div className="w-full mt-2">
                                  <div className="flex justify-end ">
                                    <label className="lblDesign" htmlFor="disc">
                                      * ناحیە/قەزا{" "}
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    name="district"
                                    readOnly
                                    id="disc"
                                    value={infoCommite.district}
                                    className="inputLogin w-full  text-right"
                                    placeholder="ناحیە/قەزا"
                                  />
                                </div>
                                {/* end parent Input & label */}
                                <div className="w-full mt-2">
                                  <div className="flex justify-end ">
                                    <label className="lblDesign" htmlFor="str">
                                      *شەقام گەڕەک{" "}
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    name="street"
                                    id="str"
                                    readOnly
                                    value={infoCommite.street}
                                    className="inputLogin w-full text-right"
                                    placeholder="شەقام گەڕەک"
                                  />
                                </div>
                                {/* end parent Input & label */}

                                <div className="w-full mt-2">
                                  <div className="flex justify-end ">
                                    <label
                                      className="lblDesign"
                                      htmlFor="closetHome"
                                    >
                                      *نزیکترین شوێن لە ماڵیانەوە
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    name="closetHome"
                                    readOnly
                                    defaultValue={infoCommite.closetHome}
                                    id="closetHome"
                                    className="inputLogin w-full text-right"
                                    placeholder="نزیکترین شوێن لە ماڵیانەوە"
                                  />
                                </div>

                                <div className="w-full mt-2">
                                  <div className="w-full justify-end flex">
                                    <label
                                      className="lblDesign"
                                      htmlFor="scoundPhoneNo"
                                    >
                                      *ژ.مۆبایلی دووەم
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    name="scoundPhoneNo"
                                    readOnly
                                    value={infoCommite.secoundHome}
                                    id="scoundPhoneNo"
                                    className="inputLogin w-full  text-right"
                                    placeholder="ژ.مۆبایلی دووەم"
                                  />
                                </div>
                                {/* end parent Input & label */}

                                <div className="w-full mt-2">
                                  <div className="flex justify-end ">
                                    <label className="lblDesign" htmlFor="card">
                                      ژ.کارتی نیشتمانی یاخود ناسنامەی
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    name="card"
                                    id="card"
                                    readOnly
                                    value={infoCommite.card}
                                    className="inputLogin w-full text-right"
                                    placeholder="ژ.کارتی نیشتمانی یاخود ناسنامەی "
                                  />
                                </div>

                                <div className="w-full mt-2">
                                  <div className="flex justify-end ">
                                    <label
                                      className="lblDesign"
                                      htmlFor="noFamily"
                                    >
                                      *ژمارەی خێزان
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    name="NoFamily"
                                    readOnly
                                    value={infoCommite.noFamily}
                                    id="number"
                                    className="inputLogin w-full text-right"
                                    placeholder="ژمارەی خێزان"
                                  />
                                </div>
                                {/* end label and input */}
                                <div className="w-full mt-2">
                                  <div className="relative w-full ">
                                    <div className="child ">
                                      <div className="flex justify-end ">
                                        <label
                                          className="lblDesign"
                                          htmlFor="gender"
                                        >
                                          {" "}
                                          * خێزاندارە{" "}
                                        </label>
                                      </div>
                                      <div className="coustmSelect ">
                                        <p>{infoCommite.married}</p>
                                        <div className={"parentCaretDown"}>
                                          <AiFillCaretDown />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div></div>
                                <div className="w-full mt-2 ">
                                  <div className="relative w-full ">
                                    <div className="child ">
                                      <div className="w-full flex justify-end ">
                                        <label
                                          className="lblDesign"
                                          htmlFor="name"
                                        >
                                          {" "}
                                          * خوێندەوارە
                                        </label>
                                      </div>
                                      <div className="coustmSelect">
                                        <p>{infoCommite.literate}</p>
                                        <div className={"parentCaretDown"}>
                                          <AiFillCaretDown />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* end parent Input & label */}
                                <div className="w-full mt-2 ">
                                  <div className="relative w-full ">
                                    <div className="child ">
                                      <div className="w-full flex justify-end ">
                                        <label
                                          className="lblDesign"
                                          htmlFor="name"
                                        >
                                          {" "}
                                          *جگەرە کێشە
                                        </label>
                                      </div>
                                      <div className="coustmSelect">
                                        <p>{infoCommite.smoke}</p>
                                        <div className={"parentCaretDown"}>
                                          <AiFillCaretDown />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* end parent Input & label */}
                                <div className="w-full mt-2">
                                  <div className="w-full justify-end flex">
                                    <label
                                      className="lblDesign"
                                      htmlFor="timeAllot"
                                    >
                                      * کاتی دیاریکراو بۆ هەڵسەنگاندن{" "}
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    name="timeAllot"
                                    id="timeAllot"
                                    readOnly
                                    value={infoCommite.timeAllote}
                                    className="inputLogin w-full text-right"
                                    placeholder="کاتی دیاریکراو بۆ هەڵسەنگاندن"
                                  />
                                </div>
                              </div>
                              <div className="text-center bg-teal-500 mt-4  py-2 rounded-md shadow-md text-white font-bold w-10/12 md:w-2/12 m-auto">
                                <p className="lg:text-lg">زانیاری پزیشکی</p>
                              </div>
                              {/* end grid col */}
                              <div className="parentInputGrid">
                                <div className="w-full mt-2">
                                  <div className="flex justify-end ">
                                    <label
                                      className="lblDesign"
                                      htmlFor="noMember"
                                    >
                                      * لەسەر ئاستی ئەندام
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    name="noMember"
                                    readOnly
                                    id="noMember"
                                    value={infoCommite.memberLevel}
                                    className="inputLogin w-full text-right"
                                    placeholder="لەسەر ئاستی ئەندام"
                                  />
                                </div>
                                {/* end parent Input & label */}

                                <div className="w-full mt-2">
                                  <div className="flex justify-end ">
                                    <label
                                      className="lblDesign"
                                      htmlFor="member"
                                    >
                                      * ئەندام
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    name="member"
                                    readOnly
                                    id="member"
                                    value={infoCommite.member}
                                    className="inputLogin w-full text-right"
                                    placeholder="ئەندام"
                                  />
                                </div>

                                {/* end parent Input & label */}

                                <div className="w-full mt-4">
                                  <label className="lblDesign" htmlFor="name">
                                    TYP *
                                  </label>
                                  <input
                                    type="text"
                                    name="typ"
                                    id="name"
                                    readOnly
                                    value={infoCommite.TYP}
                                    className="inputLogin w-full  text-right"
                                    placeholder="TYP"
                                  />
                                </div>
                                {/* end parent Input & label */}
                                <div className="w-full mt-2">
                                  <div className="flex justify-end ">
                                    <label className="lblDesign" htmlFor="spid">
                                      * پسپۆڕی جۆری نەخۆشی{" "}
                                    </label>
                                  </div>

                                  <input
                                    type="text"
                                    name="SPID"
                                    readOnly
                                    id="spid"
                                    value={infoCommite.SITD}
                                    className="inputLogin w-full text-right"
                                    placeholder="پسپۆڕی جۆری نەخۆشی"
                                  />
                                </div>
                                {/* end parent Input & label */}
                              </div>

                              <div className="parentInputGrid">
                                <div className="w-full mt-2">
                                  <div className="flex justify-end ">
                                    <label className="lblDesign" htmlFor="ea">
                                      * نەخۆشی پێش وەخت
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    readOnly
                                    name="earlyPatient"
                                    value={infoCommite.earlyPatient}
                                    id="ea"
                                    className="inputLogin w-full  text-right"
                                    placeholder="نەخۆشی پێش وەخت"
                                  />
                                </div>
                                {/* end parent Input & label */}

                                <div className="w-full mt-2">
                                  <label
                                    className="lblDesign"
                                    htmlFor="indication"
                                  >
                                    Indiction *
                                  </label>
                                  <input
                                    type="text"
                                    readOnly
                                    name="indiction"
                                    value={infoCommite.indiction}
                                    id="indication"
                                    className="inputLogin w-full  text-right"
                                    placeholder="Indiction"
                                  />
                                </div>

                                {/* end parent Input & label */}
                                <div className="w-full mt-2">
                                  <label className="lblDesign" htmlFor="hpe">
                                    H.P.E *
                                  </label>
                                  <input
                                    type="text"
                                    name="HPE"
                                    readOnly
                                    value={infoCommite.HEP}
                                    id="hpe"
                                    className="inputLogin w-full text-right"
                                    placeholder="H.P.E "
                                  />
                                </div>

                                {/* end parent Input & label */}

                                <div className="w-full mt-2">
                                  <label className="lblDesign" htmlFor="final">
                                    Final Diagnosis *
                                  </label>
                                  <input
                                    type="text"
                                    id="final"
                                    readOnly
                                    name="finalDiagnos"
                                    value={infoCommite.finalDiagnosis}
                                    className="inputLogin w-full text-right"
                                    placeholder="Final Diagnosis"
                                  />
                                </div>
                                {/* end parent Input & label */}
                              </div>

                              <div className="parentInputGrid">
                                <div className="w-full mt-2">
                                  <div className="flex justify-end ">
                                    <label className="lblDesign" htmlFor="dr">
                                      {" "}
                                      * پزیشکی نێردراو بۆ
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    readOnly
                                    name="doctor"
                                    id="dr"
                                    value={infoCommite.Doctor}
                                    className="inputLogin w-full text-right"
                                    placeholder="پزیشکی نێردراو بۆ"
                                  />
                                </div>

                                {/* end parent Input & label */}

                                <div className="w-full mt-2">
                                  <div className="flex justify-end ">
                                    <label
                                      className="lblDesign"
                                      htmlFor="ZODoctor"
                                    >
                                      {" "}
                                      *پزیشکی هاوکاری ڕیکخراوی زادە
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    readOnly
                                    name="ZODoctor"
                                    value={infoCommite.ZODoctor}
                                    className="inputLogin w-full text-right"
                                    placeholder="پزیشکی هاوکاری ڕیکخراوی زادە"
                                  />
                                </div>
                                {/* end parent Input & label */}
                                <div className="w-full mt-2">
                                  <div className="flex justify-end ">
                                    <label
                                      className="lblDesign"
                                      htmlFor="medicalProvid"
                                    >
                                      * پێداویستی پزیشکی بۆ دابینکراوە{" "}
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    readOnly
                                    name="medicalProvid"
                                    id="medicalProvid"
                                    value={infoCommite.medicalProvided}
                                    className="inputLogin w-full  text-right"
                                    placeholder="پێداویستی پزیشکی بۆ دابینکراوە"
                                  />
                                </div>
                                {/* end parent Input & label */}

                                <div className="w-full mt-2">
                                  <div className="flex justify-end ">
                                    <label
                                      className="lblDesign"
                                      htmlFor="earlySurgery"
                                    >
                                      * نەشتەرگەری پێشوەخت{" "}
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    readOnly
                                    name="earlySurgery"
                                    value={infoCommite.earlySurgery}
                                    id="earlySurgery"
                                    className="inputLogin w-full  text-right"
                                    placeholder="نەشتەرگەری پێشوەخت "
                                  />
                                </div>
                                {/* end parent Input & label */}
                              </div>
                              <div className="parentInputGrid">
                                <div></div>
                                <div className="w-full mt-2">
                                  <div className="flex justify-end w-full ">
                                    <label
                                      className="lblDesign"
                                      htmlFor="typeMedical"
                                    >
                                      * جۆری پێداویستی پزیشکی{" "}
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    readOnly
                                    name="typeMedical"
                                    id="typeMedical"
                                    value={infoCommite.Typemedicalsupplies}
                                    className="inputLogin w-full  text-right"
                                    placeholder="جۆری پێداویستی پزیشکی"
                                  />
                                </div>
                                {/* end parent Input & label */}
                                <div className="w-full mt-2">
                                  <div className="flex justify-end ">
                                    <label
                                      className="lblDesign"
                                      htmlFor="supplyLocation"
                                    >
                                      شوێنی دابینکردنی پێداویستی
                                    </label>
                                  </div>
                                  <input
                                    type="text"
                                    readOnly
                                    name="supplyLocation"
                                    value={infoCommite.supplyLocation}
                                    className="inputLogin w-full text-right"
                                    placeholder="شوێنی دابینکردنی پێداویستی"
                                  />
                                </div>

                                {/* end parent Input & label */}

                                <div className="w-full mt-2">
                                  <div className="flex justify-end ">
                                    <label
                                      className="lblDesign"
                                      htmlFor="helpingZO"
                                    >
                                      هاوکاری رێکخراوی زادە
                                    </label>
                                  </div>

                                  <input
                                    type="text"
                                    readOnly
                                    name="helpingZO"
                                    id="helpingZO"
                                    value={infoCommite.helpingZO}
                                    className="inputLogin w-full text-right"
                                    placeholder="هاوکاری رێکخراوی زادە"
                                  />
                                </div>
                              </div>
                              <div className="w-full mt-2 text-right flex gap-4">
                                <div className="flex flex-col w-full">
                                  <div>
                                    <label htmlFor="">
                                      * تێبینی زانیاری پزیشکی{" "}
                                    </label>
                                  </div>
                                  <textarea
                                    name=""
                                    value={infoCommite.note}
                                    id=""
                                    className="inputLogin w-full text-right"
                                    rows="6"
                                  ></textarea>
                                </div>
                                <div className="flex flex-col w-full">
                                  <div>
                                    <label htmlFor="">
                                      * تێبینی وەرنەگرتن{" "}
                                    </label>
                                  </div>
                                  <textarea
                                    name=""
                                    value={data.note}
                                    id=""
                                    className="inputLogin w-full text-right"
                                    rows="6"
                                  ></textarea>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    );
                  })}
                </form>
              )}
            </>
          </div>
          <div className="pb-[79rem]"></div>
        </div>
      )}
    </>
  );
};

export default ViewRejectModal;
