import React, { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";
import Logo from "../../Image/Logo.jpg";
import { PiDotsThreeOutlineBold } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  getAllUserAction,
  resetPasswordUserAction,
} from "../../Action/userRegister";
import "../../Pages/Global.css";
import { IoFilter, IoFingerPrintSharp } from "react-icons/io5";
import ReactPaginate from "react-paginate";
import { MdOutlineLockReset } from "react-icons/md";
import { RxCross2, RxUpdate } from "react-icons/rx";
import Swal from "sweetalert2";
import { createAttendanceAction } from "../../Action/AttendanceAction/AttendanceAction";
import { CiFilter } from "react-icons/ci";
const UserProfile = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const [isOpenColumns, setIsopenColumns] = useState(false);
  const toggleColumnVisibility = (columnName) => {
    if (hiddenColumns.includes(columnName)) {
      setHiddenColumns(hiddenColumns.filter((col) => col !== columnName));
    } else {
      setHiddenColumns([...hiddenColumns, columnName]);
    }
  };
  const [selectAll, setSelectAll] = useState(false);
  const handleSelectAll = () => {
    if (selectAll) {
      setHiddenColumns([]);
    } else {
      setHiddenColumns(columns.map((column) => column.name));
    }
    setSelectAll(!selectAll);
  };
  const getAllUser = useSelector((state) => state.getAllUser);
  const { user, total, loading } = getAllUser;
  const listData = [];
  const columns = [
    { name: "fullname_en", label: "Full Name En" },
    { name: "fullname_ku", label: "Full Name Ku" },
    { name: "email", label: "Email" },
    // { name: "password", label: "Password" },
    { name: "birth_date", label: "Birth Date" },
    { name: "gender", label: "Gender" },
    { name: "phone", label: "Phone" },
    { name: "secound_phone", label: "Secound Phone" },
    { name: "close_phone", label: "Close Phone" },
    { name: "married", label: "Married" },
    { name: "number_of_children", label: "Number Of Children" },
    { name: "blood_group", label: "Blood Group" },
    { name: "date_of_commencement", label: "Date Of Commencement" },
    { name: "chronic_diseases", label: "Chronic Diseases" },
    { name: "certificate", label: "Certificate" },
    { name: "certificate_field", label: "Certificate Field" },
    { name: "occupationoutside", label: "Occupation outside" },
    { name: "work_place", label: "Work Place" },
    { name: "job_tiltle", label: "Job Tiltle" },
    { name: "en_lang", label: "English language" },
    { name: "ku_lang", label: "Kurdish language" },
    { name: "ar_lang", label: "Arabic language" },
    { name: "computer_skills", label: "Computer  Skills" },
    { name: "user_cv", label: "CV" },

    { name: "branch_id", label: "Branch Name" },
    { name: "section_id", label: "Section Name" },
    { name: "role_id", label: "Role Name" },
  ];

  if (user) {
    user.map((item) => {
      listData.push({
        id: item.id,
        fullname_en: item.fullname_en,
        fullname_ku: item.fullname_ku,
        email: item.email,
        // password:item.password,
        birth_date: item.birth_date,
        gender: item.gender,
        phone: item.phone,
        secound_phone: item.secound_phone,
        close_phone: item.close_phone,
        married: item.married,
        number_of_children: item.number_of_children,
        blood_group: item.blood_group,
        date_of_commencement: item.date_of_commencement,
        chronic_diseases: item.chronic_diseases,
        certificate: item.certificate,
        certificate_field: item.certificate_field,
        occupationoutside: item.occupationoutside,
        work_place: item.work_place,
        job_tiltle: item.job_tiltle,
        en_lang: item.en_lang,
        ku_lang: item.ku_lang,
        ar_lang: item.ar_lang,
        computer_skills: item.computer_skills,
        user_cv: item.user_cv,
        branch_id: item.branch.bName,
        section_id: item.section.sName,
        role_id: item.role.name,
      });
      // console.log("list : ", item.id)
    });
  }

  const [indexx, setIndexx] = useState(null);
  const [searchParams, setSearchParams] = useState({
    fullname_en: null,
    fullname_ku: null,
    email: null,
    // password:null,
    birth_date: null,
    gender: null,
    phone: null,
    secound_phone: null,
    close_phone: null,
    married: null,
    number_of_children: null,
    blood_group: null,
    date_of_commencement: null,
    chronic_diseases: null,
    certificate: null,
    certificate_field: null,
    occupationoutside: null,
    work_place: null,
    job_tiltle: null,
    language_level: null,
    computer_skills: null,
    user_cv: null,
    branch_id: null,
    section_id: null,
    role_id: null,
    ku_lang: null,
    ar_lang: null,
  });
  const handleInputChange = (event) => {
    const { value, name } = event.target;
    setSearchParams({
      ...searchParams,
      [name]: value,
    });
  };
  const [hiddenColumns, setHiddenColumns] = useState([
    "user_cv",
    "secound_phone",
    "close_phone",
    "married",
    "number_of_children",
    "blood_group",
    "date_of_commencement",
    "chronic_diseases",
    "certificate",
    "certificate_field",
    "occupationoutside",
    "work_place",
    "job_tiltle",

    "computer_skills",
    "user_cv",
    "en_lang",
    "ku_lang",
    "ar_lang",
    "branch_id",
    "section_id",
    "role_id",
  ]);
  const dispatch = useDispatch();
  useEffect(() => {
    // console.log(searchParams);
    dispatch(
      getAllUserAction(
        currentPage,
        perPage,
        searchParams.fullname_ku,
        searchParams.fullname_en,
        searchParams.email,
        searchParams.birth_date,
        searchParams.gender,
        searchParams.phone,
        searchParams.secound_phone,
        searchParams.close_phone,
        searchParams.married,
        searchParams.number_of_children,
        searchParams.blood_group,
        searchParams.date_of_commencement,
        searchParams.chronic_diseases,
        searchParams.certificate,
        searchParams.certificate_field,
        searchParams.occupationoutside,
        searchParams.work_place,
        searchParams.job_tiltle,
        searchParams.language_level,
        searchParams.computer_skills,
        searchParams.ar_lang,
        searchParams.ku_lang
      )
    );
  }, [perPage]);

  const handleSearch = () => {
    setIsOpenFilter(false);
    setIndexx(null);
    dispatch(
      getAllUserAction(
        currentPage,
        perPage,
        searchParams.fullname_ku,
        searchParams.fullname_en,
        searchParams.email,
        searchParams.birth_date,
        searchParams.gender,
        searchParams.phone,
        searchParams.secound_phone,
        searchParams.close_phone,
        searchParams.married,
        searchParams.number_of_children,
        searchParams.blood_group,
        searchParams.date_of_commencement,
        searchParams.chronic_diseases,
        searchParams.certificate,
        searchParams.certificate_field,
        searchParams.occupationoutside,
        searchParams.work_place,
        searchParams.job_tiltle,
        searchParams.language_level,
        searchParams.computer_skills,
        searchParams.ar_lang,
        searchParams.ku_lang
      )
    );
  };

  const handleClearSearch = (key) => {
    searchParams[key] = "";

    setIsOpenFilter(false);
    setIndexx(null);
    dispatch(
      getAllUserAction(
        currentPage,
        perPage,
        searchParams.fullname_ku,
        searchParams.fullname_en,
        searchParams.email,
        searchParams.birth_date,
        searchParams.gender,
        searchParams.phone,
        searchParams.secound_phone,
        searchParams.close_phone,
        searchParams.married,
        searchParams.number_of_children,
        searchParams.blood_group,
        searchParams.date_of_commencement,
        searchParams.chronic_diseases,
        searchParams.certificate,
        searchParams.certificate_field,
        searchParams.occupationoutside,
        searchParams.work_place,
        searchParams.job_tiltle,
        searchParams.language_level,
        searchParams.computer_skills,
        searchParams.ar_lang,
        searchParams.ku_lang
      )
    );
  };

  const handleResetPassword = (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btnGreen bg-teal-600 m-2 ",
        cancelButton: "btnRed bg-red-600 ",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Reset Password!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          dispatch(resetPasswordUserAction(id));
          swalWithBootstrapButtons.fire({
            title: "Reset Password!",
            text: "Your Information Has Reset Password.",
            icon: "success",
            timer: 2000,
          });
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire({
            title: "Cancelled",
            text: "Your imaginary file is safe :)",
            icon: "error",
          });
        }
      });
  };
  return (
    <>
      {loading || user == undefined ? (
        <div className="flex flex-col">
          <img
            src={Logo}
            alt="Logo"
            className="w-56 m-auto animate-pulse mt-20 "
          />
          <div className="text-2xl text-center font-bold  ">
            <div className="flex justify-center gap-4">
              <p className="animate-charcter">Please Wait</p>
              <div class="container2">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>{" "}
            </div>
          </div>
        </div>
      ) : (
        <div className="parent ">
          <div className="parent-head px-2 my-2">
            {" "}
            <div>
              <button
                onClick={() => navigate("/AddUser")}
                className="btn-primary"
              >
                User Add
              </button>
            </div>
            <div className="flex gap-2 items-center ">
              <div>
                <div className="flex space-x-4">
                  <div
                    title="Show & hide Columns"
                    className="btn-plus "
                    onClick={() => setIsopenColumns(!isOpenColumns)}
                  >
                    <PiDotsThreeOutlineBold
                      onClick={() => setIsopenColumns(!isOpenColumns)}
                    />
                  </div>

                  <div
                    className={
                      isOpenColumns
                        ? "open-show-hide-column"
                        : "close-show-hide-column"
                    }
                  >
                    <>
                      <div className="checkbox-wrapper-35 w-full pb-4">
                        <input
                          className="switch"
                          checked={selectAll}
                          onChange={handleSelectAll}
                          type="checkbox"
                          id="switch"
                          name="switch"
                          value="private"
                        />
                        <label htmlFor="switch">
                          <span className="switch-x-toggletext">
                            <span className="switch-x-unchecked">
                              <span className="switch-x-hiddenlabel">
                                Unchecked:{" "}
                              </span>
                              show
                            </span>
                            <span className="switch-x-checked">
                              <span className="switch-x-hiddenlabel">
                                Checked:{" "}
                              </span>
                              Hide All
                            </span>
                          </span>
                        </label>
                      </div>

                      <hr />
                    </>
                    <>
                      {columns.map((column, index) => (
                        <div class="checkbox-wrapper-42  ">
                          <input
                            id={`cbx-${index}`}
                            type="checkbox"
                            checked={!hiddenColumns.includes(column.name)}
                            onChange={() => toggleColumnVisibility(column.name)}
                          />
                          <label
                            className="cbx"
                            htmlFor={`cbx-${index}`}
                          ></label>
                          <label className="lbl" htmlFor={`cbx-${index}`}>
                            <span className="text-gray-700">
                              {column.label}
                            </span>
                          </label>
                        </div>
                      ))}
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="parent-table">
            <div className="overflow-y-auto h-[90%]">
              <table className="w-full ">
                <thead className="sticky global-th top-0">
                  <tr>
                    <th className="global-th relative">Action</th>
                    {columns
                      .filter((column) => !hiddenColumns.includes(column.name))
                      .map((column, index) => (
                        <th className="global-th relative" key={index}>
                          {isOpenFilter && indexx == index && (
                            <div className="bg-white/30 p-2   absolute left-0 top-8 w-full z-50 backdrop-blur-3xl">
                              {column.name == "gender" ? (
                                <>
                                  <select
                                    className="inputUser w-full m-auto text-black"
                                    placeholder={`${column.label}`}
                                    name={`${column.name}`}
                                    onChange={handleInputChange}
                                    value={searchParams[column.name]}
                                  >
                                    <option value="">
                                      تکایە ڕەگەز دیاری بکە
                                    </option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                  </select>
                                </>
                              ) : (
                                <input
                                  type="text"
                                  className="inputUser w-full m-auto text-black"
                                  placeholder={`${column.label}`}
                                  name={`${column.name}`}
                                  onChange={handleInputChange}
                                  value={searchParams[column.name]}
                                  // onBlur={handleInputChange}
                                />
                              )}

                              <div className="flex gap-3 mt-2">
                                <div>
                                  <button
                                    className="btn-plus"
                                    onClick={handleSearch}
                                  >
                                    <FaCheck />
                                  </button>
                                </div>
                                <div>
                                  <button
                                    className="btn-danger px-2 py-1"
                                    onClick={() =>
                                      handleClearSearch(column.name)
                                    }
                                  >
                                    <RxCross2 />
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="flex justify-between items-center">
                            <div>
                              <p>{column.label}</p>
                            </div>

                            <div
                              onClick={() => {
                                setIsOpenFilter(!isOpenFilter);
                                setIndexx(index);
                              }}
                            >
                              <p className="globalFilterIcon">
                                <CiFilter />
                              </p>
                            </div>
                          </div>
                        </th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {listData.map((item, index) => (
                    <tr key={index} className="tableRow global-tr">
                      <td className="global-td">
                        <div className="flex gap-2">
                          <button
                            title="update"
                            className="btn-primary"
                            onClick={() => navigate("/edit/user/" + item.id)}
                          >
                            <RxUpdate />
                          </button>
                          <button
                            title="Reset Password"
                            className="btn-plus"
                            onClick={() => {
                              handleResetPassword(item.id);
                            }}
                          >
                            <MdOutlineLockReset />
                          </button>

                          <button
                            className="btn-danger"
                            title="Finger Print"
                            onClick={() => {
                              console.log("id")
                              dispatch(
                                createAttendanceAction(
                                  item.fullname_en,
                                  item.id
                                )
                              );
                            }}
                          >
                            <IoFingerPrintSharp />
                          </button>
                        </div>
                      </td>
                      {columns.map(
                        (column, index) =>
                          !hiddenColumns.includes(column.name) && (
                            <td
                              key={index}
                              className="border-b  py-4 global-td "
                              style={{
                                minWidth: "300px",
                                borderBottom: "solid 1px #e5e7eb",
                              }}
                            >
                              <div className="flex gap-2">
                                {column.name == "user_cv" ? (
                                  <Link
                                    className="m-auto"
                                    to={`${item[column.name]}`}
                                    target="_blank"
                                  >
                                    {" "}
                                    <img
                                      src={item[column.name]}
                                      className="w-32 m-auto"
                                      alt=""
                                    />{" "}
                                  </Link>
                                ) : (
                                  item[column.name]
                                )}
                              </div>
                            </td>
                          )
                      )}
                    </tr>
                  ))}
                  {/* Add more rows as needed */}
                </tbody>
              </table>
            </div>

            <div className="flex items-center justify-end  gap-3 mt-4 mr-2 ">
              <select
                name=""
                className="select  w-20 p-0.5 "
                onChange={(e) => setPerPage(e.target.value)}
                id=""
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>

              <ReactPaginate
                previousLabel="<"
                nextLabel={">"}
                // nextClassName='bg-blue-500  hover:bg-blue-600 text-white font-semibold px-2 py-2 rounded-md'
                breakLabel="..."
                className="flex  items-center   -space-x-px h-8 text-sm"
                previousClassName="flex items-center justify-center px-3 h-8 ml-0  border border-gray-300 rounded-l-lg "
                nextClassName="flex items-center justify-center px-3 h-8 leading-tight text-gray-500  border border-gray-300 rounded-r-lg "
                pageLinkClassName="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500  border border-gray-300  "
                breakClassName="w-8 py-2 text-center"
                pageCount={Math.ceil(total / perPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={(event) => {
                  let currentPage = event.selected + 1;

                  setCurrentPage(currentPage);
                  dispatch(
                    getAllUserAction(
                      currentPage,
                      perPage,
                      searchParams.fullname_ku,
                      searchParams.fullname_en,
                      searchParams.email,
                      searchParams.birth_date,
                      searchParams.gender,
                      searchParams.phone,
                      searchParams.secound_phone,
                      searchParams.close_phone,
                      searchParams.married,
                      searchParams.number_of_children,
                      searchParams.blood_group,
                      searchParams.date_of_commencement,
                      searchParams.chronic_diseases,
                      searchParams.certificate,
                      searchParams.certificate_field,
                      searchParams.occupationoutside,
                      searchParams.work_place,
                      searchParams.job_tiltle,
                      searchParams.language_level,
                      searchParams.computer_skills
                    )
                  );
                }}
                activeLinkClassName="bg-black text-white flex  items-center  -space-x-px h-8 text-sm"
              />
            </div>
          </div>
        </div>
      )}
      <div className="bottom-space"></div>
    </>
  );
};

export default UserProfile;
