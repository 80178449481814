import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { useDispatch } from "react-redux";
import { addUserCvAction, createUserCvAction, updateUserAction } from "../../Action/userRegister";
import { FaPlus } from "react-icons/fa";
import { FaTrash } from "react-icons/fa6";
const UserCvModal = ({ onClose, userId }) => {
  const dispatch = useDispatch();
  const [image, setImage] = useState([""]);
  const [profiles,setProfiles]=useState(["false"]);
  const handleFileImage = (event,index) => {
    // console.log(event.target)
    const { files } = event.target;
      // console.log(files)
      const newValue=[...image];
      newValue[index]=files[0];
      setImage(newValue)

  };
  const handleAddMore = () => {
    setImage([...image, ""]);
    setProfiles([...profiles,""])
  };
  const handleUpdate = () => {
   
    dispatch(addUserCvAction(userId,image,profiles))
  };
  const handleRemove = (index) => {

    const removeCheck=[...profiles];
    removeCheck.splice(index,1)
    setProfiles(removeCheck)

    const removeFile = [...image];
    removeFile.splice(index, 1);
    setImage(removeFile);
  };
  const [checked, setChecked] = useState(false);
    const handleChangeCheck = (event,index) => {
    setChecked(!checked);
    console.log(event.target.value)
    const newValue=[...profiles];
    newValue[index]=event.target.value;
    setProfiles(newValue)
  };


  return (
    <div className="parentUserModal">
      <div onClick={() => onClose(false)} className="overlayUserModal" />
      <div className="contaierUserModal">
        <div className="headUserModal">
          <div>
            <p className="font-bold">Add Image</p>
          </div>
          <div
            className="text-4xl cursor-pointer"
            onClick={() => onClose(false)}
          >
            <p className="text-red-500 text-3xl font-bold">
              <RxCross2 />
            </p>
          </div>
        </div>
        <div className="mt-2">
          <div className="mt-5   mb-4 px-0  flex w-full justify-end">
            <button className="btn-plus " onClick={handleAddMore}>
              <FaPlus />
            </button>
          </div>
          {image.map((image, index) => {
            return (
              <div className="flex items-center gap-2 ">
                <label htmlFor="file-input" className="sr-only">
                  Choose file
                </label>
                <input
                  type="file"
                  name="file-input"
                  id="file-input"
                  onChange={(event)=>handleFileImage(event,index)}
                  className="fileInput mt-1"
                />
                <div>
                  <input
                    type="checkbox"
                    onChange={(event)=>handleChangeCheck(event,index)}
                    value={!checked}
                  />
                </div>
                <div>
                  <button
                    className="btn-danger"
                    onClick={() => handleRemove(index)}
                  >
                    <FaTrash />
                  </button>
                </div>
              </div>
            );
          })}
        </div>

        <button onClick={handleUpdate} className="btn-success m-0 mt-4">Save</button>
      </div>
    </div>
  );
};

export default UserCvModal;
