import React, { useState } from "react";
import { AiFillCaretDown } from "react-icons/ai";
import { BsFillCloudArrowUpFill } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { uploadImage } from "../../../Action/patient";
import { useLocation } from "react-router-dom";

const ProfileVisits = ({ patients,patientId }) => {
  const [image, setImage] = useState([]);
    const location=useLocation();
    const dispatch=useDispatch();
  const handleSave = () => {
    uploadImage(patientId,image,location)
  };
  const handleImageChange=(e)=>{
    const newValue=[...image];
    newValue[0]=e.target.files[0];
    setImage(newValue)
  }
  return (
    <div className="bg-white pb-2 rounded-md  shadow-md mx-auto px-3">
      {patients.map((patient, index) => {
        return (
          <div>
            {patients.map((patient, index) => {
              return (
                <div key={index} className=" ">
                  <div className="parentInputGrid ">
                    {/* end parent Input & label */}

                    <div className="w-full mt-2">
                      <div className="w-full flex justify-end">
                        <label htmlFor="number"> *رەقەم مۆبایل</label>
                      </div>
                      <input
                        type="text"
                        name="closetHome"
                        readOnly
                        defaultValue={patient.phone}
                        id="number"
                        className="inputLogin w-full text-right"
                        placeholder="رەقەم مۆبایلرەقەم مۆبایل"
                      />
                    </div>
                    {/* end parent Input & label */}
                    <div className="w-full mt-2">
                      <div className="w-full  ">
                        <div className="relative w-full ">
                          <div className="child ">
                            <div className="w-full flex justify-end">
                              <label htmlFor="gender">*ڕەگەز </label>
                            </div>
                            <div className="coustmSelect ">
                              <p>
                                {patient.gender == "" || patient.gender == null
                                  ? ".. ڕەگەز "
                                  : patient.gender}
                              </p>
                              <div className={"parentCaretDown"}>
                                <AiFillCaretDown />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* end parent Input & label */}

                    <div className="w-full mt-2">
                      <div className="w-full flex justify-end">
                        <label htmlFor="name">* بەرواری لە دایکبوون</label>
                      </div>
                      <input
                        type="text"
                        name="card"
                        readOnly
                        value={patient.birthDate}
                        id="number"
                        className="inputLogin w-full text-right"
                        placeholder="بەرواری لە دایکبوون"
                      />
                    </div>
                    {/* end of label and inpt */}

                    <div className="w-full mt-2">
                      <div className="w-full flex justify-end">
                        <label htmlFor="name">*ناوی نەخۆش</label>
                      </div>
                      <input
                        type="text"
                        name="street"
                        readOnly
                        value={patient.Pname}
                        id="number"
                        className="inputLogin w-full text-right"
                        placeholder="ناوی نەخۆش"
                      />
                    </div>
                    {/* end parent Input & label */}
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
      <div
        className=" py-2  flex justify-center items-center"
        // key={index}
      >
        <label
          id="large_size"
          className="p-4 flex flex-col items-center gap-2 w-10/12 bg-violet-50 text-violet-500 rounded-lg hover:bg-violet-100 cursor-pointer"
        >
          <p>
            <BsFillCloudArrowUpFill className="w-6 h-6" />
          </p>

          <span>{image == null ? "" : image.name}</span>
          <input
            onChange={(e) => handleImageChange(e)}
            name={`image`}
            id="large_size"
            type="file"
            className="hidden"
          />
        </label>
      </div>
      <div className="mx-auto w-10/12">
        <button className="btn  " onClick={handleSave}>
          Save
        </button>
      </div>
    </div>
  );
};

export default ProfileVisits;
