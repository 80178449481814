export const SEND_INFO_WHATS_APP_REQUEST = "SEND_INFO_WHATS_APP_REQUEST";
export const SEND_INFO_WHATS_APP_SUCCESS = "SEND_INFO_WHATS_APP_SUCCESS";
export const SEND_INFO_WHATS_APP_FAIL = "SEND_INFO_WHATS_APP_FAIL";

export const FETCH_INFO_WHATS_APP_REQUEST = "FETCH_INFO_WHATS_APP_REQUEST";
export const FETCH_INFO_WHATS_APP_SUCCESS = "FETCH_INFO_WHATS_APP_SUCCESS";
export const FETCH_INFO_WHATS_APP_FAIL = "FETCH_INFO_WHATS_APP_FAIL";

export const FETCH_INFO_WHATS_APP_BY_VISIT_REQUEST =
  "FETCH_INFO_WHATS_APP_BY_VISIT_REQUEST";
export const FETCH_INFO_WHATS_APP_BY_VISIT_SUCCESS =
  "FETCH_INFO_WHATS_APP_BY_VISIT_SUCCESS";
export const FETCH_INFO_WHATS_APP_BY_VISIT_FAIL =
  "FETCH_INFO_WHATS_APP_BY_VISIT_FAIL";

export const FETCH_INFO_WHATS_APP_EXCEL_REQUEST =
  "FETCH_INFO_WHATS_APP_EXCEL_REQUEST";
export const FETCH_INFO_WHATS_APP_EXCEL_SUCCESS =
  "FETCH_INFO_WHATS_APP_EXCEL_SUCCESS";
export const FETCH_INFO_WHATS_APP_EXCEL_FAIL =
  "FETCH_INFO_WHATS_APP_EXCEL_FAIL";
