export const SEND_PATIENT_INFO_MEDIA_REQUEST =
  "SEND_PATIENT_INFO_MEDIA_REQUEST";
export const SEND_PATIENT_INFO_MEDIA_SUCCESS =
  "SEND_PATIENT_INFO_MEDIA_SUCCESS";
export const SEND_PATIENT_INFO_MEDIA_FAIL = "SEND_PATIENT_INFO_MEDIA_FAIL";

export const RECEIVE_PATIENT_INFO_MEDIA_REQUEST =
  "RECEIVE_PATIENT_INFO_MEDIA_REQUEST";
export const RECEIVE_PATIENT_INFO_MEDIA_SUCCESS =
  "RECEIVE_PATIENT_INFO_MEDIA_SUCCESS";
export const RECEIVE_PATIENT_INFO_MEDIA_FAIL =
  "RECEIVE_PATIENT_INFO_MEDIA_FAIL";

export const STORE_ALL_INFO_PATIENT_MEDIA_REQUEST =
  "STORE_ALL_INFO_PATIENT_MEDIA_REQUEST";
export const STORE_ALL_INFO_PATIENT_MEDIA_SUCCESS =
  "STORE_ALL_INFO_PATIENT_MEDIA_SUCCESS";
export const STORE_ALL_INFO_PATIENT_MEDIA_FAIL =
  "STORE_ALL_INFO_PATIENT_MEDIA_FAIL";

export const FETCH_PATIENT_MEDIA_REQUEST = "FETCH_PATIENT_MEDIA_REQUEST";
export const FETCH_PATIENT_MEDIA_SUCCESS = "FETCH_PATIENT_MEDIA_SUCCESS";
export const FETCH_PATIENT_MEDIA_FAIL = "FETCH_PATIENT_MEDIA_FAIL";

export const GET_ALL_INFO_PATIENT_MEDIA_REQUEST =
  "GET_ALL_INFO_PATIENT_MEDIA_REQUEST";
export const GET_ALL_INFO_PATIENT_MEDIA_SUCCESS =
  "GET_ALL_INFO_PATIENT_MEDIA_SUCCESS";
export const GET_ALL_INFO_PATIENT_MEDIA_FAIL =
  "GET_ALL_INFO_PATIENT_MEDIA_FAIL";

export const FETCH_SORTING_MEDIA_WORK_REQUEST =
  "FETCH_SORTING_MEDIA_WORK_REQUEST";
export const FETCH_SORTING_MEDIA_WORK_SUCCESS =
  "FETCH_SORTING_MEDIA_WORK_SUCCESS";
export const FETCH_SORTING_MEDIA_WORK_FAIL = "FETCH_SORTING_MEDIA_WORK_FAIL";

export const UPDATE_SECTION_MEDIA_WORK_REQUEST =
  "UPDATE_SECTION_MEDIA_WORK_REQUEST";
export const UPDATE_SECTION_MEDIA_WORK_SUCCESS =
  "UPDATE_SECTION_MEDIA_WORK_SUCCESS";
export const UPDATE_SECTION_MEDIA_WORK_FAIL = "UPDATE_SECTION_MEDIA_WORK_FAIL";

export const APPROVED_MEDIA_WORK_REQUEST = "APPROVED_MEDIA_WORK_REQUEST";
export const APPROVED_MEDIA_WORK_SUCCESS = "APPROVED_MEDIA_WORK_SUCCESS";
export const APPROVED_MEDIA_WORK_FAIL = "APPROVED_MEDIA_WORK_FAIL";

export const GET_MEDIA_WORK_REQUEST = "GET_MEDIA_WORK_REQUEST";
export const GET_MEDIA_WORK_SUCCESS = "GET_MEDIA_WORK_SUCCESS";
export const GET_MEDIA_WORK_FAIL = "GET_MEDIA_WORK_FAIL";

export const SEEN_MEDIA_RECIVE_REQUEST = "SEEN_MEDIA_RECIVE_REQUEST";
export const SEEN_MEDIA_RECIVE_SUCCESS = "SEEN_MEDIA_RECIVE_SUCCESS";
export const SEEN_MEDIA_RECIVE_FAIL = "SEEN_MEDIA_RECIVE_FAIL";


export const FETCH_MEDIA_RECIVE_VISIT_REQUEST = "FETCH_MEDIA_RECIVE_VISIT_REQUEST";
export const FETCH_MEDIA_RECIVE_VISIT_SUCCESS = "FETCH_MEDIA_RECIVE_VISIT_SUCCESS";
export const FETCH_MEDIA_RECIVE_VISIT_FAIL = "FETCH_MEDIA_RECIVE_VISIT_FAIL";

export const REJECT_MEDIA_RECIVE_REQUEST = "REJECT_MEDIA_RECIVE_REQUEST";
export const REJECT_MEDIA_RECIVE_SUCCESS = "REJECT_MEDIA_RECIVE_SUCCESS";
export const REJECT_MEDIA_RECIVE_FAIL = "REJECT_MEDIA_RECIVE_FAIL";


export const FETCH_ALL_INFO_MEDIA_REQUEST = "FETCH_ALL_INFO_MEDIA_REQUEST";
export const FETCH_ALL_INFO_MEDIA_SUCCESS = "FETCH_ALL_INFO_MEDIA_SUCCESS";
export const FETCH_ALL_INFO_MEDIA_FAIL = "FETCH_ALL_INFO_MEDIA_FAIL";