import React from "react";
import { Link } from "react-router-dom";
import NotFond from "../../Image/404.png";

const NotFound404 = () => {
  return (
    <div className={"grid grid-cols-1 justify-items-center place-items-center lg:grid-cols-2 bg-white shadow-xl"}>
      <div className="inline-block">
        <p className="text-sm font-medium text-blue-500 dark:text-blue-400">
          404 Error
        </p>
        <h1 className="mt-3 text-2xl font-semibold text-gray-800  md:text-3xl">
          Page not found
        </h1>
        <p className="mt-4 text-gray-500 dark:text-gray-400">
          Sorry, the page you are looking for doesn't exist.Here are some
          helpful links:
        </p>
        <Link to={"/"} className="mt-4">
            <p>Home</p>
        </Link>
      </div>
      <div>
        <img src={NotFond}  alt="" />
      </div>
    </div>
  );
};

export default NotFound404;
