import { CREATE_RECEIVE_ECONOMIC_FAIL, CREATE_RECEIVE_ECONOMIC_REQUEST, CREATE_RECEIVE_ECONOMIC_SUCCESS, FETCH_RECEIVE_ECONOMIC_FAIL, FETCH_RECEIVE_ECONOMIC_REQUEST, FETCH_RECEIVE_ECONOMIC_SUCCESS } from "../../ActionType/EconomicType/economic"

export const createReceiveEconomicReducer = (state ={loading:true,receiveEconomic:[],error:''} , action) => {
    switch (action.type) {
        case CREATE_RECEIVE_ECONOMIC_REQUEST:
            return {
                loading:true
            }
        case CREATE_RECEIVE_ECONOMIC_SUCCESS:
            return {
                loading:false,
                receiveEconomic:action.payload,
                error:''
            }
        case CREATE_RECEIVE_ECONOMIC_FAIL:
            return {
                loading:false,
                receiveEconomic:[],
                error:'Please Check Your Request'
            }
        default:
            return state
    }
}

export const getReceiveEconmicReducer = (state ={loading:true,receive:[],error:''} , action) => {
    switch (action.type) {
        case FETCH_RECEIVE_ECONOMIC_REQUEST:
            return {
                loading:true
            }
        case FETCH_RECEIVE_ECONOMIC_SUCCESS:
            return{
                loading:false,
                receive:action.payload,
                error:''
            }
        case FETCH_RECEIVE_ECONOMIC_FAIL:
            return {
                loading:false,
                receive:[],
                error:"please Check Your Request"
            }
        default:
            return state
    }
}