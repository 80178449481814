import {
  CREATE_ROLE_FAIL,
  CREATE_ROLE_REQUEST,
  CREATE_ROLE_SUCCESS,
  FETCH_ROLE_FAIL,
  FETCH_ROLE_REQUEST,
  FETCH_ROLE_SUCCESS,
  SHOW_ROLE_FAIL,
  SHOW_ROLE_REQUEST,
  SHOW_ROLE_SUCCESS,
} from "../../ActionType/RolesType/RolesType";

export const createRolesReducer = (
  state = { loading: true, roles: [], error: "" },
  action
) => {
  switch (action.type) {
    case CREATE_ROLE_REQUEST:
      return {
        loading: false,
      };
    case CREATE_ROLE_SUCCESS:
      return {
        loading: false,
        roles: action.payload,
        error: "",
      };
    case CREATE_ROLE_FAIL:
      return {
        loading: false,
        roles: [],
        error: "Please Check Your Request",
      };
    default:
      return state;
  }
};

export const getRolesReducer = (
  state = { loading: true, roles: [], total: 0, error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_ROLE_REQUEST:
      return {
        loading: false,
      };
    case FETCH_ROLE_SUCCESS:
      return {
        loading: false,
        roles: action.payload.data,
        total: action.payload.total,
        error: "",
      };
    case FETCH_ROLE_FAIL:
      return {
        loading: false,
        roles: [],
        error: "Please Check Your Request",
      };
    default:
      return state;
  }
};

export const showRolesReducer = (
  state = { loading: true, roles: [],  error: "" },
  action
) => {
  switch (action.type) {
    case SHOW_ROLE_REQUEST:
      return {
        loading: false,
      };
    case SHOW_ROLE_SUCCESS:
      console.log(action.payload)
      return {
        loading: false,
        roles: action.payload,
        // total: action.payload.total,
        error: "",
      };
    case SHOW_ROLE_FAIL:
      return {
        loading: false,
        roles: [],
        error: "Please Check Your Request",
      };
    default:
      return state;
  }
};

