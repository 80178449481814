import React, { useEffect, useState } from "react";
import { FaFilter, FaUserInjured } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { createVisitAction, getPatient } from "../../../Action/patient";
import { MdAssignmentReturn } from "react-icons/md";
import { AiFillEye, AiOutlineEdit, AiOutlineUserAdd } from "react-icons/ai";
import { Link } from "react-router-dom";
import { TbPlayerEject } from "react-icons/tb";
import Logo from "../../../Image/Logo.jpg";
// import InvoiceModal from "../../../Modal/InvoiceModal";
import PatientAdminstrartorModal from "../../../Modal/AdminstratorModal/PatientAdminstrartorModal";
import AssignModal from "../../../Modal/AssignModal";
import ReactPaginate from "react-paginate";
import EditModalPatient from "../../../Modal/EditModalPatient";
import RejectModal from "../../../Modal/RejectModal";
const Patient = () => {
  const [isOpenFilter, setIsOpentFilter] = useState(false);
  const handleOpenFilter = () => {
    setIsOpentFilter(!isOpenFilter);
  };
  const [isOpenRejectModal, setIsOpenRejectModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [isOpenAssignModal, setIsopenAssignModal] = useState(false);
  const [patientId, setPatientId] = useState(null);
  const handleNewVisit = (event, patient_id) => {
    event.preventDefault();
    // console.log(patient_id)
    dispatch(createVisitAction(patient_id));
    // const { code, fullname, phone, gender, age } = searchParams;
    dispatch(
      getPatient(locationId, currentPage, perPage, "", "", "", "", "", 1)
    );
    dispatch(
      getPatient(locationId, currentPage, perPage, "", "", "", "", "", 1)
    );
  };
  const [search, setSearch] = useState({
    code: "",
    fullname: "",
    phone: "",
    age: "",
    gender: "",
  });
  const [searchParams, setSearchParams] = useState({
    code: "",
    fullname: "",
    phone: "",
    age: "",
    gender: "",
  });
  const [isOpenInvoiceModal, setIsOpenInvoiceModal] = useState(false);
  const dispatch = useDispatch();
  const locationId = localStorage.getItem("locationId");
  const getPatients = useSelector((state) => state.getPatients);
  const { patients, loading, total } = getPatients;
  const regex = /^(\d{4})(\d{3})(\d{4})$/;
  useEffect(() => {
    dispatch(
      getPatient(
        locationId,
        currentPage,
        perPage,
        searchParams.code,
        searchParams.fullname,
        searchParams.phone,
        searchParams.gender,
        searchParams.age
      )
    );
  }, [searchParams]);
  return (
    <>
      {loading ? (
        <div className="flex flex-col">
          <img
            src={Logo}
            alt="Logo"
            className="w-56 m-auto animate-pulse mt-20 "
          />
          <div className="text-2xl text-center font-bold  ">
            <div className="flex justify-center gap-4">
              <p className="animate-charcter">Please Wait</p>
              <div class="container2">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>{" "}
            </div>
          </div>
        </div>
      ) : (
        <div className="parentPrimary">
          <div className="parentHeadPrimary">
            <div className="flex gap-2 items-center">
              <p className="text-lg text-blue-500">
                <FaUserInjured />
              </p>
              <p>Patient</p>
            </div>
            <div className="flex gap-3">
              <button
                onClick={() => setIsOpenInvoiceModal(true)}
                className="viewBtn"
              >
                Patient +
              </button>
              <Link to={"/commite"} className="assignBtn">
                Commite +
              </Link>
            </div>
          </div>
          <div className="parentTablePrimary">
            <table className="tablePrimary">
              <thead className="sticky top-0">
                <tr>
                  <th className="">
                    <div className="flex justify-between items-center">
                      <p className="pl-1">Patient ID</p>
                      <div className="filterIcon" onClick={handleOpenFilter}>
                        <FaFilter />
                      </div>
                    </div>
                    <div>
                      <div className="w-full">
                        <input
                          onChange={(e) =>
                            setSearch({
                              ...search,
                              code: e.target.value,
                            })
                          }
                          onBlur={() =>
                            setSearchParams({
                              ...searchParams,
                              code: search.code,
                            })
                          }
                          value={search.code}
                          name="code"
                          type="text"
                          placeholder="Code"
                          className={
                            isOpenFilter
                              ? "inputLogin p-1.5 shadow-xl rounded  "
                              : "hidden"
                          }
                        />
                      </div>
                    </div>
                  </th>
                  <th className="">
                    <div className="flex justify-between items-center">
                      <p className="pl-1">Full Name</p>
                      <div className="filterIcon" onClick={handleOpenFilter}>
                        <FaFilter />
                      </div>
                    </div>
                    <div>
                      <div className="w-full">
                        <input
                          onChange={(e) =>
                            setSearch({
                              ...search,
                              fullname: e.target.value,
                            })
                          }
                          onBlur={() =>
                            setSearchParams({
                              ...searchParams,
                              fullname: search.fullname,
                            })
                          }
                          value={search.fullname}
                          name="fullname"
                          type="text"
                          placeholder="Full Name"
                          className={
                            isOpenFilter
                              ? "inputLogin p-1.5 shadow-xl rounded  "
                              : "hidden"
                          }
                        />
                      </div>
                    </div>
                  </th>
                  <th className="">
                    <div className="flex justify-between items-center">
                      <p className="pl-1">Phone</p>
                      <div className="filterIcon  " onClick={handleOpenFilter}>
                        <FaFilter />
                      </div>
                    </div>
                    <div>
                      <div className="w-full">
                        <input
                          onChange={(e) =>
                            setSearch({
                              ...search,
                              phone: e.target.value,
                            })
                          }
                          onBlur={() =>
                            setSearchParams({
                              ...searchParams,
                              phone: search.phone,
                            })
                          }
                          value={search.phone}
                          name="phone"
                          type="text"
                          placeholder="Phone Number"
                          className={
                            isOpenFilter
                              ? "inputLogin p-1.5 shadow-xl rounded  "
                              : "hidden"
                          }
                        />
                      </div>
                    </div>
                  </th>
                  <th className="">
                    {" "}
                    <div className="flex justify-between items-center">
                      <p className="pl-1">Gender</p>
                      <div className="filterIcon" onClick={handleOpenFilter}>
                        <FaFilter />
                      </div>
                    </div>
                    <div>
                      <div className="w-full">
                        <select
                          // onChange={(e) =>
                          //   setSearch({
                          //     ...search,
                          //     gender: e.target.value,
                          //   })
                          // }
                          onChange={(e) =>
                            setSearchParams({
                              ...searchParams,
                              gender: e.target.value,
                            })
                          }
                          // value={search.gender}
                          name="gender"
                          type="text"
                          placeholder="Gender"
                          className={
                            isOpenFilter
                              ? "inputLogin p-0 shadow-xl rounded  "
                              : "hidden"
                          }
                        >
                          <option value="">ڕەگەز هەڵبژێرە</option>
                          <option value="Male">Male </option>
                          <option value="Female">Female</option>
                        </select>
                      </div>
                    </div>
                  </th>
                  <th className="">
                    {" "}
                    <div className="flex justify-between items-center">
                      <p className="pl-1">Birth Date</p>
                      <div className="filterIcon" onClick={handleOpenFilter}>
                        <FaFilter />
                      </div>
                    </div>
                    <div>
                      <div className="w-full">
                        <input
                          onChange={(e) =>
                            setSearch({
                              ...search,
                              age: e.target.value,
                            })
                          }
                          onBlur={() =>
                            setSearchParams({
                              ...searchParams,
                              age: search.age,
                            })
                          }
                          value={search.age}
                          name="age"
                          type="text"
                          placeholder="Birth Date"
                          className={
                            isOpenFilter
                              ? "inputLogin p-1.5 shadow-xl rounded  "
                              : "hidden"
                          }
                        />
                      </div>
                    </div>
                  </th>
                  <th>Date</th>
                  <th className="">
                    <p className="pl-1">Visits</p>
                  </th>
                  <th className="">Action</th>
                </tr>
              </thead>
              <tbody>
                {patients.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      className={
                        item.reject == 2
                          ? "tableRowRject  "
                          : item.assign == 1
                          ? "tableRowSave"
                          : item.assign == 2
                          ? "tableRowGreen"
                          : "tableRow"
                      }
                    >
                      <td className="w-80 ">{item.code}</td>
                      <td className="w-80 ">{item.Pname}</td>
                      <td className="w-80 ">
                        {item.phone.replace(regex, "$1-$2-$3")}
                      </td>
                      <td className="w-80 ">{item.gender}</td>
                      <td className="w-80 ">{item.birthDate}</td>
                      <td className="w-80 ">
                        {item.created_at.toString().slice(0, 10)}
                      </td>
                      <td className="w-80  text-red-500 font-bold text-xl text-decoration underline">
                        {item.visits_with_reject_count == 0
                          ? 1
                          : item.visits_with_reject_count}
                      </td>

                      <td className="w-80 ">
                        <div className="flex flex-col  lg:flex lg:flex-row lg:gap-3 justify-center">
                          <div data-te-toggle="tooltip" title="Assign">
                            <button
                              //  class="transititext-primary text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600
                              className={
                                item.reject == 2
                                  ? "assignBtn  cursor-not-allowed"
                                  : item.assign == 1 || item.assign == 2
                                  ? "hidden"
                                  : "assignBtn  "
                              }
                              disabled={item.reject == 2 ? true : false}
                              onClick={() => {
                                setIsopenAssignModal(true);

                                setPatientId(item.id);
                              }}
                            >
                              <MdAssignmentReturn className="rotate-180" />
                            </button>
                          </div>

                          <div data-te-toggle="tooltip" title="New Visit">
                            {item.assign == 2 ? (
                              <button
                                className="editBtn"
                                onClick={(event) => {
                                  handleNewVisit(event, item.id);
                                }}
                              >
                                <AiOutlineUserAdd />
                              </button>
                            ) : (
                              ""
                            )}
                          </div>

                          <div data-te-toggle="tooltip" title="Reject">
                            {item.assign == 1 && (
                              <button
                                className={
                                  item.reject == 2
                                    ? "rejectBtn cursor-not-allowed"
                                    : "rejectBtn"
                                }
                                disabled={item.reject == 2 ? true : false}
                                onClick={(event) => {
                                  event.preventDefault();
                                  setIsOpenRejectModal(true);
                                  setPatientId(item.id);
                                }}
                              >
                                <TbPlayerEject />
                              </button>
                            )}
                          </div>
                          <div data-te-toggle="tooltip" title="Edit/View">
                            {item.assign == 1 || item.assign == 2 ? (
                              <button
                                className="editBtn"
                                onClick={(event) => {
                                  setIsOpenEditModal(true);
                                  setPatientId(item.id);
                                  // console.log('dsfs')
                                  // handelEdit(event, item.id);
                                }}
                              >
                                {item.assign == 2 ? (
                                  <AiFillEye />
                                ) : (
                                  <AiOutlineEdit />
                                )}
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {isOpenInvoiceModal && (
            <PatientAdminstrartorModal
              closeModal={setIsOpenInvoiceModal}
              locationID={locationId}
            />
          )}

          <div className="overflow-y-auto   ">
            {isOpenEditModal && (
              <EditModalPatient
                closeModal={setIsOpenEditModal}
                openEditModal={isOpenEditModal}
                patientId={patientId}
              />
            )}
          </div>
          <div className="overflow-y-auto   ">
            {isOpenAssignModal && (
              <AssignModal
                closeModal={setIsopenAssignModal}
                isOpenAssign={isOpenAssignModal}
                patientId={patientId}
              />
            )}
          </div>
          <div className="overflow-y-auto  ">
            {isOpenRejectModal && (
              <RejectModal
                closeModal={setIsOpenRejectModal}
                isOpenReject={isOpenRejectModal}
                patientId={patientId}
              />
            )}
          </div>
          <div className="gap-3 px-3 flex justify-between items-center  pt-2  ">
            <div className="flex items-center gap-4 ">
              <p className="text-xl ">Total: </p>
              <div
                className={
                  "bg-teal-600 px-4 py-0.5 text-white rounded-md shadow-md "
                }
              >
                {total}
              </div>
            </div>
            <div className="flex items-center gap-4">
              <div>
                <select
                  name=""
                  className="mt-1  bg-white border w-20 focus:ring-2  focus:ring-slate-900 rounded-md   focus:outline-none"
                  onChange={(e) => setPerPage(e.target.value)}
                  value={perPage}
                  id=""
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>

              <div className="flex justify-center   mt-1">
                <ReactPaginate
                  previousLabel="<"
                  nextLabel={">"}
                  // nextClassName='bg-blue-500  hover:bg-blue-600 text-white font-semibold px-2 py-2 rounded-md'
                  breakLabel="..."
                  className="flex  items-center   -space-x-px h-8 text-sm"
                  previousClassName="flex items-center justify-center px-3 h-8 ml-0  border border-gray-300 rounded-l-lg "
                  nextClassName="flex items-center justify-center px-3 h-8 leading-tight text-gray-500  border border-gray-300 rounded-r-lg "
                  pageLinkClassName="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500  border border-gray-300  "
                  breakClassName="w-8 py-2 text-center"
                  pageCount={Math.ceil(total / perPage)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  // activeClassName=''
                  onPageChange={(event) => {
                    let currentPage = event.selected + 1;
                    dispatch(
                      getPatient(
                        locationId,
                        currentPage,
                        perPage,
                        "",
                        "",
                        "",
                        "",
                        "",
                        1
                      )
                    );
                    setCurrentPage(currentPage);
                  }}
                  activeLinkClassName="bg-black text-white flex  items-center  -space-x-px h-8 text-sm"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Patient;
