import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GiReturnArrow } from "react-icons/gi";
import { AiTwotoneEye } from "react-icons/ai";
import {
  disapprovedRejectPatientAction,
  getRejectPatientAction,
  rejectPatientAction,
  rejectPatientVisitAction,
  rejectPatientWithVisitAction,
} from "../../Action/rejectPatientAction";
import Logo from "../../Image/Logo.jpg";
import { FaFilter } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import ViewRejectModal from "../../Modal/ViewRejectModal/ViewRejectModal";

const RejectPatient = () => {
  const dispatch = useDispatch();
  const getRejectPatient = useSelector((state) => state.getRejectPatient);
  const { rejectPatient, loading, total } = getRejectPatient;
  const locationId = localStorage.getItem("locationId");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [patientId, setPatientId] = useState(null);
  const [rejectId, setRejectId] = useState(null);
  const rejectPatientVisit = useSelector((state) => state.rejectPatientVisit);
  const { rejects } = rejectPatientVisit;
  useEffect(() => {
    if (patientId == null) {
      return;
    }
    dispatch(rejectPatientVisitAction(patientId));
  }, [patientId]);
  const [searchParams, setSearchParams] = useState({
    code: "",
    fullname: "",
    phone: "",
    age: "",
    gender: "",
    note: "",
    month: "",
  });
  const { fullname, code, phone, age, gender, note, month } = searchParams;
  useEffect(() => {
    if (locationId == null || locationId == undefined) {
      return;
    } else {
      dispatch(
        getRejectPatientAction(
          locationId,
          currentPage,
          perPage,
          fullname,
          code,
          phone,
          age,
          gender,
          note,
          month
        )
      );
    }
  }, [code, fullname, phone, age, gender, note, currentPage, perPage, month]);
  const [isOpenDisapprovedReject, setIsOpenDisapprovedReject] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const handleSearchParams = (event) => {
    const { name, value } = event.target;
    setSearchParams({
      ...searchParams,
      [name]: value,
    });
  };

  const handleOpenFilter = () => {
    setIsOpenFilter(!isOpenFilter);
  };
  const handleDisapporvedReject = (visit_id, rejectId) => {
    dispatch(disapprovedRejectPatientAction(visit_id, rejectId, patientId));
    // dispatch(rejectPatientWithVisitAction(patientId))
    // dispatch(rejectPatientWithVisitAction(patientId))
    dispatch(
      getRejectPatientAction(
        locationId,
        currentPage,
        perPage,
        fullname,
        code,
        phone,
        age,
        gender,
        note,
        searchParams.month
      )
    );
    dispatch(
      getRejectPatientAction(
        locationId,
        currentPage,
        perPage,
        fullname,
        code,
        phone,
        age,
        gender,
        note,
        searchParams.month
      )
    );
    // console.log(patientId)
    // dispatch(rejectPatientAction(patientId,visitId));
    dispatch(rejectPatientVisitAction(patientId));
    dispatch(rejectPatientVisitAction(patientId));
  };
  let listReject = [];
  const [visitId, setVisitId] = useState(null);
  const [isOpenRejectModal, setIsOpenRejectModal] = useState(false);
  rejectPatient.map((element, index) => {
    listReject.push(
      <tr key={index} className="border-b border-gray-200 hover:bg-slate-100">
        <td className="py-4 px-6 whitespace-nowrap">
          <div className="flex items-center">
            <div className="ml-4">
              <div className="text-sm font-medium text-gray-900">
                {element.code}
              </div>
            </div>
          </div>
        </td>
        <td className="py-4 px-6 whitespace-nowrap">
          <div className="text-sm text-gray-900">{element.Pname}</div>
        </td>
        <td className="py-4 px-6 whitespace-nowrap">
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full ">
            {element.phone}
          </span>
        </td>
        <td className="py-4 px-6 whitespace-nowrap">
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full ">
            {element.birthDate}
          </span>
        </td>

        <td className="py-4 px-6 whitespace-nowrap">
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full  ">
            {element.gender}
          </span>
        </td>

        <td className="py-4 px-6 whitespace-nowrap">
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full  ">
            {element.created_at.toString().slice(0, 10)}
          </span>
        </td>

        <td className="py-4 px-6 whitespace-nowrap">
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full">
            <button
              className="bg-blue-400 text-white py-2 px-3 rounded-md shadow-md "
              onClick={() => {
                setPatientId(element.id);
                element.reject.map((reject) => {
                  setRejectId(reject.id);
                });

                setIsOpenDisapprovedReject(true);
              }}
            >
              <GiReturnArrow />
            </button>
          </span>
        </td>

        {/* View */}
        <td className="py-4 px-6 whitespace-nowrap">
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full">
            <button
              className="bg-blue-400 text-white py-2 px-3 rounded-md shadow-md "
              onClick={() => {
                setPatientId(element.id);
                element.reject.map((reject) => {
                  setRejectId(reject.id);
                });

                setIsOpenRejectModal(true);
              }}
            >
              <AiTwotoneEye />
            </button>
          </span>
        </td>
      </tr>
    );
  });

  return (
    <>
      {loading || rejects == undefined ? (
        <div className="flex flex-col">
          <img
            src={Logo}
            alt="Logo"
            className="w-56 m-auto animate-pulse mt-20 "
          />
          <div className="text-2xl text-center font-bold  ">
            <div className="flex justify-center gap-4">
              <p className="animate-charcter">Please Wait</p>
              <div class="container2">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>{" "}
            </div>
          </div>
        </div>
      ) : (
        <div className="w-[95%] m-auto bg-white">
          <div className="relative bg-white md:h-[13rem] xl:h-[24rem] overflow-y-auto  3xl:h-[43rem]   w-full m-auto  sm:rounded-lg  mt-4">
            <table className="w-full">
              <thead className="bg-gray-100">
                <tr>
                  <th className="">
                    <div className="flex justify-between items-center">
                      <p className="pl-1">Patient ID</p>
                      <div className="filterIcon" onClick={handleOpenFilter}>
                        <FaFilter />
                      </div>
                    </div>
                    <div>
                      <div className="w-full">
                        <input
                          onBlur={handleSearchParams}
                          name="code"
                          type="text"
                          placeholder="Code"
                          className={
                            isOpenFilter
                              ? "inputUser w-full p-1.5 shadow-xl rounded "
                              : "opacity-0  fixed duration-500"
                          }
                        />
                      </div>
                    </div>
                  </th>
                  <th className="">
                    <div className="flex justify-between items-center">
                      <p className="pl-1">Patient Name</p>
                      <div className="filterIcon" onClick={handleOpenFilter}>
                        <FaFilter />
                      </div>
                    </div>
                    <div>
                      <div className="w-full">
                        <input
                          onBlur={handleSearchParams}
                          name="fullname"
                          type="text"
                          placeholder="Full Name"
                          className={
                            isOpenFilter
                              ? "inputUser w-full p-1.5 shadow-xl rounded "
                              : "opacity-0  fixed duration-500"
                          }
                        />
                      </div>
                    </div>
                  </th>
                  <th className="">
                    <div className="flex justify-between items-center">
                      <p className="pl-1">Phone Number</p>
                      <div className="filterIcon" onClick={handleOpenFilter}>
                        <FaFilter />
                      </div>
                    </div>
                    <div>
                      <div className="w-full">
                        <input
                          onBlur={handleSearchParams}
                          name="phone"
                          type="text"
                          placeholder="Phone Number"
                          className={
                            isOpenFilter
                              ? "inputUser w-full p-1.5 shadow-xl rounded "
                              : "opacity-0  fixed duration-500"
                          }
                        />
                      </div>
                    </div>
                  </th>
                  <th className="">
                    <div className="flex justify-between items-center">
                      <p className="pl-1">Birth Date</p>
                      <div className="filterIcon" onClick={handleOpenFilter}>
                        <FaFilter />
                      </div>
                    </div>
                    <div>
                      <div className="w-full">
                        <input
                          onBlur={handleSearchParams}
                          name="age"
                          type="text"
                          placeholder="Birth Date"
                          className={
                            isOpenFilter
                              ? "inputUser w-full p-1.5 shadow-xl rounded "
                              : "opacity-0  fixed duration-500"
                          }
                        />
                      </div>
                    </div>
                  </th>
                  <th className="">
                    <div className="flex justify-between items-center">
                      <p className="pl-1">Gender</p>
                      <div className="filterIcon" onClick={handleOpenFilter}>
                        <FaFilter />
                      </div>
                    </div>
                    <div>
                      <div className="w-full">
                        <input
                          onBlur={handleSearchParams}
                          name="gender"
                          type="text"
                          placeholder="Gender"
                          className={
                            isOpenFilter
                              ? "inputUser w-full p-1.5 shadow-xl rounded "
                              : "opacity-0  fixed duration-500"
                          }
                        />
                      </div>
                    </div>
                  </th>

                  <th className="">
                    <div className="flex justify-between items-center">
                      <p className="pl-1">Date</p>
                      <div className="filterIcon" onClick={handleOpenFilter}>
                        <FaFilter />
                      </div>
                    </div>
                    <div>
                      <div className="w-full">
                        <input
                          onBlur={handleSearchParams}
                          name="month"
                          type="date"
                          placeholder="month"
                          className={
                            isOpenFilter
                              ? "inputUser w-full p-1.5 shadow-xl rounded "
                              : "opacity-0  fixed duration-500"
                          }
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <span>Action</span>
                  </th>
                  <th>
                    <span>View</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {listReject}
                {/* More rows */}
              </tbody>
            </table>
          </div>

          {loading == false ? (
            <div className=" flex justify-between p-2   ">
              <div className="bg-green-600 rounded-md shadow-md text-white w-20 py-1 text-center mt-3 bg-opacity-70">
                <p>total : {total}</p>
              </div>
              {/* end Total */}
              <div className="flex gap-3 items-center">
                <select
                  name=""
                  className="border w-20 p-0  rounded-md focus:border-slate-950"
                  onChange={(e) => setPerPage(e.target.value)}
                  id=""
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>

                <div className="flex justify-center  gap-4 ">
                  <ReactPaginate
                    previousLabel="<"
                    nextLabel={">"}
                    // nextClassName='bg-blue-500  hover:bg-blue-600 text-white font-semibold px-2 py-2 rounded-md'
                    breakLabel="..."
                    className="flex  items-center   -space-x-px h-8 text-sm"
                    previousClassName="flex items-center justify-center px-3 h-8 ml-0  border border-gray-300 rounded-l-lg "
                    nextClassName="flex items-center justify-center px-3 h-8 leading-tight text-gray-500  border border-gray-300 rounded-r-lg "
                    pageLinkClassName="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500  border border-gray-300  "
                    breakClassName="w-8 py-2 text-center"
                    pageCount={Math.ceil(total / perPage)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={(event) => {
                      let currentPage = event.selected + 1;
                      dispatch(
                        getRejectPatientAction(
                          locationId,
                          currentPage,
                          perPage,
                          fullname,
                          code,
                          phone,
                          age,
                          gender,
                          note
                        )
                      );
                      setCurrentPage(currentPage);
                    }}
                    activeLinkClassName="bg-black text-white flex  items-center  -space-x-px h-8 text-sm"
                  />
                </div>
              </div>
            </div>
          ) : null}
          {isOpenRejectModal && (
            <ViewRejectModal
              patient_id={patientId}
              closeModal={setIsOpenRejectModal}
            />
          )}
          {/* {isOpenReturnRejectModal && <ReturnRejectModal />} */}
          {isOpenDisapprovedReject && (
            <div className="w-full fixed top-0 left-0 z-20">
              <div
                className="overlay "
                onClick={() => setIsOpenDisapprovedReject(false)}
              ></div>
              <div className="bg-white w-5/12 py-2 absolute top-10 left-[50%] -translate-x-[50%] rounded-md shadow-md  px-3 z-50">
                <div className="flex justify-between items-center">
                  <div>
                    <select
                      onChange={(event) => setVisitId(event.target.value)}
                      name=""
                      className="select"
                      id=""
                    >
                      <option value={-1}>
                        ... تکایە کاتی سەردانیەکە دیاری بکە
                      </option>
                      {rejects.map((reject, index) => {
                        return (
                          <option key={index} value={reject.id}>
                            {reject.created_at.toString().slice(0, 10)}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div>
                    <p
                      className="text-red-500 animate-pulse text-xl"
                      onClick={() => setIsOpenDisapprovedReject(false)}
                    >
                      X
                    </p>
                  </div>
                </div>
                {visitId == null ? (
                  ""
                ) : (
                  <>
                    <div className="text-center pt-4">
                      <p>دڵنیای لە گەڕاندنەوەی زانیاری ئەم نەخۆشە ؟</p>
                    </div>

                    <div className="flex justify-center gap-2 mt-4 ">
                      <button
                        className="rejectBtn"
                        onClick={() => setIsOpenDisapprovedReject(false)}
                      >
                        No,Cancel
                      </button>
                      <button
                        className="assignBtn"
                        onClick={() => {
                          handleDisapporvedReject(visitId, rejectId);
                          setIsOpenDisapprovedReject(false);
                        }}
                      >
                        Yes, I'm sure
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default RejectPatient;
