import React from "react";
import { RxCross1 } from "react-icons/rx";
import { useDispatch } from "react-redux";
import { deleteSentFilesAdminstratorAction } from "../../Action/AdminstratorAction/SentFilesAction";
// import {
//   deleteMedicalSupliesTypesAction,
//   getMedicalSupliesTypesAction,
// } from "../../Action/MedicalSupliesTypesAction/MedicalSupliesTypesAction";

const DeleteModal = ({ id, closeModal }) => {
  const dispatch = useDispatch();
  const handleDelete = () => {
    dispatch(deleteSentFilesAdminstratorAction(id))
    closeModal();
  };
  return (
    <div className="openModal bg-white/30 backdrop-blur-2xl">
      <div className="containerModal lg:w-4/12">
        <div className="head-modal">
          <div></div>
          <div>
            <button onClick={closeModal} className="btn-danger">
              <RxCross1 />
            </button>
          </div>
        </div>

        <div>
          <p className="text-gray-800 text-center text-lg">
            Are You Sure To Delete This Value ?
          </p>
        </div>
        <div className="flex justify-center  w-full">
          <button
            className="btn-primary mx-auto w-7/12 mt-2"
            onClick={handleDelete}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
