import { useDispatch, useSelector } from "react-redux";
import {
  createVisitAction,
  getPatient,
  // rejectPatient,
} from "../../Action/patient";
import ReactPaginate from "react-paginate";
import "./css/invoice.css";
import { useEffect, useState } from "react";
// import { getUserAuth } from "../../Action/userRegister";
import Logo from "../../Image/Logo.jpg";
import { MdAssignmentReturn, MdOutlineAdd } from "react-icons/md";
import { TbPlayerEject } from "react-icons/tb";

import InvoiceModal from "../../Modal/InvoiceModal";
import AssignModal from "../../Modal/AssignModal";
import RejectModal from "../../Modal/RejectModal";

import { getNotificationAction } from "../../Action/receiveAction";
import EditModalPatient from "../../Modal/EditModalPatient";
import { AiFillEye, AiOutlineEdit, AiOutlineUserAdd } from "react-icons/ai";
import { FaFilter, FaUserInjured } from "react-icons/fa";

const NewInvoice = () => {
  const [isOpenEditModalPatient, setIsOpenEditModalPatient] = useState(false);

  const dispatch = useDispatch();

  const getPatients = useSelector((state) => state.getPatients);
  const getNotification = useSelector((state) => state.getNotification);

  const { patients, loading, total } = getPatients;
  const { notifi } = getNotification;
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [reject, setReject] = useState(false);

  localStorage.setItem("notifi", notifi);
  const [idLocation, setIdLocation] = useState(null);
  const [patientId, setPatientId] = useState(null);
  const locationID = localStorage.getItem("locationId");

  useEffect(() => {
    if (locationID == null || locationID == undefined || locationID == "") {
      return;
    }
    dispatch(getNotificationAction(locationID));
  }, [locationID]);
  const [select, setSelect] = useState(1);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenAssignModal, setIsOpenAssignModal] = useState(false);
  const [searchParams, setSearchParams] = useState({
    code: "",
    fullname: "",
    phone: "",
    age: "",
    gender: "",
  });
  const handelReject = (id) => {
    setPatientId(id);
    setReject(true);
  };
  const handelEdit = (event, id) => {
    event.preventDefault();
    setPatientId(id);
    setIsOpenEditModalPatient(true);
  };
  const regex = /^(\d{4})(\d{3})(\d{4})$/;
  useEffect(() => {
    if (locationID == undefined || locationID == null || locationID == "") {
      return;
    } else {
      const { code, fullname, phone, gender, age } = searchParams;

      dispatch(
        getPatient(
          locationID,
          currentPage,
          perPage,
          code,
          fullname,
          phone,
          gender,
          age,
          select
        )
      );
    }
  }, [
    currentPage,
    searchParams.code,
    searchParams.fullname,
    searchParams.phone,
    searchParams.age,
    searchParams.gender,
    select,
    perPage,
  ]);

  const [isOpenFilter, setIsOpentFilter] = useState(false);
  const handleOpenFilter = () => {
    setIsOpentFilter(!isOpenFilter);
  };
 

  const [search, setSearch] = useState({
    code: "",
    fullname: "",
    phone: "",
    age: "",
    gender: "",
  });
  const handleNewVisit = (event, patient_id) => {
    event.preventDefault();
    // console.log(patient_id)
    dispatch(createVisitAction(patient_id));
    const { code, fullname, phone, gender, age } = searchParams;
    dispatch(
      getPatient(
        locationID,
        currentPage,
        perPage,
        code,
        fullname,
        phone,
        gender,
        age,
        1
      )
    );
    dispatch(
      getPatient(
        locationID,
        currentPage,
        perPage,
        code,
        fullname,
        phone,
        gender,
        age,
        1
      )
    );
  };

  return (
    <>
      {loading ? (
        <div className="flex flex-col">
          <img
            src={Logo}
            alt="Logo"
            className="w-56 m-auto animate-pulse mt-20 "
          />
          <div className="text-2xl text-center font-bold  ">
            <div className="flex justify-center gap-4">
              <p className="animate-charcter">Please Wait</p>
              <div class="container2">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>{" "}
            </div>
          </div>
        </div>
      ) : (
        <div className=" containerInvoice ">
          <div className="parentHead">
            <div className="flex gap-2 items-center">
              <p className="text-green-700">
                <FaUserInjured />
              </p>
              <div>
                <p className="capitalize tracking-wider">patient</p>
              </div>
            </div>
            <div className="flex gap-2   w-11/12 justify-end ">
              <button
                className="btnCoustmDesignGreen"
                onClick={() => setIsOpenModal(true)}
              >
                <div className="flex items-center gap-1">
                  <p className="capitalize tracking-wider">patient</p>
                  <MdOutlineAdd className="text-xl font-bold" />
                </div>
              </button>

              {/* <div className="xl:w-1/12">
                <select
                  name=""
                  onChange={(e) => setSelect(e.target.value)}
                  className="select w-full"
                  id=""
                >
                  <option value="1" selected>
                    All
                  </option>
                  <option value="2">Reject</option>
                </select>
              </div> */}
            </div>
          </div>

          <hr />

          <div className="overflow-y-auto h-[69%] xl:h-[80%] rounded-md shadow-md bg-white">
            <table className="table1 ">
              <thead className="sticky top-0">
                <tr>
                  <th className="">
                    <div className="flex justify-between items-center">
                      <p className="pl-1">Patient ID</p>
                      <div className="filterIcon" onClick={handleOpenFilter}>
                        <FaFilter />
                      </div>
                    </div>
                    <div>
                      <div className="w-full">
                        <input
                          onChange={(e) =>
                            setSearch({
                              ...search,
                              code: e.target.value,
                            })
                          }
                          onBlur={() =>
                            setSearchParams({
                              ...searchParams,
                              code: search.code,
                            })
                          }
                          value={search.code}
                          name="code"
                          type="text"
                          placeholder="Code"
                          className={
                            isOpenFilter
                              ? "inputLogin p-1.5 shadow-xl rounded  "
                              : "hidden"
                          }
                        />
                      </div>
                    </div>
                  </th>
                  <th className="">
                    <div className="flex justify-between items-center">
                      <p className="pl-1">Full Name</p>
                      <div className="filterIcon" onClick={handleOpenFilter}>
                        <FaFilter />
                      </div>
                    </div>
                    <div>
                      <div className="w-full">
                        <input
                          onChange={(e) =>
                            setSearch({
                              ...search,
                              fullname: e.target.value,
                            })
                          }
                          onBlur={() =>
                            setSearchParams({
                              ...searchParams,
                              fullname: search.fullname,
                            })
                          }
                          value={search.fullname}
                          name="fullname"
                          type="text"
                          placeholder="Full Name"
                          className={
                            isOpenFilter
                              ? "inputLogin p-1.5 shadow-xl rounded  "
                              : "hidden"
                          }
                        />
                      </div>
                    </div>
                  </th>
                  <th className="">
                    <div className="flex justify-between items-center">
                      <p className="pl-1">Phone</p>
                      <div className="filterIcon  " onClick={handleOpenFilter}>
                        <FaFilter />
                      </div>
                    </div>
                    <div>
                      <div className="w-full">
                        <input
                          onChange={(e) =>
                            setSearch({
                              ...search,
                              phone: e.target.value,
                            })
                          }
                          onBlur={() =>
                            setSearchParams({
                              ...searchParams,
                              phone: search.phone,
                            })
                          }
                          value={search.phone}
                          name="phone"
                          type="text"
                          placeholder="Phone Number"
                          className={
                            isOpenFilter
                              ? "inputLogin p-1.5 shadow-xl rounded  "
                              : "hidden"
                          }
                        />
                      </div>
                    </div>
                  </th>
                  <th className="">
                    {" "}
                    <div className="flex justify-between items-center">
                      <p className="pl-1">Gender</p>
                      <div className="filterIcon" onClick={handleOpenFilter}>
                        <FaFilter />
                      </div>
                    </div>
                    <div>
                      <div className="w-full">
                        <select
                      
                          onChange={(e) =>
                            setSearchParams({
                              ...searchParams,
                              gender: e.target.value,
                            })
                          }
                          name="gender"
                          type="text"
                          placeholder="Gender"
                          className={
                            isOpenFilter
                              ? "inputLogin p-0 shadow-xl rounded  "
                              : "hidden"
                          }
                        >
                          <option value="">ڕەگەز هەڵبژێرە</option>
                          <option value="Male">Male </option>
                          <option value="Female">Female</option>
                        </select>
                      </div>
                    </div>
                  </th>
                  <th className="">
                    {" "}
                    <div className="flex justify-between items-center">
                      <p className="pl-1">Birth Date</p>
                      <div className="filterIcon" onClick={handleOpenFilter}>
                        <FaFilter />
                      </div>
                    </div>
                    <div>
                      <div className="w-full">
                        <input
                          onChange={(e) =>
                            setSearch({
                              ...search,
                              age: e.target.value,
                            })
                          }
                          onBlur={() =>
                            setSearchParams({
                              ...searchParams,
                              age: search.age,
                            })
                          }
                          value={search.age}
                          name="age"
                          type="text"
                          placeholder="Birth Date"
                          className={
                            isOpenFilter
                              ? "inputLogin p-1.5 shadow-xl rounded  "
                              : "hidden"
                          }
                        />
                      </div>
                    </div>
                  </th>
                  <th>Date</th>
                  <th className="">
                    <p className="pl-1">Visits</p>
                  </th>
                  <th className="">Action</th>
                </tr>
              </thead>

              <tbody className="w-full">
                {patients.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      className={
                        item.reject == 2
                          ? "tableRowRject  "
                          : item.assign == 1
                          ? "tableRowSave"
                          : item.assign == 2
                          ? "tableRowGreen"
                          : "tableRow"
                      }
                    >
                      <td className="w-80 ">{item.code}</td>
                      <td className="w-80 ">{item.Pname}</td>
                      <td className="w-80 ">
                        {item.phone.replace(regex, "$1-$2-$3")}
                      </td>
                      <td className="w-80 ">{item.gender}</td>
                      <td className="w-80 ">{item.birthDate}</td>
                      <td className="w-80 ">{item.created_at.toString().slice(0,10)}</td>
                      <td className="w-80  text-red-500 font-bold text-xl text-decoration underline">
                        {item.visits_with_reject_count == 0 ? 1 : item.visits_with_reject_count}
                      </td>

                      <td className="w-80 ">
                        <div className="flex flex-col  lg:flex lg:flex-row lg:gap-3 justify-center">
                          <div data-te-toggle="tooltip" title="Assign">
                            <button
                              //  class="transititext-primary text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600
                              className={
                                item.reject == 2
                                  ? "assignBtn  cursor-not-allowed"
                                  : item.assign == 1 || item.assign == 2
                                  ? "hidden"
                                  : "assignBtn  "
                              }
                              disabled={item.reject == 2 ? true : false}
                              onClick={() => {
                                setIsOpenAssignModal(true);
                                setIdLocation(item.location_patinet);
                                setPatientId(item.id);
                                item.visit.map((visit) => {
                                  // console.log(visit.id);
                                });
                              }}
                            >
                              <MdAssignmentReturn className="rotate-180" />
                            </button>
                          </div>

                          <div data-te-toggle="tooltip" title="New Visit">
                            {item.assign == 2 ? (
                              <button
                                className="editBtn"
                                onClick={(event) => {
                                  handleNewVisit(event, item.id);
                                }}
                              >
                                <AiOutlineUserAdd />
                              </button>
                            ) : (
                              ""
                            )}
                          </div>

                          <div data-te-toggle="tooltip" title="Reject">
                            {item.assign == 1 && (
                              <button
                                className={
                                  item.reject == 2 
                                    ? "rejectBtn cursor-not-allowed"
                                    : "rejectBtn"
                                }
                                disabled={item.reject == 2 ? true : false}
                                onClick={(event) => {
                                  event.preventDefault();
                                  handelReject(item.id);
                                }}
                              >
                                <TbPlayerEject />
                              </button>
                            )}
                          </div>
                          <div data-te-toggle="tooltip" title="Edit/View">
                            {item.assign == 1 || item.assign == 2 ? (
                              <button
                                className="editBtn"
                                onClick={(event) => {
                                  handelEdit(event, item.id);
                                }}
                              >
                                {item.assign == 2 ? (
                                  <AiFillEye />
                                ) : (
                                  <AiOutlineEdit />
                                )}
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="gap-3 px-3 flex justify-between items-center  pt-2  ">
            <div className="flex items-center gap-4 ">
              <p className="text-xl ">Total: </p>
              <div
                className={
                  select == 1
                    ? "bg-teal-600 px-4 py-0.5 text-white rounded-md shadow-md "
                    : "bg-red-600 px-4 py-0.5 text-white rounded-md shadow-md"
                }
              >
                {total}
              </div>
            </div>
            <div className="flex items-center gap-4">
              <div>
                <select
                  name=""
                  className="mt-1  bg-white border w-20 focus:ring-2  focus:ring-slate-900 rounded-md   focus:outline-none"
                  onChange={(e) => setPerPage(e.target.value)}
                  value={perPage}
                  id=""
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>

              <div className="flex justify-center   mt-1">
                <ReactPaginate
                  previousLabel="<"
                  nextLabel={">"}
                  // nextClassName='bg-blue-500  hover:bg-blue-600 text-white font-semibold px-2 py-2 rounded-md'
                  breakLabel="..."
                  className="flex  items-center   -space-x-px h-8 text-sm"
                  previousClassName="flex items-center justify-center px-3 h-8 ml-0  border border-gray-300 rounded-l-lg "
                  nextClassName="flex items-center justify-center px-3 h-8 leading-tight text-gray-500  border border-gray-300 rounded-r-lg "
                  pageLinkClassName="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500  border border-gray-300  "
                  breakClassName="w-8 py-2 text-center"
                  pageCount={Math.ceil(total / perPage)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  // activeClassName=''
                  onPageChange={(event) => {
                    let currentPage = event.selected + 1;
                    dispatch(
                      getPatient(
                        locationID,
                        currentPage,
                        perPage,
                        "",
                        "",
                        "",
                        "",
                        "",
                        1
                      )
                    );
                    setCurrentPage(currentPage);
                  }}
                  
                  activeLinkClassName='bg-black text-white flex  items-center  -space-x-px h-8 text-sm'
                  
                />
              </div>
            </div>
          </div>

          {isOpenModal && (
            <InvoiceModal closeModal={setIsOpenModal} locationID={locationID} />
          )}

          <div className="overflow-y-auto   ">
            {isOpenAssignModal && (
              <AssignModal
                closeModal={setIsOpenAssignModal}
                isOpenAssign={isOpenAssignModal}
                patientId={patientId}
              />
            )}
          </div>

          <div className="overflow-y-auto  ">
            {reject && (
              <RejectModal
                closeModal={setReject}
                isOpenReject={reject}
                patientId={patientId}
              />
            )}
          </div>

          <div className="overflow-y-auto   ">
            {isOpenEditModalPatient && (
              <EditModalPatient
                closeModal={setIsOpenEditModalPatient}
                openEditModal={isOpenEditModalPatient}
                patientId={patientId}
              />
            )}
          </div>
        </div>
      )}

      <div className="pb-20"></div>
    </>
  );
};

export default NewInvoice;
