import {
  CREATE_ORGAN_LEVEL_FAIL,
  CREATE_ORGAN_LEVEL_REQUEST,
  CREATE_ORGAN_LEVEL_SUCCESS,
  CREATE_SURGERY_FAIL,
  CREATE_SURGERY_REQUEST,
  CREATE_SURGERY_SUCCESS,
  FETCH_SURGERY_FAIL,
  FETCH_SURGERY_REQUEST,
  FETCH_SURGERY_SUCCESS,
  FETCH_TYPE_SURGERY_FAIL,
  FETCH_TYPE_SURGERY_REQUEST,
  FETCH_TYPE_SURGERY_SUCCESS,
} from "../../ActionType/Surgery/surgeryType";
import {
  FETCH_ALL_TYPE_SURGERY_FAIL,
  FETCH_ALL_TYPE_SURGERY_REQUEST,
  FETCH_ALL_TYPE_SURGERY_SUCCESS,
  FETCH_ORGAN_LEVEL_FAIL,
  FETCH_ORGAN_LEVEL_REQUEST,
  FETCH_ORGAN_LEVEL_SUCCESS,
} from "../../ActionType/SurgeryType/surgery";

export const getSurgeryReducer = (
  state = { loading: true, surgery: [], error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_SURGERY_REQUEST:
      return {
        loading: true,
      };
    case FETCH_SURGERY_SUCCESS:
      return {
        loading: false,
        surgery: action.payload,
        error: "",
      };
    case FETCH_SURGERY_FAIL:
      return {
        loading: false,
        surgery: [],
        error: "Please Check Your Request",
      };
    default:
      return state;
  }
};

export const getTypeSurgeryReducer = (
  state = { loading: true, typeSurgery: [], error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_TYPE_SURGERY_REQUEST:
      return {
        loading: true,
      };
    case FETCH_TYPE_SURGERY_SUCCESS:
      return {
        loading: false,
        typeSurgery: action.payload,
        error: "",
      };
    case FETCH_TYPE_SURGERY_FAIL:
      return {
        loading: false,
        typeSurgery: [],
        error: "Please Check Your Request",
      };
    default:
      return state;
  }
};

export const createSurgeryReducer = (
  state = { loading: true, surgery: [], error: "" },
  action
) => {
  switch (action.type) {
    case CREATE_SURGERY_REQUEST:
      return {
        loading: true,
      };
    case CREATE_SURGERY_SUCCESS:
      return {
        loading: false,
        surgery: action.payload,
        error: "",
      };
    case CREATE_SURGERY_FAIL:
      return {
        loading: false,
        surgery: [],
        error: "Please Check Your Request",
      };
    default:
      return state;
  }
};

export const createOrganLevelReducer = (
  state = { loading: true, organLevel: [], error: "" },
  action
) => {
  switch (action.type) {
    case CREATE_ORGAN_LEVEL_REQUEST:
      return {
        loading: true,
      };
    case CREATE_ORGAN_LEVEL_SUCCESS:
      return {
        loading: false,
        surgery: action.payload,
        error: "",
      };
    case CREATE_ORGAN_LEVEL_FAIL:
      return {
        loading: false,
        surgery: [],
        error: "Please Check Your Request",
      };
    default:
      return state;
  }
};

export const getAllTypeSurgeryReducer = (
  state = { loading: true, typeSurgery: [], error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_ALL_TYPE_SURGERY_REQUEST:
      return {
        loading: true,
      };
    case FETCH_ALL_TYPE_SURGERY_SUCCESS:
      return {
        loading: false,
        typeSurgery: action.payload,
        error: "",
      };
    case FETCH_ALL_TYPE_SURGERY_FAIL:
      return {
        loading: false,
        typeSurgery: [],
        error: "Please Check Your Request",
      };
    default:
      return state;
  }
};

export const getOrganLevelReducer = (
  state = { loading: true, organLevel: [], error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_ORGAN_LEVEL_REQUEST:
      return {
        loading: true,
      };
    case FETCH_ORGAN_LEVEL_SUCCESS:
      return {
        loading: false,
        organLevel: action.payload,
        error: "",
      };
    case FETCH_ORGAN_LEVEL_FAIL:
      return {
        loading: false,
        organLevel: [],
        error: "Please Check Your Request",
      };
    default:
      return state;
  }
};
